import { Grid, Paper, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import PunctualityTripChart from "./components/punctualityTripsChart";
import TimeSlotsChart from "./components/timeSlotsChart";
import AvgDistanceChart from "./components/avgDistanceChart";
import TotalTripsChart from "./components/totalTripsChart";
import OriginDistanceChart from "./components/originDistanceChart";

export default function TripsKPI() {
    const classes = useStyles()
    const servicesLoading = useSelector(state => state.serviceReducer.pending)
    const type = useSelector(state => state.kpiFiltersReducer.type)

    return (
        <section className={classes.wrapper}>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TotalTripsChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <PunctualityTripChart isLoading={servicesLoading} groupedBy={type} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <TimeSlotsChart isLoading={servicesLoading} />
                    </Paper>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <AvgDistanceChart isLoading={servicesLoading} groupedBy={type} />
                    </Paper>
                </Grid>
                {/* <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <OriginDistanceChart isLoading={servicesLoading} groupedBy={type} />
                    </Paper>
                </Grid> */}
            </Grid>
        </section>
    )
}

const useStyles = makeStyles({
    paper: {
        padding: "10px",
        minHeight: "250px"
    },

    paddingGraph: {
        padding: "10px 20px"
    },
    wrapper: {
        display: "grid",
        gap: "5px"
    }
})