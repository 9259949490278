import { customerConstants } from '../../constants/customer_constants';
import { userConstants } from '../../constants/user_constants';
import { operatorService } from '../../services/operatorService';
import { history } from '../../helpers/history'
import { customerService } from '../../services/customerService';
import { alertActions } from '../actions/alert_actions';
import { userActions } from '../actions/user_actions';
import { factory } from '../../helpers/factory'
import i18next from 'i18next';
import { userService } from '../../services/userService';
import { ERROR_CODES } from '../../constants/types';

const DETAIL_ERROR_CODES = {
    EmailAlreadyExists: "EmailAlreadyExists",
    UserEmailAlreadyExists: "UserEmailAlreadyExists",
    DocumentCodeAlreadyExists: "DocumentCodeAlreadyExists",
    ResetPasswordEmailNotSent: "ResetPasswordEmailNotSent"
}

export const customerActions = {
    getAll,
    getById,
    getKPI,
    insert,
    update,
    remove,
    addRelatedCustomer,
    editRelatedCustomer,
    removeRelatedCustomer,
    openModal,
    setCustomer,
    forgotPassword,
    sendVerifyEmail,
    checkProfile,
    getPersonalInfo,
    getDocumentTypes,
    forgotPasswordCheckingIfIsCustomer,
    updatePassword,
    updateUserPasswordFromEmailChange,
    customerAddress,
    enable,
    disable,
    setCurrentPage,
    cleanRelatedCustomer,
    refreshCustomerRequestTrip,
    getResetPasswordLink,
    saveCustomerFilters
};

function updatePassword(email, newPassword, urlRedirect) {
    return dispatch => {
        dispatch(request())
        customerService.updatePassword(email, newPassword).then(
            result => {
                dispatch(success())
                dispatch(alertActions.success(i18next.t('updatePassword.page.successUpdate')))
                if (urlRedirect != '') {
                    setTimeout(function () {
                        window.location = urlRedirect
                    }, 1800)
                }
            },
            error => {
                dispatch(failure(error))
                let detailError = ''
                let status = ''
                try {
                    let parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    status = parsedError.status;
                } catch (e) {
                    detailError = i18next.t('updatePassword.page.errorUpdate')
                }

                if (status === 400) {
                    switch (detailError) {
                        case 'PasswordAlreadyUsed':
                            detailError = i18next.t('updatePassword.page.passwordAlreadyUsed')
                            break;
                        default:
                            detailError = detailError
                    }
                    dispatch(alertActions.error(detailError));
                } else {
                    dispatch(alertActions.error(detailError));
                }
            }
        )
    }

    function request() {
        return { type: userConstants.UPDATE_PSW_REQUEST }
    }

    function success() {
        return { type: userConstants.UPDATE_PSW_SUCCESS }
    }

    function failure(error) {
        return { type: userConstants.UPDATE_PSW_FAILURE, error }
    }

}

function updateUserPasswordFromEmailChange(email, newPassword, urlRedirect) {
    return dispatch => {
        dispatch(request())
        customerService.updateUserPasswordFromEmailChange(email, newPassword).then(
            result => {
                dispatch(success())
                dispatch(alertActions.success(i18next.t('updatePassword.page.successUpdate')))
                if (urlRedirect != '') {
                    setTimeout(function () {
                        window.location = urlRedirect
                    }, 1800)
                }
            },
            error => {
                dispatch(failure(error))
                let detailError = ''
                let status = ''
                try {
                    let parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    status = parsedError.status;
                } catch (e) {
                    detailError = i18next.t('updatePassword.page.errorUpdate')
                }

                if (status === 400) {
                    switch (detailError) {
                        case 'PasswordAlreadyUsed':
                            detailError = i18next.t('updatePassword.page.passwordAlreadyUsed')
                            break;
                        default:
                            detailError = detailError
                    }
                    dispatch(alertActions.error(detailError));
                } else {
                    dispatch(alertActions.error(detailError));
                }
            }
        )
    }

    function request() {
        return { type: userConstants.UPDATE_PSW_REQUEST }
    }

    function success() {
        return { type: userConstants.UPDATE_PSW_SUCCESS }
    }

    function failure(error) {
        return { type: userConstants.UPDATE_PSW_FAILURE, error }
    }

}


function cleanRelatedCustomer() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: customerConstants.CUSTOMER_CLEAN_RELATED }
    }
}


function getAll() {
    return dispatch => {
        dispatch(request());
        customerService.getAll().then(
            results => {
                dispatch(success(results));
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        dispatch(userActions.refreshToken())
                        dispatch(getAll())
                        break;
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.customers.forbiddenGetAll')))
                        break;
                    default:
                        dispatch(alertActions.error(i18next.t('services.customers.getAllError')))
                }
                dispatch(failure(error));
            }
        )
    }

    function request() {
        return { type: customerConstants.GETALL_REQUEST }
    }

    function success(results) {
        return { type: customerConstants.GETALL_SUCCESS, results }
    }

    function failure(error) {
        return { type: customerConstants.GETALL_FAILURE, error }
    }
}


function customerAddress() {
    return dispatch => {
        dispatch(request());
        customerService.customerAddress().then(
            results => {
                const typ = makeEnum(results);
                dispatch(success(typ));
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(customerAddress())
                        break;
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.customers.forbiddenGetAll')))
                        break;
                    default:
                        break;
                }
                dispatch(failure(error));
            }
        )
    }

    function request() {
        return { type: customerConstants.CUSTOMER_ADDRESSES_REQUEST }
    }

    function success(results) {
        return { type: customerConstants.CUSTOMER_ADDRESSES_SUCCESS, results }
    }

    function failure(error) {
        return { type: customerConstants.CUSTOMER_ADDRESSES_FAILURE, error }
    }
}

function checkProfile(idCustomer) {
    return dispatch => {
        dispatch(request());
        customerService.canPerformTripFromProfileData(idCustomer).then(
            result => {
                dispatch(success(result));
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(checkProfile(idCustomer))
                }
                let detailError = ''
                let status = ''
                try {
                    let parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    status = parsedError.status;
                } catch (e) {
                    detailError = i18next.t('services.customers.errorOccursPetition')
                }

                if (status === 400) {
                    switch (true) {
                        case detailError.includes('CustomerDisabled'):
                            detailError = i18next.t('services.customers.CustomerDisabled')
                            break;
                        case detailError.includes('RequiredFieldsMissing'):
                            detailError = i18next.t('services.customers.RequiredFieldsMissing')
                            break;
                        case detailError.includes('CustomerNotFound'):
                            detailError = i18next.t('services.customers.getByIdError')
                            break;
                        case detailError.includes('InvalidAge'):
                            detailError = i18next.t('services.customers.InvalidAge')
                            break;
                        case detailError.includes('InvalidDocument'):
                            detailError = i18next.t('services.customers.InvalidDocument')
                            break;
                        case detailError.includes('DocumentCodeAlreadyExists'):
                            detailError = i18next.t('services.customers.repeatDoc')
                            break;
                        default:
                            detailError = detailError
                    }
                } else {
                    detailError = i18next.t('services.customers.RemovedCustomer')
                }
                dispatch(alertActions.error(detailError));
                dispatch(success(false))
                dispatch(failure(error));

            }
        )
    }

    function request() {
        return { type: customerConstants.CHECK_PROFILE_REQUEST }
    }

    function success(result) {
        return { type: customerConstants.CHECK_PROFILE_SUCCESS, result }
    }

    function failure(error) {
        return { type: customerConstants.CHECK_PROFILE_FAILURE, error }
    }
}

function getById(id, redirect) {
    return dispatch => {
        dispatch(request(id));
        customerService.getById(id).then(
            customer => {
                customer.birthDate = factory.formatDate(customer.birthDate, "yyyy-MM-DD")
                customer.relatedCustomers && customer.relatedCustomers.forEach(element => {
                    element.birthDate = factory.formatDate(element.birthDate, "yyyy-MM-DD")
                });
                dispatch(success(customer));
                if (redirect) {
                    history.push('/customers/' + id)
                }
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getById(id))
                        break;
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.customers.forbiddenGetById')))
                        break;

                    default:
                        dispatch(alertActions.error(i18next.t('services.customers.getByIdError')))
                }
                dispatch(failure(error));

            }
        )
    }

    function request(id) {
        return { type: customerConstants.GETBYID_REQUEST, id }
    }

    function success(customer) {
        return { type: customerConstants.GETBYID_SUCCESS, customer }
    }

    function failure(error) {
        return { type: customerConstants.GETBYID_FAILURE, error }
    }
}

function getKPI() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request())
            customerService.getKPI().then(
                results => {
                    dispatch(success(results))
                    resolve(results)
                },
                error => {
                    console.log("error in getCustomersKPI (actions) =>", error)
                    if (error === 401 && userService.refreshToken()) {
                        dispatch(userActions.refreshToken())
                        dispatch(getKPI())
                    } else {
                        dispatch(
                            alertActions.error(
                                i18next.t("services.customers.getKpiError")
                            ))
                        dispatch(failure(error))
                        reject(error)
                    }
                }
            )
        })
    }

    function request() {
        return { type: customerConstants.CUSTOMER_GET_KPI_REQUEST }
    }

    function success(results) {
        return { type: customerConstants.CUSTOMER_GET_KPI_SUCCESS, results }
    }

    function failure(error) {
        return { type: customerConstants.CUSTOMER_GET_KPI_FAILURE, error }
    }
}

function update(customer) {
    return dispatch => {
        dispatch(request());
        customerService.update(customer).then(
            customer => {
                dispatch(success(customer));
                history.push('/customers');
                dispatch(alertActions.success(i18next.t('services.customers.updateSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(update(customer))
                        break;
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.customers.forbiddenUpdate')))
                        break;
                    default:
                        try {
                            let parsedError = JSON.parse(error);
                            let detailError = parsedError.detail;
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.EmailAlreadyExists:
                                    dispatch(alertActions.error(i18next.t('services.customers.repeatEmail')))
                                    break
                                case DETAIL_ERROR_CODES.UserEmailAlreadyExists:
                                    dispatch(alertActions.error(i18next.t('services.customers.UserEmailAlreadyExists')))
                                    break
                                case DETAIL_ERROR_CODES.DocumentCodeAlreadyExists:
                                    dispatch(alertActions.error(i18next.t('services.customers.repeatDoc')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.customers.updateError')))
                            }
                        }
                        catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error));
            }
        )
    }

    function request() {
        return { type: customerConstants.UPDATE_REQUEST }
    }

    function success(customer) {
        return { type: customerConstants.UPDATE_SUCCESS, customer }
    }

    function failure(error) {
        return { type: customerConstants.UPDATE_FAILURE, error }
    }

}

function forgotPassword(email) {
    return dispatch => {
        dispatch(request())
        operatorService.checkSupportUser(email.toLowerCase()).then(
            result => {
                if (result) {
                    let lng = i18next.language
                    let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE
                    customerService.sendResetPasswordEmail(email.toLowerCase(), lng, url).then(
                        data => {
                            dispatch(success(data))
                            dispatch(alertActions.success("Revise su correo electrónico para restaurar la password"))
                        },
                        error => {
                            dispatch(failure(error))
                            dispatch(alertActions.error("Ha ocurrido un error y no se puede restaurar password"))
                        }
                    )
                } else {
                    dispatch(alertActions.error("Ha ocurrido un error y no se puede restaurar password"))
                }
            }
        )

    }

    function request() {
        return { type: customerConstants.RESET_PSW_REQUEST }
    }
    function success(user) {
        return { type: customerConstants.RESET_PSW_SUCCESS, user }
    }
    function failure(error) {
        return { type: customerConstants.RESET_PSW_FAILURE, error }
    }
}

function forgotPasswordCheckingIfIsCustomer(email) {
    return dispatch => {
        dispatch(request())
        customerService.checkCustomer(email).then(
            result => {
                if (result) {
                    let lng = i18next.language
                    let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE
                    customerService.sendResetPasswordEmail(email, lng, url).then(
                        data => {
                            dispatch(success(data))
                            dispatch(alertActions.success(i18next.t("services.login.resendPasswordToEmailSuccess")))
                        },
                        error => {
                            dispatch(failure(error))
                            dispatch(alertActions.error(i18next.t("services.login.resendPasswordToEmailError")))
                        }
                    )
                } else {
                    dispatch(alertActions.error(i18next.t("services.login.resendPasswordToEmailSuccess")))
                }
            }
        )

    }

    function request() {
        return { type: customerConstants.RESET_PSW_REQUEST }
    }
    function success(user) {
        return { type: customerConstants.RESET_PSW_SUCCESS, user }
    }
    function failure(error) {
        return { type: customerConstants.RESET_PSW_FAILURE, error }
    }
}

function sendVerifyEmail(email) {
    return dispatch => {
        customerService.sendVerifyEmail(email).then(
            result => {

            }
        )
    }
}


function insert(customer) {
    return dispatch => {
        dispatch(request());
        let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE_CUSTOMER
        customerService.insert(customer, url).then(
            customer => {
                dispatch(success(customer));
                history.push('/customers');
                dispatch(alertActions.success(i18next.t('services.customers.insertSuccess')))
            },
            error => {

                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(insert(customer))
                        break;
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.customers.forbiddenInsert')))
                        break;
                    default:
                        try {
                            let parsedError = JSON.parse(error);
                            let detailError = parsedError.detail;
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.EmailAlreadyExists:
                                    dispatch(alertActions.error(i18next.t('services.customers.repeatEmail')))
                                    break
                                case DETAIL_ERROR_CODES.USER_EMAIL_ALREADY_EXISTS:
                                    dispatch(alertActions.error(i18next.t('services.customers.UserEmailAlreadyExists')))
                                    break
                                case DETAIL_ERROR_CODES.DOCUMENT_CODE_ALREADY_EXISTS:
                                    dispatch(alertActions.error(i18next.t('services.customers.repeatDoc')))
                                    break
                                case DETAIL_ERROR_CODES.ResetPasswordEmailNotSent:
                                    dispatch(alertActions.error(i18next.t('services.customers.sendEmailError')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.customers.insertError')))
                            }
                        }
                        catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error));

            }
        )
    }

    function request() {
        return { type: customerConstants.ADD_REQUEST }
    }

    function success(area) {
        return { type: customerConstants.ADD_SUCCESS, area }
    }

    function failure(error) {
        return { type: customerConstants.ADD_FAILURE, error }
    }

}

function setCustomer(id, countMax, fullName, address, favouriteAddresses, name, surname, customerDateOfBirth, relatedCustomers, lang, pushToken, hasWheelChair, themeColor,) {
    return dispatch => {
        let related = [{
            customerId: id,
            name: fullName.trim(),
            isOnRelatedCustomerBehalf: false,
            birthDate: customerDateOfBirth,
            hasWheelChair: hasWheelChair
        }]
        relatedCustomers.forEach(element => {
            related.push({
                customerId: id,
                customerParentName: fullName.trim(),
                customerIdRelated: element.id,
                color: themeColor,
                isOnRelatedCustomerBehalf: true,
                name: (element.name + " " + element.surname1 + " " + element.surname2).trim(),
                birthDate: element.birthDate,
                parentBirthDate: customerDateOfBirth,
                hasWheelChair: element.hasWheelChair
            })
        });
        dispatch(request(id, countMax, fullName, address, favouriteAddresses, name, surname, customerDateOfBirth, related, lang, pushToken, hasWheelChair))
        if (related.length == 1) {
            dispatch(setOnlyOneCustomer(related[0]))
        }
    }

    function request(id, countMax, fullName, address, favouriteAddresses, name, surname, customerDateOfBirth, relatedCustomers, lang, pushToken, hasWheelChair) {
        return { type: customerConstants.SET_CUSTOMER, id, countMax, fullName, address, favouriteAddresses, name, surname, customerDateOfBirth, relatedCustomers, lang, pushToken, hasWheelChair }
    }

    function setOnlyOneCustomer(customer) {
        return { type: customerConstants.SET_ONLYONE_CUSTOMER, customer }
    }
}

function refreshCustomerRequestTrip(id) {
    return dispatch => {
        dispatch(request())
        customerService.getById(id).then(
            customer => {
                dispatch(success(customer))
                history.push('/requestTrip')
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request() {
        return { type: customerConstants.GETBYID_REQUEST };
    }

    function success(customer) {
        return { type: customerConstants.REFRESH_CUSTOMER, customer };
    }

    function failure(error) {
        return { type: customerConstants.GETBYID_FAILURE, error };
    }
}

function enable(id) {
    return (dispatch) => {
        dispatch(request());
        customerService.enable(id).then(
            (customer) => {
                dispatch(success(id));
                dispatch(
                    alertActions.success(i18next.t("services.customers.enableSuccess"))
                );
                dispatch(getAll())
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(enable(id))
                        break;
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.customers.forbiddenUpdate')))
                        break;
                    default:
                        break;
                }
                dispatch(failure(error));
                dispatch(alertActions.error(i18next.t("services.customers.enableError")));
            }
        );
    };

    function request() {
        return { type: customerConstants.CUSTOMER_ENABLED_REQUEST };
    }

    function success(id) {
        return { type: customerConstants.CUSTOMER_ENABLED_SUCCESS, id };
    }

    function failure(error) {
        return { type: customerConstants.CUSTOMER_ENABLED_FAILURE, error };
    }
}

function disable(id) {
    return (dispatch) => {
        dispatch(request());
        customerService.disable(id).then(
            (customer) => {
                dispatch(success(id));
                dispatch(
                    alertActions.success(i18next.t("services.customers.disableSuccess"))
                );
                dispatch(getAll())
            },
            (error) => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(disable(id))
                        break;
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.customers.forbiddenUpdate')))
                        break;
                    default:
                        break;

                }
                dispatch(failure(error));
                dispatch(
                    alertActions.error(i18next.t("services.customers.disableError"))
                );
            }
        );
    };

    function request() {
        return { type: customerConstants.CUSTOMER_DISABLED_REQUEST };
    }

    function success(id) {
        return { type: customerConstants.CUSTOMER_DISABLED_SUCCESS, id };
    }

    function failure(error) {
        return { type: customerConstants.CUSTOMER_DISABLED_FAILURE, error };
    }
}

function setCurrentPage(page) {
    return dispatch => {
        dispatch(request(page))
    }

    function request(page) {
        return { type: customerConstants.CUSTOMER_SET_PAGE_TABLE, page };
    }
}


function remove(id) {
    return dispatch => {
        dispatch(request());
        customerService._delete(id).then(
            customer => {
                dispatch(success(id));
                dispatch(alertActions.success(i18next.t('services.customers.deleteSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(remove(id))
                        break;
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.customers.forbiddenDelete')))
                        break;
                    default:
                        //dispatch(alertActions.error(error.toString()))
                        dispatch(alertActions.error(i18next.t('services.customers.deleteError')))
                }
                dispatch(failure(error));

            }
        )
    }

    function request() {
        return { type: customerConstants.DELETE_REQUEST }
    }

    function success(id) {
        return { type: customerConstants.DELETE_SUCCESS, id }
    }

    function failure(error) {
        return { type: customerConstants.DELETE_FAILURE, error }
    }

}

function addRelatedCustomer(customer) {
    return dispatch => {
        dispatch(request(customer))
    }

    function request(customer) {
        return { type: userConstants.ADD_RELATEDCUSTOMER, customer }
    }
}

function editRelatedCustomer(customer) {
    return dispatch => {
        dispatch(request(customer))
    }

    function request(customer) {
        return { type: userConstants.EDIT_RELATEDCUSTOMER, customer }
    }
}

function removeRelatedCustomer(customer) {
    return dispatch => {
        dispatch(request(customer))
    }

    function request(customer) {
        return { type: userConstants.REMOVE_RELATEDCUSTOMER, customer }
    }
}

function openModal(typeOperation, user = null) {
    return dispatch => {
        dispatch(request(typeOperation))

    }
    function request(typeOperation) {
        return { type: userConstants.OPEN_MODAL, typeOperation, user }
    }
}

function getPersonalInfo(customer, email, lang) {
    return dispatch => {
        dispatch(request())
        customerService.getPersonalInfo(customer, email, lang).then(
            data => {
                console.log("data getPersonalInfo => ", data);
                dispatch(success(data));
                dispatch(alertActions.success(i18next.t("customers.form.page.personalData.GDPRPetitionMessageOk")))
            },
            error => {
                let errorParsed = i18next.t('services.customers.errorOccursPetition');
                try {
                    errorParsed = JSON.parse(error);
                    if (errorParsed.detail.includes("GDPRRequestOutOfHours") && errorParsed.status == 400) {
                        dispatch(alertActions.warning(i18next.t("customers.form.page.personalData.GDPRPetitionMessageOutOfHours")))
                    } else {
                        dispatch(alertActions.error(i18next.t('services.customers.errorOccursPetition')));
                    }
                } catch (e) {
                    dispatch(alertActions.error(i18next.t('services.customers.errorOccursPetition')));
                }
                if (error == "401") {
                    dispatch(userActions.refreshToken())
                    setTimeout(() => {
                        dispatch(getPersonalInfo())
                    }, 1500);
                }
                dispatch(failure(errorParsed));
            }
        )
    }

    function request() {
        return { type: userConstants.GET_PERSONAL_INFO_REQUEST }
    }
    function success(data) {
        return { type: userConstants.GET_PERSONAL_INFO_SUCCESS, data }
    }
    function failure(error) {
        return { type: userConstants.GET_PERSONAL_INFO_FAILURE, error }
    }
}



function getDocumentTypes() {
    return (dispatch) => {
        dispatch(request())
        customerService.getDocumentTypes().then(
            dt => {
                const documentTypes = makeEnum(dt)
                dispatch(success(documentTypes))
            },
            error => {
                if (error === 401 && customerService.existRefreshToken()) {
                    dispatch(customerActions.refreshToken())
                    dispatch(getDocumentTypes())
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: customerConstants.GET_DOCUMENT_TYPES_REQUEST }
    }
    function success(data) {
        return { type: customerConstants.GET_DOCUMENT_TYPES_SUCCESS, data }
    }
    function failure(error) {
        return { type: customerConstants.GET_DOCUMENT_TYPES_FAILURE, error }
    }
}

function makeEnum(enumObject) {
    var all = [];
    for (var key in enumObject) {
        all.push({
            id: key,
            name: enumObject[key],
        });
    }
    return all;
}


function getResetPasswordLink(email) {
    return (dispatch) => {
        let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE;
        customerService.getResetPasswordLink(email, url).then(
            link => {
                navigator.clipboard.writeText(link).then(
                    success => {
                        dispatch(alertActions.success(i18next.t('services.customers.getLinkRecoverySuccess')))
                    },
                    error => {
                        dispatch(alertActions.error(i18next.t('services.customers.getLinkRecoveryError')))
                    }
                )
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.customers.getLinkRecoveryError')))
            }
        )
    }
}

function saveCustomerFilters(tableState) {
    return (dispatch) => {
        dispatch(save(tableState))
    }

    function save(tableState) {
        return { type: customerConstants.SAVE_TABLE_STATE, tableState}
    }
}