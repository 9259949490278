import React, { useMemo } from "react";
import Chart from "react-google-charts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import i18next from "i18next";
import { getTripsKPI } from "../../../../../helpers/kpiHelper";

export default function AvgDistanceChart({ isLoading, groupedBy }) {
    const { t } = useTranslation();
    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)
    const { type } = useSelector(state => state.kpiFiltersReducer)

    const { totalTrips, data, totalKM } = useMemo(() => {
        if (!confirmTrips) return { totalTrips: 0, data: [], totalKM: 0 }
        const data = [
            [t('commandBox.' + groupedBy?.toLowerCase()), t('commandBox.trips.avgDistance.AVG-Distance')]
        ]
        const { totalTrips, data: dataTrips } = getTripsKPI(confirmTrips, type)

        let totalKM = 0

        if (dataTrips) {
            data.push(...Object.entries(dataTrips).map(e => e != null && [e[0], e[1].distance]))
            totalKM = Object.entries(dataTrips).reduce((acc, item) => {
                return acc + item[1].distance
            }, 0)
        }
        return { totalTrips, data, totalKM }
    }, [confirmTrips, type])


    isLoading = isLoading || isConfirmTripsLoading
    const displayNoData = !isLoading && (!totalTrips || totalTrips == 0)

    return (
        <KpiWrapper
            title={t("commandBox.trips.avgDistance.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            total={`${totalKM.toLocaleString()} km`}
            skeletonVariant="rect">
            <Chart
                chartType="Bar"
                graphID={'chart_avg_distance'}
                data={data}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )

}