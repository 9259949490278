import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, options, getStringTimeUnits, getChartName, DownloadChart, filterDataByGroup } from '../../../customFunctions'
import i18next from 'i18next'


const filteredData = (data, filterOptions, values) => {
    const { groupedBy } = filterOptions
    let results = [[getChartName(groupedBy), i18next.t('commandBox.capacity')]]
    let vehicles = []
    values.forEach(function (value) {
        vehicles = vehicles.concat(filterDataByGroup(data, groupedBy, value))
    })
    // delete repeat vehicles
    vehicles = vehicles.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.id === value.id
        ))
    )
    // get capacity and insert in result
    vehicles.forEach(function (itemVehicle) {
        results.push([{
            v: vehicles.length < 7 ? itemVehicle.displayName : ('' + '\n'),
            f: itemVehicle.displayName
        }
            , itemVehicle.capacity])
    })
    console.log(results)
    return results
}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const CapacityByVehicle = ({ data, title, groupedBy = 'municipalities', value = [], filterByDate = true, startDate = '2022-01-01', finishDate = '2022-02-01', timeUnits }) => {
    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits)

    const chartType = 'Bar'

    return (
        <>
            <Chart
                key={Math.random()}
                graphID={'chart_0'}
                chartType={chartType}
                width="100%"
                height={'400px'}
                data={filteredData(data, filterOptions, value)}
                options={options(chartProps)}
            />
            <DownloadChart key={Math.random()} img64={0} />
        </>
    )
}

CapacityByVehicle.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(['municipalities', 'regions', 'otpName', 'services', 'tenants']).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default CapacityByVehicle
