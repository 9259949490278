import { Typography, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function KpiWrapper({ children, displayNoData = false, title, total, isLoading, skeletonVariant = "react" }) {
    const { t } = useTranslation()
    const classes = useStyles()

    if (displayNoData) {
        return (
            <div className={classes.noDataContainer}>
                <Typography variant={"h6"}>{t("commandBox.summary.kpiWrapper.noData")}</Typography>
            </div>
        )
    }

    return (
        <>
            {
                isLoading ? (
                    <>
                        <Skeleton variant={skeletonVariant} style={{ minHeight: "250px" }} />
                        {/** Por algún motivo no se carga bien el gráfico una vez se han cargado los datos, 
                         * por eso se renderiza oculto también durante la carga  */}
                        <div className={classes.hiddenContainer}>
                            {children}
                        </div>
                    </>
                ) : (
                    <div className={classes.minHeight}>
                        <div className={classes.headerContainer}>
                            {title && <Typography variant={"h6"} style={{ fontWeight: "600" }} >{title}</Typography>}
                            {total && <div className={classes.totalContainer}>
                                <Typography variant={"h6"}>{t("commandBox.summary.kpiWrapper.total")} </Typography>
                                <Typography variant={"h5"} color='primary' style={{ fontWeight: "600" }}>{total}</Typography>
                            </div>}
                        </div>
                        {children}
                    </div>
                )
            }
        </>
    )

}

KpiWrapper.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    total: PropTypes.string,
    isLoading: PropTypes.bool,
    skeletonVariant: PropTypes.string,
}

const useStyles = makeStyles({
    noDataContainer: {
        minHeight: "250px",
        alignItems: "center",
        display: "flex",
        alignContent: "center",
        justifyContent: "center"
    },
    hiddenContainer: {
        visibility: 'hidden',
        position: 'absolute',
        opacity: '0'
    },
    minHeight: {
        minHeight: "250px"
    },
    headerContainer: {
        display: "flex",
        gap: "2px",
        marginBottom: "10px",
        justifyContent: "space-between"
    },
    totalContainer: {
        display: "flex",
        gap: "4px",
        alignItems: "baseline"
    }


})
export default KpiWrapper