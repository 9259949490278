import { Button, Grid, Hidden, FormControlLabel, Checkbox } from '@material-ui/core'
import React from 'react'
import ServiceCalendarRange from './ranges'
import ServiceCalendarWorkingRange from './workingRanges'
import { Remove } from '@material-ui/icons'
import moment from 'moment'
import { history } from '../../../helpers/history'
import { factory } from '../../../helpers/factory'
import ShiftDays from './shiftDays'
import utils from '../../../helpers/validations'
import ShiftHours from './shiftHours'
import { SERVICES_TYPE } from '../../../constants/types'

class ServiceCalendarRangeView extends React.Component {
    constructor(props) {
        super()
        const service = props.serviceReducer.service
        this.state = {
            hasRestriction: service ? service.serviceResponse.hasRestrictionsOnRequests : false,
            errors: {
                shiftDays: [
                    {
                        earliestStart: { result: true, message: '' },
                        latestArrival: { result: true, message: '' },
                        weekDays: { result: true, message: '' },
                    },
                ],
                requestShiftDays: [
                    {
                        earliestStart: { result: true, message: '' },
                        latestArrival: { result: true, message: '' },
                        weekDays: { result: true, message: '' },
                    },
                ],
                workingBankHolidays: [],
            },
        }
        this.saveCalendarServiceOnClick = this.saveCalendarServiceOnClick.bind(this)
        this.saveCalendarLine = this.saveCalendarLine.bind(this)
        this.addTime = this.addTime.bind(this)
        this.addTimeShiftHours = this.addTimeShiftHours.bind(this)
        this.addRequestTime = this.addRequestTime.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.removeItemShiftHours = this.removeItemShiftHours.bind(this)
        this.removeRequestItem = this.removeRequestItem.bind(this)
        this.handleRequestShiftDaysUpdates = this.handleRequestShiftDaysUpdates.bind(this)
        this.handleShiftDaysUpdates = this.handleShiftDaysUpdates.bind(this)
        this.handleChangeDateShiftDay = this.handleChangeDateShiftDay.bind(this)
        this.handleChangeDateRequestShiftDay = this.handleChangeDateRequestShiftDay.bind(this)
        this.handleChangeTimeShiftHour = this.handleChangeTimeShiftHour.bind(this)
        this.addErrorExcludedWorkingDay = this.addErrorExcludedWorkingDay.bind(this)
        this.removeErrorExcludedWorkingDay = this.removeErrorExcludedWorkingDay.bind(this)
        let calendar = props.serviceReducer.calendar
        if (calendar?.shiftDays?.length > 1) {
            const errors = this.state.errors
            calendar.shiftDays.forEach(function (element, i) {
                errors.shiftDays[i] = {
                    earliestStart: { result: false, message: '' },
                    latestArrival: { result: false, message: '' },
                    weekDays: { result: false, message: '' },
                }
            })
            this.setState({ errors })
        }
        if (calendar?.requestShiftDays?.length > 1) {
            const errors = this.state.errors
            calendar.requestShiftDays.forEach(function (element, i) {
                errors.requestShiftDays[i] = {
                    earliestStart: { result: false, message: '' },
                    latestArrival: { result: false, message: '' },
                    weekDays: { result: false, message: '' },
                }
            })
            this.setState({ errors })
        }
        if (calendar?.workingBankHolidays?.length > 0) {
            const errors = this.state.errors
            calendar.workingBankHolidays.forEach(function (element, i) {
                errors.workingBankHolidays[i] = { shiftHours: [] }
                element.shiftHours.forEach(function (elementShiftHour, indexShiftHour) {
                    errors.workingBankHolidays[i].shiftHours[indexShiftHour] = {
                        earliestStart: { result: false, message: '' },
                        latestArrival: { result: false, message: '' },
                    }
                })
            })
            this.setState({ errors })
        }
    }

    saveCalendarServiceOnClick() {
        const params = this.props.match.params
        const id = params.id
        const serviceType = params.serviceType
        let errors = this.state.errors
        let calendarReducer = this.props.serviceReducer.calendar
        const calendar = factory.createCalendar(calendarReducer.excludedPeriods, calendarReducer.requestShiftDays, calendarReducer.shiftDays, calendarReducer.workingBankHolidays, this.state.hasRestriction)
        if (serviceType != SERVICES_TYPE.regularWithSchedule) this.validateCompleteForm(errors, calendarReducer)
        else {
            const originIsServiceList = this.props.history.location.state?.originIsServiceList ?? false
            if (!originIsServiceList)
                this.validateShiftDays(errors, calendarReducer)
            this.validateRequestShiftDays(errors, calendarReducer)
            this.setState({ errors })
        }
        if (this.isFormValid()) this.props.setCalendar(calendar, id, this.state.hasRestriction)
    }

    saveCalendarLine() {
        const params = this.props.match.params
        const id = params.id
        let errors = this.state.errors
        let calendarReducer = this.props.serviceReducer.calendar
        this.validateWorkingBankHolidays(errors, calendarReducer)
        this.setState({ errors })
        if (this.isFormValid()) this.props.setCalendarLine(calendarReducer, id)
    }

    validateWorkingBankHolidays(errors, calendar) {
        errors.workingBankHolidays.forEach(function (workingBankHoliday, index) {
            workingBankHoliday.shiftHours.forEach(function (shiftHour, indexShiftHour) {
                shiftHour.earliestStart = utils.required(calendar.workingBankHolidays[index].shiftHours[indexShiftHour].earliestStart)
                shiftHour.latestArrival = utils.required(calendar.workingBankHolidays[index].shiftHours[indexShiftHour].latestArrival)
            })
        })
    }

    validateShiftDays(errors, calendar) {
        calendar.shiftDays.forEach(function (element, i) {
            errors.shiftDays[i].earliestStart = utils.required(element.earliestStart)
            errors.shiftDays[i].latestArrival = utils.required(element.latestArrival)
            errors.shiftDays[i].weekDays = utils.validateArray(element.weekDays)
        })
    }

    validateRequestShiftDays(errors, calendar) {
        if (this.state.hasRestriction) {
            errors.requestShiftDays.forEach(function (element, i) {
                element.earliestStart = utils.required(calendar.requestShiftDays[i].earliestStart)
                element.latestArrival = utils.required(calendar.requestShiftDays[i].latestArrival)
                element.weekDays = utils.validateArray(calendar.requestShiftDays[i].weekDays)
            })
        } else {
            errors.requestShiftDays.forEach(function (element, i) {
                element.earliestStart = { result: true, message: '' }
                element.latestArrival = { result: true, message: '' }
                element.weekDays = { result: true, message: '' }
            })
        }
    }

    validateCompleteForm(errors, calendar) {
        this.validateWorkingBankHolidays(errors, calendar)
        this.validateShiftDays(errors, calendar)
        this.validateRequestShiftDays(errors, calendar)
        this.setState({ errors })
    }

    isFormValid() {
        let valid = true

        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            this.state.errors[element].forEach(function (item, i) {
                let propertiesItem = Object.getOwnPropertyNames(item)
                if (propertiesItem != 'shiftHours') {
                    propertiesItem.forEach(prop => {
                        if (!item[prop].result) valid = false
                    })
                }
            })
        })

        this.state.errors.workingBankHolidays.forEach(function (workingBankHolidayItem, index) {
            workingBankHolidayItem.shiftHours.forEach(function (shiftHour, subIndex) {
                let shiftHourProperties = Object.getOwnPropertyNames(shiftHour)
                shiftHourProperties.forEach(hourProperty => {
                    if (!shiftHour[hourProperty].result) valid = false
                })
            })
        })

        return valid
    }

    onClickBack() {
        history.goBack()
    }

    addRequestTime() {
        let errors = this.state.errors
        errors.requestShiftDays.push({
            earliestStart: { result: false, message: '' },
            latestArrival: { result: false, message: '' },
            weekDays: { result: false, message: '' },
        })
        this.setState({
            errors,
        })
        this.props.addTimeRequestShiftDays()
    }

    removeRequestItem(index) {
        let errors = this.state.errors
        errors.requestShiftDays = errors.requestShiftDays.filter((_, i) => i !== index)
        this.props.removeTimeRequestShiftDays(index)
        this.setState({
            errors,
        })
    }

    addTime() {
        let errors = this.state.errors
        errors.shiftDays.push({
            earliestStart: { result: false, message: '' },
            latestArrival: { result: false, message: '' },
            weekDays: { result: false, message: '' },
        })
        this.props.addTimeShiftDays()
        this.setState({
            errors,
        })
    }

    addTimeShiftHours(index) {
        let errors = this.state.errors
        errors.workingBankHolidays[index].shiftHours.push({
            earliestStart: { result: false, message: '' },
            latestArrival: { result: false, message: '' },
        })
        this.props.addTimeShiftHours(index)
        this.setState({
            errors,
        })
    }

    addErrorExcludedWorkingDay() {
        let errors = this.state.errors
        errors.workingBankHolidays.push({
            shiftHours: [
                {
                    earliestStart: { result: false, message: '' },
                    latestArrival: { result: false, message: '' },
                },
            ],
        })
        this.setState({
            errors,
        })
    }

    removeItem(index) {
        let errors = this.state.errors
        errors.shiftDays = errors.shiftDays.filter((_, i) => i !== index)
        this.props.removeTimeShiftDays(index)
        this.setState({
            errors,
        })
    }

    removeItemShiftHours(index, indexShiftHour) {
        let errors = this.state.errors
        errors.workingBankHolidays[index].shiftHours = errors.workingBankHolidays[index].shiftHours.filter((_, i) => i !== indexShiftHour)
        this.props.removeTimeShiftHours(index, indexShiftHour)
        this.setState({
            errors,
        })
    }

    removeErrorExcludedWorkingDay(index) {
        let errors = this.state.errors
        errors.workingBankHolidays = errors.workingBankHolidays.filter((_, i) => i !== index)
        this.setState({
            errors,
        })
    }

    handleShiftDaysUpdates = (days, option = null, index) => {
        let updatedShiftDays = this.props.serviceReducer.calendar.shiftDays
        let errors = this.state.errors
        if (option) {
            switch (true) {
                case option === '1':
                    updatedShiftDays[index].weekDays = [6, 0]
                    break
                case option === '2':
                    updatedShiftDays[index].weekDays = [1, 2, 3, 4, 5, 6, 0]
                    break
                case option === '3':
                    updatedShiftDays[index].weekDays = [1, 2, 3, 4, 5]
                    break
                default:
                    updatedShiftDays[index].weekDays = days.map(d => (d.hasOwnProperty('id') ? parseInt(d.id) : d))
            }
        } else {
            updatedShiftDays[index].weekDays = days.map(d => (d.hasOwnProperty('id') ? parseInt(d.id) : d))
        }
        errors.shiftDays[index].weekDays.message = ''
        this.props.setShiftDays(updatedShiftDays)
    }

    handleRequestShiftDaysUpdates = (days, option = null, index) => {
        let updatedShiftDays = this.props.serviceReducer.calendar.requestShiftDays
        let errors = this.state.errors
        if (option) {
            switch (true) {
                case option === '1':
                    updatedShiftDays[index].weekDays = [6, 0]
                    break
                case option === '2':
                    updatedShiftDays[index].weekDays = [1, 2, 3, 4, 5, 6, 0]
                    break
                case option === '3':
                    updatedShiftDays[index].weekDays = [1, 2, 3, 4, 5]
                    break
                default:
                    updatedShiftDays[index].weekDays = days.map(d => (d.hasOwnProperty('id') ? parseInt(d.id) : d))
            }
        } else {
            updatedShiftDays[index].weekDays = days.map(d => (d.hasOwnProperty('id') ? parseInt(d.id) : d))
        }
        errors.requestShiftDays[index].weekDays.message = ''
        this.props.setRequestShiftDays(updatedShiftDays)
    }

    handleChangeDateShiftDay(event, index) {
        let { name, value } = event.target
        let errors = this.state.errors
        let date = new Date()
        let array = value.split(':')
        date.setHours(array[0], array[1])
        array = name.split('_')
        let updatedShiftDays = this.props.serviceReducer.calendar.shiftDays
        updatedShiftDays[index][array[0]] = value
        errors.shiftDays[index][array[0]].message = ''
        this.props.setShiftDays(updatedShiftDays)
    }

    handleChangeDateRequestShiftDay(event, index) {
        let { name, value } = event.target
        let errors = this.state.errors
        let date = new Date()
        let array = value.split(':')
        date.setHours(array[0], array[1])
        array = name.split('_')
        let updatedShiftDays = this.props.serviceReducer.calendar.requestShiftDays
        updatedShiftDays[index][array[0]] = value
        errors.requestShiftDays[index][array[0]].message = ''
        this.props.setRequestShiftDays(updatedShiftDays)
    }

    handleChangeTimeShiftHour(event, index, indexShiftHour) {
        let { name, value } = event.target
        let date = new Date()
        let array = value.split(':')
        date.setHours(array[0], array[1])
        array = name.split('_')
        let updatedShiftHours = this.props.serviceReducer.calendar.workingBankHolidays[index].shiftHours
        updatedShiftHours[indexShiftHour][array[0]] = value
        this.props.setShiftHours(updatedShiftHours)
    }

    render() {
        const { t, serviceReducer, match } = this.props
        const id = match.params.id
        const serviceType = match.params.serviceType
        const title = serviceReducer.service?.serviceResponse ?? serviceReducer.serviceLine
        const bankHolidaysLine = serviceReducer.serviceLine?.bankHolidaysLine ?? false
        const calendar = serviceReducer.calendar
        const isServiceWithSchedule = serviceType == SERVICES_TYPE.regularWithSchedule
        // when come from list of services is set a boolean into the history push
        const originIsServiceList = this.props.history.location.state?.originIsServiceList ?? false
        const isLineCalendar = isServiceWithSchedule && !originIsServiceList
        const isServiceCalendar = !isServiceWithSchedule && originIsServiceList

        const showWorkingRange = isLineCalendar || isServiceCalendar

        return (

            <Grid container style={{ padding: 15 }} spacing={3}>
                <Grid item md={1} implementation="css" smDown component={Hidden} />
                <Grid item md={11}>
                    <h1>{title?.name}</h1>
                </Grid>

                {showWorkingRange ? (
                    <>
                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={8}>
                            <ServiceCalendarWorkingRange {...this.props} addError={this.addErrorExcludedWorkingDay} />
                        </Grid>
                        <Grid item md={3} implementation="css" smDown component={Hidden} />

                        {calendar.workingBankHolidays &&
                            calendar.workingBankHolidays?.map((item, index) => (
                                <React.Fragment key={'e_' + index}>
                                    <Grid item md={3} implementation="css" smDown component={Hidden} />
                                    <Grid item md={2}>
                                        <span>{t('services_comp.calendar.range.start')}: </span>
                                        {moment(item.startPeriod).format('DD/MM/YYYY')}
                                    </Grid>
                                    <Grid item md={2}>
                                        <span>{t('services_comp.calendar.range.end')}: </span>
                                        {moment(item.finishPeriod).format('DD/MM/YYYY')}
                                    </Grid>
                                    <Grid item md={2}>
                                        <Remove
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this.props.removeExcludedWorkingDays(index)
                                                this.removeErrorExcludedWorkingDay(index)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={3} implementation="css" smDown component={Hidden} />
                                    {item.shiftHours?.map((itemShiftHour, indexShiftHour) => (
                                        <ShiftHours
                                            key={'s_' + indexShiftHour}
                                            item={itemShiftHour}
                                            index={index}
                                            indexShiftHour={indexShiftHour}
                                            detail={this.props.detail}
                                            length={item.shiftHours.length}
                                            value={itemShiftHour}
                                            t={t}
                                            onChange={event => this.handleChangeTimeShiftHour(event, index, indexShiftHour)}
                                            add={this.addTimeShiftHours}
                                            remove={this.removeItemShiftHours}
                                            prop={'workingBankHolidays'}
                                            state={this.state}
                                            serviceReducer={this.props.serviceReducer}
                                            disabled={this.props.readOnly || this.props.readEdit}
                                            {...this.props}
                                        />
                                    ))}
                                </React.Fragment>
                            ))}
                        {
                            !bankHolidaysLine && (
                                <>
                                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                                    <Grid item md={8}>
                                        <ServiceCalendarRange {...this.props} />
                                    </Grid>
                                    <Grid item md={3} implementation="css" smDown component={Hidden} />

                                    {calendar &&
                                        calendar.excludedPeriods?.map((item, index) => (
                                            <React.Fragment key={'e_' + index}>
                                                <Grid item md={3} implementation="css" smDown component={Hidden} />
                                                <Grid item md={2}>
                                                    <span>{t('services_comp.calendar.range.start')}: </span>
                                                    {moment(item.startPeriod).format('DD/MM/YYYY')}
                                                </Grid>
                                                <Grid item md={2}>
                                                    <span>{t('services_comp.calendar.range.end')}: </span>
                                                    {moment(item.finishPeriod).format('DD/MM/YYYY')}
                                                </Grid>
                                                <Grid item md={2}>
                                                    <Remove style={{ cursor: 'pointer' }} onClick={() => this.props.removeExcludeDays(index)} />
                                                </Grid>
                                                <Grid item md={3} implementation="css" smDown component={Hidden} />
                                            </React.Fragment>
                                        ))}
                                </>
                            )
                        }

                    </>
                ) : null}

                {
                    // only is possible config when is not regularWithSchedule and not is origin is service list
                    originIsServiceList && !isServiceWithSchedule ? (
                        <>
                            <Grid item md={1} implementation="css" smDown component={Hidden} />
                            <Grid item md={11}>
                                <h2>{t('services_comp.calendar.shiftDays.title')}</h2>
                            </Grid>

                            {calendar.shiftDays?.map((item, index) => (
                                <ShiftDays
                                    key={'s_' + index}
                                    item={item}
                                    index={index}
                                    detail={this.props.detail}
                                    length={calendar.shiftDays.length}
                                    value={calendar.shiftDays[index]}
                                    t={t}
                                    onChange={event => this.handleChangeDateShiftDay(event, index)}
                                    add={this.addTime}
                                    remove={this.removeItem}
                                    checkUpdates={(days, option) => this.handleShiftDaysUpdates(days, option, index)}
                                    prop={'shiftDays'}
                                    state={this.state}
                                    serviceReducer={this.props.serviceReducer}
                                    disabled={this.props.readOnly || this.props.readEdit}
                                    {...this.props}
                                />
                            ))}
                        </>
                    ) : null
                }

                {
                    // only is possible config when is service's calendar
                    originIsServiceList ? (
                        <>
                            <Grid item md={1} implementation="css" smDown component={Hidden} />
                            <Grid item md={11} xs={1}>
                                <FormControlLabel control={<Checkbox checked={this.state.hasRestriction} onChange={() => this.setState({ hasRestriction: !this.state.hasRestriction })} color="primary" />} label={t('services_comp.calendar.shiftDays.existRestrictions')} />
                            </Grid>

                            <Grid item md={1} implementation="css" smDown component={Hidden} />
                            <Grid item md={11}>
                                {this.state.hasRestriction ? (

                                    <h2>{t('services_comp.calendar.shiftDays.titleRequest')}</h2>

                                ) : null}
                            </Grid>

                            {this.state.hasRestriction ? (
                                <React.Fragment>
                                    {calendar.requestShiftDays?.map((item, index) => (
                                        <ShiftDays
                                            key={'r_' + index}
                                            item={item}
                                            index={index}
                                            length={calendar.requestShiftDays.length}
                                            value={calendar.requestShiftDays[index]}
                                            t={t}
                                            onChange={event => this.handleChangeDateRequestShiftDay(event, index)}
                                            add={this.addRequestTime}
                                            remove={this.removeRequestItem}
                                            checkUpdates={(days, option) => this.handleRequestShiftDaysUpdates(days, option, index)}
                                            prop={'requestShiftDays'}
                                            state={this.state}
                                            serviceReducer={this.props.serviceReducer}
                                            disabled={this.props.readOnly || this.props.readEdit || !this.state.hasRestriction}
                                            {...this.props}
                                        />
                                    ))}
                                </React.Fragment>
                            ) : (
                                <Grid item md={12} implementation="css" smDown component={Hidden} />
                            )}
                        </>
                    ) : null
                }

                <Grid item md={3} implementation="css" smDown component={Hidden} />
                <Grid item md={2}>
                    <Button style={{ marginTop: 40 }} variant="contained" color="primary" onClick={this.onClickBack} fullWidth size="large">
                        {t('services_comp.form.page.goBack')}
                    </Button>
                </Grid>
                <Grid item md={1} implementation="css" smDown component={Hidden} />
                <Grid item md={2}>
                    <Button style={{ marginTop: 40 }} variant="contained" color="primary" fullWidth size="large" disabled={serviceReducer.pending ? true : false} onClick={originIsServiceList ? () => this.saveCalendarServiceOnClick() : () => this.saveCalendarLine(id)}>
                        {t('services_comp.form.page.buttonEdit')}
                    </Button>
                </Grid>

                <Grid item md={4} implementation="css" smDown component={Hidden} />
            </Grid>
        )
    }
}

export default ServiceCalendarRangeView
