import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, getChartName, getStringTimeUnits, options, DownloadChart, getDistinctValuesFromGroup } from '../../../customFunctions'
import moment from 'moment'
import i18next from 'i18next'

const filterDataByGroup = (data, groupedBy, value, tripOrigin) => {
    switch (tripOrigin) {
        case '10':
            data = data.filter(item => item.requestedByUser)
            break
        case '20':
            data = data.filter(item => item.requestedByCustomerService)
            break
        case '30':
            data = data.filter(item => item.requestedByIVR)
            break
        default:
            break
    }

    switch (groupedBy) {
        case 'municipalities':
            return data.filter(item => item.municipalities?.includes(value))
        case 'regions':
            return data.filter(item => item.regions?.includes(value))
        case 'otps':
            return data.filter(item => item.otps?.includes(value))
        case 'services':
            return data.filter(item => item.services?.includes(value))
        case 'tenants':
            return data.filter(item => item.tenants?.includes(value))
        default:
            return data
    }
}

const getTotalData = (data, values, groupedBy, tripOrigin) => {
    let result = [['Bookings', 'Total']]
    const filtersInData = getDistinctValuesFromGroup(data, groupedBy, values)
    filtersInData.forEach(function (value) {
        result.push([value, filterDataByGroup(data, groupedBy, value, tripOrigin).length])
    })
    console.log(result)
    return result
}

const getDailyData = (data, values, groupedBy, tripOrigin) => {
    let totals = []
    const filtersInData = getDistinctValuesFromGroup(data, groupedBy, values)
    filtersInData.forEach(function (value) {
        totals = totals.concat(filterDataByGroup(data, groupedBy, value, tripOrigin))
    })
    let dates = Array.from(new Set(totals.map(item => item.dateFormat)))
    // filter unique trips to count only once
    totals = totals.filter((value, index, self) => index === self.findIndex(t => t.tripId === value.tripId))
    let result = [
        [
            {
                type: 'date',
                id: 'Date',
            },
            {
                type: 'number',
                id: 'Count bookings',
            },
        ],
    ]
    // for each dates get quantity trips
    dates.forEach(function (date) {
        result.push([moment(date).toDate(), totals.filter(item => item.dateFormat == date).length])
    })
    console.log(result)
    return result
}

const getMonthData = (data, values, groupedBy, tripOrigin) => {
    // get all months and years options from trips
    let optionsMonth = Array.from(new Set(data.map(item => item.monthText)))
    let result = []
    const filtersInData = getDistinctValuesFromGroup(data, groupedBy, values)
    result.push([i18next.t('commandBox.month') + ' ' + getChartName(groupedBy)].concat(filtersInData))
    // for each month filter trips and insert results
    optionsMonth.forEach(function (itemMonth) {
        let dataMonth = [...new Array(filtersInData.length)]
        filtersInData.forEach(function (value, iValue) {
            dataMonth[iValue] = filterDataByGroup(data, groupedBy, value, tripOrigin).filter(item => item.monthText == itemMonth).length
        })
        result.push([itemMonth].concat(dataMonth))
    })
    console.log(result)
    return result
}

const getYearlyData = (data, values, groupedBy, tripOrigin) => {
    // get all years options from trips
    let optionsYear = Array.from(new Set(data.map(item => item.year.toString())))
    let result = []
    const filtersInData = getDistinctValuesFromGroup(data, groupedBy, values)
    result.push([i18next.t('commandBox.year') + ' ' + getChartName(groupedBy)].concat(filtersInData))
    // for each year filter trips and insert results
    optionsYear.forEach(function (itemYear) {
        let dataYear = [...new Array(filtersInData.length)]
        filtersInData.forEach(function (value, iValue) {
            dataYear[iValue] = filterDataByGroup(data, groupedBy, value, tripOrigin).filter(item => item.year == itemYear).length
        })
        result.push([itemYear].concat(dataYear))
    })
    console.log(result)
    return result
}

const filteredData = (data, filterOptions) => {
    const { groupedBy, value, timeUnits, tripOrigin } = filterOptions
    console.log('timeUnits => ', timeUnits)
    console.log('getStringTimeUnits => ', getStringTimeUnits(timeUnits))
    let array = []
    let actualIndexDate = null
    // for each trips set dates information to filter after
    data.forEach(x => {
        actualIndexDate = moment(x.requestPickUpStartTime)
        x.weekNumber = actualIndexDate.year() + '_' + actualIndexDate.week()
        x.year = actualIndexDate.year()
        x.month = actualIndexDate.year() + '_' + actualIndexDate.month()
        x.monthText = i18next.t('commandBox.months', { returnObjects: true })[actualIndexDate.month()] + '/' + x.year
        x.dateFormat = actualIndexDate.format('YYYY-MM-DD')
        array.push(x)
    })
    console.log('array => ', array)
    switch (timeUnits) {
        case '10':
            return getTotalData(array, value, groupedBy, tripOrigin)
        case '20':
            return getDailyData(array, value, groupedBy, tripOrigin)
        case '40':
            return getMonthData(array, value, groupedBy, tripOrigin)
        case '50':
            return getYearlyData(array, value, groupedBy, tripOrigin)
        default:
            return data
    }
}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const TotalBookings = ({ data, title = 'Total reserves', groupedBy = 'municipalities', value = [], filterByDate = true, startDate = '2022-01-01', finishDate = '2022-02-01', timeUnits, tripOrigin }) => {
    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits, tripOrigin)

    const chartType = ['40', '50'].includes(timeUnits) ? 'Bar' : timeUnits == '10' ? 'PieChart' : 'Calendar'
    const lengthArray = filteredData(data, filterOptions).filter(([a, b]) => b > 0).length

    return lengthArray > 0 ? (
        <>
            <Chart
                chartType={chartType}
                graphID={'chart_0'}
                width="100%"
                height="400px"
                data={filteredData(data, filterOptions)}
                options={options(chartProps)}
                chartLanguage={i18next.language}
            />
            <DownloadChart
                key={Math.random()}
                img64={0}
            />
        </>
    ) : null
}

TotalBookings.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(['municipalities', 'regions', 'otps', 'services', 'tenant']).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
    timeUnits: PropTypes.string.isRequired,
}

export default TotalBookings
