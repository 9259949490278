import i18next from "i18next";
import { KPIConstants } from "../../constants/kpi_constant";
import { ERROR_CODES, KPI_FILTER_OPTIONS } from "../../constants/types";
import { kpiService } from "../../services/kpiService";
import { alertActions } from "./alert_actions";
import { userActions } from "./user_actions";
import { getDriversGroupBy, getUsersGroupBy } from "../../helpers/kpiHelper";
import { FilterOptions } from "../../components/commandBox/customFunctions";

export const kpiActions = {
    getConfirmedTrips,
    getReservationsKPI,
    getAlertsKPI,
    getCustomersKPI,
    getDriversKPI,
    getDriverRequestsKPI,
    getJourneysKPI,
    getConfirmedGroupedTripsKPI,
    getDriverCalendarKPI,
    getTripBookingKPI,
    cleanKPI,
}

const getBody = (dateStart, dateEnd, type, data) => {
    let services = []
    let otps = []
    let tenants = []
    let regions = []
    let municipalities = []

    switch (type) {
        case KPI_FILTER_OPTIONS.services:
            services = data.map(el => el.value)
            break;
        case KPI_FILTER_OPTIONS.otps:
            otps = data.map(el => el.value)
            break;
        case KPI_FILTER_OPTIONS.tenant:
            tenants = data.map(el => el.value)
            break;
        case KPI_FILTER_OPTIONS.region:
            regions = data.map(el => el.label)
            break;
        case KPI_FILTER_OPTIONS.municipalities:
            municipalities = data.map(el => el.label)
            break;
        default:
            break;
    }

    return { dateStart, dateEnd, services, otps, tenants, regions, municipalities }
}

function getConfirmedTrips(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request());
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getConfirmedTripsKPI(body)
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    switch (error) {
                        case ERROR_CODES.unauthorized:
                            dispatch(userActions.refreshToken())
                            dispatch(getConfirmedTrips(body))
                            break;
                        case ERROR_CODES.forbidden:
                            dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetConfirmedTrips')))
                            break;
                        case ERROR_CODES.notFound:
                            dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetConfirmedTrips')))
                            break;
                        default:
                            dispatch(alertActions.error(i18next.t('services.kpi.getConfirmedTripsError')))
                            break;
                    }
                    dispatch(failure(error))
                }
            )
    };

    function request() { return { type: KPIConstants.GET_CONFIRMED_TRIPS_REQUEST } }
    function success(confirmTrips) { return { type: KPIConstants.GET_CONFIRMED_TRIPS_SUCCESS, confirmTrips } }
    function failure(error) { return { type: KPIConstants.GET_CONFIRMED_TRIPS_FAILURE, error } }
}

function getConfirmedGroupedTripsKPI(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request());
        const { type, data } = filter
        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getConfirmedGroupedTripsKPI(body)
            .then(
                result => {
                    dispatch(success(result))
                }
                ,
                error => {
                    switch (error) {
                        case ERROR_CODES.unauthorized:
                            dispatch(userActions.refreshToken())
                            dispatch(getConfirmedGroupedTripsKPI(body))
                            break;
                        case ERROR_CODES.forbidden:
                            dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetConfirmedGroupedTrips')))
                            break;
                        case ERROR_CODES.notFound:
                            dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetConfirmedGroupedTrips')))
                            break;
                        default:
                            dispatch(alertActions.error(i18next.t('services.kpi.getConfirmedGroupedTripsError')))
                            break;
                    }
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: KPIConstants.GET_CONFIRMED_GROUPED_TRIPS_REQUEST } }
    function success(confirmTrips) { return { type: KPIConstants.GET_CONFIRMED_GROUPED_TRIPS_SUCCESS, confirmTrips } }
    function failure(error) { return { type: KPIConstants.GET_CONFIRMED_GROUPED_TRIPS_FAILURE, error } }
}
function getReservationsKPI(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request());
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getReservationsKPI(body)
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    switch (error) {
                        case ERROR_CODES.unauthorized:
                            dispatch(userActions.refreshToken())
                            dispatch(getReservationsKPI(body))
                            break;
                        case ERROR_CODES.forbidden:
                            dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetReservations')))
                            break;
                        case ERROR_CODES.notFound:
                            dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetReservations')))
                            break;
                        default:
                            dispatch(alertActions.error(i18next.t('services.kpi.getReservationsError')))
                            break;
                    }
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: KPIConstants.GET_RESERVATIONS_REQUEST } }
    function success(reservations) { return { type: KPIConstants.GET_RESERVATIONS_SUCCESS, reservations } }
    function failure(error) { return { type: KPIConstants.GET_RESERVATIONS_FAILURE, error } }
}

function getAlertsKPI(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request());
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getAlertsKPI(body)
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    switch (error) {
                        case ERROR_CODES.unauthorized:
                            dispatch(userActions.refreshToken())
                            dispatch(getAlertsKPI(body))
                            break;
                        case ERROR_CODES.forbidden:
                            dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetAlerts')))
                            break;
                        case ERROR_CODES.notFound:
                            dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetAlerts')))
                            break;
                        default:
                            dispatch(alertActions.error(i18next.t('services.kpi.getAlertsError')))
                            break;
                    }
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: KPIConstants.GET_ALERTS_REQUEST } }
    function success(alerts) { return { type: KPIConstants.GET_ALERTS_SUCCESS, alerts } }
    function failure(error) { return { type: KPIConstants.GET_ALERTS_FAILURE, error } }
}

function getCustomersKPI(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request());
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getCustomersKPI(body)
            .then(
                result => {
                    const filterOptions = FilterOptions(type.toLowerCase(), data.map(el => el.label))
                    const res = getUsersGroupBy(result, filterOptions)
                    dispatch(success(res))
                },
                error => {
                    switch (error) {
                        case ERROR_CODES.unauthorized:
                            dispatch(userActions.refreshToken())
                            dispatch(getCustomersKPI(body))
                            break;
                        case ERROR_CODES.forbidden:
                            dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetCustomers')))
                            break;
                        case ERROR_CODES.notFound:
                            dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetCustomers')))
                            break;
                        default:
                            dispatch(alertActions.error(i18next.t('services.kpi.getCustomersError')))
                            break;
                    }
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: KPIConstants.GET_CUSTOMERS_REQUEST } }
    function success(customers) { return { type: KPIConstants.GET_CUSTOMERS_SUCCESS, customers } }
    function failure(error) { return { type: KPIConstants.GET_CUSTOMERS_FAILURE, error } }
}

function getDriversKPI(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request());
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getDriversKPI(body)
            .then(
                result => {
                    const filterOptions = FilterOptions(type.toLowerCase(), data.map(el => el.label))
                    const res = getDriversGroupBy(result, filterOptions)
                    dispatch(success(res))
                },
                error => {
                    switch (error) {
                        case ERROR_CODES.unauthorized:
                            dispatch(userActions.refreshToken())
                            dispatch(getDriversKPI(body))
                            break;
                        case ERROR_CODES.forbidden:
                            dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetDrivers')))
                            break;
                        case ERROR_CODES.notFound:
                            dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetDrivers')))
                            break;
                        default:
                            dispatch(alertActions.error(i18next.t('services.kpi.getDriversError')))
                            break;
                    }
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: KPIConstants.GET_DRIVERS_REQUEST } }
    function success(drivers) { return { type: KPIConstants.GET_DRIVERS_SUCCESS, drivers } }
    function failure(error) { return { type: KPIConstants.GET_DRIVERS_FAILURE, error } }
}

function getDriverCalendarKPI(dateStart, dateEnd, filter, status, lang) {
    return dispatch => {
        dispatch(request());
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getDriverCalendarKPI({ ...body, status, lang })
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    switch (error) {
                        case ERROR_CODES.unauthorized:
                            dispatch(userActions.refreshToken())
                            dispatch(getDriverCalendarKPI(body, status))
                            break;
                        case ERROR_CODES.forbidden:
                            dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetDriverCalendar')))
                            break;
                        case ERROR_CODES.notFound:
                            dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetDriverCalendar')))
                            break;
                        default:
                            dispatch(alertActions.error(i18next.t('services.kpi.getDriverCalendarError')))
                            break;
                    }
                    dispatch(failure(error))
                })
    }
    function request() { return { type: KPIConstants.GET_DRIVER_CALENDAR_REQUEST } }
    function success(driverCalendar) { return { type: KPIConstants.GET_DRIVER_CALENDAR_SUCCESS, driverCalendar } }
    function failure(error) { return { type: KPIConstants.GET_DRIVER_CALENDAR_FAILURE, error } }
}

function getDriverRequestsKPI(dateStart, dateEnd, filter, status, lang) {
    return dispatch => {
        dispatch(request());
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getDriverRequestsKPI({ ...body, status, lang })
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    switch (error) {
                        case ERROR_CODES.unauthorized:
                            dispatch(userActions.refreshToken())
                            dispatch(getDriverRequestsKPI(body, status))
                            break;
                        case ERROR_CODES.forbidden:
                            dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetDriverRequests')))
                            break;
                        case ERROR_CODES.notFound:
                            dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetDriverRequests')))
                            break;
                        default:
                            dispatch(alertActions.error(i18next.t('services.kpi.getDriverRequestsError')))
                            break;
                    }
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: KPIConstants.GET_DRIVER_REQUEST_REQUEST } }
    function success(driverRequest) { return { type: KPIConstants.GET_DRIVER_REQUEST_SUCCESS, driverRequest } }
    function failure(error) { return { type: KPIConstants.GET_DRIVER_REQUEST_FAILURE, error } }

}

function getJourneysKPI(dateStart, dateEnd, filter, status, lang) {
    return dispatch => {
        dispatch(request());
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getJourneysKPI({ ...body, status, lang })
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    switch (error) {
                        case ERROR_CODES.unauthorized:
                            dispatch(userActions.refreshToken())
                            dispatch(getJourneysKPI(body))
                            break;
                        case ERROR_CODES.forbidden:
                            dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetJourneys')))
                            break;
                        case ERROR_CODES.notFound:
                            dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetJourneys')))
                            break;
                        default:
                            dispatch(alertActions.error(i18next.t('services.kpi.getJourneysError')))
                            break;
                    }
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: KPIConstants.GET_JOURNEYS_REQUEST } }
    function success(journeys) { return { type: KPIConstants.GET_JOURNEYS_SUCCESS, journeys } }
    function failure(error) { return { type: KPIConstants.GET_JOURNEYS_FAILURE, error } }
}

function getTripBookingKPI(dateStart, dateEnd, filter, status, lang) {
    return dispatch => {
        dispatch(request());
        const { type, data } = filter
        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getTripsBookingKPI({ ...body, status, lang })
            .then(
                result => {
                    dispatch(success(result))
                },
                error => {
                    switch (error) {
                        case ERROR_CODES.unauthorized:
                            dispatch(userActions.refreshToken())
                            dispatch(getTripBookingKPI(body))
                            break;
                        case ERROR_CODES.forbidden:
                            dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetTripBooking')))
                            break;
                        case ERROR_CODES.notFound:
                            dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetTripBooking')))
                            break;
                        default:
                            dispatch(alertActions.error(i18next.t('services.kpi.getTripBookingError')))
                            break;
                    }
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: KPIConstants.GET_TRIP_BOOKING_REQUEST } }
    function success(tripBooking) { return { type: KPIConstants.GET_TRIP_BOOKING_SUCCESS, tripBooking } }
    function failure(error) { return { type: KPIConstants.GET_TRIP_BOOKING_FAILURE, error } }
}

function cleanKPI() {
    return dispatch => {
        dispatch({ type: KPIConstants.CLEAN_KPI })
    }
}