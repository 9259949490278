import { FormControl, InputLabel, Select } from '@material-ui/core'
import React from 'react'
import { withTranslation } from 'react-i18next';

const CustomMultipleSelect = ({ data, setSelected, selected, title, t }) => {

    const handleChangeMultiple = (event) => {
        const { options } = event.target
        const value = []

        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value)
            }
        }

        setSelected(value)

    }


    return (
        <div>
            <FormControl style={{ m: 1, width: '100%' }}>
                <InputLabel shrink htmlFor="select-multiple-native">
                    {title}
                </InputLabel>
                <Select
                    fullWidth
                    multiple
                    native
                    value={selected}
                    onChange={handleChangeMultiple}
                    label="Native"
                    inputProps={{
                        id: 'select-multiple-native',
                        size: 8
                    }}
                >
                    {data.map((item) => (
                        <option key={item.id} value={JSON.stringify(item)}>
                            {item.isRemoved ? item.name + t("reports.page.deleted") : item.name}
                        </option>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default withTranslation('common')(CustomMultipleSelect)