import React from 'react';
import { Chart } from "react-google-charts";
import PropTypes from 'prop-types';
import { FilterOptions, options, getStringTimeUnits, getChartName, DownloadChart } from '../../../customFunctions';
import moment from 'moment';
import i18next from 'i18next';


const filterDataByGroup = (data, groupedBy, value) => {
    switch (groupedBy) {
        case 'municipalities':
            return data.filter(item => item.municipalities?.includes(value) && item.duration > 0)
        case 'regions':
            return data.filter(item => item.regions?.includes(value) && item.duration > 0)
        case 'otps':
            return data.filter(item => item.otps?.includes(value) && item.duration > 0)
        case 'services':
            return data.filter(item => item.services?.includes(value) && item.duration > 0)
        case 'tenants':
            return data.filter(item => item.tenants?.includes(value) && item.duration > 0)
        default:
            return data
    }
}


const filteredData = (data, filterOptions, value) => {
    const { groupedBy } = filterOptions
    let array = [["Journeys", "AVG"]]
    // for each books set dates information to filter after
    value.forEach(element => {
        let journeys = filterDataByGroup(data, groupedBy, element)
        let totDuration = 0
        let numTrips = 0
        journeys.forEach(itemJourney => {
            totDuration = totDuration + itemJourney.duration
            numTrips = numTrips + itemJourney.numTrips
        });
        if (totDuration != 0 && numTrips != 0)
            array.push([element, moment.utc((totDuration / numTrips)).format('HH:mm:ss')])
        else
            array.push([element, moment.utc(0).format("HH:mm:ss")])

    });

    array.sort(function (a, b) {
        if (a[1] == b[1]) {
            return a[0] - b[0];
        }
        return b[1] - a[1];
    });

    console.log("array => ", array)
    return array
}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const AvgDrivingTimeByJourney = ({
    data,
    title = "Total viatges (acumulats)",
    groupedBy = "municipalities",
    value = [],
    filterByDate = true,
    startDate = "2022-01-01",
    finishDate = "2022-02-01",
    timeUnits
}) => {

    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits)
    const chartType = "Bar"

    return (
        <>
            <React.Fragment key={Math.random()}>
                {
                    filteredData(data, filterOptions, value).filter(item => item[1] != '00:00:00').length > 0 ?
                        <>
                            <Chart
                                chartType={chartType}
                                graphID={'chart_0'}
                                width="100%"
                                height="400px"
                                data={filteredData(data, filterOptions, value).filter(item => item[1] != '00:00:00')}
                                options={options(chartProps, null, value)}
                                chartLanguage={i18next.language}
                            />
                            <DownloadChart key={Math.random()} img64={0} />
                        </>
                        : null
                }
            </React.Fragment>

        </>
    )
}
AvgDrivingTimeByJourney.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(["municipalities", "regions", "otps", "services", "tenants"]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default AvgDrivingTimeByJourney;