import { Grid } from "@material-ui/core"
import React, { useCallback, useEffect, useState } from 'react';
import AlertDialog from "../../alert-dialog";
import i18next from "i18next";
import { GridCenter, ListOfTripsPlannedComponent, ShowErrorComponent, withListOfTrips, withSelectNewTimeComponent } from "./details";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { multipleTripsActions } from "../../../redux/actions/multipleTrips_action";
import Spinner from "../../shared/spinner";
import { SERVICES_TYPE } from "../../../constants/types";



const MultipleTrips = ({ listOfTrips, cancelTripPlanned }) => {

    const [errorsToShow, setErrorsToShow] = useState([])
    const [tripToCancel, setTripToCancel] = useState(null)
    const [tripToRetry, setTripToRetry] = useState(null)
    const [newTimeToRetryTrip, setNewTimeToRetryTrip] = useState('')
    const [openModalError, setOpenModalError] = useState(false)
    const [openModalCancel, setOpenModalCancel] = useState(false)
    const [openModalRetry, setOpenModalRetry] = useState(false)
    const multipleTripReducer = useSelector((state) => state.multipleTripReducer)
    const dispatch = useDispatch()
    const serviceType = useSelector((state) => state.multipleTripReducer.serviceType)


    const handleMouseOver = (trip) => {
        if (trip) {
            setErrorsToShow(trip.apiErrors)
            setOpenModalError(true)
        }
    }

    const handleCancelTrip = (trip) => {
        if (trip) {
            setTripToCancel(trip)
            setOpenModalCancel(true)
        }
    }

    const handleRetryTrip = (trip) => {
        if (trip) {
            setTripToRetry(trip)
            setOpenModalRetry(true)
            if (![SERVICES_TYPE.regularWithSchedule].includes(serviceType))
                setNewTimeToRetryTrip(moment(trip.requestPickUpTime).toDate())
        }
    }




    const handleRetryTripAccept = useCallback(() => {
        const tripIdOriginal = multipleTripReducer.tripIdToPlan
        let originalSchedule = moment(tripToRetry.requestPickUpTime)
        const newTimeSplitted = newTimeToRetryTrip.split(":")
        originalSchedule.set({ hour: parseInt(newTimeSplitted[0]), minute: parseInt(newTimeSplitted[1]) })
        const body = {
            tripId: tripIdOriginal,
            requestPickUpStartTime: originalSchedule.toDate().toISOString(),
            lang: i18next.language
        }
        dispatch(multipleTripsActions.processMultipleTripRetry(body, tripToRetry.tripId, () => setOpenModalRetry(false)))

    }, [tripToRetry, newTimeToRetryTrip])

    const isPending = multipleTripReducer.pending

    const ComponentWrapped = withListOfTrips(ListOfTripsPlannedComponent)
    return (
        <Grid container spacing={1} style={{ paddingTop: 30, paddingBottom: 75 }}>
            <Grid item md={12} style={{ display: 'flex' }}>
                <GridCenter>{i18next.t('trips.multipleBook.date')}</GridCenter>
                <GridCenter>{i18next.t('trips.multipleBook.status')}</GridCenter>
            </Grid>
            <ComponentWrapped
                listOfTrips={listOfTrips}
                handleMouseOver={handleMouseOver}
                handleCancelTrip={handleCancelTrip}
                handleRetryTrip={handleRetryTrip}
                serviceType={serviceType}
            />
            <AlertDialog
                open={openModalError}
                onClickAccept={() => setOpenModalError(false)}
                onClose={() => setOpenModalError(false)}
                title={""}
                content={
                    () => <ShowErrorComponent
                        errorsToShow={errorsToShow}
                    />
                }
            />
            <AlertDialog
                open={openModalCancel}
                title={isPending ? i18next.t('trips.multipleBook.cancelingTrip') : i18next.t('customers.trips.page.alert_dialog.titleDelete')}
                onClickAccept={() => {
                    cancelTripPlanned(tripToCancel.tripId, () => setOpenModalCancel(false))
                }}
                conditionDisableCancel={isPending}
                conditionDisableAccept={isPending}
                content={
                    () => isPending ? <Spinner loading={isPending} /> : null
                }
                onClickCancel={() => setOpenModalCancel(false)}
            />
            <AlertDialog
                open={openModalRetry}
                onClickCancel={() => setOpenModalRetry(false)}
                title={isPending ? i18next.t('trips.multipleBook.retringTrip') : i18next.t('trips.multipleBook.retryTripTitle')}
                content={() =>
                    withSelectNewTimeComponent(serviceType, tripToRetry.pickupHours, newTimeToRetryTrip, setNewTimeToRetryTrip, isPending)
                }
                conditionDisableAccept={newTimeToRetryTrip == '' || isPending}
                conditionDisableCancel={isPending}
                onClickAccept={handleRetryTripAccept}

            />
        </Grid>
    )
}

export default MultipleTrips