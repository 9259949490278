import React from 'react';
import { Chart } from "react-google-charts";
import PropTypes from 'prop-types';
import { FilterOptions, options, DownloadChart } from '../../../customFunctions';
import i18next from 'i18next';


const filterDataByGroup = (data, groupedBy, valuesFilter) => {
    switch (groupedBy) {
        case 'municipalities':
            return data.filter(item => item.municipalities?.some(r => valuesFilter.includes(r)))
        case 'regions':
            return data.filter(item => item.regions?.some(r => valuesFilter.includes(r)))
        case 'otps':
            return data.filter(item => item.otps?.some(r => valuesFilter.includes(r)))
        case 'services':
            return data.filter(item => item.services?.some(r => valuesFilter.includes(r)))
        case 'tenants':
            return data.filter(item => item.tenants?.some(r => valuesFilter.includes(r)))
        default:
            return data
    }
}


const filteredData = (data, filterOptions, title, valuesFilter) => {
    const { groupedBy } = filterOptions
    let dataValue = filterDataByGroup(data, groupedBy, valuesFilter)
    let optionsDrivers = Array.from(new Set(dataValue.map(item => item.fullName)))
    let results = []
    results.push(['Services', 'Total'])
    optionsDrivers.forEach(driverName => {
        results.push([driverName, dataValue.filter(item => item.fullName === driverName).length])
    });
    console.log('results', results)
    return results;
}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const TripsAcceptedByDriver = ({
    data,
    title,
    groupedBy = "municipalities",
    value = [],
    filterByDate = true,
    startDate = "2022-01-01",
    finishDate = "2022-02-01",
    timeUnits
}) => {

    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits);

    const chartType = "Bar"

    return (
        <>
            {
                filteredData(data, filterOptions, title, value).filter(([a, b]) => b > 0).length > 0 ?
                    (
                        <React.Fragment>
                            <Chart
                                key={Math.random()}
                                graphID={'chart_0'}
                                chartType={chartType}
                                width="100%"
                                height="400px"
                                data={filteredData(data, filterOptions, title, value)}
                                options={options(chartProps, null)}
                            />
                            <DownloadChart key={Math.random()} img64={0} />
                        </React.Fragment>


                    ) : null
            }
        </>
    );
}

TripsAcceptedByDriver.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(["municipalities", "regions", "otps", "services", "tenants"]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default TripsAcceptedByDriver;