import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDriverTable } from "../../../../../helpers/kpiHelper";
import KpiWrapper from "../../../components/kpiWrapper";

export default function DriversJourneyTable({ isLoading }) {
    const { t } = useTranslation()
    const journeys = useSelector(state => state.kpiReducer.journeys)
    const isJourneysLoading = useSelector(state => state.kpiReducer.journeysLoading)

    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)

    const { data } = useMemo(() => {
        if (isJourneysLoading || isConfirmTripsLoading) {
            return { data: [] }
        }

        let data = getDriverTable({ journeys, confirmTrips })
        return { data }
    }, [journeys, confirmTrips])

    isLoading = isLoading || isJourneysLoading || isConfirmTripsLoading

    return (
        <KpiWrapper isLoading={isLoading} skeletonVariant="rect">
            <TableContainer component={Box}>
                <Table arial-label="reservation table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("commandBox.drivers.driverTable.driver")}</TableCell>
                            <TableCell align="right">{t("commandBox.drivers.driverTable.journeys")}</TableCell>
                            <TableCell align="right">{t("commandBox.drivers.driverTable.lateTrips")}</TableCell>
                            <TableCell align="right">{t("commandBox.drivers.driverTable.price")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(data).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell align="right">{value.journeys}</TableCell>
                                    <TableCell align="right">{value.punctuality ? Math.round(value.punctuality / value.totalTrips * 100) : 0}%</TableCell>
                                    <TableCell align="right">{value.price}€</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </KpiWrapper>
    )
}