import React from 'react';
import store from '../../../../redux/store';
import ActiveServicesChart from './numActiveServices';
import VehiclesPerServiceChart from './vehiclesPerService';

const KPI_SELECTED = { 'TOTAL_SERVICES': 'totalServices', 'VEHICLES_PER_SERVICE': 'totalVehiclesByService' }
// 0: "Total de servicios"
// 1: "Número de vehículos por servicio"

export const getDataForGeneralDataChart = (kpiSelected) => {
    if (kpiSelected == KPI_SELECTED.TOTAL_SERVICES) {
        //console.log("totalservices -> return allservices data from reducer")
        return store.getState().serviceReducer.allServices ?? null;
    }
    else if (kpiSelected == KPI_SELECTED.VEHICLES_PER_SERVICE) {
        //console.log("vehiclesPerService -> return vehiclesgeneraldatakpi data from reducer")
        return store.getState().vehicleReducer.vehiclesGeneralDataKPI ?? null
    }
}

export const getGeneralDataChart = (kpiSelected, chartProps, data) => {
    const { chartType, title, groupedBy, value, filterByDate, startDate, finishDate } = chartProps;
    if (kpiSelected == KPI_SELECTED.TOTAL_SERVICES) {
        return (
            <ActiveServicesChart
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
            />
        )
    }
    else if (kpiSelected == KPI_SELECTED.VEHICLES_PER_SERVICE) {
        return (
            <VehiclesPerServiceChart
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
            />
        )
    } else {
        return null
    }
}