import es from './default.json'
import en from './default.en.json'
import ca from './default.cat.json'

import alert_dialog_es from '../../components/alert-dialog/locales/default.json'
import alert_dialog_en from '../../components/alert-dialog/locales/default.en.json'
import alert_dialog_ca from '../../components/alert-dialog/locales/default.cat.json'
import register_es from '../../components/register/locales/default.json'
import register_en from '../../components/register/locales/default.en.json'
import register_ca from '../../components/register/locales/default.cat.json'
import login_es from '../../components/login/locales/default.json'
import login_en from '../../components/login/locales/default.en.json'
import login_ca from '../../components/login/locales/default.cat.json'
import profile_es from '../../components/profile/locales/default.json'
import profile_en from '../../components/profile/locales/default.en.json'
import profile_ca from '../../components/profile/locales/default.cat.json'
import resetPassword_es from '../../components/resetPassword/locales/default.json'
import resetPassword_en from '../../components/resetPassword/locales/default.en.json'
import resetPassword_ca from '../../components/resetPassword/locales/default.cat.json'
import updatePassword_es from '../../components/updatePassword/locales/default.json'
import updatePassword_en from '../../components/updatePassword/locales/default.en.json'
import updatePassword_ca from '../../components/updatePassword/locales/default.cat.json'
import operators_es from '../../components/operators/locales/default.json'
import operators_en from '../../components/operators/locales/default.en.json'
import operators_ca from '../../components/operators/locales/default.cat.json'
import roles_es from '../../components/roles/locales/default.json'
import roles_en from '../../components/roles/locales/default.en.json'
import roles_ca from '../../components/roles/locales/default.cat.json'
import drivers_es from '../../components/drivers/locales/default.json'
import drivers_en from '../../components/drivers/locales/default.en.json'
import drivers_ca from '../../components/drivers/locales/default.cat.json'
import vehicles_es from '../../components/vehicles/locales/default.json'
import vehicles_en from '../../components/vehicles/locales/default.en.json'
import vehicles_ca from '../../components/vehicles/locales/default.cat.json'
import otp_es from '../../components/otp/locales/default.json'
import otp_en from '../../components/otp/locales/default.en.json'
import otp_ca from '../../components/otp/locales/default.cat.json'
import areas_es from '../../components/areas/locales/default.json'
import areas_en from '../../components/areas/locales/default.en.json'
import areas_ca from '../../components/areas/locales/default.cat.json'
import shared_es from '../../components/shared/locales/default.json'
import shared_en from '../../components/shared/locales/default.en.json'
import shared_ca from '../../components/shared/locales/default.cat.json'
import services_comp_es from '../../components/services/locales/default.json'
import services_comp_en from '../../components/services/locales/default.en.json'
import services_comp_ca from '../../components/services/locales/default.cat.json'
import tenants_es from '../../components/tenants/locales/default.json'
import tenants_en from '../../components/tenants/locales/default.en.json'
import tenants_ca from '../../components/tenants/locales/default.cat.json'
/* import customers_es from '../../components/customers/locales/default.json'
import customers_en from '../../components/customers/locales/default.en.json'
import customers_cat from '../../components/customers/locales/default.cat.json' */

import dashboard_es from '../../components/dashboard/locales/default.json'
import dashboard_en from '../../components/dashboard/locales/default.en.json'
import dashboard_ca from '../../components/dashboard/locales/default.cat.json'
import constansts_es from '../../constants/locales/default.json'
import constansts_en from '../../constants/locales/default.en.json'
import constansts_ca from '../../constants/locales/default.cat.json'
import services_es from '../../services/locales/default.json'
import services_en from '../../services/locales/default.en.json'
import services_ca from '../../services/locales/default.cat.json'
import maps_es from '../../components/maps/locales/default.json'
import maps_en from '../../components/maps/locales/default.en.json'
import maps_ca from '../../components/maps/locales/default.cat.json'
import journeys_es from '../../components/journeys/locales/default.json'
import journeys_en from '../../components/journeys/locales/default.en.json'
import journeys_ca from '../../components/journeys/locales/default.cat.json'
import routes_es from '../../components/routes/locales/default.json'
import routes_en from '../../components/routes/locales/default.en.json'
import routes_ca from '../../components/routes/locales/default.cat.json'
import zones_es from '../../components/zones/locales/default.json'
import zones_en from '../../components/zones/locales/default.en.json'
import zones_ca from '../../components/zones/locales/default.cat.json'
import incidents_es from '../../components/incidents/locales/default.json'
import incidents_en from '../../components/incidents/locales/default.en.json'
import incidents_ca from '../../components/incidents/locales/default.cat.json'
import journeyTrips_es from '../../components/journeyTrips/locales/default.json'
import journeyTrips_en from '../../components/journeyTrips/locales/default.en.json'
import journeyTrips_ca from '../../components/journeyTrips/locales/default.cat.json'
import chat_es from '../../components/chat/locales/default.json'
import chat_en from '../../components/chat/locales/default.en.json'
import chat_ca from '../../components/chat/locales/default.cat.json'
import customers_es from '../../components/customers/locales/default.json'
import customers_en from '../../components/customers/locales/default.en.json'
import customers_ca from '../../components/customers/locales/default.cat.json'
import trips_es from '../../components/requestTrips/locales/default.json'
import trips_en from '../../components/requestTrips/locales/default.en.json'
import trips_ca from '../../components/requestTrips/locales/default.cat.json'
import notifications_es from '../../components/notifications/locales/default.json'
import notifications_en from '../../components/notifications/locales/default.en.json'
import notifications_ca from '../../components/notifications/locales/default.cat.json'
import reports_es from '../../components/reports/locales/default.json'
import reports_en from '../../components/reports/locales/default.en.json'
import reports_ca from '../../components/reports/locales/default.cat.json'
import tripsAll_es from '../../components/trips/locales/default.json'
import tripsAll_en from '../../components/trips/locales/default.en.json'
import tripsAll_cat from '../../components/trips/locales/default.cat.json'
import commandBox_es from '../../components/commandBox/locales/default.json'
import commandBox_en from '../../components/commandBox/locales/default.en.json'
import commandBox_cat from '../../components/commandBox/locales/default.cat.json'
import noAuth_es from '../../components/no-auth-page/local/default.json'
import noAuth_en from '../../components/no-auth-page/local/default.en.json'
import noAuth_cat from '../../components/no-auth-page/local/default.cat.json'
import observations_es from '../../components/observations/locales/default.json'
import observations_en from '../../components/observations/locales/default.en.json'
import observations_cat from '../../components/observations/locales/default.cat.json'

es['es']['alert-dialog'] = alert_dialog_es['es']
en['en']['alert-dialog'] = alert_dialog_en['en']
ca['ca']['alert-dialog'] = alert_dialog_ca['ca']
es['es'].register = register_es['es']
en['en'].register = register_en['en']
ca['ca'].register = register_ca['ca']
es['es'].operators = operators_es['es']
en['en'].operators = operators_en['en']
ca['ca'].operators = operators_ca['ca']
es['es'].roles = roles_es['es']
en['en'].roles = roles_en['en']
ca['ca'].roles = roles_ca['ca']
es['es'].drivers = drivers_es['es']
en['en'].drivers = drivers_en['en']
ca['ca'].drivers = drivers_ca['ca']
es['es'].vehicles = vehicles_es['es']
en['en'].vehicles = vehicles_en['en']
ca['ca'].vehicles = vehicles_ca['ca']
es['es'].otp = otp_es['es']
en['en'].otp = otp_en['en']
ca['ca'].otp = otp_ca['ca']
es['es'].areas = areas_es['es']
en['en'].areas = areas_en['en']
ca['ca'].areas = areas_ca['ca']
es['es'].shared = shared_es['es']
en['en'].shared = shared_en['en']
ca['ca'].shared = shared_ca['ca']
es['es'].services_comp = services_comp_es['es']
en['en'].services_comp = services_comp_en['en']
ca['ca'].services_comp = services_comp_ca['ca']
es['es'].login = login_es['es']
en['en'].login = login_en['en']
ca['ca'].login = login_ca['ca']
es['es'].profile = profile_es['es']
en['en'].profile = profile_en['en']
ca['ca'].profile = profile_ca['ca']
es['es'].resetPassword = resetPassword_es['es']
en['en'].resetPassword = resetPassword_en['en']
ca['ca'].resetPassword = resetPassword_ca['ca']
es['es'].updatePassword = updatePassword_es['es']
en['en'].updatePassword = updatePassword_en['en']
ca['ca'].updatePassword = updatePassword_ca['ca']
es['es'].dashboard = dashboard_es['es']
en['en'].dashboard = dashboard_en['en']
ca['ca'].dashboard = dashboard_ca['ca']
/* es['es'].customers = customers_es['es'];
en['en'].customers = customers_en['en'];
ca['ca'].customers = customers_ca['ca']; */

es['es'].material_table = constansts_es['es']
en['en'].material_table = constansts_en['en']
ca['ca'].material_table = constansts_ca['ca']
es['es'].validations = constansts_es['es'].validations
en['en'].validations = constansts_en['en'].validations
ca['ca'].validations = constansts_ca['ca'].validations
es['es'].services = services_es['es'].services
en['en'].services = services_en['en'].services
ca['ca'].services = services_ca['ca'].services
es['es'].maps = maps_es['es']
en['en'].maps = maps_en['en']
ca['ca'].maps = maps_ca['ca']
es['es'].journeys = journeys_es['es']
en['en'].journeys = journeys_en['en']
ca['ca'].journeys = journeys_ca['ca']
es['es'].routes = routes_es['es']
en['en'].routes = routes_en['en']
ca['ca'].routes = routes_ca['ca']
es['es'].zones = zones_es['es']
en['en'].zones = zones_en['en']
ca['ca'].zones = zones_ca['ca']
es['es'].incidents = incidents_es['es']
en['en'].incidents = incidents_en['en']
ca['ca'].incidents = incidents_ca['ca']
es['es'].trips = trips_es['es']
en['en'].trips = trips_en['en']
ca['ca'].trips = trips_ca['ca']
es['es'].chat = chat_es['es']
en['en'].chat = chat_en['en']
ca['ca'].chat = chat_ca['ca']
es['es'].customers = customers_es['es']
en['en'].customers = customers_en['en']
ca['ca'].customers = customers_ca['ca']
es['es'].trips = trips_es['es']
en['en'].trips = trips_en['en']
ca['ca'].trips = trips_ca['ca']
es['es'].journeyTrips = journeyTrips_es['es']
en['en'].journeyTrips = journeyTrips_en['en']
ca['ca'].journeyTrips = journeyTrips_ca['ca']
es['es'].notifications = notifications_es['es']
en['en'].notifications = notifications_en['en']
ca['ca'].notifications = notifications_ca['ca']
es['es'].reports = reports_es['es']
en['en'].reports = reports_en['en']
ca['ca'].reports = reports_ca['ca']
en['en'].tripsAll = tripsAll_en['en']
es['es'].tripsAll = tripsAll_es['es']
ca['ca'].tripsAll = tripsAll_cat['cat']
en['en'].tenants = tenants_en['en']
es['es'].tenants = tenants_es['es']
ca['ca'].tenants = tenants_ca['ca']

en['en'].commandBox = commandBox_en['en']
es['es'].commandBox = commandBox_es['es']
ca['ca'].commandBox = commandBox_cat['ca']

en['en'].noAuth = noAuth_en['en']
es['es'].noAuth = noAuth_es['es']
ca['ca'].noAuth = noAuth_cat['ca']

en['en'].observations = observations_en['en']
es['es'].observations = observations_es['es']
ca['ca'].observations = observations_cat['ca']

export { es, en, ca }
