import React from 'react';
import Spinner from '../../shared/spinner';
import DriverForm from '../form';

class DriverEditView extends React.Component {

    componentDidMount() {
        this.props.getVehiclesByOTP(this.props.driver.otpId)
    }

    render() {
        const { driverReducer, otpReducer, vehicleReducer } = this.props
        return (
            <React.Fragment>
                {driverReducer.pending || otpReducer.pending ?
                    <Spinner loading={driverReducer.pending || otpReducer.pending} /> :
                    <DriverForm
                        {...this.props}
                    />
                }
            </React.Fragment>
        );
    }
}

export default DriverEditView;