import React from 'react';
import store from '../../../../redux/store';
import { tripActions } from '../../../../redux/actions/trip_actions';
import TotalTripsByJourney from './totalTrips';
import TotalPassengerByJourney from './totalPassengers';
import AvgDrivingTimeByJourney from './avgDrivingTime';
import DistanceByJourney from './distance';

const KPI_SELECTED = {
    'TIME_AVG': 'timeAVGByJourney',
    'TOTAL_KM': 'totalKMByJourney',
    'TOTAL_PASSENGER': 'totalPassengersByJourney',
    'TOTAL_TRIPS': 'totalTripsByJourney',
}
// punctualityTrips: "Puntualidad por viaje (desv. +-5')"
// timeDistributionTrips: "Distribución horaria de los viajes"
// totalTrips: "Total de viajes"

export const getDataForJourneyChart = (kpiSelected, chartProps) => {
    if (kpiSelected == KPI_SELECTED.MATRIX || kpiSelected == KPI_SELECTED.TIME_AVG || kpiSelected == KPI_SELECTED.TOTAL_KM || kpiSelected == KPI_SELECTED.TOTAL_PASSENGER || kpiSelected == KPI_SELECTED.TOTAL_TRIPS) {
        const { startDate, finishDate } = chartProps;
        return store.dispatch(tripActions.getJourneyKPI(startDate, finishDate)).then(
            trips => {
                return trips
            },
            (error) => {
                console.log("error getDataForTripsChart => ", error)
                return null
            }
        )
    }
}

export const getJourneyChart = (kpiSelected, chartProps, data) => {
    const { chartType, title, groupedBy, value, filterByDate, startDate, finishDate, timeUnits } = chartProps;
    if (kpiSelected == KPI_SELECTED.TOTAL_TRIPS) {
        return (
            <TotalTripsByJourney
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
            />
        )
    }
    else if (kpiSelected == KPI_SELECTED.TOTAL_PASSENGER) {
        return (
            <TotalPassengerByJourney
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
            />
        )
    }
    else if (kpiSelected == KPI_SELECTED.TIME_AVG) {
        return (
            <AvgDrivingTimeByJourney
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                timeUnits={timeUnits}
            />
        )
    } else if (kpiSelected == KPI_SELECTED.TOTAL_KM) {
        return(
            <DistanceByJourney
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
            />
        )
    } else {
        return null
    }
}