import React from 'react';
import TotalTripsAccumulated from './numTotalTrips';
import store from '../../../../redux/store';
import { tripActions } from '../../../../redux/actions/trip_actions';
import TripsTimeDistribution from './timeDistribution';
import TripsPuntuality from './puntuality';
import MatrixOriginDestinationTrips from './matrixOriginDestination';
import DistanceByTrip from './distance';

const KPI_SELECTED = {
    'TOTAL_TRIPS': 'totalTrips',
    'PUNCTUALITY_TRIPS': 'punctualityTrips',
    'TIME_DISTRIBUTION_TRIPS': 'timeDistributionTrips',
    'MATRIX': 'matrixOriginDestination',
    'AVG_KM_BY_TRIP':'avgkmByTrip'
}

export const getDataForTripsChart = (kpiSelected, chartProps) => {
    const { startDate, finishDate } = chartProps;
    if (kpiSelected == KPI_SELECTED.TOTAL_TRIPS || kpiSelected == KPI_SELECTED.TIME_DISTRIBUTION_TRIPS || kpiSelected == KPI_SELECTED.PUNCTUALITY_TRIPS || kpiSelected	 == KPI_SELECTED.AVG_KM_BY_TRIP) {
        return store.dispatch(tripActions.getConfirmedTripsKPI(startDate, finishDate)).then(
            trips => {
                return trips
            },
            (error) => {
                console.log("error getDataForTripsChart => ", error)
                return null
            }
        )
    } else if (kpiSelected === KPI_SELECTED.MATRIX) {
        return store.dispatch(tripActions.getConfirmedGroupedTripsKPI(startDate, finishDate)).then(
            trips => {
                return trips
            },
            (error) => {
                console.log("error getConfirmedGroupedTripsKPI", error)
                return null
            }
        )
    }
}

export const getTripsChart = (kpiSelected, chartProps, data) => {
    const { chartType, title, groupedBy, value, filterByDate, startDate, finishDate, timeUnits, groupByFilter, tripOrigin } = chartProps;
    if (kpiSelected == KPI_SELECTED.TOTAL_TRIPS) {
        return (
            <TotalTripsAccumulated
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                timeUnits={timeUnits}
            />
        )
    }
    else if (kpiSelected == KPI_SELECTED.PUNCTUALITY_TRIPS) {
        return (
            <TripsPuntuality
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                timeUnits={timeUnits}

            />
        )
    }
    else if (kpiSelected == KPI_SELECTED.TIME_DISTRIBUTION_TRIPS) {
        return (
            <TripsTimeDistribution
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                timeUnits={timeUnits}
                tripOrigin={tripOrigin}
            />
        )
    } else if(kpiSelected === KPI_SELECTED.AVG_KM_BY_TRIP){
        return(
            <DistanceByTrip
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
            />
        )
    }
    else if (kpiSelected === KPI_SELECTED.MATRIX)
        return (
            <MatrixOriginDestinationTrips
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                timeUnits={timeUnits}
                groupByFilter={groupByFilter}

            />
        )
    else {
        return null
    }
}