import { Grid, Paper, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import ReservationTable from "./components/reservationTable";

export default function ReservationsKPI() {
    const classes = useStyles()

    const servicesLoading = useSelector(state => state.serviceReducer.pending)

    return (
        <section className={classes.wrapper}>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Paper className={[classes.paper, classes.paddingGraph]} >
                        <ReservationTable isLoading={servicesLoading} />
                    </Paper>
                </Grid>
            </Grid>
        </section>
    )
}

const useStyles = makeStyles({
    paper: {
        padding: "10px",
        minHeight: "250px"
    },

    paddingGraph: {
        padding: "10px 20px"
    },
    wrapper: {
        display: "grid",
        gap: "5px"
    }
})