import React from 'react'
import {
    FormControl,
    Grid,
    Hidden,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import utils from '../../../helpers/validations'
import { factory } from '../../../helpers/factory'
import Spinner from '../../shared/spinner'
import { history } from '../../../helpers/history'
import DateVehicle from './dateVehicle'
import GoogleMaps from './mapsInput'
import { googleMapsService } from '../../../services/googleMapsService'
import { allowAccessRole } from '../../../helpers/roles'
import i18next from 'i18next'
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants'

const styles = makeStyles(theme => ({
    ' & .MuiFormControl-root': {
        width: '100%',
    },
}))

const MONGO_DB_NULL = "000000000000000000000000"

const filter = createFilterOptions()

class VehiclesFormView extends React.Component {
    constructor(props) {
        super()
        const vehicle = props.vehicle ? props.vehicle : {}
        const create = props.nextAction == 'create' ? true : false
        this.state = {
            id: vehicle.id || '',
            brand: vehicle.brand || '',
            model: vehicle.model || '',
            plateNumber: vehicle.plateNumber || '',
            paxCapacity: create ? '' : vehicle.capacity,
            luggageCapacity: create ? '' : vehicle.luggageCapacity,
            hasWheelChairSpace: vehicle.wheelchairPlaces || false,
            returnToStart: vehicle.returnToStart || false,
            descriptionEn: vehicle.descriptionEn || "",
            descriptionEs: vehicle.descriptionEs || "",
            descriptionCa: vehicle.descriptionCa || "",
            initialPosition: vehicle.initialPosition || {
                address: '',
                lat: '',
                lng: '',
            },
            priority: vehicle.vehiclePriority || '',
            shiftDays:
                vehicle.shiftDays && vehicle.shiftDays.length > 0
                    ? vehicle.shiftDays
                    : [
                        {
                            weekDays: [],
                            earliestStart: '',
                            latestArrival: '',
                            endsNextDay: true,
                            driverId: '',
                            driverName: '',
                            serviceId: '',
                            serviceName: ''
                        },
                    ],
            otpId: vehicle.otpId || '',
            otpName: vehicle.otpName || '',
            otpNewName: '',
            otpVatNumber: '',
            otpContactPerson: '',
            otpEmail: '',
            otpPhoneNumber: '',
            errors: {
                brand: { result: false, message: '' },
                descriptionEs: { result: true, message: '' },
                descriptionCa: { result: true, message: '' },
                descriptionEn: { result: true, message: '' },
                priority: { result: props.detail ? true : false, message: '' },
                initialPosition: {
                    result: props.detail ? true : false,
                    message: '',
                },
                model: { result: false, message: '' },
                surname2: { result: true, message: '' },
                plateNumber: {
                    result: props.detail ? true : false,
                    message: '',
                },
                paxCapacity: {
                    result: props.detail ? true : false,
                    message: '',
                },
                luggageCapacity: {
                    result: props.detail ? true : false,
                    message: '',
                },
                otpId: { result: props.detail ? true : false, message: '' },
                shiftDays: [
                    {
                        earliestStart: { result: true, message: '' },
                        latestArrival: { result: true, message: '' },
                        weekDays: { result: true, message: '' },
                        serviceId: { result: true, message: '' },
                        driverId: { result: true, message: '' },
                    },
                ],
                otpNewName: { result: true, message: '' },
                otpVatNumber: { result: true, message: '' },
                otpEmail: { result: true, message: '' },
                otpContactPerson: { result: true, message: '' },
                otpPhoneNumber: { result: true, message: '' },
            },
        }
        if (this.state.shiftDays.length > 1) {
            const errors = this.state.errors
            this.state.shiftDays.forEach(function (element, i) {
                errors.shiftDays[i] = {
                    earliestStart: { result: true, message: '' },
                    latestArrival: { result: true, message: '' },
                    weekDays: { result: true, message: '' },
                    serviceId: { result: true, message: '' },
                    driverId: { result: true, message: '' },
                }
            })
            this.state.errors = errors
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.addTime = this.addTime.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.onClickBack = this.onClickBack.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    handleChange(event) {
        let { name, value } = event.target
        let errors = this.state.errors
        if (name == 'plateNumber') {
            value = value.toUpperCase()
        }
        errors[name].message = ''
        this.setState({ [name]: value })
    }

    handleChangeDate(event, index) {
        let { name, value } = event.target
        let errors = this.state.errors
        var date = new Date()
        var array = value.split(':')
        date.setHours(array[0], array[1])
        array = name.split('_')
        let updatedShiftDays = this.state.shiftDays
        updatedShiftDays[index][array[0]] = value
        errors.shiftDays[index][array[0]].message = ''
        this.setState({ shiftDays: updatedShiftDays })
    }

    addTime() {
        let errors = this.state.errors
        errors.shiftDays.push({
            earliestStart: { result: false, message: '' },
            latestArrival: { result: false, message: '' },
            weekDays: { result: false, message: '' },
            serviceId: { result: true, message: '' },
            driverId: { result: true, message: '' },
        })
        this.setState({
            shiftDays: [
                ...this.state.shiftDays,
                {
                    weekDays: [],
                    earliestStart: '',
                    latestArrival: '',
                    endsNextDay: true,
                    driverId: '',
                    driverName: '',
                    serviceId: '',
                    serviceName: ''
                },
            ],
            errors,
        })
    }

    removeItem(index) {
        let errors = this.state.errors
        errors.shiftDays = errors.shiftDays.filter((_, i) => i !== index)
        this.setState({
            shiftDays: this.state.shiftDays.filter((_, i) => i !== index),
            errors,
        })
    }

    onClickBack() {
        history.goBack()
        this.props.clearVehicle()
    }

    updateBlur(name, value) {
        this.setState({
            [name]: value.toUpperCase(),
        })
        if (name == 'brand') {
            this.props.getModeldsByBrandString(value)
        }
    }

    validateForm() {
        let errors = this.state.errors
        let state = this.state
        errors.brand = utils.required(this.state.brand)
        errors.model = utils.required(this.state.model)
        errors.plateNumber = utils.required(this.state.plateNumber)
        if (errors.plateNumber.result && !this.props.detail) {
            errors.plateNumber = utils.validateMatricula(this.state.plateNumber)
        }
        if (!this.props.detail) {
            errors.paxCapacity = utils.required(this.state.paxCapacity)
            errors.otpId = utils.required(this.state.otpId)
            errors.luggageCapacity = utils.required(this.state.luggageCapacity)
            errors.initialPosition = utils.required(
                this.state.initialPosition.address,
            )
            errors.priority = utils.required(this.state.priority)
            errors.shiftDays.forEach(function (element, i) {
                element.earliestStart = utils.required(
                    state.shiftDays[i].earliestStart,
                )
                element.latestArrival = utils.required(
                    state.shiftDays[i].latestArrival,
                )
                element.weekDays = utils.validateArray(
                    state.shiftDays[i].weekDays,
                )
                if (state.shiftDays[i].driverId !== '' && (state.shiftDays[i].serviceId === '' || state.shiftDays[i].serviceId === MONGO_DB_NULL))
                    element.serviceId = utils.required(state.shiftDays[i].serviceId === MONGO_DB_NULL ? '' : state.shiftDays[i].serviceId)
                if (state.shiftDays[i].serviceId !== '' && (state.shiftDays[i].driverId === '' || state.shiftDays[i].driverId === MONGO_DB_NULL))
                    element.driverId = utils.required(state.shiftDays[i].driverId === MONGO_DB_NULL ? '' : state.shiftDays[i].driverId)
            })
            if (this.state.otpId.id == 'createOTP') {
                errors.otpNewName = utils.required(this.state.otpNewName)
                errors.otpVatNumber = utils.validateCIF_Nif(
                    this.state.otpVatNumber,
                )
                errors.otpContactPerson = utils.required(
                    this.state.otpContactPerson,
                )
                errors.otpPhoneNumber = utils.required(
                    this.state.otpPhoneNumber,
                )
                if (errors.otpPhoneNumber.result && !this.props.detail) {
                    errors.otpPhoneNumber = utils.validatePhone(
                        this.state.otpPhoneNumber,
                    )
                }
                errors.otpEmail = utils.required(this.state.otpEmail)
                if (errors.otpEmail.result)
                    errors.otpEmail = utils.validateEmail(this.state.otpEmail)
            } else {
                errors.otpNewName = { result: true, message: '' }
                errors.otpVatNumber = { result: true, message: '' }
                errors.otpContactPerson = { result: true, message: '' }
                errors.otpPhoneNumber = { result: true, message: '' }
                errors.otpEmail = { result: true, message: '' }
            }
        }

        this.setState({ errors })
    }

    componentWillUnmount() { }

    isFormValid() {
        let valid = true
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (element == 'shiftDays')
                this.state.errors.shiftDays.forEach(function (item, i) {
                    let propertiesItem = Object.getOwnPropertyNames(item)
                    propertiesItem.forEach(prop => {
                        if (!item[prop].result) valid = false
                    })
                })
            else if (!this.state.errors[element].result) valid = false
        })
        return valid
    }

    onChangeOtp = (event, newValue) => {
        let errors = this.state.errors
        errors.otpId.message = ''
        this.setState({
            otpId: newValue ? newValue : '',
        })
        if (newValue && this.state.initialPosition.address != '') {
            this.props.positionInitialIsInOtpServiceZones(
                newValue.id,
                this.state.initialPosition.lat,
                this.state.initialPosition.long != null
                    ? this.state.initialPosition.long
                    : this.state.initialPosition.lon,
            )
        }
        if (newValue && newValue.id != 'createOTP') {
            this.props.getDriversFullName(newValue.id)
            this.props.getServicesByOtp(newValue.id)
            if (
                newValue &&
                newValue.otpServices &&
                newValue.otpServices.length == 0
            ) {
                this.props.warning(i18next.t('vehicles.form.page.notService'))
            }
        } else {
            this.props.cleanDriversFullName()
        }
    }

    onChangePriority = (event, newValue) => {
        let errors = this.state.errors
        errors.priority.message = ''
        this.setState({
            priority: newValue,
        })
    }

    onChangeBrand = (event, newValue) => {
        let errors = this.state.errors
        errors.brand.message = ''
        if (newValue && newValue.inputValue) {
            this.setState({
                brand: newValue.inputValue.toUpperCase(),
                model: '',
            })
            this.props.clearModels()
            return
        }
        this.setState({
            brand: newValue ? newValue.brandName : '',
            model: '',
        })
        this.props.clearModels()
        if (newValue && newValue.brandId) {
            this.props.getModeldsByBrands(newValue.brandId)
        }
    }

    onChangeInitialPosition = newValue => {
        let errors = this.state.errors
        errors.initialPosition.message = ''
        if (newValue) {
            googleMapsService
                .getLocation(newValue.description)
                .then(location => {
                    this.setState({
                        initialPosition: {
                            address: newValue.description,
                            lat: location.lat,
                            long: location.lng,
                        },
                    })
                    if (this.state.otpId != null) {
                        this.props.positionInitialIsInOtpServiceZones(
                            this.state.otpId.id
                                ? this.state.otpId.id
                                : this.state.otpId,
                            location.lat,
                            location.lng,
                        )
                    }
                })
        } else {
            this.setState({
                initialPosition: { address: '', location: null },
            })
        }
    }

    filterOptions(options, params) {
        const filtered = filter(options, params)

        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                title: `Agregar "${params.inputValue}"`,
            })
        }
        return filtered
    }

    onChangeModel = (event, newValue) => {
        let errors = this.state.errors
        errors.model.message = ''
        if (newValue && newValue.inputValue) {
            this.setState({
                model: newValue.inputValue.toUpperCase(),
            })
            return
        }
        this.setState({
            model: newValue ? newValue.model : '',
        })
    }

    getOptionLabelBrand = option => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
            return option
        }
        if (option.inputValue) {
            return option.inputValue
        }
        return option.brandName
    }

    getOptionLabelModel = option => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
            return option
        }
        if (option.inputValue) {
            return option.inputValue
        }
        return option.model
    }

    onSubmit = event => {
        event.preventDefault()
        this.validateForm()
        if (this.isFormValid()) {
            const vehicle = factory.createVehicle(
                this.state.shiftDays,
                this.state.id,
                this.state.brand,
                this.state.model,
                this.state.plateNumber,
                this.state.paxCapacity,
                this.state.luggageCapacity,
                this.state.hasWheelChairSpace ? 1 : 0,
                this.state.otpId.id ? this.state.otpId.id : this.state.otpId,
                this.state.returnToStart,
                this.state.otpId.name
                    ? this.state.otpId.name
                    : this.state.otpName,
                this.state.shiftHours,
                this.state.initialPosition,
                this.state.priority.id
                    ? this.state.priority.id
                    : this.state.priority,
                this.state.descriptionCa,
                this.state.descriptionEs,
                this.state.descriptionEn,

            )
            this.props.sendVehicle(vehicle)
        }
    }

    handleShiftDaysUpdates = (days, option = null, index) => {
        let updatedShiftDays = this.state.shiftDays
        let errors = this.state.errors
        if (option) {
            switch (true) {
                case option === '1':
                    updatedShiftDays[index].weekDays = [6, 0]
                    break
                case option === '2':
                    updatedShiftDays[index].weekDays = [1, 2, 3, 4, 5, 6, 0]
                    break
                case option === '3':
                    updatedShiftDays[index].weekDays = [1, 2, 3, 4, 5]
                    break
                default:
                    updatedShiftDays[index].weekDays = days.map(d =>
                        d.hasOwnProperty('id') ? parseInt(d.id) : d,
                    )
            }
        } else {
            updatedShiftDays[index].weekDays = days.map(d =>
                d.hasOwnProperty('id') ? parseInt(d.id) : d,
            )
        }
        errors.shiftDays[index].weekDays.message = ''
        this.setState({ shiftDays: updatedShiftDays })
    }

    handleChangeDriver = (driver, option = null, index) => {
        let updatedShiftDays = this.state.shiftDays
        let errors = this.state.errors
        errors.shiftDays[index].driverId = { result: true, message: '' }
        errors.shiftDays[index].serviceId = { result: true, message: '' }
        if (driver && driver.id != null) {
            updatedShiftDays[index].driverId = driver.id
            updatedShiftDays[index].driverName = driver.fullName
            updatedShiftDays[index].pushToken = driver.pushToken ? driver.pushToken : ''
            updatedShiftDays[index].notificationLanguage = driver.language ? driver.language : ''
        } else {
            updatedShiftDays[index].driverId = ''
            updatedShiftDays[index].driverName = ''
            updatedShiftDays[index].pushToken = ''
            updatedShiftDays[index].notificationLanguage = ''
        }
        console.log(updatedShiftDays)
        this.setState({ shiftDays: updatedShiftDays, errors: errors })
    }

    handleChangeService = (service, option = null, index) => {
        let updatedShiftDays = this.state.shiftDays
        let errors = this.state.errors
        errors.shiftDays[index].serviceId = { result: true, message: '' }
        errors.shiftDays[index].driverId = { result: true, message: '' }
        if (service && service.id != null) {
            updatedShiftDays[index].serviceId = service.id
            updatedShiftDays[index].serviceName = service.name
        } else {
            updatedShiftDays[index].serviceId = ''
            updatedShiftDays[index].serviceName = ''
        }
        this.setState({ shiftDays: updatedShiftDays, errors: errors })
    }

    render() {
        const { t, vehicleReducer, otpReducer } = this.props
        const otps = otpReducer.results
        const brands = vehicleReducer.brands
        const models = vehicleReducer.models
        const priorities = vehicleReducer.priorities

        return (
            <React.Fragment>
                <Spinner loading={vehicleReducer.pending} />
                <h2 style={{ padding: 50 }}>{this.props.title}</h2>
                <form onSubmit={this.onSubmit} autoComplete="off" style={{ padding: 50 }}>
                    <Grid className={styles.root} container spacing={3}>
                        <Grid item md={3} xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="brand"
                                    value={this.state.brand || ''}
                                    onChange={this.onChangeBrand}
                                    onBlurCapture={e =>
                                        this.updateBlur('brand', e.target.value)
                                    }
                                    filterOptions={this.filterOptions}
                                    options={brands}
                                    freeSolo
                                    getOptionSelected={(option, value) =>
                                        option.value == value.value
                                    }
                                    getOptionLabel={this.getOptionLabelBrand}
                                    disabled={
                                        this.props.readOnly ||
                                        this.props.readEdit
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant={'outlined'}
                                            label={t(
                                                'vehicles.form.page.brand',
                                            )}
                                            helperText={
                                                this.state.errors.brand.message
                                            }
                                            name="brand"
                                            error={
                                                this.state.errors.brand.message
                                                    .length === 0
                                                    ? false
                                                    : true
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="model"
                                    value={this.state.model || ''}
                                    onChange={this.onChangeModel}
                                    onBlurCapture={e =>
                                        this.updateBlur('model', e.target.value)
                                    }
                                    options={models}
                                    freeSolo
                                    filterOptions={this.filterOptions}
                                    getOptionSelected={(option, value) =>
                                        option.value == value.value
                                    }
                                    getOptionLabel={this.getOptionLabelModel}
                                    disabled={
                                        this.props.readOnly ||
                                        this.props.readEdit
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant={'outlined'}
                                            label={t(
                                                'vehicles.form.page.model',
                                            )}
                                            helperText={
                                                this.state.errors.model.message
                                            }
                                            name="model"
                                            error={
                                                this.state.errors.model.message
                                                    .length === 0
                                                    ? false
                                                    : true
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t('vehicles.form.page.plate')}
                                    value={this.state.plateNumber || ''}
                                    variant="outlined"
                                    name="plateNumber"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={
                                        this.state.errors.plateNumber.message
                                            .length === 0
                                            ? false
                                            : true
                                    }
                                    helperText={
                                        this.state.errors.plateNumber.message
                                    }
                                    inputProps={{
                                        readOnly:
                                            this.props.readOnly ||
                                            this.props.readEdit,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            implementation="css"
                            smDown
                            component={Hidden}
                        />

                        {/* <Grid
                            item
                            md={1}
                            implementation="css"
                            smDown
                            component={Hidden}
                        /> */}
                        <Grid item md={2} xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t('vehicles.form.page.paxC')}
                                    value={this.state.paxCapacity}
                                    variant="outlined"
                                    name="paxCapacity"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={
                                        this.state.errors.paxCapacity.message
                                            .length === 0
                                            ? false
                                            : true
                                    }
                                    helperText={
                                        this.state.errors.paxCapacity.message
                                    }
                                    inputProps={{
                                        readOnly:
                                            this.props.readOnly ||
                                            this.props.readEdit,
                                        min: 0,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t('vehicles.form.page.luggageC')}
                                    value={this.state.luggageCapacity}
                                    variant="outlined"
                                    name="luggageCapacity"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={
                                        this.state.errors.luggageCapacity
                                            .message.length === 0
                                            ? false
                                            : true
                                    }
                                    helperText={
                                        this.state.errors.luggageCapacity
                                            .message
                                    }
                                    inputProps={{
                                        readOnly:
                                            this.props.readOnly ||
                                            this.props.readEdit,
                                        min: 0,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="otpId"
                                    value={this.state.otpId || ''}
                                    onChange={this.onChangeOtp}
                                    options={[
                                        /* { id: "createOTP", name: t('vehicles.form.page.createOTP') }, */ ...otps,
                                    ]}
                                    getOptionSelected={(option, value) =>
                                        option.value == value.value
                                    }
                                    getOptionLabel={option =>
                                        option.name ||
                                        factory.getLabelForName(
                                            otps,
                                            this.state.otpId,
                                        )
                                    }
                                    disabled={
                                        this.props.readOnly ||
                                        this.props.readEdit
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant={'outlined'}
                                            label={t('vehicles.form.page.otp')}
                                            helperText={
                                                this.state.errors.otpId.message
                                            }
                                            name="otpId"
                                            error={
                                                this.state.errors.otpId.message
                                                    .length === 0
                                                    ? false
                                                    : true
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.hasWheelChairSpace}
                                        disabled={
                                            this.props.readOnly ||
                                            this.props.readEdit
                                        }
                                        onChange={() =>
                                            this.setState({
                                                hasWheelChairSpace:
                                                    !this.state
                                                        .hasWheelChairSpace,
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label={t(
                                    'vehicles.form.page.hasWheelChairSpace',
                                )}
                            />
                        </Grid>

                        {this.state.otpId.id == 'createOTP' &&
                            this.props.nextAction == 'create' && (
                                <React.Fragment>
                                    <Grid
                                        item
                                        md={1}
                                        implementation="css"
                                        smDown
                                        component={Hidden}
                                    />
                                    <Grid item md={3} xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t('otp.form.page.name')}
                                                value={
                                                    this.state.otpNewName || ''
                                                }
                                                variant="outlined"
                                                name="otpNewName"
                                                fullWidth
                                                onChange={this.handleChange}
                                                error={
                                                    this.state.errors.otpNewName
                                                        .message.length === 0
                                                        ? false
                                                        : true
                                                }
                                                helperText={
                                                    this.state.errors.otpNewName
                                                        .message
                                                }
                                                inputProps={{
                                                    readOnly:
                                                        this.props.readOnly ||
                                                        this.props.readEdit,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t(
                                                    'otp.form.page.vatNumber',
                                                )}
                                                value={
                                                    this.state.otpVatNumber ||
                                                    ''
                                                }
                                                variant="outlined"
                                                name="otpVatNumber"
                                                fullWidth
                                                onChange={this.handleChange}
                                                error={
                                                    this.state.errors
                                                        .otpVatNumber.message
                                                        .length === 0
                                                        ? false
                                                        : true
                                                }
                                                helperText={
                                                    this.state.errors
                                                        .otpVatNumber.message
                                                }
                                                inputProps={{
                                                    readOnly:
                                                        this.props.readOnly ||
                                                        this.props.readEdit,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t(
                                                    'otp.form.page.contact',
                                                )}
                                                value={
                                                    this.state
                                                        .otpContactPerson || ''
                                                }
                                                variant="outlined"
                                                name="otpContactPerson"
                                                fullWidth
                                                onChange={this.handleChange}
                                                error={
                                                    this.state.errors
                                                        .otpContactPerson
                                                        .message.length === 0
                                                        ? false
                                                        : true
                                                }
                                                helperText={
                                                    this.state.errors
                                                        .otpContactPerson
                                                        .message
                                                }
                                                inputProps={{
                                                    readOnly:
                                                        this.props.readOnly ||
                                                        this.props.readEdit,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        implementation="css"
                                        smDown
                                        component={Hidden}
                                    />

                                    {/* <Grid
                                        item
                                        md={1}
                                        implementation="css"
                                        smDown
                                        component={Hidden}
                                    /> */}
                                    <Grid item md={3} xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t('otp.form.page.email')}
                                                value={
                                                    this.state.otpEmail || ''
                                                }
                                                variant="outlined"
                                                name="otpEmail"
                                                fullWidth
                                                onChange={this.handleChange}
                                                error={
                                                    this.state.errors.otpEmail
                                                        .message.length === 0
                                                        ? false
                                                        : true
                                                }
                                                helperText={
                                                    this.state.errors.otpEmail
                                                        .message
                                                }
                                                inputProps={{
                                                    readOnly:
                                                        this.props.readOnly ||
                                                        this.props.readEdit,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t('otp.form.page.phone')}
                                                value={
                                                    this.state.otpPhoneNumber ||
                                                    ''
                                                }
                                                variant="outlined"
                                                name="otpPhoneNumber"
                                                fullWidth
                                                type={'tel'}
                                                onChange={this.handleChange}
                                                error={
                                                    this.state.errors
                                                        .otpPhoneNumber.message
                                                        .length === 0
                                                        ? false
                                                        : true
                                                }
                                                helperText={
                                                    this.state.errors
                                                        .otpPhoneNumber.message
                                                }
                                                inputProps={{
                                                    readOnly:
                                                        this.props.readOnly ||
                                                        this.props.readEdit,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        implementation="css"
                                        smDown
                                        component={Hidden}
                                    />
                                </React.Fragment>
                            )}

                        <Grid item md={2} xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.returnToStart}
                                        disabled={
                                            this.props.readOnly ||
                                            this.props.readEdit
                                        }
                                        onChange={() =>
                                            this.setState({
                                                returnToStart:
                                                    !this.state.returnToStart,
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label={t('vehicles.form.page.returnToStart')}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} sm={6}>
                            <FormControl fullWidth>
                                <GoogleMaps
                                    label={t(
                                        'vehicles.form.page.initialPosition',
                                    )}
                                    name="initialPosition"
                                    onChange={this.onChangeInitialPosition}
                                    disabled={
                                        this.props.readOnly ? true : false
                                    }
                                    value={this.state.initialPosition.address}
                                    error={this.state.errors.initialPosition}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="priority"
                                    value={this.state.priority || ''}
                                    onChange={this.onChangePriority}
                                    options={priorities}
                                    freeSolo
                                    getOptionSelected={(option, value) =>
                                        option.id == value ||
                                        option.id == value.id
                                    }
                                    getOptionLabel={option =>
                                        option.name ||
                                        factory.getLabelForName(
                                            priorities,
                                            this.state.priority,
                                        )
                                    }
                                    disabled={
                                        this.props.readOnly ||
                                        this.props.readEdit
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant={'outlined'}
                                            label={t(
                                                'vehicles.form.page.priority',
                                            )}
                                            helperText={
                                                this.state.errors.priority
                                                    .message
                                            }
                                            name="priority"
                                            error={
                                                this.state.errors.priority
                                                    .message.length === 0
                                                    ? false
                                                    : true
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={1}
                            implementation="css"
                            smDown
                            component={Hidden}
                        />

                        <Grid item md={3} sm={9} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="descriptionCa"
                                    label={t('vehicles.form.page.descriptionCa')}
                                    name={'descriptionCa'}
                                    value={this.state.descriptionCa}
                                    multiline
                                    onChange={this.handleChange}
                                    rows={4}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={9} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="descriptionEs"
                                    label={t('vehicles.form.page.descriptionEs')}
                                    multiline
                                    onChange={this.handleChange}
                                    value={this.state.descriptionEs}
                                    rows={4}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                    }}
                                    name={'descriptionEs'}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={9} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="descriptionEn"
                                    label={t('vehicles.form.page.descriptionEn')}
                                    multiline
                                    onChange={this.handleChange}
                                    value={this.state.descriptionEn}
                                    rows={4}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                    }}
                                    name={'descriptionEn'}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={1}
                            implementation="css"
                            smDown
                            component={Hidden}
                        />


                        {this.state.shiftDays.map((item, index) => (
                            <DateVehicle
                                key={index}
                                item={item}
                                index={index}
                                detail={this.props.detail}
                                length={this.state.shiftDays.length}
                                value={this.state.shiftDays[index]}
                                t={t}
                                onChange={event =>
                                    this.handleChangeDate(event, index)
                                }
                                add={this.addTime}
                                remove={this.removeItem}
                                checkUpdates={(days, option) =>
                                    this.handleShiftDaysUpdates(
                                        days,
                                        option,
                                        index,
                                    )
                                }
                                checkDriver={(driver, option) =>
                                    this.handleChangeDriver(
                                        driver,
                                        option,
                                        index,
                                    )
                                }
                                checkService={(service, option) =>
                                    this.handleChangeService(
                                        service,
                                        option,
                                        index,
                                    )
                                }
                                state={this.state}
                                serviceReducer={this.props.serviceReducer}
                                driverReducer={this.props.driverReducer}
                                disabled={
                                    this.props.readOnly || this.props.readEdit
                                }
                            />
                        ))}

                        <Grid
                            item
                            md={3}
                            implementation="css"
                            smDown
                            component={Hidden}
                        />
                        <Grid item md={2} xs={12} sm={9}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                onClick={this.onClickBack}
                            >
                                {this.props.goBack}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            md={1}
                            xs={3}
                            implementation="css"
                            smDown
                            component={Hidden}
                        />
                        {allowAccessRole(MODULES.Vehicles, OPERATIONS_ALLOWED.manage) && (
                            <Grid item md={2} xs={12} sm={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                    size="large"
                                >
                                    {this.props.buttonRight}
                                </Button>
                            </Grid>
                        )}
                        <Grid
                            item
                            md={3}
                            xs={1}
                            implementation="css"
                            smDown
                            component={Hidden}
                        />
                    </Grid>
                </form>
            </React.Fragment>
        )
    }
}

export default VehiclesFormView
