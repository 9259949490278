import React from 'react'
import MapsServiceView from './page'
import { connect } from 'react-redux'
import Dashboard from '../dashboard'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { vehiclesActions } from '../../redux/actions/vehicles_actions'
import stringConstants from '../../constants/strings'
import Spinner from '../shared/spinner'
import { zoneActions } from '../../redux/actions/zones_actions'
import { otpActions } from '../../redux/actions/otp_actions'
import { serviceActions } from '../../redux/actions/services_actions'
import { chatActions } from '../../redux/actions/chat_actions'
import { allowAccessRole } from '../../helpers/roles'
import { MODULES, OPERATIONS_ALLOWED } from '../roles/constants'

class MapsService extends React.Component {
    componentDidMount() {
        this.props.getAllActivesWithLocations()

        if (allowAccessRole(MODULES.Chat, OPERATIONS_ALLOWED.read)) {
            this.props.getAllConversationsBySenderId(this.props.authenticationReducer.mongoId)
        }

        let intervalId = setInterval(() => {
            this.props.getAllActivesWithLocations()
            // this.props.getAllConversationsBySenderId(
            //   this.props.authenticationReducer.mongoId
            // );
        }, stringConstants.TIME_REFRESH_MAPS)
        this.setState({ intervalId: intervalId })

        if (allowAccessRole(MODULES.Zones, OPERATIONS_ALLOWED.read)) this.props.getZones()
        if (allowAccessRole(MODULES.Zones, OPERATIONS_ALLOWED.read)) this.props.getAllActiveStops()
        if (allowAccessRole(MODULES.OTP, OPERATIONS_ALLOWED.read)) this.props.getAllOtp()
        if (allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.read)) this.props.getAllService()
        if (allowAccessRole(MODULES.Vehicles, OPERATIONS_ALLOWED.read)) this.props.getAllVehicle()
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId)
        localStorage.removeItem('vehicles')
    }

    render() {
        const {
            vehicleReducer,
            zoneReducer,
            otpReducer,
            serviceReducer,
        } = this.props
        return (
            <Dashboard
                component={
                    !zoneReducer.pending ||
                        !vehicleReducer.pending ||
                        !otpReducer.pending ||
                        !serviceReducer.pending ? (
                        <MapsServiceView {...this.props} />
                    ) : (
                        <Spinner
                            loading={
                                vehicleReducer.pending ||
                                zoneReducer.pending ||
                                otpReducer.pending ||
                                serviceReducer.pending
                            }
                            {...this.props}
                        />
                    )
                }
            />
        )
    }
}

function mapState(state) {
    const {
        vehicleReducer,
        zoneReducer,
        otpReducer,
        serviceReducer,
        authenticationReducer,
    } = state
    return {
        vehicleReducer,
        zoneReducer,
        otpReducer,
        serviceReducer,
        authenticationReducer,
    }
}

const actionCreators = {
    getAllActivesWithLocations: vehiclesActions.getAllActivesWithLocations,
    getZones: zoneActions.getAll,
    getAllOtp: otpActions.getAll,
    getAllService: serviceActions.getAll,
    getAvailableVehicleCapacity: serviceActions.getAvailableVehicleCapacity,
    getAllVehicle: vehiclesActions.getAll,
    getAllConversationsBySenderId: chatActions.getAllConversationsBySenderId,
    getAllActiveStops: zoneActions.getAllActiveStops
}

export default compose(
    withTranslation('common'),
    connect(mapState, actionCreators),
)(MapsService)
