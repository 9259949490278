import React from 'react';
import { Chart } from "react-google-charts";
import PropTypes from 'prop-types';
import { FilterOptions, options, getStringTimeUnits, getChartName, DownloadChart, filterDataByGroup, getDistinctValuesFromGroup } from '../../../customFunctions';
import moment from 'moment';
import i18next from 'i18next';


const getTotalData = (data, values, groupedBy) => {
    let result = [['Trips', 'Total']]
    const filtersInData = getDistinctValuesFromGroup(data, groupedBy, values)
    filtersInData.forEach(function (value) {
        result.push([value, filterDataByGroup(data, groupedBy, value).length])
    })
    console.log(result)
    return result

}

const getDailyData = (data, values, groupedBy) => {
    let totals = []
    const filtersInData = getDistinctValuesFromGroup(data, groupedBy, values)
    filtersInData.forEach(function (value) {
        totals = totals.concat(filterDataByGroup(data, groupedBy, value))
    })
    // filter unique trips to count only once
    let dates = Array.from(new Set(totals.map(item => item.dateFormat)))
    totals = totals.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.tripId === value.tripId
        ))
    )
    let result = [[
        {
            type: "date",
            id: "Date",
        },
        {
            type: "number",
            id: "Count trips",
        },
    ]]
    dates.forEach(function (date) {
        result.push([moment(date).toDate(), totals.filter(item => item.dateFormat == date).length])
    })
    console.log(result)
    return result
}

const getMonthData = (data, values, groupedBy) => {
    // get all months and years options from boooks
    let optionsMonth = Array.from(new Set(data.map(item => item.monthText)))
    let result = []
    const filtersInData = getDistinctValuesFromGroup(data, groupedBy, values)
    result.push([(i18next.t('commandBox.month') + ' ' + getChartName(groupedBy))].concat(filtersInData))
    optionsMonth.forEach(function (itemMonth) {
        let dataMonth = [...new Array(filtersInData.length)]
        filtersInData.forEach(function (value, iValue) {
            dataMonth[iValue] = filterDataByGroup(data, groupedBy, value).filter(item => item.monthText == itemMonth).length
        })
        result.push([itemMonth].concat(dataMonth))
    })

    return result
}

const getYearlyData = (data, values, groupedBy) => {
    // get all years options from books
    let optionsYear = Array.from(new Set(data.map(item => item.year.toString())))
    let result = []
    const filtersInData = getDistinctValuesFromGroup(data, groupedBy, values)
    result.push([(i18next.t('commandBox.year') + ' ' + getChartName(groupedBy))].concat(filtersInData))
    // for each year filter books and insert results
    optionsYear.forEach(function (itemYear) {
        let dataYear = [...new Array(filtersInData.length)]
        filtersInData.forEach(function (value, iValue) {
            dataYear[iValue] = filterDataByGroup(data, groupedBy, value).filter(item => item.year == itemYear).length
        })
        result.push([itemYear].concat(dataYear))
    })
    return result

}


const filteredData = (data, filterOptions) => {
    const { groupedBy, value, timeUnits } = filterOptions
    console.log("timeUnits => ", timeUnits)
    console.log("getStringTimeUnits => ", getStringTimeUnits(timeUnits))
    let array = []
    let actualIndexDate = null
    // for each books set dates information to filter after
    data.forEach(x => {
        actualIndexDate = moment(x.schedulePickUpTime)
        x.weekNumber = actualIndexDate.year() + '_' + actualIndexDate.week()
        x.year = actualIndexDate.year()
        x.month = actualIndexDate.year() + '_' + actualIndexDate.month()
        x.monthText = i18next.t('commandBox.months', { returnObjects: true })[actualIndexDate.month()] + '/' + x.year
        x.dateFormat = actualIndexDate.format("YYYY-MM-DD")
        array.push(x)
    });
    console.log("array => ", array)
    switch (timeUnits) {
        case "10":
            return getTotalData(array, value, groupedBy)
        case "20":
            return getDailyData(array, value, groupedBy)
        case "40":
            return getMonthData(array, value, groupedBy)
        case "50":
            return getYearlyData(array, value, groupedBy)
        default:
            return data
    }


}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const TotalTripsAccumulated = ({
    data,
    title = "Total viatges (acumulats)",
    groupedBy = "municipalities",
    value = [],
    filterByDate = true,
    startDate = "2022-01-01",
    finishDate = "2022-02-01",
    timeUnits
}) => {

    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits);

    const chartType = ["40", "50"].includes(timeUnits) ? "Bar" : timeUnits == "10" ? "PieChart" : "Calendar"
    const lengthArray = filteredData(data, filterOptions).length/*.filter(([a, b]) => b > 0).length*/

    return (
        lengthArray > 1 ?
            <>
                <Chart
                    chartType={chartType}
                    graphID={'chart_0'}
                    width="100%"
                    height="400px"
                    data={filteredData(data, filterOptions)}
                    options={options(chartProps)}
                    chartLanguage={i18next.language}
                />
                <DownloadChart
                    key={Math.random()}
                    img64={0}
                />

            </> : null
    );
}

TotalTripsAccumulated.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(["municipalities", "regions", "otps", "services", "tenants"]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default TotalTripsAccumulated;