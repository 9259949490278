import { Grid, Hidden, TextField, MenuItem, Button } from '@material-ui/core';
import React from 'react';
import i18next from 'i18next';
import store from '../../../redux/store';
import { alertActions } from '../../../redux/actions/alert_actions';
import GridExpedition from './gridExpeditions';

class ItemServiceExpedition extends React.Component {

    constructor(props) {
        super()
        this.state = {
            day: '',
            hour: '',
            minute: '',
        }
        this.addItem = this.addItem.bind(this)
        this.remove = this.remove.bind(this)
    }

    componentDidMount() {
        if (this.props.vehicle?.includes['000000000000000000000000']) {
            store.dispatch(alertActions.warning(i18next.t('services.vehicles.vehicleLineEmpty')))
        }
    }

    addItem() {
        if (this.props.isRegularService) {
            this.props.addExpeditionItem(this.state.day, this.state.hour, this.state.minute, this.state.dayWeek)
        } else {
            this.props.addExpeditionItemServiceWithStop(this.state.day, this.state.hour, this.state.minute, this.state.dayWeek)
        }
    }

    remove(key, time) {
        if (this.props.isRegularService) {
            this.props.removeExpeditionItem(key, time)
        } else {
            this.props.removeExpeditionItemServiceWithStop(key, time)
        }
    }


    render() {
        return (
            <React.Fragment>
                {
                    this.props.isRegularService ?
                        <>
                            <Grid item md={2}>
                                <TextField
                                    label={i18next.t('services_comp.expeditions.expeditionMinutes')}
                                    value={this.props.expeditionMinutes}
                                    variant="outlined"
                                    type='number'
                                    fullWidth
                                    onChange={this.props.onChangeExpeditionMinutes}
                                    error={this.props.errors.expeditionMinutes.message.length === 0 ? false : true}
                                    helperText={this.props.errors.expeditionMinutes.message}
                                    inputProps={
                                        { min: 0 }
                                    }
                                />
                            </Grid>
                            <Grid item md={9} implementation="css" smDown component={Hidden} />
                        </>
                        : null
                }



                <Grid item md={1} implementation="css" smDown component={Hidden} />
                <Grid item md={3}>
                    <TextField
                        label={i18next.t('services_comp.expeditions.day')}
                        value={this.state.day}
                        select
                        variant="outlined"
                        name="direction"
                        fullWidth
                        onChange={(e) => {
                            let dayWeek = this.props.daysOfWeek.find(x => x.id == e.target.value)?.name
                            this.setState({ day: e.target.value, dayWeek: dayWeek })

                        }}
                    >
                        {
                            this.props.daysOfWeek.map(day =>
                                <MenuItem
                                    value={day.id}
                                    key={Math.random()}
                                >
                                    {day.name}
                                </MenuItem>
                            )
                        }

                    </TextField>
                </Grid>
                <Grid item md={1}>
                    <TextField
                        label={i18next.t('services_comp.expeditions.hour')}
                        value={this.state.hour}
                        select
                        variant="outlined"
                        name="hour"
                        fullWidth
                        onChange={(e) => {
                            if (e.target.value === "--")
                                this.setState({ hour: e.target.value, minute: "--" })
                            else
                                this.setState({ hour: e.target.value, minute: "" })
                        }}
                    >
                        <MenuItem
                            value={"--"}
                            key={Math.random()}
                        >
                            --
                        </MenuItem>
                        {
                            Array.from({ length: 24 }, (_, i) => i).map(
                                item =>
                                    <MenuItem
                                        value={item.toString().padStart(2, "0")}
                                        key={Math.random()}
                                    >
                                        {item.toString().padStart(2, "0")}
                                    </MenuItem>
                            )
                        }
                    </TextField>
                </Grid>
                <Grid item md={1}>
                    <TextField
                        label={i18next.t('services_comp.expeditions.minute')}
                        value={this.state.minute}
                        select
                        variant="outlined"
                        name="minute"
                        fullWidth
                        disabled={this.state.hour === "--"}
                        onChange={(e) => this.setState({ minute: e.target.value })}
                    >
                        <MenuItem value={"--"} key={Math.random()} disabled>
                            --
                        </MenuItem>
                        {
                            Array.from({ length: 60 }, (_, i) => i).map(
                                item =>
                                    <MenuItem
                                        value={item.toString().padStart(2, "0")}
                                        key={Math.random()}
                                    >
                                        {item.toString().padStart(2, "0")}
                                    </MenuItem>
                            )
                        }

                    </TextField>
                </Grid>
                <Grid item md={2} style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={this.state.day == '' || this.state.hour == '' || this.state.minute == '' || this.props.vehicle?.includes['000000000000000000000000']}
                        onClick={this.addItem}
                        size="medium"
                    >{i18next.t('services_comp.expeditions.add')}
                    </Button>
                </Grid>
                <Grid item md={4} implementation="css" smDown component={Hidden} />

                <Grid item md={1} implementation="css" smDown component={Hidden} />

                {/* Dias de la semana */}


                <GridExpedition
                    {...this.props}
                    remove={this.remove}
                    readOnly={false}
                />


            </React.Fragment >
        );
    }
}

export default ItemServiceExpedition;