import stringConstants from '../constants/strings';

export const googleMapsService = {
    getLocation,
    calculateTimePoints,
    getAddress,
    getAddressAndName
};


function getLocation(address) {
    const geocoder = new window.google.maps.Geocoder()
    return new Promise(function (resolve, reject) {
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status === 'OK') {
                let location = results[0].geometry.location
                const loc_LatLng = new window.google.maps.LatLng(location.lat(), location.lng())
                resolve(loc_LatLng.toJSON());
            } else {
                reject(new Error('Couldnt\'t find the location ' + address));
            }
        })
    })

}

function getAddress(lat, lng) {
    const geocoder = new window.google.maps.Geocoder()
    const latlng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
    };
    return new Promise(function (resolve, reject) {
        geocoder
            .geocode({ location: latlng }, function (results, status) {
                if (status == 'OK') {
                    resolve(results[0])
                } else {
                    reject(new Error('Couldnt\'t find the location '));
                }
            })
    })
}

function getAddressAndName(lat, lng, nameStop) {
    const geocoder = new window.google.maps.Geocoder()
    const latlng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
    };
    return new Promise(function (resolve, reject) {
        geocoder
            .geocode({ location: latlng }, function (results, status) {
                if (status == 'OK') {
                    results[0].nameStop = nameStop
                    resolve(results[0])
                } else {
                    reject(new Error('Couldnt\'t find the location ,' + nameStop));
                }
            })
    })
}


function calculateTimePoints(origin, destination, mode) {
    const matrixDistance = new window.google.maps.DistanceMatrixService()
    return new Promise(function (resolve, reject) {
        matrixDistance.getDistanceMatrix({
            origins: [{ lat: origin[0], lng: origin[1] }],
            destinations: [{ lat: destination[0], lng: destination[1] }],
            travelMode: mode
        }, function (results, status) {
            if (status === 'OK') {
                let elements = results.rows[0].elements
                resolve(elements)
            } else {
                reject(new Error('Couldnt\'t find the location ' + origin + " " + destination));
            }
        })
    })
}