import React from 'react'
import { history } from '../../helpers/history'
import AlertDialog from '../alert-dialog'
import { Tooltip, Button } from '@material-ui/core'
import MaterialTableExtend from '../material-table'
import Spinner from '../shared/spinner'
import InfoIcon from '@material-ui/icons/Info'
import { allowAccessRole } from '../../helpers/roles'
import { withTheme } from '@material-ui/core/styles';
import { MODULES, OPERATIONS_ALLOWED } from '../roles/constants'


class CustomerView extends React.Component {
    constructor(props) {
        super()
        this.state = {
            openModal: false,
            openModalDisable: false,
            openModalEnable: false,
            item: {},
            openResetPasswordModal: false,
            user: {},
            languageSelected: '',
        }
        this.onClickCancelModal = this.onClickCancelModal.bind(this)
        this.onClickAcceptModalDelete = this.onClickAcceptModalDelete.bind(this)
        this.onClickAcceptModalDisable = this.onClickAcceptModalDisable.bind(this)
        this.onClickAcceptModalEnable = this.onClickAcceptModalEnable.bind(this)
    }

    addOnClick() {
        history.push('/customers/create')
    }

    viewTrips(customer) {
        this.props.setCustomer(
            customer.id,
            1,
            customer.fullName,
            customer.addressPosition,
            customer.favouriteAddresses,
            customer.name,
            customer.surname1,
            customer.birthDate,
            customer.relatedCustomers,
            customer.language,
            customer.pushToken,
            customer.haswheelchair
        )
        history.push('/customers/trips/' + customer.id)
    }

    onClickCancelModal() {
        this.setState({
            openModal: false,
            openResetPasswordModal: false,
            openModalEnable: false,
            openModalDisable: false,
        })
    }

    onClickOpenResetPasswordModal(data) {
        this.setState({ openResetPasswordModal: true, user: data })
    }


    onClickTrip(id, relatedCustomersCount, fullName, address, favouriteAddresses, name, surname, customerDateOfBirth, relatedCustomers, lang, token, hasWheelChair) {
        let count = relatedCustomersCount == null ? 1 : relatedCustomersCount + 1
        this.props.cleanRepeatTrip()
        let themeColor = this.props.theme.palette.primary.main
        this.props.setCustomer(
            id,
            count,
            fullName,
            address,
            favouriteAddresses,
            name,
            surname,
            customerDateOfBirth,
            relatedCustomers,
            lang,
            token,
            hasWheelChair,
            themeColor)
        this.props.refreshCustomerRequestTrip(id)
    }

    onClickAcceptModalDelete() {
        this.setState({ openModal: false })
        this.props.remove(this.state.item.id)
    }

    onClickOpenModal(data) {
        this.setState({ openModal: true, item: data })
    }

    onClickAcceptModalResetPassword = () => {
        this.props.sendResetPasswordEmail(this.state.user.email)
        this.setState({ openResetPasswordModal: false })
    }

    handleChangeLanguage = event => {
        this.setState({ languageSelected: event.target.value })
    }

    onClickCommentsCustomer(data) {
        history.push('/customers/observations/' + data.id)
    }

    onClickAcceptModalDisable() {
        this.setState({ openModalDisable: false })
        this.props.disable(this.state.item.id)
    }

    onClickAcceptModalEnable() {
        this.setState({ openModalEnable: false })
        this.props.enable(this.state.item.id)
    }

    onClickOpenModalEnable(data) {
        if (!data.isActive)
            this.setState({ openModalEnable: true, item: data })
    }

    onClickOpenModalDisable(data) {
        if (data.isActive)
            this.setState({ openModalDisable: true, item: data })
    }

    render() {
        const { customerReducer, t } = this.props
        const Wrapper = () => (
            allowAccessRole(MODULES.Users, OPERATIONS_ALLOWED.manage) &&
            < React.Fragment >
                <Button
                    onClick={this.addOnClick}
                    variant="contained"
                    style={{ marginLeft: '24px' }}
                    color="primary"
                    size="large">
                    {t('customers.page.wrapper.add')}
                </Button>
            </React.Fragment >
        )

        const Content = () => (
            <React.Fragment>
                <label>
                    {t('customers.page.alert_dialog.sendInformation')}{' '}
                    {this.state.user.email}
                </label>
            </React.Fragment>
        )
        const theme = this.props.theme;

        return (
            <React.Fragment>
                {!customerReducer.pending ? (
                    <React.Fragment>
                        <MaterialTableExtend
                            buttons={<Wrapper />}
                            title={t('customers.page.title')}
                            setCurrentPage={this.props.setCurrentPage}
                            currentPage={customerReducer.currentPage}
                            onFilterChange={(filter) => {
                                this.props.saveCustomerFilters(filter)
                            }}
                            columns={[
                                {
                                    title: t('customers.page.columns.names'),
                                    field: 'fullName',
                                    defaultFilter: customerReducer.tableState.filter((col) => col.column.field == 'fullName')[0]?.value ?? null,
                                    render: rowData => {
                                        return rowData &&
                                            !rowData.hasSetPassword &&
                                            rowData.email !== '' ? (
                                            <React.Fragment>
                                                <Tooltip
                                                    title={t(
                                                        'customers.page.columns.tooltipHasSetPassword',
                                                    )}
                                                    arrow
                                                >
                                                    <InfoIcon
                                                        color="primary"
                                                        style={{
                                                            fontSize: 15,
                                                            marginRight: 2,
                                                        }}
                                                    />
                                                </Tooltip>
                                                <label>
                                                    {rowData.fullName}
                                                </label>
                                            </React.Fragment>
                                        ) : (
                                            rowData.fullName
                                        )
                                    },
                                },
                                {
                                    title: t('customers.page.columns.document'),
                                    field: 'identityDocumentCode',
                                    defaultFilter: customerReducer.tableState.filter((col) => col.column.field == 'identityDocumentCode')[0]?.value ?? null,
                                },
                                { title: 'id', field: 'id', hidden: true },
                                {
                                    title: t('customers.page.columns.phone'),
                                    field: 'phoneNumber',
                                    defaultFilter: customerReducer.tableState.filter((col) => col.column.field == 'phoneNumber')[0]?.value ?? null,
                                },
                                {
                                    title: t('customers.page.columns.email'),
                                    field: 'email',
                                    defaultFilter: customerReducer.tableState.filter((col) => col.column.field == 'email')[0]?.value ?? null,
                                },
                            ]}
                            data={customerReducer.results}
                            actionsColumnIndex={6}
                            actions={[
                                {
                                    icon: 'view_list',
                                    tooltip: t('customers.page.actions.detail'),
                                    onClick: (event, rowData) =>
                                        this.props.getById(rowData.id, true),
                                },
                                {
                                    icon: 'explore',
                                    tooltip: t('customers.page.actions.trips'),
                                    onClick: (event, rowData) =>
                                        this.viewTrips(rowData),
                                    hidden: !allowAccessRole(MODULES.Trips, OPERATIONS_ALLOWED.read)
                                },
                                rowData => ({
                                    icon: 'add_location',
                                    tooltip: rowData.isActive ? t('customers.page.actions.requestTrip') : t('customers.page.actions.customerDisabled'),
                                    disabled: !rowData.isActive,
                                    onClick: (event, rowData) => this.onClickTrip(
                                        rowData.id,
                                        rowData.relatedCustomersCount,
                                        rowData.fullName,
                                        rowData.addressPosition,
                                        rowData.favouriteAddresses,
                                        rowData.name,
                                        rowData.surname1,
                                        rowData.birthDate,
                                        rowData.relatedCustomers,
                                        rowData.language,
                                        rowData.pushToken,
                                        rowData.hasWheelChair
                                    ),
                                    hidden: !allowAccessRole(MODULES.Trips, OPERATIONS_ALLOWED.manage)
                                }),
                                rowData => ({
                                    icon: 'email_rounded',
                                    tooltip: t('customers.page.actions.sendResetPasswordEmail'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenResetPasswordModal(
                                            rowData,
                                        ),
                                    hidden: rowData.email === '' || !allowAccessRole(MODULES.Users, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'link',
                                    tooltip: t('customers.page.actions.linkRecovery'),
                                    onClick: (event, rowData) =>
                                        this.props.getResetPasswordLink(
                                            rowData.email
                                        ),
                                    hidden: rowData.email === '' || !allowAccessRole(MODULES.Users, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'toggle_off_icon',
                                    iconProps: { style: { color: 'black' } },
                                    tooltip: t('customers.page.actions.enable'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenModalEnable(rowData),
                                    hidden:
                                        rowData.isActive ||
                                        !allowAccessRole(MODULES.Users, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'toggle_on_icon',
                                    iconProps: { style: { color: theme.palette.primary.main } },
                                    tooltip: t('customers.page.actions.disable'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenModalDisable(rowData),
                                    hidden:
                                        !rowData.isActive ||
                                        !allowAccessRole(MODULES.Users, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'note_outlined',
                                    iconProps: {
                                        style: {
                                            color: rowData.hasActivities
                                                ? theme.palette.primary.main
                                                : '',
                                        },
                                    },
                                    tooltip: t('customers.page.actions.observacions'),
                                    onClick: (event, rowData) => this.onClickCommentsCustomer(rowData)
                                }),
                                {
                                    icon: 'delete',
                                    tooltip: t('customers.page.actions.delete'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenModal(rowData),
                                    hidden: !allowAccessRole(MODULES.Users, OPERATIONS_ALLOWED.manage),
                                },
                            ]}
                        />
                        <AlertDialog
                            open={this.state.openModal}
                            title={`${t("customers.page.alert_dialog.titleDelete")} (${this.state.item.fullName})`}
                            onClickAccept={this.onClickAcceptModalDelete}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog
                            open={this.state.openModalDisable}
                            title={t('customers.page.alert_dialog.titleDisable')}
                            onClickAccept={this.onClickAcceptModalDisable}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog  
                            open={this.state.openModalEnable}
                            title={t('customers.page.alert_dialog.titleEnable')}
                            onClickAccept={this.onClickAcceptModalEnable}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog
                            open={this.state.openResetPasswordModal}
                            onClickAccept={this.onClickAcceptModalResetPassword}
                            onClickCancel={this.onClickCancelModal}
                            title={`${t('customers.page.alert_dialog.sendInformation')} ${this.state.user.email}`}
                        />
                    </React.Fragment>
                ) : (
                    <Spinner loading={customerReducer.pending} />
                )}
            </React.Fragment >
        )
    }
}

export default withTheme(CustomerView)
