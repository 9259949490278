import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, options, DownloadChart, filterDataByGroup } from '../../../customFunctions'
import i18next from 'i18next'


const filteredData = (data, filterOptions, title, value) => {
    const { groupedBy } = filterOptions

    let dataValue = filterDataByGroup(data, groupedBy, value)
    const textsPuntuality = i18next.t('commandBox.puntuality', { returnObjects: true })
    let results = [['Trips', textsPuntuality[0]]]
    results.push([textsPuntuality[1], dataValue.filter(item => !item.isLateArrival).length])
    results.push([textsPuntuality[2], dataValue.filter(item => item.isLateArrival).length])
    console.log(results)
    return results
}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const TripsPuntuality = ({ data, title, groupedBy = 'municipalities', value = [], filterByDate = true, startDate = '2022-01-01', finishDate = '2022-02-01', timeUnits }) => {
    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits)

    const chartType = 'PieChart'

    return (
        <>
            {value.map(function (element, i) {
                return filteredData(data, filterOptions, title, element).filter(([a, b]) => b > 0).length > 0 ? (
                    <React.Fragment>
                        <Chart key={Math.random()} graphID={'chart_' + i.toString()} chartType={chartType} width="100%" height="400px" data={filteredData(data, filterOptions, title, element)} options={options(chartProps, null, element)} />
                        <DownloadChart key={Math.random()} img64={i} />
                    </React.Fragment>
                ) : null
            })}
        </>
    )
}

TripsPuntuality.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(['municipalities', 'regions', 'otps', 'services', 'tenants']).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default TripsPuntuality
