import React from 'react'
import ServiceCalendarRangeView from './page'
import Dashboard from '../../dashboard'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import Spinner from '../../shared/spinner'
import { serviceActions } from '../../../redux/actions/services_actions'
import { SERVICES_TYPE } from '../../../constants/types'
import { regularLine_actions } from '../../../redux/actions/regularLine_actions'
import { allowAccessRole } from '../../../helpers/roles'
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants'

class ServiceCalendar extends React.Component {

    componentDidMount() {
        allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage)

        const params = this.props.match.params
        const id = params.id
        const serviceType = params.serviceType
        const originIsServiceList = this.props.history.location.state?.originIsServiceList ?? false
        this.props.getWeekDays()
        if (serviceType != SERVICES_TYPE.regularWithSchedule || originIsServiceList)
            this.props.getById(id, false)
        else
            this.props.getServiceLine(id)
    }

    render() {
        const { serviceReducer } = this.props
        return <Dashboard
            component={
                serviceReducer.pending || serviceReducer.calendar == null || serviceReducer.weekDays.length == 0 ?
                    <Spinner loading={serviceReducer.pending} {...this.props} />
                    :
                    <ServiceCalendarRangeView {...this.props} />} />
    }
}

function mapState(state) {
    const { serviceReducer } = state
    return { serviceReducer }
}

const actionCreators = {
    getWeekDays: serviceActions.getWeekDays,
    getById: serviceActions.getById,
    removeExcludeDays: serviceActions.removeExcludeDays,
    removeExcludedWorkingDays: serviceActions.removeExcludedWorkingDays,
    setExcludeDays: serviceActions.setExcludeDays,
    setExcludedWorkingDays: serviceActions.setExcludedWorkingDays,
    setWorkDays: serviceActions.setWorkDays,
    setRangeCalendar: serviceActions.setRangeCalendar,
    setWorkingRangeCalendar: serviceActions.setWorkingRangeCalendar,
    setCalendar: serviceActions.setCalendar,
    addTimeRequestShiftDays: serviceActions.addTimeRequestShiftDays,
    addTimeShiftDays: serviceActions.addTimeShiftDays,
    addTimeShiftHours: serviceActions.addTimeShiftHours,
    removeTimeRequestShiftDays: serviceActions.removeTimeRequestShiftDays,
    removeTimeShiftDays: serviceActions.removeTimeShiftDays,
    removeTimeShiftHours: serviceActions.removeTimeShiftHours,
    setShiftDays: serviceActions.setShiftDays,
    setRequestShiftDays: serviceActions.setRequestShiftDays,
    setShiftHours: serviceActions.setShiftHours,
    getServiceLine: regularLine_actions.getServiceLine,
    setCalendarLine: regularLine_actions.setCalendarLine
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(ServiceCalendar)
