import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withTheme } from '@material-ui/core/styles';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField } from '@material-ui/core'
import i18next from 'i18next'
import moment from 'moment';
import { serviceActions } from '../../redux/actions/services_actions'
import { otpActions } from '../../redux/actions/otp_actions'
import CustomMultipleSelect from '../shared/customMultipleSelect'
import useStyles from './styles'
import { areaActions } from '../../redux/actions/areas_actions'
import { operatorsActions } from '../../redux/actions/operators_actions'
import { ChartProps, getChart, KPI_CATEGORY, transformObjectToDictionary } from './customFunctions'
import { vehiclesActions } from '../../redux/actions/vehicles_actions';
import { tripActions } from '../../redux/actions/trip_actions'
import Spinner from '../shared/spinner';

const CommandBoxView = ({
    getKpiTypes,
    getKPISByType,
    getAllMunicipalities,
    kpiFilters,
    getAllServices,
    getAllRegions,
    getAllTenants,
    getAllOtps,
    serviceReducer,
    areaReducer,
    operatorReducer,
    otpReducer,
    getVehiclesGeneralDataKPI,
    getKPITimeUnits,
    theme,
    getWeekDays,
    getKPITripOrigins,
    t,
    getTypeTipologies
}) => {

    const [selectedIndex, setSelectedIndex] = useState(0) //Category of KPI (General, etc..)
    const [selectedKPIIndex, setSelectedKPIIndex] = useState('-1') //-1 KPI Combo
    const [selectedKPIsByType, setSelectedKPIsByType] = useState({})
    const [selectedFilter, setSelectedFilter] = useState(null) //10
    const [selectedFilterResults, setSelectedFilterResults] = useState([])
    const [filterData, setFilterData] = useState([])
    const [chart, setChart] = useState(null)
    const [errorList, setErrorList] = useState([])
    const [showDateSelector, setShowDateSelector] = useState(true)
    const [showTripOriginSelector, setShowTripOriginSelector] = useState(false)
    const [showTimeUnitsSelector, setShowTimeUnitsSelector] = useState(false)
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(moment().add(-1, 'month').format('YYYY-MM-DD'))//("2022-01-01")
    const [finishDate, setFinishDate] = useState(moment().format('YYYY-MM-DD'))
    const [timeUnitsSelected, setTimeUnitsSelected] = useState("10") //Total
    const [timeUnitsList, setTimeUnitsList] = useState({});
    const [tripOriginSelected, setTripOriginSelected] = useState("40") //Todos
    const [typologySelected, setTyplogySelected] = useState("00") //Todos
    const [tripOriginList, setTripOriginList] = useState({});
    const [typologyList, setTypologyList] = useState({});
    const [groupByFilter, setGroupByFilter] = useState(false);

    const classes = useStyles()
    useEffect(() => {
        getKpiTypes(i18next.language)
        // Load first General data KPI types and filters
        getKPISByType('10', i18next.language)
        kpiFilters('10', i18next.language)
        getKPITimeUnits(i18next.language)
        getKPITripOrigins(i18next.language)
        getWeekDays()
        getTypeTipologies()
    }, [])

    useEffect(() => {
        loadFilterResults()
        setSelectedKPIsByType(serviceReducer.kpisByType)
        setSelectedFilter(null)
        setSelectedFilterResults([])
        setTimeUnitsList(serviceReducer.KPITimeUnits)
        setTripOriginList(serviceReducer.kPITripOrigins)
        setTypologyList(serviceReducer.typeTypologies)
    }, [serviceReducer.kpisByType, serviceReducer.KPITimeUnits, serviceReducer.kPITripOrigins, serviceReducer.typeTypologies])

    const loadFilterResults = () => {
        getAllRegions()
        getAllServices(true)
        getAllMunicipalities()
        getAllTenants()
        getAllOtps()
        getVehiclesGeneralDataKPI()
    }

    useEffect(() => {
        if ((selectedIndex == KPI_CATEGORY.BOOKINGS && selectedKPIIndex == "totalRequests") || (selectedIndex == KPI_CATEGORY.TRIPS && selectedKPIIndex == "totalTrips")) {
            setShowTimeUnitsSelector(true)
            /* if (selectedKPIIndex == "totalRequests" && selectedIndex == KPI_CATEGORY.BOOKINGS)
                setShowTripOriginSelector(true)
            else
                setShowTripOriginSelector(false) */
        } else {
            setShowTimeUnitsSelector(false)
        }
    }, [selectedKPIIndex])

    const handleSelectedCategoryOfKPI = (index) => {
        setSelectedIndex(index)
        setShowDateSelector(true)
        setShowTimeUnitsSelector(false)
        /* if (index === KPI_CATEGORY.TRIPS) {
            setShowDateSelector(true)
        } else if (index === KPI_CATEGORY.BOOKINGS) {
            setShowDateSelector(true);
        } else {
            setShowDateSelector(false)
            setShowTimeUnitsSelector(false)
        } */
    }

    const selectedFilterSwitch = {
        municipalities: () => setSelectedFilterResults(areaReducer.municipalities), //10
        regions: () => setSelectedFilterResults(areaReducer.regions), //20
        otps: () => setSelectedFilterResults(otpReducer.results), //30
        services: () => setSelectedFilterResults(serviceReducer.allServices), //40
        tenants: () => setSelectedFilterResults(operatorReducer.tenants) //50
    }

    const handleSelectFilter = (e) => {
        e.preventDefault()
        setSelectedFilter(e.target.value)
        setFilterData([])
        setChart(null)
        if (selectedFilterSwitch[e.target.value]) {
            selectedFilterSwitch[e.target.value]()
        } else {
            setSelectedFilterResults([])
        }
    }

    const handleSearch = async (selectedKPIIndex, startDate = "", finishDate = "", groupByFilter, typologySelected) => {
        let transformedData = transformObjectToDictionary(filterData)
        if (areFieldsValid(transformedData)) {
            setLoading(true)
            const filterByDate = showDateSelector;
            const chartProps = ChartProps("Bar", selectedKPIsByType[selectedKPIIndex], selectedFilter, transformedData, filterByDate, startDate, finishDate, timeUnitsSelected, serviceReducer, tripOriginSelected, groupByFilter, typologySelected)
            let chartComponent = await getChart(selectedIndex, selectedKPIIndex, chartProps);
            setLoading(false)
            setChart(chartComponent)
        }
    }

    const areFieldsValid = (transformedData) => {
        //console.log("selectedKPIIndex => ", selectedKPIIndex) 
        let errors = [];
        if (selectedKPIIndex == -1) errors = [...errors, "KPI"]
        if (selectedFilter == null) errors = [...errors, t('commandBox.filter').toLowerCase()]
        if (transformedData.length == 0) errors = [...errors, t('commandBox.errorMessages.filterDataMessage').toLowerCase()]
        setErrorList(errors)
        //console.log("errors => ", errors)
        if (errors.length == 0) {
            return true
        } else {
            return false
        }
    }
    return (
        <div className={classes.container}>
            <List className={classes.flexContainer}>
                {
                    Object.entries(serviceReducer.kpiTypes).map(([key, value], index) =>
                        <ListItem key={key} className={index === selectedIndex ? `${classes.menuItem} ${classes.selected}` : classes.menuItem}>
                            <ListItemText
                                className={index === selectedIndex ? `${classes.menuItemText} ${classes.selectedText}` : classes.menuItemText}
                                onClick={() => {
                                    handleSelectedCategoryOfKPI(index)
                                    if (selectedIndex != index) {
                                        setSelectedKPIIndex(-1) //0
                                        setSelectedFilter(null)
                                        setSelectedFilterResults([])
                                        setFilterData([])
                                        setChart(null)
                                        getKPISByType(key, i18next.language)
                                        kpiFilters(key, i18next.language)
                                        setErrorList([])
                                    }
                                }}
                                primary={value}
                            />
                        </ListItem>
                    )
                }
            </List>
            <Grid container alignItems='center' justify='center'>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel> KPI </InputLabel>
                        <Select
                            //value={selectedKPIsByType[selectedKPIIndex]}
                            value={selectedKPIIndex}
                            onChange={(e) => {
                                setSelectedKPIIndex(e.target.value)
                                setSelectedFilter(null)
                                setSelectedFilterResults([])
                                setFilterData([])
                                setChart(null)
                                const value = e.target.value
                                // hide dateSelector
                                if (["totalServices", "totalVehiclesByService", "totalUsers", "totalDrivers"].includes(value)) {
                                    setShowDateSelector(false)
                                } else {
                                    setShowDateSelector(true)
                                }
                            }}
                        >
                            {
                                Object.entries(selectedKPIsByType).map(([key, value], index) => <MenuItem value={key} key={index} > {value} </MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel> {t('commandBox.filter')} </InputLabel>
                    <Select
                        disabled={selectedKPIIndex == -1 ? true : false}
                        value={selectedFilter || ''}
                        onChange={handleSelectFilter}
                    >
                        {
                            Object.entries(serviceReducer.kpiFilters).map(([key, value], index) => <MenuItem value={key} key={index}> {value} </MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <CustomMultipleSelect
                    data={selectedFilterResults}
                    setSelected={setFilterData}
                    selected={filterData}
                    title={t('commandBox.filterData')}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                {showDateSelector && (
                    <>
                        {showTimeUnitsSelector && (
                            <div className={classes.timeUnitsSelector}>
                                <FormControl style={{ minWidth: 200, maxWidth: 220 }}>
                                    <InputLabel> {t('commandBox.timeUnits')} </InputLabel>
                                    <Select
                                        value={timeUnitsSelected}
                                        onChange={(e) => {
                                            console.log("e.target.value => ", e.target.value)
                                            setTimeUnitsSelected(e.target.value)
                                        }}
                                    >
                                        {
                                            Object.entries(timeUnitsList).map(([key, value], index) => <MenuItem value={key} key={index} > {value} </MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        {
                            [KPI_CATEGORY.BOOKINGS, KPI_CATEGORY.TRIPS, KPI_CATEGORY.USERS].includes(selectedIndex) &&
                            ["totalRequests", "totalTripsByUser", "timeDistributionTrips", "timeDistributionRequest"].includes(selectedKPIIndex) &&
                            (
                                <div className={classes.tripOriginSelector}>
                                    <FormControl style={{ minWidth: 200, maxWidth: 220 }}>
                                        <InputLabel> {t('commandBox.tripOrigin')} </InputLabel>
                                        <Select
                                            value={tripOriginSelected}
                                            onChange={(e) => {
                                                console.log("e.target.value => ", e.target.value)
                                                setTripOriginSelected(e.target.value)
                                            }}
                                        >
                                            {
                                                Object.entries(tripOriginList).map(([key, value], index) => <MenuItem value={key} key={index} > {value} </MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                            )
                        }
                        {
                            [KPI_CATEGORY.ECONOMICS_DATA].includes(selectedIndex) && ["rateByUser"].includes(selectedKPIIndex) &&
                            (
                                <div className={classes.tripOriginSelector}>
                                    <FormControl style={{ minWidth: 200, maxWidth: 220 }}>
                                        <InputLabel> {t('commandBox.typology')} </InputLabel>
                                        <Select
                                            value={typologySelected}
                                            onChange={(e) => {
                                                console.log("e.target.value => ", e.target.value)
                                                setTyplogySelected(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={"00"} key={0}> {t('commandBox.all')} </MenuItem>
                                            {
                                                typologyList.map(item => <MenuItem value={item.id} key={item.id} > {item.name} </MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            )
                        }
                        <div className={classes.startDate}>
                            <TextField
                                id="startDate"
                                label={t('commandBox.startDate')}
                                type="date"
                                value={startDate}
                                InputProps={{ inputProps: { min: "2021-01-01", max: moment().format('YYYY-MM-DD') } }}
                                onChange={(e) => {
                                    console.log("value => ", e.target.value)
                                    setStartDate(e.target.value)
                                }}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className={classes.finishDate}>
                            <TextField
                                id="finishDate"
                                label={t('commandBox.finishDate')}
                                type="date"
                                value={finishDate}
                                InputProps={{ inputProps: { min: "2021-01-01", max: moment().format('YYYY-MM-DD') } }}
                                onChange={(e) => {
                                    console.log("value => ", e.target.value)
                                    setFinishDate(e.target.value)
                                }}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        {
                            selectedIndex === KPI_CATEGORY.TRIPS && selectedKPIIndex === "matrixOriginDestination" && (
                                <div className={classes.groupByFilterCheck}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={groupByFilter}
                                                onChange={() => setGroupByFilter(!groupByFilter)}
                                                name="groupByFilter"
                                                color='primary'
                                            />
                                        }
                                        label={t('commandBox.groupFilter')}
                                    />
                                </div>
                            )

                        }
                    </>
                )}
            </Grid>
            <Grid item xs={12} style={{ marginTop: 12 }}>
                {errorList.length > 0 &&
                    <p className={classes.errorMessage}>{t('commandBox.errorMessages.requiredFields') + ": " + errorList.join(', ')}</p>
                }
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        handleSearch(selectedKPIIndex, startDate, finishDate, groupByFilter, typologySelected)
                    }}
                    disabled={loading}
                >
                    {t('commandBox.search')}
                </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 15 }}>
                {loading
                    ? <Spinner
                        loading={loading}
                    />
                    : chart
                }
            </Grid>
        </div>
    )
}

function mapState(state) {
    const { serviceReducer, areaReducer, operatorReducer, otpReducer } = state
    return { serviceReducer, areaReducer, operatorReducer, otpReducer }
}

const actionCreators = {
    getKpiTypes: serviceActions.getKPITypes,
    getKPISByType: serviceActions.getKPIsByType,
    kpiFilters: serviceActions.getKPIFiltersByGroup,
    getAllServices: serviceActions.getAll,
    getAllMunicipalities: areaActions.getAllMunicipalities,
    getAllRegions: areaActions.getAllRegions,
    getAllTenants: operatorsActions.getAllTenants,
    getAllOtps: otpActions.getAll,
    getVehiclesGeneralDataKPI: vehiclesActions.getVehiclesGeneralDataKPI,
    getAllKPI: tripActions.getAllKPI,
    getKPITimeUnits: serviceActions.getKPITimeUnits,
    getWeekDays: serviceActions.getWeekDays,
    getKPITripOrigins: serviceActions.getKPITripOrigins,
    getTypeTipologies: serviceActions.getTypeTipologies
}

export default compose(withTheme, connect(mapState, actionCreators))(CommandBoxView)