import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { withTranslation } from 'react-i18next'
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import moment from 'moment'
import { Paper, Typography } from '@material-ui/core';


class MaterialTableExtend extends React.Component {

    constructor(props) {
        super()
        this.state = {
            page: props.currentPage ? props.currentPage : 0
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.data != nextProps.data
    }

    render() {
        const { t } = this.props;

        let translations = t('material_table.tableStrings', { returnObjects: true })
        if (this.props.deleteText && this.props.deleteTooltip) {
            translations.body['deleteTooltip'] = this.props.deleteTooltip
            translations.body['editRow']['deleteText'] = this.props.deleteText
        }

        const Title = (title) => {
            return <Typography style={{ fontWeight: 500, margin: '20px 0' }} variant='h5' component='h2' >{title}</Typography>
        }

        return (
            <MaterialTable
                {...this.props}
                title={Title(this.props.title)}
                tableRef={ref => {
                    this.table = ref;
                }}
                style={{ maxWidth: '100%', width: '100%', overflowX: 'scroll' }}
                components={{
                    Toolbar: props => (
                        <React.Fragment>
                            <MTableToolbar {...props} />
                            {
                                this.props.buttons
                            }
                            {
                                this.props.toolBar
                            }
                        </React.Fragment>
                    ),
                    Container: props => <Paper {...props} elevation={0} />
                }}
                localization={translations}
                options={{
                    filtering: true,
                    // tableLayout: this.props.tableLayout ? "fixed" : "auto",
                    exportButton: true,
                    doubleHorizontalScroll: true,
                    fixedColumns: this.props.fixedColumns ? this.props.fixedColumns : null,
                    actionsCellStyle: this.props.alignButtonsCenter ? {
                        margin: "0 auto",
                        display: "flex",
                        flexGrow: "1",
                        justifyContent: "center",
                        border: "none"
                    } : null,
                    exportCsv: (columns, renderData) => {
                        if (this.props.exportCsv) {
                            this.props.exportCsv(columns, renderData, this.table)
                        }
                        else {
                            columns = columns
                                .filter(columnDef => {
                                    return !columnDef.hidden && columnDef.field && columnDef.export !== false;
                                });

                            const data = renderData.map(rowData =>
                                columns.map(columnDef => rowData[columnDef.field])
                            );

                            // eslint-disable-next-line no-unused-vars
                            const builder = new CsvBuilder((this.props.exportFileName || this.props.title || 'data') + '.csv')
                                .setDelimeter(this.props.exportDelimiter)
                                .setColumns(columns.map(columnDef => columnDef.title))
                                .addRows(data)
                                .exportFile();
                        }
                    },
                    exportPdf: (columns, renderData) => {

                        if (this.props.exportPdf) {
                            this.props.exportPdf(columns, renderData, this.table)
                        } else {
                            const doc = new jsPDF({ orientation: 'l' });

                            columns = columns.filter(columnDef => { return !columnDef.hidden && columnDef.field && columnDef.export !== false })

                            const columnTitles = columns.map(columnDef => columnDef.title);

                            const pdfData = renderData.map(rowData =>
                                columns.map(columnDef => {
                                    if (moment(rowData[columnDef.field], moment.ISO_8601, true).isValid()) {
                                        return moment(rowData[columnDef.field]).format('DD/MM/YYYY HH:mm')
                                    }

                                    if (columnDef.field.includes(".")) {
                                        return rowData[columnDef.field.split(".")[0]][columnDef.field.split(".")[1]]
                                    }

                                    return columnDef.lookup ? columnDef.lookup[rowData[columnDef.field]] : rowData[columnDef.field]
                                }),
                            );

                            doc.text(this.table.props.title, 15, 8, { baseline: 'top' });
                            doc.autoTable({
                                head: [columnTitles],
                                body: pdfData,
                                didDrawPage: function (data) {
                                    doc.setFontSize(20)
                                    doc.setTextColor(40)
                                }
                            });

                            doc.save(`${this.props.title}.pdf`);
                        }
                    },
                    initialPage: this.state.page,
                    selection: this.props.selection ? true : false,
                    actionsColumnIndex: this.props.actionsColumnIndex,
                    pageSizeOptions: [5, 10, 20, 100],
                    pageSize: this.props.pageSize ? this.props.pageSize : 100,
                    rowStyle: rowData => {
                        if (rowData.tableData.checked) {
                            return { backgroundColor: 'rgb(228, 229, 229)', whiteSpace: 'pre-line' };
                        }
                        return { whiteSpace: 'pre-line' }
                    },
                    headerStyle: {
                        whiteSpace: 'pre-line'
                    }
                }
                }
                onChangePage={
                    (page) => {
                        if (this.props.setCurrentPage) {
                            this.props.setCurrentPage(page)
                        }
                    }
                }
            />
        );
    }
}

export default withTranslation('common')(MaterialTableExtend);