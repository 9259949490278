import React from 'react'
import ServiceEditView from './page'
import Dashboard from '../../dashboard'
import { connect } from 'react-redux'
import { serviceActions } from '../../../redux/actions/services_actions'
import { otpActions } from '../../../redux/actions/otp_actions'
import { zoneActions } from '../../../redux/actions/zones_actions'
import { vehiclesActions } from '../../../redux/actions/vehicles_actions'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { customerActions } from '../../../redux/actions/customer_actions'

class ServiceEdit extends React.Component {

    componentDidMount() {
        this.props.setZones(this.props.serviceReducer.service.zonesResponse)
        const zonesResponse = this.props.serviceReducer.service.zonesResponse
        const zonesRelationsResponse = this.props.serviceReducer.service.zoneRelationsResponse
        zonesResponse.forEach(element => {
            let filterArray = zonesRelationsResponse.filter(a => a.originZoneId == element.id)
            let destinations = filterArray.map(x => x.destinationZoneId)
            var filteredArray = zonesResponse.filter(function (array_el) {
                return (
                    destinations.filter(function (anotherOne_el) {
                        return anotherOne_el == array_el.id
                    }).length != 0
                )
            })
            this.props.setCombination(element.id, filteredArray)
        })
    }

    componentWillUnmount() {
        this.props.clearCombination()
    }

    render() {
        const { t } = this.props
        return (
            <Dashboard
                component={
                    <ServiceEditView
                        title={t('services_comp.edit.title')}
                        buttonRight={t('services_comp.form.page.buttonEdit')}
                        goBack={t('services_comp.form.page.goBack')}
                        nextAction={'edit'}
                        sendService={this.props.update}
                        service={this.props.serviceReducer.service}
                        {...this.props}
                    />}
            />
        )
    }
}

function mapState(state) {
    const { serviceReducer, zoneReducer, otpReducer, vehicleReducer, customerReducer } = state
    return { serviceReducer, zoneReducer, otpReducer, vehicleReducer, customerReducer }
}

const actionCreators = {
    getById: serviceActions.getById,
    getTypesService: serviceActions.getTypesService,
    getServicesTypes: serviceActions.getServicesTypes,
    getAllZones: zoneActions.getAll,
    getAllOTP: otpActions.getAll,
    update: serviceActions.update,
    getByOtpIdWithOutServices: vehiclesActions.getByOtpIdWithOutServices,
    setZones: zoneActions.setZonesSelected,
    clearZones: zoneActions.clearZonesSelected,
    setCombination: zoneActions.setCombinationZone,
    clearCombination: zoneActions.clearZonesCombination,
    setServiceDataEdit: serviceActions.setServiceDataEdit,
    getAnticipationTypes: serviceActions.getAnticipationTypes,
    getAllCustomers: customerActions.getAll,
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(ServiceEdit)
