import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, options, DownloadChart, getDistinctValuesFromGroup } from '../../../customFunctions'


const filterDataByGroup = (data, groupedBy, value) => {
    switch (groupedBy) {
        case 'municipalities':
            return data.filter(item => item.municipalities?.some(m => value.includes(m)))
        case 'regions':
            return data.filter(item => item.regions?.some(r => value.includes(r)))
        case 'otps':
            return data.filter(item => item.otps?.some(o => value.includes(o)))
        case 'services':
            return data.filter(item => item.services?.some(s => value.includes(s)))
        case 'tenants':
            return data.filter(item => item.tenants?.some(t => value.includes(t)))
        default:
            return data
    }
}


const filteredData = (data, filterOptions, values, tripOrigin) => {
    const { groupedBy } = filterOptions
    switch (tripOrigin) {
        case '10':
            data = data.filter(item => item.requestedByUser)
            break
        case '20':
            data = data.filter(item => item.requestedByCustomerService)
            break
        case '30':
            data = data.filter(item => item.requestedByIVR)
            break
        default:
            break
    }
    let dataValue = filterDataByGroup(data, groupedBy, values)
    let optionsUsers = Array.from(new Set(dataValue.map(item => item.customerName)))
    const emptyArray = Array(values.length).fill(0)
    const filtersInData = getDistinctValuesFromGroup(data, groupedBy, values)
    let results = [['Total'].concat(filtersInData)]
    optionsUsers.forEach(function (itemUser, iUser) {
        let dataTotal = [...new Array(filtersInData.length)]
        filtersInData.forEach(function (value, iValue) {
            dataTotal[iValue] = filterDataByGroup(dataValue, groupedBy, value).filter(item => item.customerName == itemUser).length
        })
        if (!dataTotal.every(i => emptyArray.includes(i)))
            results.push([itemUser].concat(dataTotal))
    })
    console.log(results)
    return results
}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const TotalTripsByUser = ({
    data,
    title,
    groupedBy = 'municipalities',
    value = [],
    filterByDate = true,
    startDate = '2022-01-01',
    finishDate = '2022-02-01',
    timeUnits,
    tripOrigin
}) => {
    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits)

    const chartType = 'BarChart'
    const totalCustomer = Array.from(new Set(data.map(item => item.customerName)))

    return (
        <>
            {
                filteredData(data, filterOptions, value, tripOrigin).length > 1 ?
                    <>
                        <Chart
                            key={Math.random()}
                            graphID={'chart_0'}
                            chartType={chartType}
                            width="100%"
                            height={(totalCustomer * 50).toString() + 'px'}
                            data={filteredData(data, filterOptions, value, tripOrigin)}
                            options={options(chartProps)}
                        />
                        <DownloadChart key={Math.random()} img64={0} />
                    </>
                    : null
            }
        </>
    )
}

TotalTripsByUser.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(['municipalities', 'regions', 'otps', 'services', 'tenants']).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default TotalTripsByUser
