import { Grid } from '@material-ui/core';
import React from 'react';
import i18next from 'i18next';
import { vehiclesService } from '../../../services/vehiclesService';
import store from '../../../redux/store';
import { alertActions } from '../../../redux/actions/alert_actions';
import { factory } from '../../../helpers/factory';
import utils from '../../../helpers/validations';
import { SERVICES_TYPE } from '../../../constants/types';
import RegularServiceExpedition from './lineRegularService';
import SwitchConfigOptions from '../shared/switchConfigOptions';

class ServiceExpeditionsView extends React.Component {

    constructor(props) {
        super()
        this.state = {
            direction: '',
            entries: [
                props.line?.outbound?.calendar?.expeditions,
                props.line?.return?.calendar?.expeditions
            ],
            expeditionMinutes: [
                props.line?.outbound?.expeditionMinutes,
                props.line?.return?.expeditionMinutes,
            ],
            expeditionMinutesRegularLine: props.line?.outbound?.calendars,
            stopSelected: props.line?.outbound?.zoneStops[0]?.id,
            errors: {
                expeditionMinutes: {
                    result: false, message: ''
                }
            }
        }
        this.addExpeditionItem = this.addExpeditionItem.bind(this)
        this.addExpeditionItemServiceWithStop = this.addExpeditionItemServiceWithStop.bind(this)
        this.removeExpeditionItem = this.removeExpeditionItem.bind(this)
        this.removeExpeditionItemServiceWithStop = this.removeExpeditionItemServiceWithStop.bind(this)
        this.onChangeExpeditionMinutes = this.onChangeExpeditionMinutes.bind(this)
        this.updateExpeditionMinutes = this.updateExpeditionMinutes.bind(this)
    }

    addExpeditionItem(key, hour, minutes, dayText) {
        const time = hour + ':' + minutes
        const vehicle = this.props.serviceReducer.serviceLine.vehicleId
        const expeditionMinutes = this.state.expeditionMinutes[this.state.direction]
        const serviceId = this.props.id
        if (hour === "--" && minutes === "--") {
            this.addItemWithHourEmpty(time, key)
        } else
            if (!Object.values(this.state.entries[this.state.direction])[key].includes(time)) {
                vehiclesService.validateExpedition(time, key, vehicle, serviceId, expeditionMinutes).then(
                    result => {
                        if (result.result) {
                            Object.values(this.state.entries[this.state.direction])[key].push(time)
                            Object.values(this.state.entries[this.state.direction])[key].sort()
                            this.setState({ entries: this.state.entries });
                        } else {
                            store.dispatch(alertActions.warning(
                                result.vehicleDisplayName + i18next.t('services.vehicles.validateExpedition.notOperative') +
                                dayText + i18next.t('services.vehicles.validateExpedition.at') + time + i18next.t('services.vehicles.validateExpedition.toService')
                            ))
                        }
                    },
                    error => {
                        store.dispatch(alertActions.error('error'))
                    }
                )
            }

    }

    addExpeditionItemServiceWithStop(key, hour, minutes, dayText) {
        const time = hour + ':' + minutes
        const vehicles = this.props.serviceReducer.serviceLine.vehicleIds
        const serviceId = this.props.id
        if (hour === "--" && minutes === "--") {
            this.addItemWithHourEmpty(time, key)
        } else
            if (!this.state.expeditionMinutesRegularLine?.find(x => x.stopId == this.state.stopSelected)?.expeditions[key].includes(time)) {
                let body = {
                    serviceId: serviceId,
                    time: time,
                    day: key,
                    vehicles: vehicles
                }
                vehiclesService.validateExpeditionRegular(body).then(
                    result => {
                        if (result.result) {
                            let exp = this.state.expeditionMinutesRegularLine?.find(x => x.stopId == this.state.stopSelected)?.expeditions[key]
                            exp.push(time)
                            this.setState({ expeditionMinutesRegularLine: this.state.expeditionMinutesRegularLine });
                        } else {
                            store.dispatch(alertActions.warning(
                                result.vehicleDisplayName + i18next.t('services.vehicles.validateExpedition.notOperative') +
                                dayText + i18next.t('services.vehicles.validateExpedition.at') + time + i18next.t('services.vehicles.validateExpedition.toService')
                            ))
                        }
                    },
                    error => {
                        store.dispatch(alertActions.error('error'))
                    }
                )
            }
    }

    addItemWithHourEmpty(time, key) {
        let exp = this.state.direction ? Object.values(this.state.entries[this.state.direction])[key]
            : this.state.expeditionMinutesRegularLine?.find(x => x.stopId == this.state.stopSelected)?.expeditions[key]
        exp.push(time)
        //exp.sort()
        this.setState({ expeditionMinutesRegularLine: this.state.expeditionMinutesRegularLine });
    }

    removeExpeditionItem(key, time) {
        var array = this.state.entries; // make a separate copy of the array
        var index = Object.values(this.state.entries[this.state.direction])[key].indexOf(time)
        if (index !== -1) {
            Object.values(this.state.entries[this.state.direction])[key].splice(index, 1);
            this.setState({ entries: array });
        }
    }

    removeExpeditionItemServiceWithStop(key, time) {
        var index = Object.values(this.state.expeditionMinutesRegularLine.find(item => item.stopId == this.state.stopSelected)?.expeditions)[key].indexOf(time)
        if (index !== -1) {
            Object.values(this.state.expeditionMinutesRegularLine.find(item => item.stopId == this.state.stopSelected)?.expeditions)[key].splice(index, 1);
            this.setState({ expeditionMinutesRegularLine: this.state.expeditionMinutesRegularLine });
        }
    }

    onChangeExpeditionMinutes(e) {
        let newArr = [...this.state.expeditionMinutes];
        newArr[this.state.direction] = e.target.value
        this.setState({
            expeditionMinutes: newArr
        })
    }

    updateExpeditionMinutes(newValue) {
        if (this.state.direction) {
            this.setState(prevState => ({
                items: {
                    ...prevState.entries,
                    [prevState.entries[this.state.direction]]: newValue
                }
            }))
        } else {
            this.setState(prevState => ({
                items: {
                    ...prevState.expeditionMinutesRegularLine,
                    [prevState.expeditionMinutesRegularLine[this.state.expeditionMinutesRegularLine?.find(x => x.stopId == this.state.stopSelected)?.expeditions]]: newValue
                }
            }))
        }

    }

    validateForm() {
        let errors = this.state.errors
        errors.expeditionMinutes = utils.minValue(this.state.expeditionMinutes[this.state.direction], 0)
        this.setState({ errors })
    }

    isValid() {
        let valid = true
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result) valid = false
        })
        return valid
    }

    saveExpedition() {
        const direction = this.state.direction == '0' ? '10' : '20'
        const expeditionMinutes = this.state.expeditionMinutes[this.state.direction]
        const id = this.props.id
        this.validateForm()
        if (this.isValid()) {
            let expeditions = factory.createExpedition(Object.values(this.state.entries)[this.state.direction])
            this.props.setExpeditions(expeditions, id, direction, expeditionMinutes)
        }
    }

    saveRegularLineExpeditions() {
        const expeditions = this.state.expeditionMinutesRegularLine?.find(item => item.stopId == this.state.stopSelected)
        this.props.setRegularLineExpeditions(this.props.lineId, expeditions)
    }

    render() {
        const { serviceReducer, type } = this.props
        const isRegularService = type == SERVICES_TYPE.regularService
        const vehicle = isRegularService ? [this.props.serviceReducer?.serviceLine?.vehicleId] : this.props.serviceReducer?.serviceLine?.vehicleIds

        return (
            <>
                <h2 style={{ padding: 50 }}>{i18next.t('services_comp.expeditions.title')}</h2>
                <Grid container spacing={3}>

                    <SwitchConfigOptions
                        type={type}
                        serviceReducer={serviceReducer}
                        vehicle={vehicle}
                        direction={this.state.direction}
                        onChangeDirection={(value) => this.setState({ direction: value })}
                        onChangeStop={(value) => this.setState({ stopSelected: value })}
                        onSave={() => isRegularService ? this.saveExpedition() : this.saveRegularLineExpeditions()}
                        checkDisabledSave={true}
                        conditionDisabledSaveButton={
                            this.state.direction == '' && this.state.expeditionMinutesRegularLine == null
                        }
                        stopSelected={this.state.stopSelected}
                    />


                    <RegularServiceExpedition
                        conditionToShow={
                            isRegularService ?
                                this.state.direction != ''
                                :
                                this.state.stopSelected
                        }
                        entries={Object.values(this.state.entries)[this.state.direction] ?? this.state.expeditionMinutesRegularLine?.find(x => x.stopId == this.state.stopSelected)?.expeditions}
                        direction={this.state.direction}
                        expeditionMinutes={this.state.expeditionMinutes[this.state.direction] ?? this.state.expeditionMinutesRegularLine?.find(x => x.stopId == this.state.stopSelected)?.expeditions}
                        onChangeExpeditionMinutes={this.onChangeExpeditionMinutes}
                        addExpeditionItem={this.addExpeditionItem}
                        addExpeditionItemServiceWithStop={this.addExpeditionItemServiceWithStop}
                        errors={this.state.errors}
                        removeExpeditionItem={this.removeExpeditionItem}
                        removeExpeditionItemServiceWithStop={this.removeExpeditionItemServiceWithStop}
                        serviceReducer={serviceReducer}
                        vehicle={vehicle}
                        isRegularService={isRegularService}
                        setEntries={this.updateExpeditionMinutes}
                    />


                </Grid>
            </>
        );
    }
}

export default ServiceExpeditionsView;