import React, { useMemo } from "react";
import Chart from "react-google-charts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KpiWrapper from "../../../components/kpiWrapper";
import i18next from "i18next";
import { getTripsKPI } from "../../../../../helpers/kpiHelper";

export default function PunctualityTripChart({ isLoading, groupedBy }) {
    const { t } = useTranslation();
    const confirmTrips = useSelector(state => state.kpiReducer.confirmTrips)
    const isConfirmTripsLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)
    const { type } = useSelector(state => state.kpiFiltersReducer)

    const { totalTrips, data } = useMemo(() => {
        const data = [[t('commandBox.' + groupedBy?.toLowerCase()), t('commandBox.trips.punctualityTripsChart.punctual'), t('commandBox.trips.punctualityTripsChart.no-punctual')]]
        const { totalTrips, data: dataTrips } = getTripsKPI(confirmTrips, type)

        if (dataTrips) {
            data.push(...Object.entries(dataTrips).map(e => e != null && [e[0], e[1].trips - e[1].numberOfLateArrival, e[1].numberOfLateArrival]))
        }
        return { totalTrips, data }
    }, [confirmTrips, type])

    isLoading = isLoading || isConfirmTripsLoading
    const displayNoData = !isLoading && (!totalTrips || totalTrips == 0)

    return (
        <KpiWrapper
            title={t("commandBox.trips.punctualityTripsChart.title")}
            displayNoData={displayNoData}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ComboChart"
                graphID={'chart_punctuality_trips'}
                data={data}
                options={{
                    seriesType: "bars",
                    isStacked: true,
                }}
                chartLanguage={i18next.language}
            />
        </KpiWrapper>
    )
}