import React from 'react'
import { FormControl, Grid, Hidden, TextField, Button, InputLabel, Select, MenuItem, FormHelperText, FormControlLabel, Checkbox, Tooltip, FormLabel, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'
import Autocomplete from '@material-ui/lab/Autocomplete'
import utils from '../../../helpers/validations'
import { factory } from '../../../helpers/factory'
import Spinner from '../../shared/spinner'
import { history } from '../../../helpers/history'
import ServicesZonesDefinition from './modal'
import ZonesRelations from './zonesRelations'
import AlertDialog from '../../alert-dialog'
import { allowAccessRole } from '../../../helpers/roles'
import { serviceService } from '../../../services/servicesService'
import { REGULAR_SERVICES_TYPES, SERVICES_TYPE } from '../../../constants/types'
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants'

const styles = makeStyles(theme => ({
    ' & .MuiFormControl-root': {
        width: '100%',
    },
}))

const IconWithTooltip = props => (
    <Tooltip title={props.text}>
        <HelpIcon />
    </Tooltip>
)




class ServiceFormView extends React.Component {
    constructor(props) {
        super()
        const service = props.service ? props.service.serviceResponse : {}
        const zones = props.service ? props.service.zonesResponse : false
        const authorizedCustomers = props.service ? props.service.authorizedCustomersResponse : false
        const zonesRelations = props.service ? props.service.zoneRelationsResponse : []
        const create = props.nextAction == 'create' ? true : false
        this.state = {
            id: service.id || '',
            name: service.name || '',
            zones: zones || [],
            zonesRelations: zonesRelations,
            otpId: service.otpId ? { id: service.otpId, name: service.otpName } : '',
            authorizedCustomers: authorizedCustomers || [],
            anticipationRequestTime: create ? '' : service.anticipationMinRequestTime,
            anticipationMinType: create ? '' : service.anticipationMinType,
            anticipationRequestTimeMax: create ? '1' : service.anticipationMaxRequestTimeDays,
            notificationDeviationTimeMinutes: create ? '' : service.notificationDeviationTimeMinutes,
            assignedPickupDeviationTimeMinutes: create ? '' : service.assignedPickupDeviationTimeMinutes,
            pickupDeviationTimeMinutes: create ? '' : service.pickupDeviationTimeMinutes,
            cancelTime: create ? '' : service.cancellationTripTimeMinutes,
            additionalPaxs: create ? '' : service.additionalPaxs,
            hasZoneWithStops: service.hasZoneWithStops || false,
            serviceType: create ? '' : service.serviceType,
            assignmentMaxWaitingTimeMinutes: create ? '' : service.assignmentMaxWaitingTimeMinutes,
            restrictionMessageEs: create ? '' : service.restrictionMessageEs,
            restrictionMessageEn: create ? '' : service.restrictionMessageEn,
            restrictionMessageCa: create ? '' : service.restrictionMessageCa,
            modalStops: false,
            modalRestrictions: false,
            editTripTimeMinutes: create ? '' : service.editTripTimeMinutes,
            maxAbsences: create ? '' : service.maxAbsences,
            penaltyDays: create ? '' : service.penaltyDays,
            isRestricted: service.isRestricted || false,
            penaltiesEnabled: create ? true : service.penaltiesEnabled,
            maxTravelTimeFactor: create ? '1' : service.maxTravelTimeFactor,
            minimumAgeAuthorized: create ? '' : service.minimumAgeAuthorized,
            absenceDaysExpiration: create ? '' : service.absenceDaysExpiration,
            showPrice: create ? true : service.showPrice,
            showDriverInfo: create ? false : service.showDriverInfo,
            serviceTimePickUpSeconds: create ? '15' : service.serviceTimePickUpSeconds,
            serviceTimeWheelChairSeconds: create ? '120' : service.serviceTimeWheelChairSeconds,
            serviceTimeLuggageSeconds: create ? '30' : service.serviceTimeLuggageSeconds,
            regions: create ? [] : service.regions,
            municipalities: create ? [] : service.municipalities,
            pickupAndDropOffAutoComplete: create ? false : service.pickupAndDropOffAutoComplete,
            alternativePickupTimeOffset: create ? 0 : service.alternativePickupTimeOffset,
            pricePerDistance: create ? '' : service.pricePerDistance,
            avoidDistanceMatrixCalculationService: create ? false : service.avoidDistanceMatrixCalculationService,
            openModal: false,
            errors: {
                name: { result: false, message: '' },
                zones: { result: false, message: '' },
                minimumAgeAuthorized: { result: false, message: '' },
                otpId: { result: false, message: '' },
                authorizedCustomers: { result: true, message: '' },
                anticipationMinType: { result: false, message: '' },
                anticipationRequestTime: { result: false, message: '' },
                anticipationRequestTimeMax: { result: false, message: '' },
                notificationDeviationTimeMinutes: { result: true, message: '', },
                assignedPickupDeviationTimeMinutes: { result: true, message: '', },
                pickupDeviationTimeMinutes: { result: true, message: '' },
                assignmentMaxWaitingTimeMinutes: { result: false, message: '' },
                restrictionMessageEs: { result: false, message: '' },
                restrictionMessageEn: { result: false, message: '' },
                restrictionMessageCa: { result: false, message: '' },
                cancelTime: { result: false, message: '' },
                serviceType: { result: false, message: '' },
                maxTravelTimeFactor: { result: false, message: '' },
                editTripTimeMinutes: { result: false, message: '' },
                additionalPaxs: { result: false, message: '' },
                maxAbsences: { result: true, message: '' },
                penaltyDays: { result: true, message: '' },
                absenceDaysExpiration: { result: true, message: '' },
                serviceTimePickUpSeconds: { result: false, message: '' },
                serviceTimeWheelChairSeconds: { result: false, message: '' },
                serviceTimeLuggageSeconds: { result: false, message: '' },
                pricePerDistance: { result: false, message: '' },
                alternativePickupTimeOffset: { result: true, message: '' },
            },
        }
        if (this.state.zones.length > 0) {
            const errors = this.state.errors
            this.state.zones.forEach(element => {
                errors[element.id] = { result: true, message: '' }
            })
            this.setState({ errors })
        }
        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    handleChange(event) {
        let { name, value } = event.target
        const { t } = this.props
        let errors = this.state.errors
        errors[name].message = ''
        if (name == 'name' && value) {
            serviceService.serviceHasSameName(this.state.id, value).then(result => {
                if (result) {
                    errors[name] = {
                        result: false,
                        message: t('services.services.SameNameServiceExists'),
                    }
                    this.setState({ errors })
                }
            })
        }
        this.setState({ [name]: value })
    }

    handleCloseModal() {
        this.setState({
            openModal: false,
        })
    }

    onClickBack() {
        history.goBack()
    }

    isFormValid() {
        let valid = true
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result) valid = false
        })
        return valid
    }

    validateForm() {
        let errors = this.state.errors
        errors.name = utils.required(this.state.name)
        errors.zones = utils.validateArray(this.state.zones)
        errors.otpId = utils.required(this.state.otpId)
        errors.anticipationMinType = utils.required(this.state.anticipationMinType)
        errors.anticipationRequestTime = utils.minValue(this.state.anticipationRequestTime, 0)
        errors.anticipationRequestTimeMax = utils.minValue(this.state.anticipationRequestTimeMax, 1)
        if (this.state.serviceType != SERVICES_TYPE.regularWithSchedule) {
            errors.notificationDeviationTimeMinutes = utils.minValue(this.state.notificationDeviationTimeMinutes, 0)
            errors.assignedPickupDeviationTimeMinutes = utils.minValue(this.state.assignedPickupDeviationTimeMinutes, 0)
            errors.pickupDeviationTimeMinutes = utils.minValue(this.state.pickupDeviationTimeMinutes, 0)
            errors.alternativePickupTimeOffset = utils.maxValue(this.state.alternativePickupTimeOffset, 240)
        } else {
            errors.notificationDeviationTimeMinutes = { result: true, message: '' }
            errors.assignedPickupDeviationTimeMinutes = { result: true, message: '' }
            errors.pickupDeviationTimeMinutes = { result: true, message: '' }
            errors.alternativePickupTimeOffset = { result: true, message: '' }
        }
        errors.cancelTime = utils.minValue(this.state.cancelTime, 0)
        errors.additionalPaxs = utils.minValue(this.state.additionalPaxs, 0)
        if (this.state.serviceType != SERVICES_TYPE.regularWithSchedule && this.state.penaltiesEnabled) {
            errors.maxAbsences = utils.minValue(this.state.maxAbsences, 0)
            errors.penaltyDays = utils.minValue(this.state.penaltyDays, 0)
            errors.absenceDaysExpiration = utils.minValue(this.state.absenceDaysExpiration, 0)
        } else {
            errors.maxAbsences = { result: true, message: '' }
            errors.penaltyDays = { result: true, message: '' }
            errors.absenceDaysExpiration = { result: true, message: '' }
        }
        errors.editTripTimeMinutes = utils.minValue(this.state.editTripTimeMinutes, 0)
        errors.maxTravelTimeFactor = utils.minValue(this.state.maxTravelTimeFactor, 1)
        errors.maxTravelTimeFactor = utils.maxValue(this.state.maxTravelTimeFactor, 10)
        if (this.state.isRestricted) {
            errors.restrictionMessageEs = utils.required(this.state.restrictionMessageEs)
            errors.restrictionMessageEn = utils.required(this.state.restrictionMessageEn)
            errors.restrictionMessageCa = utils.required(this.state.restrictionMessageCa)
        } else {
            errors.restrictionMessageEs = { result: true, message: '' }
            errors.restrictionMessageEn = { result: true, message: '' }
            errors.restrictionMessageCa = { result: true, message: '' }
        }
        if ([SERVICES_TYPE.assignmentWheelService.toString(), SERVICES_TYPE.regularWithSchedule.toString()].includes(this.state.serviceType)) {
            errors.assignmentMaxWaitingTimeMinutes = utils.minValue(this.state.assignmentMaxWaitingTimeMinutes, 1)
        } else {
            errors.assignmentMaxWaitingTimeMinutes = { result: true, message: '', }
        }
        if (this.state.serviceType == SERVICES_TYPE.regularWithSchedule) {
            errors.pricePerDistance = utils.minValue(this.state.pricePerDistance, 0)
        } else {
            errors.pricePerDistance = { result: true, message: '', }
        }
        errors.serviceType = utils.required(this.state.serviceType)
        errors.minimumAgeAuthorized = utils.minValue(this.state.minimumAgeAuthorized, 0)

        errors.serviceTimePickUpSeconds = utils.minValue(this.state.serviceTimePickUpSeconds, 0)
        errors.serviceTimeWheelChairSeconds = utils.minValue(this.state.serviceTimeWheelChairSeconds, 0)
        errors.serviceTimeLuggageSeconds = utils.minValue(this.state.serviceTimeLuggageSeconds, 0)
        this.setState({ errors })
    }

    onChangeZones = (event, newValue) => {
        let errors = this.state.errors
        const { zoneReducer } = this.props
        errors.zones.message = ''
        newValue.forEach(element => {
            errors[element.id] = { result: true, message: '' }
        })
        this.setState({
            zones: newValue,
            errors,
        })
        this.props.setZones(newValue, zoneReducer.zonesCombination)
    }

    onChangeOtp = (event, newValue) => {
        let errors = this.state.errors
        errors.otpId.message = ''
        this.setState({
            otpId: newValue,
        })
        if (newValue) {
            this.props.nextAction === 'edit' && this.props.setServiceDataEdit(newValue)
        }
    }

    onChangeVehicles = (event, newValue) => {
        let errors = this.state.errors
        errors.vehicles.message = ''
        this.setState({
            vehicles: newValue,
        })
    }

    onChangeCustomers = (event, newValue) => {
        let errors = this.state.errors
        errors.authorizedCustomers.message = ''
        this.setState({
            authorizedCustomers: newValue,
        })
    }

    onSubmit = event => {
        if (this.props.detail) {
            this.props.sendService()
        } else {
            event.preventDefault()
            this.validateForm()
            if (this.isFormValid()) {
                this.setState({
                    openModal: true,
                })
            }
        }
    }

    uncheckHasZoneWithStopsInRegularService(serviceType) {
        if (REGULAR_SERVICES_TYPES.includes(serviceType)) {
            this.setState({ hasZoneWithStops: true })
        } else
            this.setState({ hasZoneWithStops: false })
    }

    render() {
        const { t, serviceReducer, zoneReducer, otpReducer, vehicleReducer, customerReducer } = this.props
        const zones = zoneReducer.results
        const otps = otpReducer.results
        const types = serviceReducer.serviceTypes
        const typesPrices = serviceReducer.typesPrices
        const anticipationTypes = serviceReducer.anticipationTypes
        return (
            <React.Fragment>
                <Spinner loading={serviceReducer.pending} />
                <h2 style={{ padding: 50 }}>{this.props.title}</h2>
                <form onSubmit={this.onSubmit} autoComplete="off">
                    <Grid className={styles.root} container spacing={3}>
                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t('services_comp.form.page.name')}
                                    value={this.state.name || ''}
                                    variant="outlined"
                                    name="name"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.name.message.length === 0 ? false : true}
                                    helperText={this.state.errors.name.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={7} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="zones"
                                    value={this.state.zones || []}
                                    onChange={this.onChangeZones}
                                    options={zones}
                                    multiple
                                    getOptionLabel={option => (option.Name ? option.Name : option.name ? option.name : '')}
                                    getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                    disabled={this.props.readOnly || this.props.readEdit}
                                    renderInput={params => <TextField {...params} variant={'outlined'} label={t('services_comp.form.page.zones')} helperText={this.state.errors.zones.message} name="zones" error={this.state.errors.zones.message.length === 0 ? false : true} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1} implementation="css" smDown component={Hidden} />


                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="otpId"
                                    value={this.state.otpId || ''}
                                    onChange={this.onChangeOtp}
                                    options={otps}
                                    getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                    getOptionLabel={option => option.name || factory.getLabelForName(otps, this.state.otpId)}
                                    disabled={this.props.readOnly}
                                    renderInput={params => <TextField {...params} variant={'outlined'} label={t('services_comp.form.page.otp')} helperText={this.state.errors.otpId.message} name="otpId" error={this.state.errors.otpId.message.length === 0 ? false : true} />}
                                />
                            </FormControl>
                        </Grid>

                        {/* <Grid item md={7} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="vehicles"
                                    multiple
                                    value={this.state.vehicles || []}
                                    onChange={this.onChangeVehicles}
                                    options={vehicleReducer.resultsByOTP}
                                    getOptionLabel={option => (option.displayName ? option.displayName : '')}
                                    getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                    disabled={this.props.readOnly || this.state.otpId == ''}
                                    renderInput={params => <TextField {...params} variant={'outlined'} label={t('services_comp.form.page.vehicles')} helperText={this.state.errors.vehicles.message} name="vehicle" error={this.state.errors.vehicles.message.length === 0 ? false : true} />}
                                />
                            </FormControl>
                        </Grid> */}
                        <Grid item md={8} implementation="css" smDown component={Hidden} />

                        {
                            this.props.detail ?
                                <>
                                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                                    <Grid item md={8} xs={6} sm={3}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                id="regions"
                                                multiple
                                                value={this.state.regions || []}
                                                options={[]}
                                                getOptionLabel={option => (option)}
                                                getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                                disabled={true}
                                                renderInput={params => <TextField {...params} variant={'outlined'} label={t('services_comp.form.page.region')} />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} implementation="css" smDown component={Hidden} />

                                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                                    <Grid item md={8} xs={6} sm={3}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                id="municipalities"
                                                multiple
                                                value={this.state.municipalities || []}
                                                options={[]}
                                                getOptionLabel={option => (option)}
                                                getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                                disabled={this.props.readOnly || this.state.otpId == ''}
                                                renderInput={params => <TextField {...params} variant={'outlined'} label={t('services_comp.form.page.municipality')} />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} implementation="css" smDown component={Hidden} />

                                </>
                                : null
                        }

                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink id="ServiceTypeLabel">
                                    {t('services_comp.form.page.serviceType')}
                                </InputLabel>
                                <Select
                                    labelId="ServiceTypeLabel"
                                    id="serviceTypePrice"
                                    name="serviceType"
                                    disabled={this.props.nextAction == "edit"}
                                    value={this.state.serviceType}
                                    onChange={e => {
                                        this.handleChange(e)
                                        this.uncheckHasZoneWithStopsInRegularService(e.target.value)
                                    }}
                                    error={this.state.errors.serviceType.message.length === 0 ? false : true}
                                    displayEmpty
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                    }}
                                >
                                    {types.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error>{this.state.errors.serviceType.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={1} sm={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.isRestricted}
                                        disabled={this.props.readOnly}
                                        onChange={() =>
                                            this.setState({
                                                modalRestrictions: this.state.isRestricted ? false : true,
                                                isRestricted: this.state.isRestricted ? false : this.state.isRestricted,
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label={t('services_comp.form.page.isRestricted')}
                            />
                        </Grid>
                        <Grid item md={2} xs={1} sm={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.hasZoneWithStops}
                                        disabled={REGULAR_SERVICES_TYPES.includes(this.state.serviceType) || this.state.serviceType == '' || this.props.readOnly}
                                        onChange={() => this.setState({ modalStops: true })}
                                        color="primary"
                                    />
                                }
                                label={t('services_comp.form.page.hasZoneWithStops')}
                            />
                        </Grid>
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={<div>{t('services_comp.form.page.assignmentMaxWaitingTime')}</div>}
                                    value={this.state.assignmentMaxWaitingTimeMinutes}
                                    variant="outlined"
                                    name="assignmentMaxWaitingTimeMinutes"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    disabled={this.state.serviceType != SERVICES_TYPE.assignmentWheelService && this.state.serviceType != SERVICES_TYPE.regularWithSchedule}
                                    error={this.state.errors.assignmentMaxWaitingTimeMinutes.message.length === 0 ? false : true}
                                    helperText={this.state.errors.assignmentMaxWaitingTimeMinutes.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        min: 0
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        {this.state.serviceType == SERVICES_TYPE.regularWithSchedule ?
                            <>
                                <Grid item md={2} xs={6} sm={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            InputLabelProps={{
                                                style: { pointerEvents: 'auto' },
                                                shrink: true,
                                            }}
                                            label={
                                                <div>
                                                    {t('services_comp.form.page.pricePerDistance')}
                                                </div>
                                            }
                                            value={this.state.pricePerDistance}
                                            variant="outlined"
                                            name="pricePerDistance"
                                            fullWidth
                                            type={'number'}
                                            onChange={this.handleChange}
                                            error={this.state.errors.pricePerDistance.message.length === 0 ? false : true}
                                            helperText={this.state.errors.pricePerDistance.message}
                                            inputProps={{
                                                min: 0,
                                                step: '.01',
                                            }}
                                            InputProps={{
                                                readOnly: this.props.readOnly,
                                                endAdornment: <InputAdornment position='end'>€</InputAdornment>
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item md={1} sm={7} xs={7} implementation="css" smDown component={Hidden} />
                            </>
                            :
                            <Grid item md={3} sm={7} xs={7} implementation="css" smDown component={Hidden} />

                        }



                        {this.state.isRestricted ? (
                            <React.Fragment>
                                <Grid item md={1} implementation="css" smDown component={Hidden} />
                                <Grid item md={3} sm={3} xs={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="restrictionMessageEs"
                                            label={t('services_comp.form.page.restrictionMessageEs')}
                                            name={'restrictionMessageEs'}
                                            value={this.state.restrictionMessageEs}
                                            multiline
                                            onChange={this.handleChange}
                                            error={this.state.errors.restrictionMessageEs.message.length === 0 ? false : true}
                                            helperText={this.state.errors.restrictionMessageEs.message}
                                            rows={4}
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: this.props.readOnly,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={3} sm={3} xs={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="restrictionMessageEn"
                                            label={t('services_comp.form.page.restrictionMessageEn')}
                                            multiline
                                            onChange={this.handleChange}
                                            value={this.state.restrictionMessageEn}
                                            error={this.state.errors.restrictionMessageEn.message.length === 0 ? false : true}
                                            helperText={this.state.errors.restrictionMessageEn.message}
                                            rows={4}
                                            InputProps={{
                                                readOnly: this.props.readOnly,
                                            }}
                                            name={'restrictionMessageEn'}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={3} sm={3} xs={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="restrictionMessageCa"
                                            label={t('services_comp.form.page.restrictionMessageCa')}
                                            multiline
                                            onChange={this.handleChange}
                                            value={this.state.restrictionMessageCa}
                                            error={this.state.errors.restrictionMessageCa.message.length === 0 ? false : true}
                                            helperText={this.state.errors.restrictionMessageCa.message}
                                            rows={4}
                                            InputProps={{
                                                readOnly: this.props.readOnly,
                                            }}
                                            name={'restrictionMessageCa'}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={2} implementation="css" smDown component={Hidden} />
                                <Grid item md={1} implementation="css" smDown component={Hidden} />
                                <Grid item md={9} sm={3} xs={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="authorizedCustomers"
                                            name="authorizedCustomers"
                                            multiple
                                            value={this.state.authorizedCustomers || []}
                                            onChange={this.onChangeCustomers}
                                            options={customerReducer.results}
                                            getOptionLabel={option => (option && option.fullName ? option && option.fullName : customerReducer.results.find(x => x.id == option) ? customerReducer.results.find(x => x.id == option).fullName : '')}
                                            getOptionSelected={(option, value) => option.fullName == value || option.fullName == value.fullName}
                                            disabled={this.props.readOnly}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant={'outlined'}
                                                    label={t('services_comp.form.page.authorizedCustomers')}
                                                    helperText={this.state.errors.authorizedCustomers.message}
                                                    name="customer"
                                                    error={this.state.errors.authorizedCustomers.message.length === 0 ? false : true}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item md={2} implementation="css" smDown component={Hidden} />
                            </React.Fragment>
                        ) : null}

                        <Grid item md={1} sm={3} xs={3} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} sm={6} xs={6}>
                            <FormLabel>{t('services_comp.form.page.titleRequest')}</FormLabel>
                        </Grid>
                        <Grid item md={8} sm={3} xs={3} implementation="css" smDown component={Hidden} />

                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={
                                        <div>
                                            {t('services_comp.form.page.minimumAgeAuthorized')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.minimumAgeAuthorized')} />
                                        </div>
                                    }
                                    value={this.state.minimumAgeAuthorized}
                                    variant="outlined"
                                    name="minimumAgeAuthorized"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.minimumAgeAuthorized.message.length === 0 ? false : true}
                                    helperText={this.state.errors.minimumAgeAuthorized.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        min: 0,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={
                                        <div>
                                            {t('services_comp.form.page.additionalPaxs')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.additionalPaxs')} />
                                        </div>
                                    }
                                    value={this.state.additionalPaxs}
                                    variant="outlined"
                                    name="additionalPaxs"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.additionalPaxs.message.length === 0 ? false : true}
                                    helperText={this.state.errors.additionalPaxs.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        min: 0,
                                        max: 50,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink id="ServiceTypeLabel">
                                    {t('services_comp.form.page.anticipationMinType')}
                                </InputLabel>
                                <Select
                                    labelId="ServiceTypeLabel"
                                    id="anticipationMinType"
                                    name="anticipationMinType"
                                    value={this.state.anticipationMinType}
                                    onChange={this.handleChange}
                                    error={this.state.errors.anticipationMinType.message.length === 0 ? false : true}
                                    displayEmpty
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                    }}
                                >
                                    {anticipationTypes.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error>{this.state.errors.anticipationMinType.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={
                                        <div>
                                            {t('services_comp.form.page.anticipationRequestTime')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.anticipationRequestTime')} />
                                        </div>
                                    }
                                    value={this.state.anticipationRequestTime}
                                    variant="outlined"
                                    name="anticipationRequestTime"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.anticipationRequestTime.message.length === 0 ? false : true}
                                    helperText={this.state.errors.anticipationRequestTime.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        min: 0,
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={
                                        <div>
                                            {t('services_comp.form.page.anticipationRequestTimeMax')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.anticipationRequestTimeMax')} />
                                        </div>
                                    }
                                    value={this.state.anticipationRequestTimeMax}
                                    variant="outlined"
                                    name="anticipationRequestTimeMax"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.anticipationRequestTimeMax.message.length === 0 ? false : true}
                                    helperText={this.state.errors.anticipationRequestTimeMax.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1} implementation="css" smDown component={Hidden} />

                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={
                                        <div>
                                            {t('services_comp.form.page.cancelTime')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.cancelTime')} />
                                        </div>
                                    }
                                    value={this.state.cancelTime}
                                    variant="outlined"
                                    name="cancelTime"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.cancelTime.message.length === 0 ? false : true}
                                    helperText={this.state.errors.cancelTime.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        min: 0,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={
                                        <div>
                                            {t('services_comp.form.page.editTripTimeMinutes')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.editTripTimeMinutes')} />
                                        </div>
                                    }
                                    value={this.state.editTripTimeMinutes}
                                    variant="outlined"
                                    name="editTripTimeMinutes"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.editTripTimeMinutes.message.length === 0 ? false : true}
                                    helperText={this.state.errors.editTripTimeMinutes.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        min: 0,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={7} implementation="css" smDown component={Hidden} />


                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} xs={1} sm={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.showPrice}
                                        disabled={this.props.readOnly}
                                        onChange={() =>
                                            this.setState({
                                                showPrice: this.state.showPrice ? false : true,
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label={t('services_comp.form.page.showPrice')}
                            />
                        </Grid>
                        <Grid item md={2} xs={1} sm={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.showDriverInfo}
                                        disabled={this.props.readOnly}
                                        onChange={() =>
                                            this.setState({
                                                showDriverInfo: this.state.showDriverInfo ? false : true,
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label={t('services_comp.form.page.showDriverInfo')}
                            />
                        </Grid>
                        <Grid item md={4} xs={1} sm={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.pickupAndDropOffAutoComplete}
                                        disabled={this.props.readOnly}
                                        onChange={() =>
                                            this.setState({
                                                pickupAndDropOffAutoComplete: this.state.pickupAndDropOffAutoComplete ? false : true
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label={t('services_comp.form.page.pickupAndDropOffAutoComplete')}
                            />
                        </Grid>
                        <Grid item md={3} implementation="css" smDown component={Hidden} />


                        {this.state.serviceType != SERVICES_TYPE.regularWithSchedule && (
                            <>
                                <Grid item md={1} implementation="css" smDown component={Hidden} />
                                <Grid item md={3}>
                                    <FormLabel>{t('services_comp.form.page.titlePenalty')}</FormLabel>
                                </Grid>
                                <Grid item md={8} implementation="css" smDown component={Hidden} />

                                <Grid item md={1} implementation="css" smDown component={Hidden} />
                                <Grid item md={2}>
                                    <FormControlLabel fullWidth
                                        control={
                                            <Checkbox
                                                checked={this.state.penaltiesEnabled}
                                                onChange={(event) => this.setState({ penaltiesEnabled: event.target.checked })}
                                                color={"primary"}
                                                disabled={this.props.readOnly}
                                            />
                                        }
                                        label={t('services_comp.form.page.enablePenalty')}
                                    />
                                </Grid>
                                <Grid item md={2} xs={6} sm={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            InputLabelProps={{
                                                style: { pointerEvents: 'auto' },
                                                shrink: true,
                                            }}
                                            label={
                                                <div>
                                                    {t('services_comp.form.page.penaltyDays')}
                                                    <IconWithTooltip text={t('services_comp.form.page.tooltip.penaltyDays')} />
                                                </div>
                                            }
                                            value={this.state.penaltyDays}
                                            variant="outlined"
                                            name="penaltyDays"
                                            fullWidth
                                            type={'number'}
                                            onChange={this.handleChange}
                                            error={this.state.errors.penaltyDays.message.length === 0 ? false : true}
                                            helperText={this.state.errors.penaltyDays.message}
                                            InputProps={{
                                                readOnly: this.props.readOnly,
                                                min: 0,
                                            }}
                                            disabled={!this.state.penaltiesEnabled}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={2} xs={6} sm={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            InputLabelProps={{
                                                style: { pointerEvents: 'auto' },
                                                shrink: true,
                                            }}
                                            label={
                                                <div>
                                                    {t('services_comp.form.page.maxAbsences')}
                                                    <IconWithTooltip text={t('services_comp.form.page.tooltip.maxAbsences')} />
                                                </div>
                                            }
                                            value={this.state.maxAbsences}
                                            variant="outlined"
                                            name="maxAbsences"
                                            fullWidth
                                            type={'number'}
                                            onChange={this.handleChange}
                                            error={this.state.errors.maxAbsences.message.length === 0 ? false : true}
                                            helperText={this.state.errors.maxAbsences.message}
                                            InputProps={{
                                                readOnly: this.props.readOnly,
                                                min: 0,
                                            }}
                                            disabled={!this.state.penaltiesEnabled}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={2} xs={6} sm={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            InputLabelProps={{
                                                style: { pointerEvents: 'auto' },
                                                shrink: true,
                                            }}
                                            label={
                                                <div>
                                                    {t('services_comp.form.page.absenceDaysExpiration')}
                                                    <IconWithTooltip text={t('services_comp.form.page.tooltip.absenceDaysExpiration')} />
                                                </div>
                                            }
                                            value={this.state.absenceDaysExpiration}
                                            variant="outlined"
                                            name="absenceDaysExpiration"
                                            fullWidth
                                            type={'number'}
                                            onChange={this.handleChange}
                                            error={this.state.errors.absenceDaysExpiration.message.length === 0 ? false : true}
                                            helperText={this.state.errors.absenceDaysExpiration.message}
                                            InputProps={{
                                                readOnly: this.props.readOnly,
                                                min: 0,
                                            }}
                                            disabled={!this.state.penaltiesEnabled}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={3} implementation="css" smDown component={Hidden} />
                            </>
                        )}

                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3}>
                            <FormLabel>{t('services_comp.form.page.titlePlanner')}</FormLabel>
                        </Grid>
                        <Grid item md={8} implementation="css" smDown component={Hidden} />

                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={
                                        <div>
                                            {t('services_comp.form.page.maxTravelTimeFactor')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.maxTravelTimeFactor')} />
                                        </div>
                                    }
                                    value={this.state.maxTravelTimeFactor}
                                    variant="outlined"
                                    name="maxTravelTimeFactor"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.maxTravelTimeFactor.message.length === 0 ? false : true}
                                    helperText={this.state.errors.maxTravelTimeFactor.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        min: 1,
                                        step: '.01',
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={
                                        <div>
                                            {t('services_comp.form.page.serviceTimePickUpSeconds')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.serviceTimePickUpSeconds')} />
                                        </div>
                                    }
                                    value={this.state.serviceTimePickUpSeconds}
                                    variant="outlined"
                                    name="serviceTimePickUpSeconds"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.serviceTimePickUpSeconds.message.length === 0 ? false : true}
                                    helperText={this.state.errors.serviceTimePickUpSeconds.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        min: 1,
                                        max: 1000,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={6} sm={3}>
                            {
                                this.state.serviceType != SERVICES_TYPE.regularWithSchedule && (
                                    <FormControl fullWidth>
                                        <TextField
                                            InputLabelProps={{
                                                style: { pointerEvents: 'auto' },
                                                shrink: true,
                                            }}
                                            label={
                                                <div>
                                                    {t('services_comp.form.page.notificationDeviationTimeMinutes')}
                                                    <IconWithTooltip text={t('services_comp.form.page.tooltip.notificationDeviationTimeMinutes')} />
                                                </div>
                                            }
                                            value={this.state.notificationDeviationTimeMinutes}
                                            variant="outlined"
                                            name="notificationDeviationTimeMinutes"
                                            fullWidth
                                            type={'number'}
                                            onChange={this.handleChange}
                                            error={this.state.errors.notificationDeviationTimeMinutes.message.length === 0 ? false : true}
                                            helperText={this.state.errors.notificationDeviationTimeMinutes.message}
                                            InputProps={{
                                                readOnly: this.props.readOnly,
                                                min: 0,
                                            }}
                                        />
                                    </FormControl>
                                )
                            }
                        </Grid>
                        <Grid item md={2} xs={6} sm={3}>
                            {
                                this.state.serviceType != SERVICES_TYPE.regularWithSchedule && (
                                    <FormControl fullWidth>
                                        <TextField
                                            InputLabelProps={{
                                                style: { pointerEvents: 'auto' },
                                                shrink: true,
                                            }}
                                            label={
                                                <div>
                                                    {t('services_comp.form.page.pickupDeviationTimeMinutes')}
                                                    <IconWithTooltip text={t('services_comp.form.page.tooltip.pickupDeviationTimeMinutes')} />
                                                </div>
                                            }
                                            value={this.state.pickupDeviationTimeMinutes}
                                            variant="outlined"
                                            name="pickupDeviationTimeMinutes"
                                            fullWidth
                                            type={'number'}
                                            onChange={this.handleChange}
                                            error={this.state.errors.pickupDeviationTimeMinutes.message.length === 0 ? false : true}
                                            helperText={this.state.errors.pickupDeviationTimeMinutes.message}
                                            InputProps={{
                                                readOnly: this.props.readOnly,
                                                min: 0,
                                            }}
                                        />
                                    </FormControl>
                                )
                            }
                        </Grid>
                        <Grid item md={2} xs={6} sm={3}>
                            {
                                this.state.serviceType != SERVICES_TYPE.regularWithSchedule && (
                                    <FormControl fullWidth>
                                        <TextField
                                            InputLabelProps={{
                                                style: { pointerEvents: 'auto' },
                                                shrink: true,
                                            }}
                                            label={
                                                <div>
                                                    {t('services_comp.form.page.assignedPickupDeviationTimeMinutes')}
                                                    <IconWithTooltip text={t('services_comp.form.page.tooltip.assignedPickupDeviationTimeMinutes')} />
                                                </div>
                                            }
                                            value={this.state.assignedPickupDeviationTimeMinutes}
                                            variant="outlined"
                                            name="assignedPickupDeviationTimeMinutes"
                                            fullWidth
                                            type={'number'}
                                            onChange={this.handleChange}
                                            error={this.state.errors.assignedPickupDeviationTimeMinutes.message.length === 0 ? false : true}
                                            helperText={this.state.errors.assignedPickupDeviationTimeMinutes.message}
                                            InputProps={{
                                                readOnly: this.props.readOnly,
                                                min: 0,
                                            }}
                                        />
                                    </FormControl>
                                )
                            }
                        </Grid>

                        <Grid item md={1} implementation="css" smDown component={Hidden} />

                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={
                                        <div>
                                            {t('services_comp.form.page.serviceTimeWheelChairSeconds')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.serviceTimeWheelChairSeconds')} />
                                        </div>
                                    }
                                    value={this.state.serviceTimeWheelChairSeconds}
                                    variant="outlined"
                                    name="serviceTimeWheelChairSeconds"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.serviceTimeWheelChairSeconds.message.length === 0 ? false : true}
                                    helperText={this.state.errors.serviceTimeWheelChairSeconds.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        min: 1,
                                        max: 1000,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    InputLabelProps={{
                                        style: { pointerEvents: 'auto' },
                                        shrink: true,
                                    }}
                                    label={
                                        <div>
                                            {t('services_comp.form.page.serviceTimeLuggageSeconds')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.serviceTimeLuggageSeconds')} />
                                        </div>
                                    }
                                    value={this.state.serviceTimeLuggageSeconds}
                                    variant="outlined"
                                    name="serviceTimeLuggageSeconds"
                                    fullWidth
                                    type={'number'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.serviceTimeLuggageSeconds.message.length === 0 ? false : true}
                                    helperText={this.state.errors.serviceTimeLuggageSeconds.message}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        min: 1,
                                        max: 1000,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        {this.state.serviceType != SERVICES_TYPE.assignmentWheelService && this.state.serviceType != SERVICES_TYPE.regularWithSchedule && (
                            <Grid item md={2} xs={6} sm={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        InputLabelProps={{
                                            style: { pointerEvents: 'auto' },
                                            shrink: true,
                                        }}
                                        label={
                                            <div>
                                                {t('services_comp.form.page.alternativePickupTimeOffset')}
                                                <IconWithTooltip text={t('services_comp.form.page.tooltip.alternativePickupTimeOffset')} />
                                            </div>
                                        }
                                        value={this.state.alternativePickupTimeOffset}
                                        variant="outlined"
                                        name="alternativePickupTimeOffset"
                                        fullWidth
                                        type={'number'}
                                        onChange={this.handleChange}
                                        error={this.state.errors.alternativePickupTimeOffset.message.length === 0 ? false : true}
                                        helperText={this.state.errors.alternativePickupTimeOffset.message}
                                        InputProps={{
                                            readOnly: this.props.readOnly,
                                            min: 0,
                                            max: 240,
                                        }}
                                    />
                                </FormControl>
                            </Grid>)
                        }
                        {this.state.serviceType == SERVICES_TYPE.regularWithSchedule &&
                            <Grid item md={2} xs={6} sm={3}>
                                <FormControlLabel
                                    fullWidth
                                    control={
                                        <Checkbox
                                            checked={this.state.avoidDistanceMatrixCalculationService}
                                            onChange={() => this.setState({ avoidDistanceMatrixCalculationService: !this.state.avoidDistanceMatrixCalculationService })}
                                            color="primary"
                                            disabled={this.props.readOnly}
                                        />
                                    }
                                    label={
                                        <div>
                                            {t('services_comp.form.page.avoidDistanceMatrixCalculationService')}
                                            <IconWithTooltip text={t('services_comp.form.page.tooltip.avoidDistanceMatrixCalculationService')} />
                                        </div>
                                    }
                                />
                            </Grid>
                        }
                        {this.state.serviceType == SERVICES_TYPE.assignmentWheelService && <Grid item md={2} implementation="css" smDown component={Hidden} />}
                        <Grid item md={5} implementation="css" smDown component={Hidden} />

                        {this.props.detail ? (
                            <React.Fragment>
                                <Grid item md={2} xs={3} implementation="css" smDown component={Hidden} />
                                <Grid item md={6} xs={6} sm={3}>
                                    <ZonesRelations results={this.state.zonesRelations} t={this.props.t} />
                                </Grid>
                                <Grid item md={4} xs={3} implementation="css" smDown component={Hidden} />
                            </React.Fragment>
                        ) : null}

                        <Grid item md={3} xs={3} sm={3} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} sm={4} xs={4}>
                            <Button variant="contained" color="primary" fullWidth size="large" onClick={this.onClickBack}>
                                {this.props.goBack}
                            </Button>
                        </Grid>
                        <Grid item md={1} xs={1} implementation="css" smDown component={Hidden} />
                        {allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage) && (
                            <Grid item md={2} sm={4} xs={4}>
                                <Button variant="contained" color="primary" type="submit" disabled={serviceReducer.pending} fullWidth size="large">
                                    {this.props.buttonRight}
                                </Button>
                            </Grid>
                        )}
                        <Grid item md={3} xs={1} implementation="css" smDown component={Hidden} />
                        <ServicesZonesDefinition
                            t={t}
                            open={this.state.openModal}
                            closeModal={this.handleCloseModal}
                            zones={this.state.zones}
                            state={this.state}
                            {...this.props}
                        />
                        <AlertDialog
                            open={this.state.modalStops}
                            onClickCancel={() =>
                                this.setState({
                                    modalStops: false,
                                })
                            }
                            onClickAccept={() =>
                                this.setState({
                                    modalStops: false,
                                    hasZoneWithStops: !this.state.hasZoneWithStops,
                                })
                            }
                            title={this.state.hasZoneWithStops ? t('services_comp.form.modal.titleUnchecked') : t('services_comp.form.modal.titleChecked')}
                        />
                        <AlertDialog
                            open={this.state.modalRestrictions}
                            onClickCancel={() =>
                                this.setState({
                                    modalRestrictions: false,
                                })
                            }
                            onClickAccept={() =>
                                this.setState({
                                    modalRestrictions: false,
                                    isRestricted: !this.state.isRestricted,
                                })
                            }
                            title={t('services_comp.form.page.shouldCompleteMessages')}
                        />
                    </Grid>
                </form>
            </React.Fragment>
        )
    }
}

export default ServiceFormView
