import { Grid } from "@material-ui/core"
import RequestTripForm from "../form"
import Spinner from "../../shared/spinner"
import TripMap from "../maps/index"
import i18next from "i18next"
import React from 'react';



export const RequestTripComponent = ({ tripReducer, child, getNoRestrictionValue, setOriginAndDestination, ...props }) => {
    return (
        <>
            <Grid item md={4} xs={12}>
                <RequestTripForm
                    {...props}
                    tripReducer={tripReducer}
                    title={i18next.t('trips.form.page.title')}
                    editable={tripReducer.serviceSelected == null}
                    ref={child}
                />
            </Grid>
            <Grid item md={8} xs={12}>
                {tripReducer.pending ?
                    <Spinner loading={tripReducer.pending} /> :
                    <TripMap
                        {...props}
                        tripReducer={tripReducer}
                        getNoRestrictionValue={getNoRestrictionValue}
                        setOriginAndDestination={setOriginAndDestination}
                    />
                }
            </Grid>
        </>
    )
}