import React from 'react';
import Spinner from '../../shared/spinner';
import ServiceForm from '../form';

class ServicesDetailsView extends React.Component {

    render() {
        const { serviceReducer } = this.props
        return (
            <React.Fragment>
                {serviceReducer.pending ?
                    <Spinner loading={serviceReducer.pending} /> :
                    <ServiceForm
                        {...this.props}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ServicesDetailsView;