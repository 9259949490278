import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React from 'react';
import Spinner from '../../shared/spinner';
import RequestTripForm from '../form';
import TripMap from '../maps/index'
import ResumeTrip from '../resume'
import MultipleBook from '../multipleBook';
import { RequestTripComponent, RequestTripWrapper } from './details';


class RequestTripView extends React.Component {

    constructor(props) {
        super()
        this.child = React.createRef();
        this.setOriginAndDestination = this.setOriginAndDestination.bind(this)
        this.setRequestTripProperties = this.setRequestTripProperties.bind(this)
        this.getNoRestrictionValue = this.getNoRestrictionValue.bind(this)
    }


    setOriginAndDestination(zoneId, zoneName, point) {
        this.child.current.setOriginAndDestination(zoneId, zoneName, point);
    }

    setRequestTripProperties(customer, origin, destination, luggage, hasWheelChair, numPassengers) {
        this.child.current.setRequestTripProperties(customer, origin, destination, luggage, hasWheelChair, numPassengers)
        this.props.checkProfile(customer.customerId)
    }

    getNoRestrictionValue() {
        return this.child.current?.state?.noRestriction
    }


    render() {
        const { tripReducer, t, serviceReducer, multipleTripReducer } = this.props
        return (
            <Grid container>
                {tripReducer.trip == null && !tripReducer.detail ?
                    <RequestTripComponent
                        child={this.child}
                        getNoRestrictionValue={this.getNoRestrictionValue}
                        setOriginAndDestination={this.setOriginAndDestination}
                        tripReducer={tripReducer}
                        {...this.props}
                    />
                    :
                    <ResumeTrip
                        {...this.props}
                        setRequestTripProperties={this.setRequestTripProperties}
                        setTripToPlan={this.props.setTripToPlan}
                        openModalSelectFrecuency={this.props.openModalSelectFrecuency}
                    />

                }

                <MultipleBook
                    isPending={multipleTripReducer.pending}
                    processMultipleTrip={this.props.processMultipleTrip}
                    tripIdToPlan={multipleTripReducer.tripIdToPlan}
                    open={multipleTripReducer.openModalSelectFrecuency}
                    setOpenModalSelectFrecuency={this.props.setOpenModalSelectFrecuency}
                    weekDays={serviceReducer.weekDays}
                    requestPickUpStartTime={multipleTripReducer.requestPickUpStartTime}
                />

            </Grid>
        );
    }
}

export default RequestTripView;