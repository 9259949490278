import { multipleTripConstants } from "../../constants/multipleTrip_constants"
import { tripConstants } from "../../constants/trip_constants"


let initialState = {
    openModalSelectFrecuency: false,
    openModalShowResults: false,
    executeMultipleTrips: false,
    results: [],
    pending: false,
    tripIdToPlan: null,
    requestPickUpStartTime: null
}

export function multipleTripReducer(state = initialState, action) {
    switch (action.type) {
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_REQUEST:
            return {
                ...state,
                pending: true
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_SUCCESS:
            return {
                ...state,
                pending: false,
                executeMultipleTrips: true,
                openModalSelectFrecuency: false,
                results: action.results
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case multipleTripConstants.OPEN_MODAL_SELECT_FRECUENCY:
            return {
                ...state,
                executeMultipleTrips: false,
                openModalSelectFrecuency: action.value,
                tripIdToPlan: action.tripId,
                requestPickUpStartTime: action.requestPickUpStartTime,
                serviceType: action.serviceType,
                maxRequestTripDays: action.maxRequestTimeDays
            }
        case multipleTripConstants.SHOW_MODAL_DETAILS:
            return {
                ...state,
                openModalShowResults: action.value
            }
        case multipleTripConstants.SET_TRIP_ID_PLAN:
            return {
                ...state,
                tripIdToPlan: action.value
            }
        case multipleTripConstants.UPDATE_LIST_TRIPS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(item => item.tripId != action.tripId)
            }
        case tripConstants.SET_TRIP:
            return {
                ...state,
                results: [],
                executeMultipleTrips: false
            }
        case tripConstants.GET_TRIP_SERVICES_REQUEST:
            return {
                ...state,
                results: [],
                executeMultipleTrips: false
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_REQUEST:
            return {
                ...state,
                pending: true
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.map((item) => item.tripId === action.tripIdToRetry ? action.result : item)
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case multipleTripConstants.CANCEL_TRIP_REQUEST:
            return {
                ...state,
                pending: true
            }
        case multipleTripConstants.CANCEL_TRIP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }


        default:
            return state
    }
}