import stringConstants from "../constants/strings"
import { authHeader } from "../helpers/authHeader"
import { handleResponse } from '../helpers/services';

export const regularLineService = {
    getRegularLines,
    getRegularLine,
    getRegularLineAvailableStops,
    getRegularLineUserTariff,
    setRegularLine,
    setRegularLineExpeditions,
    setRegularLineUserTariff,
    removeLine,
    getRegularLinePossibleDropoffs,
    getRegularLineStopScheduledPickupHours,
    setCalendarLine
}

//  endpoint only for type service with schedule
function getRegularLines(serviceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(
        stringConstants.URL_ApiGateway +
        `/services/v1/regularLine/getRegularLines?serviceId=${serviceId}`,
        requestOptions,
    ).then(handleResponse)
}

//  endpoint only for type service with schedule
function getRegularLine(lineId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(
        stringConstants.URL_ApiGateway +
        `/services/v1/regularLine/getRegularLine?lineId=${lineId}`,
        requestOptions,
    ).then(handleResponse)
}

//  endpoint only for type service with schedule
function getRegularLineUserTariff(lineId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(
        stringConstants.URL_ApiGateway +
        `/services/v1/regularLine/getRegularLineUserTariff?lineId=${lineId}`,
        requestOptions,
    ).then(handleResponse)
}

// endpoint only for type service with schedule
function getRegularLineAvailableStops(serviceId, lineId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const url = lineId ? `/services/v1/regularLine/getRegularLineAvailableStops?serviceId=${serviceId}&lineId=${lineId}` : `/services/v1/regularLine/getRegularLineAvailableStops?serviceId=${serviceId}`
    return fetch(
        stringConstants.URL_ApiGateway + url,
        requestOptions,
    ).then(handleResponse)
}

function getRegularLinePossibleDropoffs(dateTime, stopId) {
    let body = {
        'requestPickUpStartTime': dateTime,
        'pickupStopId': stopId
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }
    const url = `/services/v1/regularLine/getRegularLinePossibleDropoffs`
    return fetch(
        stringConstants.URL_ApiGateway + url,
        requestOptions,
    ).then(handleResponse)
}

function getRegularLineStopScheduledPickupHours(dateTime, stopIdPickUp, stopIdDropOff) {
    let body = {
        'requestPickUpStartTime': dateTime,
        'pickupStopId': stopIdPickUp,
        'dropoffStopId': stopIdDropOff
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }
    const url = `/services/v1/regularLine/getRegularLineStopScheduledPickupHours`
    return fetch(
        stringConstants.URL_ApiGateway + url,
        requestOptions,
    ).then(handleResponse)
}

function setRegularLine(lineId, line) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(line),
    }
    const url = lineId ? `/services/v1/regularLine/setRegularLine?lineId=${lineId}` : `/services/v1/regularLine/setRegularLine`
    return fetch(stringConstants.URL_ApiGateway + url, requestOptions).then(handleResponse)
}

function removeLine(lineId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader() }
    }
    const url = `/services/v1/regularLine/remove?id=${lineId}`
    return fetch(stringConstants.URL_ApiGateway + url, requestOptions).then(handleResponse)
}

function setRegularLineExpeditions(lineId, expeditions) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(expeditions),
    }
    return fetch(stringConstants.URL_ApiGateway + `/services/v1/regularLine/setRegularLineExpeditions?lineId=${lineId}`, requestOptions).then(handleResponse)
}

function setRegularLineUserTariff(lineId, tariff) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(tariff),
    }
    return fetch(stringConstants.URL_ApiGateway + `/services/v1/regularLine/setRegularLineUserTariff?lineId=${lineId}`, requestOptions).then(handleResponse)
}

function setCalendarLine(calendar, lineId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(calendar),
    }
    return fetch(stringConstants.URL_ApiGateway + `/services/v1/regularLine/setRegularLineCalendar?lineId=${lineId}`, requestOptions).then(handleResponse)
}