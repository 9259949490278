// totalDrivers: "Total de conductores"
// timeServicesByDriver: "Horas de servicio por conductor"
// totalJourneyByDriver: "Total de trayectos por conductor"
// totalAcceptedServicesByDriver: "Total de servicios aceptados por conductor"
// totalRejectedServicesByDriver: "Total de servicios rechazados por conductor"
// punctualityByDriver: "Puntualidad por conductor"
// totalMonthlyBillingByDriver: "Facturación mensual por conductor"
// totalKMByDriver: "Kms totales por conductor"
import React from 'react'
import { driversActions } from '../../../../redux/actions/driver_actions'
import { vehiclesActions } from '../../../../redux/actions/vehicles_actions'
import { tripActions } from '../../../../redux/actions/trip_actions'
import store from '../../../../redux/store'
import TimeServicesByDriver from './timeServicesByDriver'
import TripsPunctuality from './punctuality'
import TotalJourneysByDriver from './totalJourneysByDriver'
import TripsAcceptedByDriver from './tripsAccepted'
import TripsRejectedByDriver from './tripsRejected'
import NumTotalDrivers from './totalDrivers'
import DistanceByDriver from './distance'
import FullCostByDriver from './fullCost'

const KPI_SELECTED = { JOURNEYS_BY_DRIVER: 'totalJourneyByDriver', PUNCTUALITY_TRIPS_PER_DRIVER: 'punctualityByDriver', SERVICE_HOURS_PER_DRIVER: 'timeServicesByDriver', TRIPS_ACCEPTED: 'totalAcceptedServicesByDriver', TRIPS_REJECTED: 'totalRejectedServicesByDriver', TOTAL_DRIVERS: 'totalDrivers', TOTAL_KM_BY_DRIVER: 'totalKMByDriver', TOTAL_MONTHLY_BILLING_BY_DRIVER: 'totalMonthlyBillingByDriver' }

export const getDataForDriversChart = (kpiSelected, chartProps) => {
    const { startDate, finishDate } = chartProps
    if (kpiSelected == KPI_SELECTED.SERVICE_HOURS_PER_DRIVER) {
        return store.dispatch(vehiclesActions.getCalendarDriversKPI()).then(
            services => {
                return services
            },
            error => {
                console.log('error getCalendarDriversKPI =>', error)
                return null
            },
        )
    } else if (kpiSelected == KPI_SELECTED.JOURNEYS_BY_DRIVER || kpiSelected == KPI_SELECTED.TOTAL_KM_BY_DRIVER) {
        const { startDate, finishDate } = chartProps
        return store.dispatch(tripActions.getJourneyKPI(startDate, finishDate)).then(
            trips => {
                return trips
            },
            error => {
                console.log('error getDataForTripsChart => ', error)
                return null
            },
        )
    } else if (kpiSelected == KPI_SELECTED.TOTAL_DRIVERS) {
        return store.dispatch(driversActions.getDriversKPI()).then(
            results => {
                return results
            },
            error => {
                console.log('error getDriversKPI =>', error)
                return null
            },
        )
    } else if (kpiSelected == KPI_SELECTED.PUNCTUALITY_TRIPS_PER_DRIVER || kpiSelected == KPI_SELECTED.TOTAL_MONTHLY_BILLING_BY_DRIVER) {
        return store.dispatch(tripActions.getConfirmedTripsKPI(startDate, finishDate)).then(
            trips => {
                return trips
            },
            error => {
                console.log('error getDataForTripsChart => ', error)
                return null
            },
        )
    } else {
        if (kpiSelected === KPI_SELECTED.TRIPS_ACCEPTED || kpiSelected === KPI_SELECTED.TRIPS_REJECTED) {
            return store.dispatch(driversActions.getDriverRequests(kpiSelected === KPI_SELECTED.TRIPS_ACCEPTED ? 20 : 30, startDate, finishDate)).then(
                services => {
                    return services
                },
                error => {
                    console.log('error getDriverRequestsKPI =>', error)
                    return null
                },
            )
        }
    }

    return []
}

export const getDriversChart = (kpiSelected, chartProps, data) => {
    const { chartType, title, groupedBy, value, filterByDate, startDate, finishDate, weekDays } = chartProps

    console.log('kpiSelected =>', kpiSelected)

    if (kpiSelected == KPI_SELECTED.SERVICE_HOURS_PER_DRIVER) {
        return <TimeServicesByDriver data={data} chartType={chartType} title={title} groupedBy={groupedBy} value={value} filterByDate={filterByDate} startDate={startDate} finishDate={finishDate} weekDays={weekDays} />
    } else if (kpiSelected == KPI_SELECTED.JOURNEYS_BY_DRIVER) {
        return <TotalJourneysByDriver data={data} chartType={chartType} title={title} groupedBy={groupedBy} value={value} filterByDate={filterByDate} startDate={startDate} finishDate={finishDate} />
    } else if (kpiSelected == KPI_SELECTED.PUNCTUALITY_TRIPS_PER_DRIVER) {
        return <TripsPunctuality data={data} chartType={chartType} title={title} groupedBy={groupedBy} value={value} filterByDate={filterByDate} startDate={startDate} finishDate={finishDate} />
    } else if (kpiSelected == KPI_SELECTED.TOTAL_DRIVERS) {
        return <NumTotalDrivers groupedBy={groupedBy} chartType={chartType} data={data} value={value} title={title} filterByDate={filterByDate} startDate={startDate} finishDate={finishDate} />
    } else if (kpiSelected == KPI_SELECTED.TRIPS_ACCEPTED || kpiSelected == KPI_SELECTED.TRIPS_REJECTED) {
        if (kpiSelected == KPI_SELECTED.TRIPS_ACCEPTED) return <TripsAcceptedByDriver data={data} chartType={chartType} title={title} groupedBy={groupedBy} value={value} filterByDate={filterByDate} startDate={startDate} finishDate={finishDate} />
        else return <TripsRejectedByDriver data={data} chartType={chartType} title={title} groupedBy={groupedBy} value={value} filterByDate={filterByDate} startDate={startDate} finishDate={finishDate} />
    } else if(kpiSelected == KPI_SELECTED.TOTAL_KM_BY_DRIVER){
        return <DistanceByDriver data={data} chartType={chartType} title={title} groupedBy={groupedBy} value={value} filterByDate={filterByDate} startDate={startDate} finishDate={finishDate} />
    } else if(kpiSelected == KPI_SELECTED.TOTAL_MONTHLY_BILLING_BY_DRIVER){
        return <FullCostByDriver data={data} chartType={chartType} title={title} groupedBy={groupedBy} value={value} filterByDate={filterByDate} startDate={startDate} finishDate={finishDate} />
    }
}
