import React from "react";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import KpiWrapper from "../../../components/kpiWrapper";

export function CustomersChart({ isLoading }) {
    const { t } = useTranslation()

    const customers = useSelector(state => state.kpiReducer.customers);
    const isCustomersLoading = useSelector(state => state.kpiReducer.customersLoading)
    const data = customers.length > 0 ? customers : undefined

    const totalUsers = data && data.slice(1).reduce((acc, act) => {
        return acc + act[1].f
    }, 0)

    isLoading = isLoading || isCustomersLoading
    const displayNoData = !isLoading && data?.length === 1

    return (
        <KpiWrapper
            title={t("commandBox.users.customersChart.title")}
            displayNoData={displayNoData}
            total={totalUsers}
            isLoading={isLoading}
            skeletonVariant="rect">
            <Chart
                chartType="ColumnChart"
                graphID={'chart_customers'}
                data={data}
                width={"100%"}
                chartLanguage={i18next.language}
                options={{
                    vAxis: { title: t("commandBox.users.customersChart.vAxis") },
                    legend: { position: "none" },
                }}
            />
        </KpiWrapper>
    )
}