import { Grid, Hidden, Button } from '@material-ui/core'
import React from 'react'
import PersonalData from './shared/personalData'
import ListRelatedCustomers from './shared/listRelatedCustomer'
import { googleMapsService } from '../../../services/googleMapsService';
import DownloadIcon from '@material-ui/icons/GetApp'
import CustomerParamsModal from './modal'
import PenaltiesAndAbsences from './shared/penalties'
import ItemAddress from './shared/itemAddress'
import utils from '../../../helpers/validations';

class CustomerFormView extends React.Component {

    constructor(props) {
        const customer = props.customer ? props.customer : {}
        super()
        this.state = {
            openModal: false,
            openModalForm: false,
            name: customer.name || '',
            surname1: customer.surname1 || '',
            surname2: customer.surname2 || '',
            email: customer.email || '',
            document: customer.document || '',
            phone: customer.phone || '',
            registerUser:
                (customer.firebaseUID == null ? false : true) || false,
            address: customer.addressPosition || null,
            relatedCustomers: customer.relatedCustomers || [],
            absences: customer.absences || [],
            favouriteAddresses: customer.favouriteAddresses && customer.favouriteAddresses.length > 0 ? customer.favouriteAddresses : [
                {
                    addressPosition: {
                        lat: 0,
                        lon: 0,
                        address: ""
                    },
                    type: ''
                }
            ],
            errors: {
                favouriteAddresses: [
                    {
                        address: { result: true, message: "" },
                        type: { result: true, message: "" },
                    },
                ],
                name: { result: false, message: '' },
                surname1: { result: false, message: '' },
                surname2: { result: false, message: '' },
                email: { result: false, message: '' },
                document: { result: false, message: '' },
                phone: { result: false, message: '' },
            },
        }
        if (this.state.favouriteAddresses.length > 1) {
            const errors = this.state.errors;
            this.state.favouriteAddresses.forEach(function (element, i) {
                errors.favouriteAddresses[i] = {

                    address: { result: true, message: "" },
                    type: { result: true, message: "" },
                };
            });
            this.state.errors = errors
        }
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.saveRelatedCustomer = this.saveRelatedCustomer.bind(this)
        this.addTime = this.addTime.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.validateFavoriteAddresses = this.validateFavoriteAddresses.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.customerReducer.customer !=
            prevProps.customerReducer.customer
        ) {
            console.log(
                'customer ha canviat => ',
                this.props.customerReducer.customer,
            )
        }
    }

    saveRelatedCustomer(relatedCustomer) {
        this.setState({
            relatedCustomers: [...this.state.relatedCustomers, relatedCustomer],
        })
    }

    onClickOpenModal(data) {
        this.setState({ openModal: true })
    }

    openModal() {
        this.setState({ openModalForm: true })
    }

    closeModal() {
        this.setState({ openModalForm: false })
    }

    onClickCancelModal() {
        this.setState({ openModal: false })
    }

    getPersonalInfo = (customer, email, lang) => {
        this.props.getPersonalInfo(this.props.customer, email, lang)
        this.setState({ openModal: false })
    }

    addTime() {
        let errors = this.state.errors;
        errors.favouriteAddresses.push({
            type: { result: false, message: "" },
            address: { result: false, message: "" },
        });
        this.setState({
            favouriteAddresses: [
                ...this.state.favouriteAddresses,
                {
                    addressPosition: {
                        lat: 0,
                        lon: 0,
                        address: ""
                    },
                    type: ''
                },
            ],
            errors,
        });
    }

    removeItem(index) {
        let errors = this.state.errors;
        errors.favouriteAddresses = errors.favouriteAddresses.filter((_, i) => i !== index);
        this.setState({
            favouriteAddresses: this.state.favouriteAddresses.filter((_, i) => i !== index),
            errors,
        });
    }

    onChange(event) {
        let { name, value } = event.target;
        let favouriteAddresses = this.state.favouriteAddresses;
        let errors = this.state.errors;
        let array = name.split('_')
        favouriteAddresses[array[1]][array[0]] = value
        errors.favouriteAddresses[array[1]][array[0]].message = ""
        this.setState({ favouriteAddresses })
    }

    onChangeAddress = (newValue, event) => {
        const array = event.nativeEvent.currentTarget.activeElement.name.split('_')
        let index = array[1]
        let favouriteAddresses = this.state.favouriteAddresses
        if (newValue) {
            googleMapsService.getLocation(newValue.description).then(
                location => {
                    favouriteAddresses[index]["addressPosition"]["address"] = newValue.description
                    favouriteAddresses[index]["addressPosition"].lat = location.lat
                    favouriteAddresses[index]["addressPosition"].lon = location.lng
                }
            )
        } else {
            favouriteAddresses[index]["addressPosition"]["address"] = ""
            favouriteAddresses[index]["addressPosition"].lat = ""
            favouriteAddresses[index]["addressPosition"].lon = ""
        }
        this.setState({
            favouriteAddresses
        })
    }

    validateFavoriteAddresses() {
        let state = this.state
        let errors = this.state.errors
        this.state.errors.favouriteAddresses.forEach(function (element, i) {
            if (state.favouriteAddresses[i].addressPosition.address != '' && state.favouriteAddresses[i].type == "") {
                errors.favouriteAddresses[i].type = utils.required(state.favouriteAddresses[i].type)
            } else {
                errors.favouriteAddresses[i].type = { result: true, message: "" }
            }
            if (state.favouriteAddresses[i].addressPosition.address == '' && state.favouriteAddresses[i].type != "") {
                errors.favouriteAddresses[i].address = utils.required(state.favouriteAddresses[i].addressPosition.address)
            } else {
                errors.favouriteAddresses[i].address = { result: true, message: "" }
            }
        });
        this.setState({ errors })
    }

    render() {
        const { t, customer, customerReducer } = this.props
        return (
            <React.Fragment>
                <PersonalData
                    {...this.props}
                    user={this.props.customer}
                    withAction={true}
                    favouriteAddresses={this.state.favouriteAddresses}
                    errorsFavouriteAddresses={this.state.errors.favouriteAddresses}
                    closeModal={this.closeModal}
                    validateFavoriteAddresses={this.validateFavoriteAddresses}
                />
                <Grid container spacing={3}>
                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                    <Grid item md={11}>
                        <h2>{t('customers.form.page.addresses.title')}</h2>
                    </Grid>
                    {this.state.favouriteAddresses.map((item, index) => (
                        <ItemAddress
                            key={index}
                            readOnly={this.props.readOnly}
                            item={item}
                            index={index}
                            t={t}
                            onChange={(event) => this.onChange(event)}
                            onChangeAddress={this.onChangeAddress}
                            add={this.addTime}
                            remove={this.removeItem}
                            length={this.state.favouriteAddresses.length}
                            value={this.state.favouriteAddresses[index]}
                            customerReducer={customerReducer}
                            state={this.state}
                        />
                    ))}
                    <Grid
                        item
                        md={1}
                        xs={1}
                        implementation="css"
                        smDown
                        component={Hidden}
                    />
                    <Grid item md={9}>
                        <ListRelatedCustomers
                            {...this.props}
                            open={this.state.openModalForm}
                            openModal={this.openModal}
                            closeModal={this.closeModal}
                            saveRelatedCustomer={this.saveRelatedCustomer}
                        />
                        {this.props.action != 'create' ? (
                            <PenaltiesAndAbsences {...this.props} />
                        ) : null}

                        <Button
                            onClick={() => this.onClickOpenModal()}
                            variant="contained"
                            color="primary"
                            startIcon={<DownloadIcon />}
                            disabled={
                                this.props.customerReducer.isSubmittingGDPR
                            }
                        >
                            {t(
                                'customers.form.page.personalData.downloadMyData',
                            )}
                        </Button>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        xs={1}
                        implementation="css"
                        smDown
                        component={Hidden}
                    />
                </Grid>
                {customer && (
                    <CustomerParamsModal
                        open={this.state.openModal}
                        title={t(
                            'customers.form.page.personalData.setEmailAndLanguage',
                        )}
                        onClickCancel={() => this.onClickCancelModal()}
                        onClickAccept={(customer, email, lang) =>
                            this.getPersonalInfo(customer, email, lang)
                        }
                        customer={customer}
                        handleChange={event => this.handleChange(event)}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default CustomerFormView
