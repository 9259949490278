import React from 'react';
import { Chart } from "react-google-charts";
import PropTypes from 'prop-types';
import { FilterOptions, options, DownloadChart, filterDataByGroup } from '../../../customFunctions';
import i18next from 'i18next';


const filteredData = (data, filterOptions, value) => {
    const { groupedBy } = filterOptions
    let array = [["Trips", "AVG Distance"]]

    value.forEach(element => {
        let trips = filterDataByGroup(data, groupedBy, element).filter(t => t.distance > 0)
        let totDistance = 0        
        trips.forEach(itemTrip => {
            totDistance += itemTrip.distance;
        });

        if(totDistance > 0)
            array.push([element, totDistance/ trips.length])
    });

    console.log("array => ", array)
    return array
}

const DistanceByTrip = ({
    data,
    title = "Promig Kms per viatge",
    groupedBy = "municipalities",
    value = [],
    filterByDate = true,
    startDate = "2022-01-01",
    finishDate = "2022-02-01",
    timeUnits
}) => {

    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits)
    const chartType = "Bar"

    return (
        <>
            <React.Fragment key={Math.random()}>
                {
                    filteredData(data, filterOptions, value).length > 0 ?
                        <>
                            <Chart
                                chartType={chartType}
                                graphID={'chart_0'}
                                width="100%"
                                height="400px"
                                data={filteredData(data, filterOptions, value)}
                                options={options(chartProps, null, value)}
                                chartLanguage={i18next.language}
                            />
                            <DownloadChart key={Math.random()} img64={0} />
                        </>
                        : null
                }
            </React.Fragment>

        </>
    )
}
DistanceByTrip.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(["municipalities", "regions", "otps", "services", "tenants"]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default DistanceByTrip;