import { Button, Grid, Hidden } from "@material-ui/core";
import React from "react";
import VehicleCalendarRange from "./ranges";
import { Remove } from "@material-ui/icons";
import moment from "moment";
import { history } from "../../../helpers/history";

class VehicleCalendarRangeView extends React.Component {
    constructor(props) {
        super();
        this.saveCalendarOnClick = this.saveCalendarOnClick.bind(this);
    }

    saveCalendarOnClick() {
        const { vehicle } = this.props.vehicleReducer;
        this.props.setCalendar(vehicle.excludedPeriods, vehicle.id);
    }

    onClickBack() {
        history.goBack();
    }

    render() {
        const { t, vehicleReducer } = this.props;
        const { excludedPeriods } = vehicleReducer.vehicle;
        const { vehicle } = vehicleReducer;

        return (
            <React.Fragment>
                <Grid container style={{ padding: 15 }}>

                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                    <Grid item md={11}>
                        <h1>{vehicle.displayName}</h1>
                    </Grid>


                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                    <Grid item md={8}>
                        <VehicleCalendarRange {...this.props} />
                    </Grid>
                    <Grid item md={3} implementation="css" smDown component={Hidden} />

                    {excludedPeriods &&
                        excludedPeriods.map((item, index) => (
                            <React.Fragment key={index}>
                                <Grid
                                    item
                                    md={3}
                                    implementation="css"
                                    smDown
                                    component={Hidden}
                                />
                                <Grid item md={2}>
                                    <span>{t("services_comp.calendar.range.start")}: </span>
                                    {moment(item.startPeriod).format("DD/MM/YYYY")}
                                </Grid>
                                <Grid item md={2}>
                                    <span>{t("services_comp.calendar.range.end")}: </span>
                                    {moment(item.finishPeriod).format("DD/MM/YYYY")}
                                </Grid>
                                <Grid item md={2}>
                                    <Remove
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.props.removeExcludeDays(index)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    implementation="css"
                                    smDown
                                    component={Hidden}
                                />
                            </React.Fragment>
                        ))}

                    <Grid item md={3} implementation="css" smDown component={Hidden} />
                    <Grid item md={2}>
                        <Button
                            style={{ marginTop: 40 }}
                            variant="contained"
                            color="primary"
                            onClick={this.onClickBack}
                            fullWidth
                            size="large"
                        >
                            {t("services_comp.form.page.goBack")}
                        </Button>
                    </Grid>
                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                    <Grid item md={2}>
                        <Button
                            style={{ marginTop: 40 }}
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            disabled={vehicleReducer.pending ? true : false}
                            onClick={this.saveCalendarOnClick}
                        >
                            {t("services_comp.form.page.buttonEdit")}
                        </Button>
                    </Grid>

                    <Grid item md={4} implementation="css" smDown component={Hidden} />
                </Grid>
            </React.Fragment>
        );
    }
}

export default VehicleCalendarRangeView;
