import React from 'react'
import MaterialTableExtend from '../material-table'
import { history } from '../../helpers/history'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { CsvBuilder } from 'filefy'
import AlertDialog from '../alert-dialog'
import Spinner from '../shared/spinner'
import FilterDates from '../shared/filter/filterRanges'
import SelectDriverToTrip from './selectDriverToTrip'
import CancelTripModal from './cancelTripModal'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import i18next from 'i18next'
import { withTheme } from '@material-ui/core/styles';
import { SERVICES_TYPE, TripStatus } from '../../constants/types'
import FilterComponent from '../shared/filter'
import { allowAccessRole } from '../../helpers/roles'
import { MODULES, OPERATIONS_ALLOWED } from '../roles/constants'
import { tripReducer } from '../../redux/reducers/tripReducer'
import { tripActions } from '../../redux/actions/trip_actions'

class TripsView extends React.Component {
    constructor(props) {
        super()
        this.onClickCancelModal = this.onClickCancelModal.bind(this)
        this.onClickAcceptModalDelete = this.onClickAcceptModalDelete.bind(this)
        this.exportCsv = this.exportCsv.bind(this)
        this.onClickCancelModalAssingDriver = this.onClickCancelModalAssingDriver.bind(this)
        this.exportPdf = this.exportPdf.bind(this)
        this.setValue = this.setValue.bind(this)
        this.state = {
            openModal: false,
            openModalWheel: false,
            item: {},
            filter: props.tripReducer.filtersTrips
        }
    }

    onClickCancelModal() {
        this.setState({ openModal: false, openModalWheel: false })
    }

    onClickAcceptModalDelete() {
        this.setState({ openModal: false })
        this.props.cancelTrip(this.state.item.tripId, this.props.tripReducer.filterStartDate, this.props.tripReducer.filterEndDate)
    }

    onClickOpenModal(data) {
        if (data.status != TripStatus.RejectedByAllDrivers) this.setState({ openModal: true, item: data })
        else this.setState({ openModalWheel: true, item: data })
    }

    onClickBack() {
        history.goBack()
    }

    onClickCancelModalAssingDriver() {
        this.props.closeModal()
    }

    onClickAssingDriver(trip, movementType) {
        this.props.getPosibleDriversToAssing(trip)
        this.setState({ trip: trip, movementType: movementType })
    }

    onClickDetail(trip) {
        this.props.setTrip(trip)
        const params = new URLSearchParams({ id: trip.tripId });
        history.push({ pathname: '/resumeTrip', search: params.toString() });
    }

    onClickCommentsTrips(data) {
        history.push('/customers/trips/observations/' + data.tripId)
    }

    isDate(dateToTest) {
        return isNaN(dateToTest) && !isNaN(Date.parse(dateToTest));
    }

    exportCsv(columns, data, table) {
        const { tripReducer } = this.props
        // Turn headers into array of strings
        columns = columns.filter(i => !i.hidden || i.export)
        const headerRow = columns.map(col => {
            if (typeof col.title === 'object') {
                /** I am not sure what props the Translate component exposes
                 *  but I would need to change `text` in `col.title.props.text`
                 *  to whatever prop I need
                 */
                return col.title.props.text
            }
            return col.title
        })

        const headerFilter = columns.map(col => {
            if (typeof col.field === 'object' && !col.hidden && col.export) {
                return col.field.props.text
            }
            return col.field
        })

        // Turn data into an array of string arrays, without the `tableData` prop
        const dataRows = data.map(({ tableData, ...row }) => {
            let obj = {}
            let result = []
            for (const element of headerFilter) {
                if (!element.includes("."))
                    obj[element] = row[element]
                else {
                    let splitted = element.split('.')
                    obj[element] = row[splitted[0]][splitted[1]]
                }
            }

            Object.entries(obj).forEach(([key, value]) => {

                if (this.isDate(value)) {
                    value = moment(value).format('DD/MM/YYYY HH:mm')
                }
                if (key == 'status') {
                    value = tripReducer.typesTrips.find(x => x.id == value)?.name || ''
                }
                result.push(value?.toLocaleString())
            })
            return result
        })

        /** Aggregate header data and 'body' data
         *  Mirror default export behavior by joining data via
         *  the delimeter specified within material table (by default comma delimited)
         */
        const { exportDelimiter } = table.props.options
        const delimiter = exportDelimiter || ';'

        /** This mirrors the default export behavior where the
         *  exported file name is the table title.
         */
        const csvFileName = table.props.title

        // Allow user to download file as .csv
        // eslint-disable-next-line no-unused-vars
        const builder = new CsvBuilder(csvFileName + '.csv').setDelimeter(delimiter).setColumns(headerRow).addRows(dataRows).exportFile()
    }

    exportPdf(columns, data, table) {
        const doc = new jsPDF({
            orientation: 'l',
            unit: 'px',
            format: [625, 800],
        })

        const columnTitles = columns.filter(item => !item.hidden).map(columnDef => columnDef.field && i18next.t(`tripsAll.export.pdf.${columnDef.field}`))
        const pdfData = data.map(rowData => {
            return columns.filter(item => !item.hidden).map(columnDef => {
                if (columnDef.field?.includes('pickUpLocation') || columnDef.field?.includes('dropOffLocation')) {
                    return rowData[columnDef.field.split('.')[0]].address
                }

                if (this.isDate(rowData[columnDef.field])) {
                    return moment(rowData[columnDef.field]).format('DD/MM/YYYY HH:mm')
                }

                return columnDef.field == 'status' ? columnDef.render(rowData) : rowData[columnDef.field] || ''
            })
        })
        doc.text(table.props.title, 30, 25)
        doc.autoTable({
            head: [columnTitles],
            body: pdfData,
            didDrawPage: function (data) {
                doc.setFontSize(20)
                doc.setTextColor(40)
            },
        })

        doc.save(`${table.props.title}.pdf`)
    }


    canAssignDriver(rowData) {
        return rowData.status === TripStatus.RejectedByAllDrivers || (rowData.status === TripStatus.NoDriversAvailable && rowData.serviceType === SERVICES_TYPE.regularWithSchedule)
    }

    canDelegateTrip(rowData) {
        return ([TripStatus.AcceptedByUser, TripStatus.AcceptedByCustomerService].includes(rowData.status) && [SERVICES_TYPE.tadService, SERVICES_TYPE.regularService].includes(rowData.serviceType))
            || ([TripStatus.AcceptedByDriver, TripStatus.DriverAssignedByOperator, TripStatus.DriverAssignedAfterCompaction].includes(rowData.status) && [SERVICES_TYPE.regularWithSchedule, SERVICES_TYPE.assignmentWheelService].includes(rowData.serviceType))
    }

    getTypes(tripReducer) {
        const obj = {}
        tripReducer.typesTrips.forEach(element => {
            obj[element.id] = element.name
        })
        return obj
    }

    setValue(prop, values) {
        const { tripReducer } = this.props;
        const tripStatus = this.getTypes(tripReducer)
        values = values.map(item => Object.keys(tripStatus)[Object.values(tripStatus).indexOf(item)])
        const filters = {
            status: prop == 'status' ? values : tripReducer.filterTrips.status
        }
        this.props.filterTrips(filters, tripReducer.allTrips)
    }

    render() {
        const { t, tripReducer, driverReducer } = this.props
        const tripStatus = this.getTypes(tripReducer)
        const isPending = this.props.location.pathname.includes('tripsPending')
        return (
            <React.Fragment>
                <FilterDates
                    onClickFilter={this.props.location.pathname.includes('tripsPending') ? this.props.getTripsPending : this.props.getTrips}
                    startDate={tripReducer.filterStartDate}
                    endDate={tripReducer.filterEndDate}
                    setFilterDate={this.props.setFilterDate}
                    cleanFilters={this.props.cleanFilters}
                />
                {tripReducer.pending ? (
                    <Spinner loading={tripReducer.pending} />
                ) : (
                    <React.Fragment>
                        <MaterialTableExtend
                            title={t('journeys.page.title2')}
                            onFilterChange={filter => {
                                this.props.saveTableFilters(filter)
                            }}
                            columns={[
                                {
                                    title: '',
                                    hidden: true,
                                    export: false
                                },
                                {
                                    title: t('customers.trips.page.columns.serviceName'),
                                    field: 'serviceName',
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "serviceName")[0]?.value ?? null,
                                    render: (rowData) => {
                                        return (
                                            <React.Fragment>
                                                {rowData.isOnRelatedCustomerBehalf && (
                                                    <Tooltip title={t('customers.trips.page.columns.relatedTrip') + rowData.relatedCustomerName} arrow>
                                                        <GroupIcon
                                                            color="primary"
                                                            style={{
                                                                fontSize: 15,
                                                                marginRight: 2,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                {rowData.isLateCancellation && (
                                                    <Tooltip title={t('customers.trips.page.columns.lateCancellation')} arrow>
                                                        <NotificationsIcon
                                                            style={{
                                                                fontSize: 15,
                                                                marginRight: 2,
                                                                color: 'orange',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                {rowData.serviceName}
                                            </React.Fragment>)
                                    }
                                },
                                {
                                    title: t('customers.trips.page.columns.status'),
                                    field: 'status',
                                    render: (rowData) => tripStatus[rowData.status],
                                    width: 150,
                                    filterComponent: rowData => (
                                        <FilterComponent
                                            {...this.props}
                                            optionsArray={[
                                                ...new Set(
                                                    tripReducer.allTrips
                                                        .sort((a, b) => a.status.toString() - b.status.toString())
                                                        .map(x => tripStatus[x.status.toString()]),
                                                )
                                            ]}
                                            setValue={this.setValue}
                                            prop={'status'}
                                            values={tripReducer.filterTrips?.status?.map(item => Object.values(tripStatus)[Object.keys(tripStatus).indexOf(item)])}
                                        />
                                    ),
                                },
                                {
                                    title: t('customers.trips.page.columns.ref'),
                                    hidden: isPending,
                                    export: isPending,
                                    field: 'journeyRef',
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "journeyRef")[0]?.value ?? null,
                                    render: rowData => {
                                        return (
                                            rowData && (
                                                <label>{rowData.journeyRef != '0' ? rowData.journeyRef : ''}</label>)
                                        )
                                    },
                                },
                                {
                                    title: t('customers.trips.page.columns.schedulePickUpTime'),
                                    field: 'schedulePickUpTime',
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "schedulePickUpTime")[0]?.value ?? null,
                                    customFilterAndSearch: (term, rowData) => moment(rowData.schedulePickUpTime).format('DD/MM/YYYY HH:mm').includes(term),
                                    render: rowData => (rowData.schedulePickUpTime ? `${moment(rowData.schedulePickUpTime).format('DD/MM/YYYY HH:mm')}` : ''),
                                },
                                {
                                    title: t('customers.trips.page.columns.pickUpTime'),
                                    field: 'actualPickUpTime',
                                    hidden: isPending,
                                    export: isPending,
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "actualPickUpTime")[0]?.value ?? null,
                                    customFilterAndSearch: (term, rowData) => moment(rowData.actualPickUpTime).format('DD/MM/YYYY HH:mm').includes(term),
                                    render: rowData => (rowData.actualPickUpTime ? `${moment(rowData.actualPickUpTime).format('DD/MM/YYYY HH:mm')}` : ''),
                                },
                                {
                                    title: t('customers.trips.page.columns.origin'),
                                    field: 'pickUpLocation.address',
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "pickUpLocation.address")[0]?.value ?? null,
                                },
                                {
                                    title: t('customers.trips.page.columns.destination'),
                                    field: 'dropOffLocation.address',
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "dropOffLocation.address")[0]?.value ?? null,
                                },
                                {
                                    title: t('customers.trips.page.columns.passenger'),
                                    field: 'customerName',
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "customerName")[0]?.value ?? null,
                                },
                                {
                                    title: t('customers.trips.page.columns.passengers'),
                                    field: 'numPassengers',
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "numPassengers")[0]?.value ?? null,
                                },
                                {
                                    title: t('customers.trips.page.columns.driver'),
                                    field: 'driverName',
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "driverName")[0]?.value ?? null,
                                    export: isPending,
                                    hidden: isPending,
                                },
                                {
                                    title: t('customers.trips.page.columns.dropOffTime'),
                                    field: 'actualDropOffTime',
                                    hidden: isPending,
                                    export: isPending,
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "actualDropOffTime")[0]?.value ?? null,
                                    render: rowData => (rowData.actualDropOffTime ? `${moment(rowData.actualDropOffTime).format('DD/MM/YYYY HH:mm')}` : ''),
                                },
                                {
                                    title: t('customers.trips.page.columns.scheduleDropOffTime'),
                                    field: 'scheduleDropOffTime',
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "scheduleDropOffTime")[0]?.value ?? null,
                                    customFilterAndSearch: (term, rowData) => moment(rowData.scheduleDropOffTime).format('DD/MM/YYYY HH:mm').includes(term),
                                    render: rowData => (rowData.scheduleDropOffTime ? `${moment(rowData.scheduleDropOffTime).format('DD/MM/YYYY HH:mm')}` : ''),
                                },
                                {
                                    title: t('customers.trips.page.columns.cancelledTime'),
                                    field: 'cancelled',
                                    hidden: isPending,
                                    export: isPending,
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "cancelled")[0]?.value ?? null,
                                    customFilterAndSearch: (term, rowData) => moment(rowData.cancelled).format('DD/MM/YYYY HH:mm').includes(term),
                                    render: rowData => (rowData.cancelled ? `${moment(rowData.cancelled).format('DD/MM/YYYY HH:mm')}` : ''),
                                },
                                {
                                    title: t('customers.trips.page.columns.vehicle'),
                                    field: 'vehicleDisplayName',
                                    defaultFilter: tripReducer.tableFilters.filter(col => col.column.field == "vehicleDisplayName")[0]?.value ?? null,
                                },
                                {
                                    title: t('customers.trips.page.columns.customerAmount'),
                                    field: 'price.customerGrossAmount',
                                    export: true,
                                    hidden: true
                                },
                                {
                                    title: t('customers.trips.page.columns.holderAmount'),
                                    field: 'price.holderGrossAmount',
                                    export: true,
                                    hidden: true
                                },
                                {
                                    title: t('customers.trips.page.columns.totalAmount'),
                                    field: 'price.totalGrossAmount',
                                    export: true,
                                    hidden: true
                                },
                                {
                                    title: t('customers.trips.page.columns.pickupAndDropoffConcatenated'),
                                    field: 'pickupAndDropoffConcatenated',
                                    export: true,
                                    hidden: true
                                },
                                {
                                    title: t('customers.trips.page.columns.expeditionId'),
                                    field: 'expeditionId',
                                    export: true,
                                    hidden: true
                                }
                            ]}
                            actions={[
                                rowData => ({
                                    icon: 'view_list',
                                    tooltip: t('customers.page.actions.detail'),
                                    onClick: (event, rowData) => this.onClickDetail(rowData),
                                    hidden: rowData.pickUpLocation.address == null || rowData.dropOffLocation.address == null,
                                }),
                                rowData => ({
                                    icon: 'note_outlined',
                                    iconProps: {
                                        style: {
                                            color: rowData.hasActivities ? this.props.theme.palette.primary.main : '',
                                        },
                                    },
                                    tooltip: t('customers.page.actions.observacions'),
                                    onClick: (event, rowData) => {
                                        this.onClickCommentsTrips(rowData)
                                    },
                                    hidden: !allowAccessRole(MODULES.Trips, OPERATIONS_ALLOWED.manage)
                                }),
                                rowData => ({
                                    icon: 'cancel',
                                    tooltip: t('customers.trips.page.actions.delete'),
                                    onClick: (event, rowData) => this.onClickOpenModal(rowData),
                                    hidden: !rowData.isCancellable || !allowAccessRole(MODULES.Trips, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'drive_eta',
                                    tooltip: t('customers.trips.page.actions.assing'),
                                    onClick: (event, rowData) => this.onClickAssingDriver(rowData, "assign"),
                                    hidden: !this.canAssignDriver(rowData) || !allowAccessRole(MODULES.Trips, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'drive_eta',
                                    tooltip: t('customers.trips.page.actions.delegate'),
                                    onClick: (event, rowData) => this.onClickAssingDriver(rowData, "delegate"),
                                    hidden: !this.canDelegateTrip(rowData) || !allowAccessRole(MODULES.Trips, OPERATIONS_ALLOWED.manage),
                                }),
                            ]}
                            data={tripReducer.results}
                            actionsColumnIndex={0}
                            /*fixedColumns={{
                                left: 1,
                            }}*/
                            alignButtonsCenter={true}
                            exportCsv={this.exportCsv}
                            exportPdf={this.exportPdf}
                        />
                        <AlertDialog
                            open={this.state.openModal}
                            title={t('customers.trips.page.alert_dialog.titleDelete')}
                            onClickAccept={this.onClickAcceptModalDelete}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <CancelTripModal
                            open={this.state.openModalWheel}
                            startDate={tripReducer.filterStartDate}
                            endDate={tripReducer.filterEndDate}
                            itemSelected={this.state.item}
                            cancelTrip={this.props.cancelTrip}
                            cancelTripDriver={this.props.cancelTripByDriverUnavailable}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <SelectDriverToTrip
                            open={driverReducer.modalToAssingDriver}
                            t={t}
                            driverReducer={driverReducer}
                            tripId={this.state.trip?.tripId}
                            startDate={tripReducer.filterStartDate}
                            endDate={tripReducer.filterEndDate}
                            onClickCancel={this.onClickCancelModalAssingDriver}
                            onClickAccept={this.state.movementType === "assign" ? this.props.confirmTripVehicle : this.props.delegateTripVehicle}
                            pending={this.props.driverReducer.pending}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default withTheme(TripsView)
