export const KPIConstants = {
    GET_CONFIRMED_TRIPS_REQUEST: 'GET_CONFIRMED_TRIPS_REQUEST',
    GET_CONFIRMED_TRIPS_SUCCESS: 'GET_CONFIRMED_TRIPS_SUCCESS',
    GET_CONFIRMED_TRIPS_FAILURE: 'GET_CONFIRMED_TRIPS_FAILURE',

    GET_RESERVATIONS_REQUEST: 'GET_RESERVATIONS_REQUEST',
    GET_RESERVATIONS_SUCCESS: 'GET_RESERVATIONS_SUCCESS',
    GET_RESERVATIONS_FAILURE: 'GET_RESERVATIONS_FAILURE',

    GET_ALERTS_REQUEST: 'GET_ALERTS_REQUEST',
    GET_ALERTS_SUCCESS: 'GET_ALERTS_SUCCESS',
    GET_ALERTS_FAILURE: 'GET_ALERTS_FAILURE',

    GET_CUSTOMERS_REQUEST: 'GET_CUSTOMERS_REQUEST',
    GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
    GET_CUSTOMERS_FAILURE: 'GET_CUSTOMERS_FAILURE',

    GET_DRIVERS_REQUEST: 'GET_DRIVERS_REQUEST',
    GET_DRIVERS_SUCCESS: 'GET_DRIVERS_SUCCESS',
    GET_DRIVERS_FAILURE: 'GET_DRIVERS_FAILURE',

    GET_DRIVER_REQUEST_REQUEST: 'GET_DRIVER_REQUEST_REQUEST',
    GET_DRIVER_REQUEST_SUCCESS: 'GET_DRIVER_REQUEST_SUCCESS',
    GET_DRIVER_REQUEST_FAILURE: 'GET_DRIVER_REQUEST_FAILURE',

    GET_JOURNEYS_REQUEST: 'GET_JOURNEYS_REQUEST',
    GET_JOURNEYS_SUCCESS: 'GET_JOURNEYS_SUCCESS',
    GET_JOURNEYS_FAILURE: 'GET_JOURNEYS_FAILURE',

    GET_CONFIRMED_GROUPED_TRIPS_REQUEST: 'GET_CONFIRMED_GROUPED_TRIPS_REQUEST',
    GET_CONFIRMED_GROUPED_TRIPS_SUCCESS: 'GET_CONFIRMED_GROUPED_TRIPS_SUCCESS',
    GET_CONFIRMED_GROUPED_TRIPS_FAILURE: 'GET_CONFIRMED_GROUPED_TRIPS_FAILURE',

    GET_DRIVER_CALENDAR_REQUEST: 'GET_DRIVER_CALENDAR_REQUEST',
    GET_DRIVER_CALENDAR_SUCCESS: 'GET_DRIVER_CALENDAR_SUCCESS',
    GET_DRIVER_CALENDAR_FAILURE: 'GET_DRIVER_CALENDAR_FAILURE',

    GET_TRIP_BOOKING_REQUEST: 'GET_TRIP_BOOKING_REQUEST',
    GET_TRIP_BOOKING_SUCCESS: 'GET_TRIP_BOOKING_SUCCESS',
    GET_TRIP_BOOKING_FAILURE: 'GET_TRIP_BOOKING_FAILURE',

    CLEAN_KPI: 'CLEAN_KPI'
}