import { handleResponse } from '../helpers/services';
import { authHeader } from '../helpers/authHeader';
import stringConstants from '../constants/strings';

export const kpiService = {
    getConfirmedTripsKPI,
    getReservationsKPI,
    getAlertsKPI,
    getCustomersKPI,
    getDriversKPI,
    getDriverRequestsKPI,
    getJourneysKPI,
    getConfirmedGroupedTripsKPI,
    getDriverCalendarKPI,
    getTripsBookingKPI,
}

function getConfirmedTripsKPI({ dateStart, dateEnd, services = [], otps = [], tenants = [], regions = [], municipalities = [] }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ dateStart, dateEnd, services, otPs: otps, tenants, regions, municipalities })
    };

    return fetch(stringConstants.URL_ApiGateway + '/services/v1/KPI/GetConfirmedTripsKPIDashboard', requestOptions).then(handleResponse);
}

function getReservationsKPI({ dateStart, dateEnd, services = [], otps = [], tenants = [], regions = [], municipalities = [] }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ dateStart, dateEnd, services, otPs: otps, tenants, regions, municipalities })
    };

    return fetch(stringConstants.URL_ApiGateway + '/services/v1/KPI/GetTripsKPIDashboard', requestOptions).then(handleResponse);
}

function getAlertsKPI({ dateStart, dateEnd, services = [], otps = [], tenants = [], regions = [], municipalities = [] }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ dateStart, dateEnd, services, otPs: otps, tenants, regions, municipalities })
    };

    return fetch(stringConstants.URL_ApiGateway + '/services/v1/KPI/GetAlertsKPIDashboard', requestOptions).then(handleResponse);
}

function getCustomersKPI({ dateStart, dateEnd, services = [], otps = [], tenants = [], regions = [], municipalities = [] }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ dateStart, dateEnd, services, otPs: otps, tenants, regions, municipalities })
    };

    return fetch(stringConstants.URL_ApiGateway + '/customers/v1/KPI/GetCustomersKPIDashboard', requestOptions).then(handleResponse);
}

function getDriversKPI({ dateStart, dateEnd, services = [], otps = [], tenants = [], regions = [], municipalities = [] }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ dateStart, dateEnd, services, otPs: otps, tenants, regions, municipalities })
    };

    return fetch(stringConstants.URL_ApiGateway + '/drivers/v1/KPI/GetDriversKPIDashboard', requestOptions).then(handleResponse);
}

function getDriverRequestsKPI({ dateStart, dateEnd, services = [], otps = [], tenants = [], regions = [], municipalities = [], status = [], lang = "es" }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ lang, dateStart, dateEnd, services, otPs: otps, tenants, regions, municipalities, requestStatuses: status })
    };

    return fetch(stringConstants.URL_ApiGateway + '/drivers/v1/KPI/GetDriverRequestsKPIDashboard', requestOptions).then(handleResponse);
}

function getJourneysKPI({ dateStart, dateEnd, services = [], otps = [], tenants = [], regions = [], municipalities = [], status = [], lang = "es" }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ lang, dateStart, dateEnd, services, otPs: otps, tenants, regions, municipalities, requestStatues: status })
    };

    return fetch(stringConstants.URL_ApiGateway + '/services/v1/KPI/GetJourneysKPIDashboard', requestOptions).then(handleResponse);
}

function getConfirmedGroupedTripsKPI({ dateStart, dateEnd, services = [], otps = [], tenants = [], regions = [], municipalities = [], status = [], lang = "es" }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ lang, dateStart, dateEnd, services, otPs: otps, tenants, regions, municipalities, requestStatues: status })
    };

    return fetch(stringConstants.URL_ApiGateway + '/services/v1/KPI/GetConfirmedGroupedTripsKPIDashboard', requestOptions).then(handleResponse);
}

function getDriverCalendarKPI({ dateStart, dateEnd, services = [], otps = [], tenants = [], regions = [], municipalities = [], status = [], lang = "es" }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ lang, dateStart, dateEnd, services, otPs: otps, tenants, regions, municipalities, requestStatues: status })
    };

    return fetch(stringConstants.URL_ApiGateway + '/OTPS/v1/KPI/GetCalendarDriversKPI', requestOptions).then(handleResponse);
}

function getTripsBookingKPI({ dateStart, dateEnd, services = [], otps = [], tenants = [], regions = [], municipalities = [], status = [], lang = "es" }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ lang, dateStart, dateEnd, services, otPs: otps, tenants, regions, municipalities, requestStatues: status })
    };

    return fetch(stringConstants.URL_ApiGateway + '/services/v1/KPI/GetTripsKPIBookingsDashboard', requestOptions).then(handleResponse);
}