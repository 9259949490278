import MaterialTable from 'material-table';
import React from 'react';
import { InputLabel } from "@material-ui/core";


class ZonesRelations extends React.Component {

    render() {
        const { t } = this.props
        const translations = t("material_table.tableStrings", { returnObjects: true, });

        return (
            <React.Fragment>
                <MaterialTable
                    localization={translations}
                    components={{
                        Toolbar: (props) => (
                            <React.Fragment>
                                <InputLabel>
                                    {t('services_comp.form.page.zonesRelations.title')}
                                </InputLabel>
                            </React.Fragment>
                        )
                    }}

                    columns={[
                        { title: t('services_comp.form.page.zonesRelations.origin'), field: 'originZoneName' },
                        { title: t('services_comp.form.page.zonesRelations.destination'), field: 'destinationZoneName' }
                    ]}
                    options={{ pageSize: 3 }}
                    data={this.props.results}
                />

            </React.Fragment>
        );
    }
}

export default ZonesRelations;