import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTripsByUserKPI } from "../../../../../helpers/kpiHelper";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import i18next from "i18next";
import KpiWrapper from "../../../components/kpiWrapper";

export default function TotalTripsByUserChart() {
    const { t } = useTranslation()
    const trips = useSelector(state => state.kpiReducer.confirmTrips);
    const isLoading = useSelector(state => state.kpiReducer.confirmTripsLoading)

    const { type } = useSelector(state => state.kpiFiltersReducer)

    const data = useMemo(() => {
        return getTripsByUserKPI(trips, { groupedBy: type });
    }, [trips, type]);

    const totalCustomer = data.length - 1

    return (
        totalCustomer > 0 &&
        <KpiWrapper
            title={t("commandBox.users.totalTripsByUserChart.title")}
            isLoading={isLoading}
            skeletonVariant="rect"
            displayNoData={!isLoading && totalCustomer === 0}
        >
            <Chart
                style={{ fontSize: "10px" }}
                chartType="BarChart"
                graphID={"chart_total_trips_by_user"}
                data={data}
                width="100%"
                chartLanguage={i18next.language}
                options={{
                    height: `${(totalCustomer * 40).toString()}px`,
                    isStacked: true,
                    vAxis: {
                        textStyle: {
                            fontSize: 14
                        },
                    },
                    legend: { position: "top", textStyle: { fontSize: 14 } },
                }}
            />
        </KpiWrapper>
    )
}