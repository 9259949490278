import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0'
    },
    menuItem: {
        cursor: 'pointer',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'white'
    },
    menuItemText: {
        color: 'black'
    },
    selectedText: {
        color: theme.palette.secondary.main
    },
    selected: {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main
    },
    container: {
        padding: 20
    },
    startDate: {
        marginRight: 20,
        marginTop: 20,
        marginBottom: 20
    },
    finishDate: {
        marginTop: 20,
        marginBottom: 20
    },
    groupByFilterCheck: {
        marginTop: 20,
        marginLeft: 30
    },
    timeUnitsSelector: {
        marginRight: 20
    },
    tripOriginSelector: {
        marginRight: 20
    },
    errorMessage: {
        color: 'red'
    }
}));


export default useStyles;