import React from 'react'
import { history } from '../../helpers/history'
import AlertDialog from '../alert-dialog'
import { Button } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import MaterialTableExtend from '../material-table'
import Spinner from '../shared/spinner'
import FilterComponent from '../shared/filter'
import { allowAccessRole } from '../../helpers/roles'
import { SERVICES_TYPE } from '../../constants/types'
import { MODULES, OPERATIONS_ALLOWED } from '../roles/constants'

class ServicesView extends React.Component {
    constructor(props) {
        super()
        this.state = {
            openModal: false,
            changeActiveModal: false,
            item: {},
        }
        this.onClickCancelModal = this.onClickCancelModal.bind(this)
        this.onClickAcceptModalDelete = this.onClickAcceptModalDelete.bind(this)
        this.onClickCancelModalChangeStatus = this.onClickCancelModalChangeStatus.bind(this)
        this.onClickAcceptModalChangeStatus = this.onClickAcceptModalChangeStatus.bind(this)
        this.setValue = this.setValue.bind(this)
    }
    addOnClick() {
        history.push('/services/create')
    }
    onClickCancelModal() {
        this.setState({ openModal: false })
    }
    onClickCancelModalChangeStatus() {
        this.setState({ changeActiveModal: false })
    }
    onClickAcceptModalDelete() {
        this.setState({ openModal: false })
        this.props.remove(this.state.item.id)
    }
    onClickAcceptModalChangeStatus() {
        this.setState({ changeActiveModal: false })
        if (this.state.item.isActive)
            this.props.disable(this.state.item.id)
        else
            this.props.enable(this.state.item.id)
    }
    onClickOpenModal(data) {
        this.setState({ openModal: true, item: data })
    }
    onClickAddStop(id) {
        history.push('/services/addStop/' + id)
    }
    onClickConfigCalendar(id, serviceType) {
        history.push({
            pathname: '/services/configCalendar/' + id + '/' + serviceType,
            state: {
                originIsServiceList: true
            }
        })
    }
    onClickChangeStatus(item) {
        this.setState({
            item,
            changeActiveModal: true
        })
    }
    onClickCustomerTipology(id, type) {
        history.push(`/services/setCustomerTypology/${id}/${type}`)
    }
    onClickCostTipology(id) {
        history.push('/services/setCostTypology/' + id)
    }
    setValue(prop, values) {
        const { serviceReducer } = this.props
        const filters = {
            otpName: prop == 'OTP' ? values : serviceReducer.service_filterOTP,
        }
        this.props.setServiceFilter(prop, values, filters)
        this.props.getAllWithZonesAndVehicles(filters)
    }

    onClickConfigureLines(id, type) {
        const path = type == SERVICES_TYPE.regularService ? `/services/setLines/${id}/${type}` : `/services/getLines/${id}`
        history.push({
            pathname: path,
            state: { type: type }
        })
    }

    onClickConfigureExpedition(id, type) {
        const path = type == SERVICES_TYPE.regularService ? `/services/setExpeditions/${id}/${type}` : `/services/setExpeditions/${id}`
        history.push({
            pathname: path,
            state: { type: type }
        })
    }

    renderMunicipalities(municipalities) {
        if (municipalities?.length > 10) {
            let newSpliced = municipalities.slice(0, 9)
            newSpliced.push('...')
            return newSpliced
        } else {
            return municipalities
        }
    }

    render() {
        const { serviceReducer, t } = this.props
        const types = serviceReducer.serviceTypes
        const Wrapper = () => (
            <React.Fragment>
                {allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage) && (
                    <Button
                        onClick={this.addOnClick}
                        variant="contained"
                        style={{ marginLeft: '24px' }}
                        color="primary"
                        size="large">
                        {t('services_comp.page.wrapper.add')}
                    </Button>
                )}
            </React.Fragment>
        )
        return (
            <React.Fragment>
                {!serviceReducer.pending && !serviceReducer.typePending ? (
                    <React.Fragment>
                        <MaterialTableExtend
                            buttons={<Wrapper />}
                            title={t('services_comp.page.title')}
                            onFilterChange={(filter) => {
                                this.props.saveTableFilters(filter)
                            }}
                            columns={[
                                {
                                    title: t('services_comp.page.columns.name'),
                                    field: 'name',
                                    defaultFilter: serviceReducer.tableFilters.filter((col) => col.column.field == 'name')[0]?.value ?? null,
                                    customFilterAndSearch: (term, rowData) => rowData.name.toUpperCase().includes(term.toUpperCase()),
                                    render: rowData => {
                                        return rowData && rowData.isRestricted ? (
                                            <React.Fragment>
                                                <LockIcon style={{ fontSize: 15 }} color="primary" /> <label>{rowData.name}</label>{' '}
                                            </React.Fragment>
                                        ) : (
                                            rowData?.name
                                        )
                                    },
                                },
                                {
                                    title: t('services_comp.page.columns.type'),
                                    field: 'serviceType',
                                    defaultFilter: serviceReducer.tableFilters.filter((col) => col.column.field == 'serviceType')[0]?.value ?? null,
                                    customFilterAndSearch: (term, rowData) => types.some(e => e.name.toUpperCase().includes(term.toUpperCase()) && e.id == rowData?.serviceType.toString()),
                                    render: rowData => types.map(item => (item.id == rowData?.serviceType ? item.name : '')),
                                },
                                {
                                    title: t('services_comp.page.columns.otp'),
                                    field: 'otpName',
                                    defaultFilter: serviceReducer.tableFilters.filter((col) => col.column.field == 'otpName')[0]?.value ?? null,
                                    filterComponent: rowData => (
                                        <FilterComponent {...this.props} optionsArray={[...new Set(serviceReducer.allServices?.sort((a, b) => a?.otpName.localeCompare(b?.otpName)).map(x => x?.otpName))]} setValue={this.setValue} prop={'OTP'} values={serviceReducer.service_filterOTP} />
                                    ),
                                },
                                {
                                    title: t('services_comp.page.columns.vehicles'),
                                    field: 'vehicles',
                                    defaultFilter: serviceReducer.tableFilters.filter((col) => col.column.field == 'vehicles')[0]?.value ?? null,
                                    customFilterAndSearch: (term, rowData) => rowData.serviceVehicles?.some(e => e.includes(term.toUpperCase())),
                                    render: rowData => {
                                        return rowData.serviceVehicles?.map(v => (
                                            <React.Fragment key={Math.random()}>
                                                {v}
                                                <br />
                                            </React.Fragment>
                                        ))
                                    },
                                },
                                {
                                    title: t('services_comp.page.columns.region'),
                                    field: 'regions',
                                    defaultFilter: serviceReducer.tableFilters.filter((col) => col.column.field == 'regions')[0]?.value ?? null,
                                    customFilterAndSearch: (term, rowData) => rowData.regions?.map(a => a.toUpperCase())?.some(e => e.includes(term.toUpperCase())),
                                    render: rowData => {
                                        return rowData.regions?.map(v => (
                                            <React.Fragment key={Math.random()}>
                                                {v}
                                                <br />
                                            </React.Fragment>
                                        ))
                                    },
                                },
                                {
                                    title: t('services_comp.page.columns.municipality'),
                                    field: 'municipalities',
                                    defaultFilter: serviceReducer.tableFilters.filter((col) => col.column.field == 'municipalities')[0]?.value ?? null,
                                    customFilterAndSearch: (term, rowData) => rowData.municipalities?.map(a => a.toUpperCase())?.some(e => e.includes(term.toUpperCase())),
                                    render: rowData => {
                                        return this.renderMunicipalities(rowData.municipalities)?.map(v => (
                                            <React.Fragment key={Math.random()}>
                                                {v}
                                                <br />
                                            </React.Fragment>
                                        ))
                                    },
                                },
                                { title: 'id', field: 'id', hidden: true },
                            ]}
                            data={serviceReducer.results}
                            actionsColumnIndex={7}
                            actions={[
                                {
                                    icon: 'view_list',
                                    tooltip: t('services_comp.page.actions.detail'),
                                    onClick: (event, rowData) => this.props.getById(rowData.id, true),
                                },
                                rowData => ({
                                    icon: rowData.isActive ? 'pause' : 'play_circle',
                                    tooltip: rowData.isActive ? t('services_comp.page.actions.disable') : t('services_comp.page.actions.enable'),
                                    onClick: (event, rowData) => this.onClickChangeStatus(rowData),
                                    hidden: !allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'date_range',
                                    iconProps: { style: { color: rowData && !rowData.hasCalendar ? 'orange' : '' } },
                                    tooltip: t('services_comp.page.actions.calendar'),
                                    onClick: (event, rowData) => this.onClickConfigCalendar(rowData.id, rowData.serviceType),
                                    hidden: !allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'person_add',
                                    iconProps: { style: { color: rowData && !rowData.hasCustomerBilling ? 'orange' : '' } },
                                    tooltip: t('services_comp.page.actions.set_typologyCustomer'),
                                    onClick: (event, rowData) => this.onClickCustomerTipology(rowData.id, rowData.serviceType),
                                    hidden: !allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage)
                                        || [SERVICES_TYPE.regularWithSchedule].includes(rowData?.serviceType),
                                }),
                                rowData => ({
                                    icon: 'euro',
                                    iconProps: { style: { color: rowData && !rowData.hasHolderBilling ? 'orange' : '' } },
                                    tooltip: t('services_comp.page.actions.set_typologyHolder'),
                                    onClick: (event, rowData) => this.onClickCostTipology(rowData.id),
                                    hidden: !allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage)
                                        || [SERVICES_TYPE.regularWithSchedule].includes(rowData?.serviceType),
                                }),
                                rowData => ({
                                    icon: 'edit_location',
                                    iconProps: { style: { color: rowData && rowData.serviceStopsPending ? 'orange' : '' } },
                                    tooltip: t('services_comp.page.actions.add_stop'),
                                    onClick: (event, rowData) => this.onClickAddStop(rowData.id),
                                    hidden: !allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage)
                                        || !rowData?.hasZoneWithStops
                                        || (rowData.hasZoneWithStops && ![SERVICES_TYPE.tadService, SERVICES_TYPE.assignmentWheelService].includes(rowData.serviceType))
                                }),
                                rowData => ({
                                    icon: 'import_export',
                                    iconProps: { style: { color: !rowData.hasLine ? "orange" : '' } },
                                    tooltip: t('services_comp.page.actions.lines'),
                                    onClick: (event, rowData) => this.onClickConfigureLines(rowData?.id, rowData?.serviceType),
                                    hidden: !allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage)
                                        || ![SERVICES_TYPE.regularService, SERVICES_TYPE.regularWithSchedule].includes(rowData?.serviceType)
                                }),
                                rowData => ({
                                    icon: 'schedule',
                                    iconProps: { style: { color: !rowData.hasExpeditions ? "orange" : "" } },
                                    tooltip: rowData?.hasExpeditions ? t('services_comp.page.actions.expedition') : t('services_comp.page.actions.expeditionDisable'),
                                    onClick: (event, rowData) => this.onClickConfigureExpedition(rowData.id, rowData?.serviceType),
                                    disabled: !rowData.hasLine,
                                    hidden: !allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage)
                                        || ![SERVICES_TYPE.regularService].includes(rowData?.serviceType)

                                }),
                                {
                                    icon: 'delete',
                                    tooltip: t('services_comp.page.actions.delete'),
                                    onClick: (event, rowData) => this.onClickOpenModal(rowData),
                                    hidden: !allowAccessRole(MODULES.Services, OPERATIONS_ALLOWED.manage),
                                },
                            ]}
                        />
                        <AlertDialog
                            open={this.state.openModal}
                            title={`${t('services_comp.page.alert_dialog.titleDelete')} (${this.state.item.name})`}
                            onClickAccept={this.onClickAcceptModalDelete}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog
                            open={this.state.changeActiveModal}
                            title={`${this.state.item.isActive ? t('services_comp.page.alert_dialog.titleDisable') : t('services_comp.page.alert_dialog.titleEnable')} (${this.state.item.name})`}
                            onClickAccept={this.onClickAcceptModalChangeStatus}
                            onClickCancel={this.onClickCancelModalChangeStatus}
                        />
                    </React.Fragment >
                ) : (
                    <Spinner loading={serviceReducer.pending} />
                )
                }
            </React.Fragment>
        )
    }
}
export default ServicesView
