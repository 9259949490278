
export const getCustomerRequestTrip = (tripReducer) => {
    return tripReducer.customerToRequest ?? ''
}

export const getOriginRequestTrip = (tripReducer) => {
    return {
        desc: tripReducer.pickUpLocation?.desc?.description ?? '',
        location: tripReducer.pickUpLocation?.location ?? {}
    }
}

export const getDestinationRequestTrip = (tripReducer) => {
    return {
        desc: tripReducer.dropOffLocation?.desc?.description ?? '',
        location: tripReducer.dropOffLocation?.location ?? {}
    }
}

export const getPickUpDateRequestTrip = (tripReducer) => {
    return tripReducer.pickUpDate ?? ''
}

export const getQuantityPassengerRequestTrip = (tripReducer) => {
    if (tripReducer.tripToModify?.numPassengers) {
        return tripReducer.tripToModify.numPassengers
    }
    return tripReducer.numPassengers ?? 1
}

export const getHasWheelChairsRequestTrip = (tripReducer) => {
    /*
    First condition is for when modifying a trip, it retrieves the value from the trip in progress.
    The second condition is for when repeating a trip, it retrieves the value from the previous trip.
    The third one is for when creating a new trip, it retrieves the value from the customer's profile.
    If everything fails it appears unchecked by default.
    */
    return tripReducer.tripToModify?.wheelChairs ?? tripReducer?.wheelChairs ?? tripReducer.customerToRequest?.hasWheelChair ?? false
}

export const getLuggageRequestTrip = (tripReducer) => {
    if (tripReducer.tripToModify?.quantityLuggage) {
        return tripReducer.tripToModify.quantityLuggage
    }
    return tripReducer.quantityLuggage ?? 0
}
