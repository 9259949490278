import React from 'react';
import Dashboard from '../../dashboard';
import RequestTripView from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { tripActions } from '../../../redux/actions/trip_actions';
import { positionActions } from '../../../redux/actions/position_actions';
import { zoneActions } from '../../../redux/actions/zones_actions';
import Spinner from '../../shared/spinner';
import { customerActions } from '../../../redux/actions/customer_actions';
import { serviceActions } from '../../../redux/actions/services_actions';
import { multipleTripsActions } from '../../../redux/actions/multipleTrips_action';

class RequestTrip extends React.Component {


    componentWillUnmount() {
        this.props.clear()
        this.setState({
            tripId: null
        })
    }

    componentDidMount() {
        let searchParams = new URLSearchParams(this.props.location.search);
        const id = searchParams.get('id')
        if (id != null && this.props.tripReducer.trip == null) {
            this.props.getById(id)
            this.setState({
                tripIdToPlan: id
            })
        }
        this.props.getZones()
        this.props.getWeekDays()
        if (this.props.customerReducer.currentCustomer) {
            this.props.checkProfile(this.props.customerReducer.currentCustomer)
        }


    }

    render() {
        const { zoneReducer } = this.props
        return (
            <Dashboard
                component={
                    !zoneReducer.pending ?
                        <RequestTripView
                            {...this.props}
                            sendTrip={this.props.getTripServices}
                        />
                        :
                        <Spinner
                            loading={zoneReducer.pending}
                            {...this.props}
                        />
                }

            />
        );
    }
}

function mapState(state) {
    const { customerReducer, tripReducer, zoneReducer, serviceReducer, multipleTripReducer } = state;
    return { customerReducer, tripReducer, zoneReducer, serviceReducer, multipleTripReducer };
}

const actionCreators = {
    insert: tripActions.insert,
    getById: tripActions.getById,
    getTripServices: tripActions.getTripServices,
    getZones: zoneActions.getAll,
    selectServiceClose: tripActions.selectServiceClose,
    clear: tripActions.clear,
    setMarker: tripActions.setMarker,
    clearMarker: tripActions.clearMarker,
    clearAllMarkers: tripActions.clearAllMarkers,
    checkProfile: customerActions.checkProfile,
    getPositionForTrip: positionActions.getPositionForTrip,
    setServiceNotServiceLine: tripActions.setServiceNotServiceLine,
    setServiceServiceLine: tripActions.setServiceServiceLine,
    setOrigin: tripActions.setOrigin,
    setDestination: tripActions.setDestination,
    closeModalRequestTrip: tripActions.closeModalRequestTrip,
    openModalRequestTrip: tripActions.openModalRequestTrip,
    setOriginDistanceTime: tripActions.setOriginDistanceTime,
    setDestinationDistanceTime: tripActions.setDestinationDistanceTime,
    cleanRepeatTrip: tripActions.cleanRepeatTrip,
    modifyTrip: tripActions.modifyTrip,
    getWeekDays: serviceActions.getWeekDays,
    closeModalExpeditions: tripActions.closeModalExpeditions,
    closeModalSelectScheduledPickUp: tripActions.closeModalSelectScheduledPickUp,
    updateDateOfTripFromDatePicker: tripActions.updateDateOfTripFromDatePicker,
    repeatTrip: tripActions.repeatTrip,
    setOpenModalSelectFrecuency: multipleTripsActions.setOpenModalSelectFrecuency,
    setOpenModalShowDetails: multipleTripsActions.setOpenModalShowDetails,
    processMultipleTrip: multipleTripsActions.processMultipleTrip
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(RequestTrip);