import React from 'react'
import Chart from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, getChartName, options, DownloadChart } from '../../../customFunctions'

const numTotalDrivers = ({
    data,
    title = "Drivers total",
    chartType = "Bar",
    groupedBy = "municipalities",
    value = [],
}) => {

    const chartProps = { title }
    const filterOptions = FilterOptions(groupedBy, value)

    const filteredData = (data, filterOptions) => {

        const { groupedBy, value } = filterOptions
        let totalDrivers = []

        value.forEach(element => {

            let numTotalDrivers = 0
            data.forEach(user => {

                if (Array.isArray(user[groupedBy])) {
                    if (user[groupedBy].find(el => el == element) != undefined) {
                        numTotalDrivers++
                    }
                } else {
                    if (user[groupedBy] == element) {
                        numTotalDrivers++
                    }
                }

            })            
            if (numTotalDrivers > 0 && totalDrivers.find(el => el == element) == undefined) {
                if (value.length < 7 && groupedBy !== 'tenants') {
                    totalDrivers.push([
                        { v: element + '\n', f: element },
                        { v: numTotalDrivers, f: numTotalDrivers },
                    ])
                }else{
                    totalDrivers.push([
                        { v: '\n', f: element },
                        { v: numTotalDrivers, f: numTotalDrivers },
                    ])
                }
                
            }

        })

        let result = [[getChartName(groupedBy), "Drivers"]]

        totalDrivers.forEach(el => {
            result.push([el[0], el[1]])
        })

        return result

    }

    return (
        <>
            <Chart
                chartType={chartType}
                graphID={'chart_0'}
                width="100%"
                height="400px"
                data={filteredData(data, filterOptions)}
                options={options(chartProps)}
            />
            <DownloadChart key={Math.random()} img64={0} />
        </>
    )
}

numTotalDrivers.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(["municipalities", "regions", "otps", "services", "tenants"]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
}

export default numTotalDrivers