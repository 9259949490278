import React from 'react'
import store from '../../../../redux/store'
import { vehiclesActions } from '../../../../redux/actions/vehicles_actions'
import { tripActions } from '../../../../redux/actions/trip_actions'
import CapacityByVehicle from './capacity'
import DistanceByVehicle from './distance'

const KPI_SELECTED = { CAPACITY_BY_VEHICLE: 'capacityByVehicle', KMS_BY_VEHICLE: 'kMsByVehicle'  }

export const getDataForVehicleChart = (kpiSelected, chartProps) => {
    console.log('kpiSelected', kpiSelected)
    if (kpiSelected == KPI_SELECTED.CAPACITY_BY_VEHICLE) {
        return store.dispatch(vehiclesActions.getVehiclesGeneralDataKPI()).then(
            results => {
                return results
            },
            error => {
                console.log('error getVehiclesGeneralDataKPI => ', error)
                return null
            },
        )
    }else if (kpiSelected == KPI_SELECTED.KMS_BY_VEHICLE) {
        const { startDate, finishDate } = chartProps;
        return store.dispatch(tripActions.getJourneyKPI(startDate, finishDate)).then(
            trips => {
                return trips
            },
            (error) => {
                console.log("error getDataForTripsChart => ", error)
                return null
            }
        )
    }
}

export const getVehiclesChart = (kpiSelected, chartProps, data) => {
    const { chartType, title, groupedBy, value, filterByDate, startDate, finishDate } = chartProps

    if (kpiSelected == KPI_SELECTED.CAPACITY_BY_VEHICLE) {
        return (
            <CapacityByVehicle 
                groupedBy={groupedBy}
                chartType={chartType}
                data={data}
                value={value}
                title={title}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate} 
            />
        )
    }else if(kpiSelected == KPI_SELECTED.KMS_BY_VEHICLE){
        return(
            <DistanceByVehicle
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
            />
        )
    }

    return null
}
