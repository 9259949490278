import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, getChartName, options, DownloadChart, filterDataByGroup } from '../../../customFunctions'
import moment from 'moment'
import i18next from 'i18next'


const filteredData = (data, filterOptions, value) => {
    const { groupedBy } = filterOptions
    let array = []
    let actualIndexDate = null
    // for each trips set dates information to filter after
    filterDataByGroup(data, groupedBy, value).forEach(x => {
        actualIndexDate = moment(x.requestPickUpStartTime)
        x.year = actualIndexDate.year()
        x.month = actualIndexDate.year() + '_' + actualIndexDate.month()
        x.monthText = i18next.t('commandBox.months', { returnObjects: true })[actualIndexDate.month()] + '/' + x.year
        array.push(x)
    })

    return getMonthData(array, value, groupedBy)
}

const getMonthData = (data, value, groupedBy) => {
    // get all months and years options from trips
    let optionsMonth = Array.from(new Set(data.map(item => item.monthText)))
    let result = []
    const driverTrips = [...new Set(data.filter(t => t.driverName).map(x => x.driverName))]
    result.push([i18next.t('commandBox.month') + ' ' + getChartName(groupedBy)].concat(driverTrips))
    // for each month filter trips and insert results
    optionsMonth.forEach(function (itemMonth) {
        let dataMonth = [...new Array(driverTrips.length)]
        driverTrips.forEach(function (val, iVal) {
            dataMonth[iVal] = data.filter(item => item.monthText == itemMonth && item.driverName == val).map(t => t.price.totalAmount).reduce((a, b) => a + b, 0)
        })
        result.push([itemMonth].concat(dataMonth))
    })
    console.log(result)
    return result
}

const getFormatters = (data) => {    
    var formatters = [];
    let totalCols = data[0].length -1
    for (var i = 0; i < totalCols; i++) {
        formatters.push({
            type: "NumberFormat",
            column: i+1,                                   
            options: {
                decimalSymbol: ",",
                suffix: "€",
            }
        })
    }
    
    return formatters;
}

const FullCostByDriver = ({ data, title = 'Facturació mensual per conductor', groupedBy = 'municipalities', value = [], filterByDate = true, startDate = '2022-01-01', finishDate = '2022-02-01' }) => {
    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate)

    const chartType = 'ColumnChart'

    return (
        <>
            {value.map(function (element, i) {                
            return (
                filteredData(data, filterOptions, element).filter(([a, b]) => b > 0).length > 0 ?                    
                    <React.Fragment key={Math.random()}>
                        <Chart
                            key={Math.random()}
                            graphID={'chart_' + i.toString()}
                            chartType={chartType}
                            width="100%"
                            height="400px"
                            data={filteredData(data, filterOptions, element)}
                            options={options(chartProps, null, element)}
                            formatters={getFormatters(filteredData(data, filterOptions, element))}                            
                        />
                        <DownloadChart key={Math.random()} img64={i} />
                    </React.Fragment>
                    : null
            )
            })}
        </>
    )
}

FullCostByDriver.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(['municipalities', 'regions', 'otps', 'services', 'tenant']).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
    timeUnits: PropTypes.string.isRequired,
}

export default FullCostByDriver