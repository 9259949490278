import React from 'react'
import Chart from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, options, DownloadChart } from '../../../customFunctions'
import { getUsersGroupBy } from '../../../../../helpers/kpiHelper'

const NumTotalUsers = ({
    data,
    title = "Users total",
    chartType = "Bar",
    groupedBy = "municipalities",
    value = [],
}) => {

    const chartProps = { title }
    const filterOptions = FilterOptions(groupedBy, value)

    return (
        <>
            <Chart
                chartType={chartType}
                graphID={'chart_0'}
                width="100%"
                height="400px"
                data={getUsersGroupBy(data, filterOptions)}
                options={options(chartProps)}
            />
            <DownloadChart key={Math.random()} img64={0} />
        </>
    )
}

NumTotalUsers.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(["municipalities", "regions", "otps", "services", "tenants"]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
}

export default NumTotalUsers