import { AppBar, BottomNavigation, BottomNavigationAction, Paper, Tab, Tabs, Toolbar, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import Filters from "./components/filters";
import Dashboard from "../dashboard";
import { history } from '../../helpers/history';
import { useDispatch } from "react-redux";
import { serviceActions } from "../../redux/actions/services_actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CommandBoxView from "./page";
import { useTranslation, withTranslation } from "react-i18next";
import { otpActions } from "../../redux/actions/otp_actions";
import { tenantsActions } from "../../redux/actions/tenant_actions";
import { areaActions } from "../../redux/actions/areas_actions";

export default function KpiLayout({ children }) {
    return (
        <Dashboard
            isBackgroundTransparent={true}
            component={<Layout>{children}</Layout>}
        />
    )
}

// const KpiLayout = (props) => {
//     return (
//         <Dashboard
//             component={
//                 <CommandBoxView
//                     {...props}
//                 />
//             }
//         />
//     )
// }

// export default withTranslation('common')(KpiLayout);

const Layout = ({ children }) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const currentKPI = location.pathname.split("/")[2]

    useEffect(() => {
        dispatch(serviceActions.getServiceList())
        dispatch(otpActions.getAll())
        dispatch(tenantsActions.getAll())
        dispatch(areaActions.getAllRegions())
        dispatch(areaActions.getAllMunicipalities())
        dispatch(serviceActions.getWeekDays())
    }, [dispatch])


    return (
        <div style={{ display: "grid", gap: "10px" }}>
            <Paper style={{ display: "grid", gap: "5px", }}>
                <div style={{ padding: "20px" }}>
                    <Filters currentKPI={currentKPI || "summary"} />
                </div>
                <KPINavBar currentKPIPage={currentKPI} />
            </Paper>
            {children}
        </ div >
    )
}

function KPINavBar({ currentKPIPage }) {
    const { t } = useTranslation()
    currentKPIPage = currentKPIPage || ""

    return (
        <Tabs
            value={currentKPIPage}
            onChange={(_, newValue) => {
                history.push(`/commandBox/${newValue}`)
            }}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            variant="scrollable"
        >
            <Tab label={t('commandBox.summary.title')} value="" />
            <Tab label={t('commandBox.trips.title')} value="trips" />
            <Tab label={t('commandBox.journeys.title')} value="journeys" />
            <Tab label={t('commandBox.reservations.title')} value="reservations" />
            <Tab label={t('commandBox.users.title')} value="users" />
            <Tab label={t('commandBox.drivers.title')} value="drivers" />
            <Tab label={t('commandBox.vehicles.title')} value="vehicles" />
            <Tab label={t('commandBox.economyData.title')} value="economyData" />
        </Tabs>
    )
}