import React from "react";
import VehicleCalendarRangeView from "./page";
import Dashboard from "../../dashboard";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Spinner from "../../shared/spinner";
import { vehiclesActions } from "../../../redux/actions/vehicles_actions";

class VehicleCalendar extends React.Component {
    componentDidMount() {
        const params = this.props.location.pathname.split("/") || [];
        const id = params[params.length - 1];
        this.props.getById(id, false);
    }

    render() {
        const { vehicleReducer } = this.props;
        return (
            <Dashboard
                component={
                    vehicleReducer.pending || vehicleReducer.vehicle == null ? (
                        <Spinner
                            loading={vehicleReducer.pending}
                            {...this.props}

                        />
                    ) : (
                        <VehicleCalendarRangeView {...this.props} />
                    )
                }
            />
        );
    }
}

function mapState(state) {
    const { vehicleReducer } = state;
    return { vehicleReducer };
}

const actionCreators = {
    getById: vehiclesActions.getById,
    update: vehiclesActions.update,
    removeExcludeDays: vehiclesActions.removeExcludeDays,
    setExcludeDays: vehiclesActions.setExcludeDays,
    setRangeCalendar: vehiclesActions.setRangeCalendar,
    setCalendar: vehiclesActions.setCalendar,
};

export default compose(
    withTranslation("common"),
    connect(mapState, actionCreators)
)(VehicleCalendar);
