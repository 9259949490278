export const tripConstants = {

    ADD_REQUEST: 'TRIP_ADD_REQUEST',
    ADD_SUCCESS: 'TRIP_ADD_SUCCESS',
    ADD_FAILURE: 'TRIP_ADD_FAILURE',

    GET_TRIP_SERVICES_REQUEST: 'GET_TRIP_SERVICES_REQUEST',
    GET_TRIP_SERVICES_SUCCESS: 'GET_TRIP_SERVICES_SUCCESS',
    GET_TRIP_SERVICES_FAILURE: 'GET_TRIP_SERVICES_FAILURE',

    GETALL_REQUEST: 'TRIP_GETALL_REQUEST',
    GETALL_SUCCESS: 'TRIP_GETALL_SUCCESS',
    GETALL_FAILURE: 'TRIP_GETALL_FAILURE',

    GETALL_CLEAR: 'TRIP_GETALL_CLEAR',

    GETBYID_REQUEST: 'TRIP_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'TRIP_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'TRIP_GETBYID_FAILURE',

    DELETE_REQUEST: 'TRIP_DELETE_REQUEST',
    DELETE_SUCCESS: 'TRIP_DELETE_SUCCESS',
    DELETE_FAILURE: 'TRIP_DELETE_FAILURE',

    UPDATE_REQUEST: 'TRIP_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'TRIP_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'TRIP_UPDATE_FAILURE',

    GET_LOCATION_REQUEST: 'GET_LOCATION_REQUEST',
    GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
    GET_LOCATION_FAILURE: 'GET_LOCATION_FAILURE',

    GET_TRIPS_TYPES_REQUEST: 'GET_TRIPS_TYPES_REQUEST',
    GET_TRIPS_TYPES_SUCCESS: 'GET_TRIPS_TYPES_SUCCESS',
    GET_TRIPS_TYPES_FAILURE: 'GET_TRIPS_TYPES_FAILURE',

    CLEAR_TRIP: 'TRIP_CLEAR',

    SET_MARKER: 'TRIP_SET_MARKER',
    SET_TRIP: 'TRIP_SET_TRIP',
    CLEAR_MARKER: 'TRIP_CLEAR_MARKER',
    CLEAR_ALL_MARKER: 'TRIP_CLEAR_ALL_MARKER',

    SELECT_SERVICE_TRIP_OPEN: 'SELECT_SERVICE_TRIP_OPEN',
    SELECT_SERVICE_TRIP_CLOSE: 'SELECT_SERVICE_TRIP_CLOSE',


    GET_TRIPS_CUSTOMER_REQUEST: 'TRIPS_GET_TRIPS_CUSTOMER_REQUEST',
    GET_TRIPS_CUSTOMER_SUCCESS: 'TRIPS_GET_TRIPS_CUSTOMER_SUCCESS',
    GET_TRIPS_CUSTOMER_FAILURE: 'TRIPS_GET_TRIPS_CUSTOMER_FAILURE',

    CANCEL_TRIP_REQUEST: 'TRIP_CANCEL_REQUEST',
    CANCEL_TRIP_SUCCESS: 'TRIP_CANCEL_SUCCESS',
    CANCEL_TRIP_FAILURE: 'TRIP_CANCEL_FAILURE',

    REJECT_TRIP_REQUEST: 'TRIP_CANCEL_REQUEST',
    REJECT_TRIP_SUCCESS: 'TRIP_CANCEL_SUCCESS',
    REJECT_TRIP_FAILURE: 'TRIP_CANCEL_FAILURE',

    ACCEPT_TRIP_REQUEST: 'TRIP_ACCEPT_REQUEST',
    ACCEPT_TRIP_SUCCESS: 'TRIP_ACCEPT_SUCCESS',
    ACCEPT_TRIP_FAILURE: 'TRIP_ACCEPT_FAILURE',

    SET_SERVICE_NOT_SERVICE_LINE: 'TRIP_SELECT_SERVICE_NOT_SERVICE_LINE',
    SET_SERVICE_SERVICE_LINE: 'TRIP_SELECT_SERVICE_SERVICE_LINE',
    SET_ORIGIN: 'TRIP_SET_ORIGIN_TRIP',
    SET_DESTINATION: 'TRIP_SET_DESTINATION_TRIP',

    CLOSE_MODAL_REQUEST_TRIP: 'CLOSE_MODAL_REQUEST_TRIP',
    OPEN_MODAL_REQUEST_TRIP: 'OPEN_MODAL_REQUEST_TRIP',

    CUSTOMER_CAN_PERFORM_TRIP_REQUEST: 'CUSTOMER_CAN_PERFORM_TRIP_REQUEST',
    CUSTOMER_CAN_PERFORM_TRIP_SUCCESS: 'CUSTOMER_CAN_PERFORM_TRIP_SUCCESS',
    CUSTOMER_CAN_PERFORM_TRIP_FAILURE: 'CUSTOMER_CAN_PERFORM_TRIP_FAILURE',

    SET_ORIGIN_DISTANCE_TIME: 'SET_ORIGIN_DISTANCE_TIME',
    SET_DESTINATION_DISTANCE_TIME: 'SET_DESTINATION_DISTANCE_TIME',
    SET_REPEAT_TRIP: 'TRIP_SET_REPEAT_TRIP',
    SET_MODIFY_TRIP: 'TRIP_SET_MODIFY_TRIP',
    CLEAN_REPEAT_TRIP: 'TRIP_CLEAN_REPEAT_TRIP',

    MODIFY_TRIP_REQUEST: 'TRIP_MODIFY_REQUEST',
    MODIFY_TRIP_SUCCESS: 'TRIP_MODIFY_SUCCESS',
    MODIFY_TRIP_FAILURE: 'TRIP_MODIFY_FAILURE',

    ASSING_DRIVER_MANUAL_TRIP_REQUEST: 'ASSING_DRIVER_MANUAL_TRIP_REQUEST',
    ASSING_DRIVER_MANUAL_TRIP_SUCCESS: 'ASSING_DRIVER_MANUAL_TRIP_SUCCESS',
    ASSING_DRIVER_MANUAL_TRIP_FAILURE: 'ASSING_DRIVER_MANUAL_TRIP_FAILURE',

    SET_FILTER_TRIPS_DATES: 'SET_FILTER_TRIPS_DATES',

    SET_FILTER_TRIPS: 'SET_FILTER_TRIPS',
    CLEAN_FILTER_TRIPS: 'TRIPS_CLEAN_FILTER',

    GETALL_KPI_REQUEST: 'GETALL_KPI_REQUEST',
    GETALL_KPI_SUCCESS: 'GETALL_KPI_SUCCESS',
    GETALL_KPI_FAILURE: 'GETALL_KPI_FAILURE',

    OPEN_MODAL_SHOW_EXPEDITIONS: 'EXPEDITIONS_OPEN_MODAL',
    CLOSE_MODAL_SHOW_EXPEDITIONS: 'EXPEDITIONS_CLOSE_MODAL',

    GET_CONFIRMED_GROUPEDKPI_REQUEST: 'TRIPS_GET_CONFIRMED_GROUPEDKPI_REQUEST',
    GET_CONFIRMED_GROUPEDKPI_SUCCESS: 'TRIPS_GET_CONFIRMED_GROUPEDKPI_SUCCESS',
    GET_CONFIRMED_GROUPEDKPI_FAILURE: 'TRIPS_GET_CONFIRMED_GROUPEDKPI_FAILED',

    GET_POSSIBLE_DESTINATIONS_REG_LINE_REQUEST: 'GET_POSSIBLE_DESTINATIONS_REG_LINE_REQUEST',
    GET_POSSIBLE_DESTINATIONS_REG_LINE_SUCCESS: 'GET_POSSIBLE_DESTINATIONS_REG_LINE_SUCCESS',
    GET_POSSIBLE_DESTINATIONS_REG_LINE_FAILURE: 'GET_POSSIBLE_DESTINATIONS_REG_LINE_FAILURE',

    GET_SCHEDULED_PICKUP_REG_LINE_REQUEST: 'GET_SCHEDULED_PICKUP_REG_LINE_REQUEST',
    GET_SCHEDULED_PICKUP_REG_LINE_SUCCESS: 'GET_SCHEDULED_PICKUP_REG_LINE_SUCCESS',
    GET_SCHEDULED_PICKUP_REG_LINE_FAILURE: 'GET_SCHEDULED_PICKUP_REG_LINE_FAILURE',

    CLOSE_MODAL_SELECT_SCHEDULE_PICKUP: 'CLOSE_MODAL_SELECT_SCHEDULE_PICKUP',
    UPDATE_DATE_TRIP: 'UPDATE_DATE_TRIP_DATEPICKER',

    SAVE_TABLE_FILTERS: 'TRIP_SAVE_TABLE_FILTERS'
};