import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, options, DownloadChart, filterDataByGroup } from '../../../customFunctions'
import i18next from 'i18next'

const filteredData = (data, filterOptions, element) => {
    const { groupedBy } = filterOptions
    let array = [['Journeys', 'Total']]

    let journeys = filterDataByGroup(data, groupedBy, element)
    let journeyDrivers = [...new Set(journeys.filter(j => j.driverName).map(x => x.driverName))]
    journeyDrivers.forEach(journeyDriver => {
        array.push([journeyDriver, journeys.filter(x => x.driverName == journeyDriver).length])
    })

    console.log('array => ', array)
    return array
}

const TotalJourneysByDriver = ({ data, title = 'Total trajectes (per conductor)', groupedBy = 'municipalities', value = [], filterByDate = true, startDate = '2022-01-01', finishDate = '2022-02-01', timeUnits }) => {
    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits)
    const chartType = 'PieChart'

    return (
        <>
            {value.map(function (element, i) {
                return filteredData(data, filterOptions, element).filter(([a, b]) => b > 0).length > 0 ? (
                    <React.Fragment key={Math.random()}>
                        <Chart chartType={chartType} graphID={'chart_' + i.toString()} width="100%" height="400px" data={filteredData(data, filterOptions, element)} options={options(chartProps, null, element)} chartLanguage={i18next.language} />
                        <DownloadChart key={Math.random()} img64={i} />
                    </React.Fragment>
                ) : null
            })}
        </>
    )
}
TotalJourneysByDriver.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(['municipalities', 'regions', 'otps', 'services', 'tenants']).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default TotalJourneysByDriver
