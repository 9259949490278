import store from "./redux/store";
import { incidentActions } from "./redux/actions/incident_actions";
import { userService } from "./services/userService";

export const onIncidentsListener = () => {
    const user = userService.getWithExpiry("user_webAdmin");
    const claims = userService.getWithExpiry("claims")
    let name = null;
    if (user && user.name) {
        console.log("...");
        name = user.displayName || user.email;
    }

    if (user != null && claims != null) {
        store.dispatch(incidentActions.getAll());
        store.dispatch(incidentActions.getNumberOfTicketsToReviewFromOpUser(claims.mongo_id));
    }
}