import React from 'react';
import Dashboard from '../../dashboard';
import CustomerTripsView from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { tripActions } from '../../../redux/actions/trip_actions';

class CustomerTrips extends React.Component {

    componentDidMount() {
        const params = this.props.location.pathname.split('/')
        const id = params[params.length - 1]
        this.props.getTripsByCustomerId(id)
        this.props.getTripStatuses()
    }

    render() {
        const params = this.props.location.pathname.split('/')
        const id = params[params.length - 1]
        return (
            <Dashboard
                component={
                    <CustomerTripsView
                        {...this.props}
                        customerId={id}
                    />
                }
            />

        );
    }
}

function mapState(state) {
    const { tripReducer, customerReducer } = state;
    return { tripReducer, customerReducer };
}

const actionCreators = {
    getTripsByCustomerId: tripActions.getTripsByCustomerId,
    cancelTrip: tripActions.cancelTrip,
    cancelTripByDriverUnavailable: tripActions.cancelTripByDriverUnavailable,
    setTrip: tripActions.setTrip,
    getTripStatuses: tripActions.getTripStatuses,
    repeatTrip: tripActions.repeatTrip,
    setModifyTrip: tripActions.setModifyTrip,
    cleanRepeatTrip: tripActions.cleanRepeatTrip


}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(CustomerTrips);