import React from 'react';
import { Chart } from "react-google-charts";
import PropTypes from 'prop-types';
import { FilterOptions, options, DownloadChart, filterDataByGroup } from '../../../customFunctions';
import i18next from 'i18next';



const filteredData = (data, filterOptions, element, typologySelected) => {
    const { groupedBy } = filterOptions
    let array = [["Trips", "Total"]]
    let trips = filterDataByGroup(data, groupedBy, element)
    switch (typologySelected) {
        case '10':
            trips = trips.filter(trip => trip.price.customerType === 10)
            break
        case '20':
            trips = trips.filter(trip => trip.price.customerType === 20)
            break
        case '30':
            trips = trips.filter(trip => trip.price.customerType === 30)
            break
        case '40':
            trips = trips.filter(trip => trip.price.customerType === 40)
            break
        case '50':
            trips = trips.filter(trip => trip.price.customerType === 50)
            break
        default:
            break
    }
    let allPrices = [...new Set(trips.map(x => x.price.holderAmount))].sort()
    allPrices.forEach(price => {
        array.push([price.toLocaleString() + '€', trips.filter(t => t.price.holderAmount === price).length])
    });
    console.log("array => ", array)
    return array


}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const OnlyServiceCost = ({
    data,
    title = "Total viatges (acumulats)",
    groupedBy = "municipalities",
    value = [],
    filterByDate = true,
    startDate = "2022-01-01",
    finishDate = "2022-02-01",
    timeUnits,
    typologySelected
}) => {

    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits)
    const chartType = "PieChart"
    return (
        <>
            {
                value.map(function (element, i) {
                    return (
                        filteredData(data, filterOptions, element, typologySelected).filter(([a, b]) => b > 0).length > 0 ?
                            <React.Fragment key={Math.random()}>
                                <Chart
                                    chartType={chartType}
                                    graphID={'chart_' + i.toString()}
                                    width="100%"
                                    height="400px"
                                    data={filteredData(data, filterOptions, element, typologySelected)}
                                    options={options(chartProps, null, element)}                                    
                                    chartLanguage={i18next.language}
                                />
                                <DownloadChart key={Math.random()} img64={i} />
                            </React.Fragment>
                            : null
                    )
                })
            }
        </>
    )
}
OnlyServiceCost.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(["municipalities", "regions", "otps", "services", "tenants"]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default OnlyServiceCost;