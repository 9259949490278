import React, { useState } from "react";
import { connect } from 'react-redux'
import { Button, Checkbox, FormControlLabel, Grid, Hidden, TextField } from "@material-ui/core";
import i18next from "i18next";
import { history } from "../../../helpers/history";
import { factory } from '../../../helpers/factory'
import AutoCompleteVehicleLine from "./autoCompleteVehicle";
import LineServiceRegular from "./lineRegularService";
import LineRegularServiceWithSchedule from "./withSchedule/stops";
import { SERVICES_TYPE } from "../../../constants/types";



const ServiceLinesView = ({ serviceReducer, vehicleReducer, id, setServiceLine, setRegularLine, type, lineId }) => {

    const { serviceLine } = serviceReducer

    const [name, setName] = useState(serviceLine?.name ?? '')
    const [numExpeditions, setNumExpeditions] = useState(serviceLine?.numExpeditions ?? 1)
    const [bankHolidaysLine, setBankHolidaysLine] = useState(serviceLine?.bankHolidaysLine ?? false)
    const [vehicles] = useState(vehicleReducer.results)
    const [vehicle, setVehicle] = useState(serviceReducer.serviceLine?.vehicleId ?? serviceReducer.serviceLine?.vehicleIds ?? [])
    const [lineObject, setLine] = useState(serviceReducer.serviceLine ?? {
        outbound: {
            zoneStops: []
        }
    })
    const [stopsSingle, setstopsSingle] = useState(serviceReducer.serviceLine?.outbound?.zoneStops)
    const [stopsReturn, setstopsReturn] = useState(serviceReducer.serviceLine?.return?.zoneStops)

    const [allowChangeDirectionSingle, setallowChangeDirectionSingle] = useState(serviceReducer.serviceLine?.outbound?.allowOppositeDirections ?? false)
    const [allowChangeDirectionReturn, setallowChangeDirectionReturn] = useState(serviceReducer.serviceLine?.return?.allowOppositeDirections ?? false)
    const [modalDefineDistances, setModalDefineDistances] = useState(false)

    const changeVehicleSingle = (event, newValues) => {
        if (newValues) {
            setVehicle(newValues)
        } else {
            setVehicle({})
        }
    }
    const changeVehicleMultiple = (event, newValues) => {
        if (newValues) {
            setVehicle(newValues.map(item => item.id))
        } else {
            setVehicle([])
        }
    }

    const saveLine = () => {
        let outBoundObj = {
            allowChangeDirection: allowChangeDirectionSingle,
            zoneStops: stopsSingle.map(stop => stop),
            calendar: lineObject.outbound.calendar
        }
        let returnObj = {
            allowChangeDirection: allowChangeDirectionReturn,
            zoneStops: stopsReturn.map(stop => stop),
            calendar: lineObject.return.calendar
        }
        const line = factory.createLine(name, vehicle.id ? vehicle.id : vehicle, id, outBoundObj, returnObj)
        setServiceLine(line)
    }

    const saveLineWithSchedule = () => {
        let outBoundObj = {
            zoneStops: stopsSingle.map(stop => stop)
        }
        const line = factory.createLineWithSchedule(name, vehicle, id, outBoundObj, numExpeditions, bankHolidaysLine)
        setRegularLine(lineId, line)
        setModalDefineDistances(false)
    }

    const openModal = () => {
        setModalDefineDistances(true)
    }

    return (
        <>
            <h2 style={{ padding: 50 }}>{i18next.t('services_comp.lines.title')}</h2>

            <Grid container spacing={3} style={{ paddingTop: 13 }}>
                <Grid item md={1} implementation="css" smDown component={Hidden} />
                <Grid item md={4}>
                    <TextField
                        label={i18next.t('services_comp.lines.name')}
                        value={name}
                        variant="outlined"
                        name="name"
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                    />
                </Grid>
                <Grid item md={5}>

                    <AutoCompleteVehicleLine
                        vehicles={vehicles}
                        value={
                            type == SERVICES_TYPE.regularService ? vehicles.find(x => x.id == vehicle || x.id == vehicle?.id)
                                :
                                vehicles.filter(f => vehicle.some(item => item === f.id))
                        }
                        onChange={type == SERVICES_TYPE.regularWithSchedule ? changeVehicleMultiple : changeVehicleSingle}
                        multiple={type == SERVICES_TYPE.regularWithSchedule}

                    />
                </Grid>
                <Grid item md={2} implementation="css" smDown component={Hidden} />

                {
                    type == SERVICES_TYPE.regularWithSchedule ?
                        <>
                            <Grid item md={1} implementation="css" smDown component={Hidden} />
                            <Grid item md={2}>
                                <TextField
                                    label={i18next.t('services_comp.lines.numExpeditions')}
                                    value={numExpeditions}
                                    variant="outlined"
                                    type="number"
                                    name="numExpeditions"
                                    fullWidth
                                    inputProps={{
                                        min: 1
                                    }}
                                    onChange={(e) => setNumExpeditions(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={bankHolidaysLine}
                                            onChange={(event) => setBankHolidaysLine(event.target.checked)} />}
                                    label={i18next.t('services_comp.lines.bankHolidayLine')}
                                />
                            </Grid>
                            <Grid item md={7} implementation="css" smDown component={Hidden} />
                        </>
                        : null
                }

                <Grid item md={3} implementation="css" smDown component={Hidden} />
                <Grid item md={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => history.goBack()}
                        size="large"
                    >{i18next.t('services_comp.lines.backButton')}
                    </Button>
                </Grid>
                <Grid item md={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => type == SERVICES_TYPE.regularService ? saveLine() : openModal()}
                        disabled={(stopsSingle ? stopsSingle[0]?.length <= 1 : true) || (vehicle == null && vehicle?.length === 0 || vehicle == '000000000000000000000000' || Object.keys(vehicle)?.length === 0)}
                        size="large"
                    >{i18next.t('services_comp.lines.saveButton')}
                    </Button>
                </Grid>
                <Grid item md={5} implementation="css" smDown component={Hidden} />

                {
                    type == SERVICES_TYPE.regularService ?

                        <LineServiceRegular
                            serviceType={type}
                            lineObject={lineObject}
                            serviceReducer={serviceReducer}
                            setstopsSingle={setstopsSingle}
                            setstopsReturn={setstopsReturn}
                            setallowChangeDirectionSingle={setallowChangeDirectionSingle}
                            allowChangeDirectionSingle={allowChangeDirectionSingle}
                            setallowChangeDirectionReturn={setallowChangeDirectionReturn}
                            allowChangeDirectionReturn={allowChangeDirectionReturn}
                        />

                        :
                        <LineRegularServiceWithSchedule
                            serviceType={type}
                            lineObject={lineObject}
                            serviceReducer={serviceReducer}
                            stopsSingle={stopsSingle}
                            setstopsSingle={setstopsSingle}
                            openModal={modalDefineDistances}
                            setModal={setModalDefineDistances}
                            saveLineWithSchedule={saveLineWithSchedule}
                        />
                }

            </Grid >
        </>

    );
}

function mapState(state) {
    const { serviceReducer, vehicleReducer } = state
    return { serviceReducer, vehicleReducer }
}

const actionCreators = {
    //
}

export default connect(mapState, actionCreators)(ServiceLinesView)