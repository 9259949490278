import React from 'react';
import { FormControl, Grid, TextField, Hidden, IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import GoogleMaps from './form/mapsInput';


class ItemStop extends React.Component {


    render() {
        const { t } = this.props
        return (
            <React.Fragment>
                <Grid item md={2} implementation="css" smDown component={Hidden} />
                <Grid item md={4} xs={6} sm={3}>
                    <FormControl fullWidth>
                        <GoogleMaps
                            label={t('zones.stops.stop.location')}
                            onChange={this.props.onChangeAddress}
                            value={this.props.value.address}
                            name={"address_" + this.props.index}
                            error={this.props.errors.address}
                            index={this.props.index}
                            disabled={
                                this.props.value.point.lat != ''
                                && this.props.value.point.long != ''
                                && this.props.value.address == ''
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={1} xs={6} sm={3}>
                    <FormControl fullWidth>
                        <TextField
                            label={t('zones.stops.stop.lat')}
                            value={this.props.value.point.lat || ''}
                            required
                            variant="outlined"
                            type="number"
                            name={"lat_" + this.props.index}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={this.props.onChangeLatLon}
                            inputProps={
                                { step: ".01", min: 0 }
                            }
                            helperText={this.props.errors.lat.message}
                            error={this.props.errors.lat.message.length == 0 ? false : true}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={1} xs={6} sm={3}>
                    <FormControl fullWidth>
                        <TextField
                            label={t('zones.stops.stop.long')}
                            value={this.props.value.point.lon || ''}
                            required
                            variant="outlined"
                            type="number"
                            name={"lon_" + this.props.index}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={this.props.onChangeLatLon}
                            inputProps={
                                { step: ".01", min: 0 }
                            }
                            helperText={this.props.errors.lon.message}
                            error={this.props.errors.lon.message.length == 0 ? false : true}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={6} sm={3}>
                    <FormControl fullWidth>
                        <TextField
                            label={t('zones.stops.stop.name')}
                            value={this.props.value.name || ''}
                            required
                            variant="outlined"
                            name={"name_" + this.props.index}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={this.props.onChange}
                            inputProps={
                                { readOnly: this.props.readOnly || this.props.readEdit }
                            }
                            helperText={this.props.errors.name.message}
                            error={this.props.errors.name.message.length == 0 ? false : true}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={1} xs={6} sm={3} style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {
                        (this.props.index + 1 == this.props.length ?
                            <React.Fragment>
                                {
                                    this.props.length > 1 &&
                                    <IconButton>
                                        <Remove
                                            onClick={() => this.props.remove(this.props.index, this.props.id)}
                                        />
                                    </IconButton>
                                }
                                <IconButton>
                                    <Add
                                        onClick={() => this.props.add()}
                                    />
                                </IconButton>
                            </React.Fragment>
                            :
                            <IconButton>
                                <Remove
                                    onClick={() => this.props.remove(this.props.index, this.props.id)}
                                />
                            </IconButton>
                        )
                    }
                </Grid>
            </React.Fragment>
        );
    }
}

export default ItemStop;