import { Box, Button, Grid, IconButton } from '@material-ui/core'
import React from 'react'
import { history } from '../../../helpers/history'
import { Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import UserTariffTypology from '../../services/typology/userTariff'
import { SERVICES_TYPE, TripStatus } from '../../../constants/types'
import { customerService } from '../../../services/customerService'
import { getDropOffLocationAddress, getPickUpLocationAddress } from '../../../helpers/tools'
import { BackButton } from '../../shared/BackAndSave'
import { TripProperty } from './details'
import AlertDialog from '../../alert-dialog'
import { Prompt } from 'react-router-dom'
import { allowAccessRole } from '../../../helpers/roles'
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants'

class ResumeTripView extends React.Component {
    constructor(props) {
        super()
        this.state = {
            clickButton: false,
            clickInfoPrice: false,
            preventExitWithTripPending: false
        }
        this.handleClickInfo = this.handleClickInfo.bind(this)
        this.onClickBack = this.onClickBack.bind(this)
    }

    onAcceptClick(id) {
        const { tripReducer } = this.props
        if (tripReducer.tripToModify == null)
            this.props.acceptTrip(id)
        else
            this.props.acceptModifyTrip(id, tripReducer.tripToModify.tripId)
        this.setState({
            clickButton: true,
        })
    }

    handleClickInfo() {
        this.setState({
            clickInfoPrice: !this.state.clickInfoPrice,
        })
    }

    onRejectClick(id) {
        this.props.rejectTrip(id)
        this.setState({
            clickButton: true,
        })
    }

    onRepeatTrip(trip) {
        const pickUp = { ...trip.pickUpLocation }
        const dropOff = { ...trip.dropOffLocation }
        trip.pickUpLocation = dropOff
        trip.dropOffLocation = pickUp
        const tripRepeat = { ...trip }
        customerService.getById(tripRepeat.customerId).then(result => {
            const data = {
                customerId: trip.customerId,
                name: result.fullName,
                birthDate: result.birthDate,
                isOnRelatedCustomerBehalf: trip.isOnRelatedCustomerBehalf,
            }
            this.props.cleanRepeatTrip()
            this.props.setRequestTripProperties(
                data,
                getPickUpLocationAddress(tripRepeat),
                getDropOffLocationAddress(tripRepeat),
                tripRepeat.luggage,
                tripRepeat.hasWheelChair,
                tripRepeat.numPassengers,
            )
            this.props.repeatTrip(tripRepeat, data)
            history.push('/requestTrip')
        })
    }

    handleOnClickPlan(trip) {
        this.props.setOpenModalSelectFrecuency(true, trip.tripId, trip.requestPickUpStartTime, trip.serviceType, trip.serviceId)
    }

    onClickBack() {
        const { multipleTripReducer } = this.props
        const hasTripWithError = multipleTripReducer.results.some(item => item.hasErrors)
        if (hasTripWithError) {
            this.setState({ preventExitWithTripPending: true })
        } else
            history.goBack()
    }

    componentWillUnmount() {
        const { trip } = this.props.tripReducer
        if (trip != null) {
            if (trip.status == TripStatus.Available && !this.state.clickButton) {
                this.props.rejectTrip(trip.tripId)
            }
        }
        this.props.setOpenModalSelectFrecuency(false)
    }

    render() {
        const { tripReducer, t, multipleTripReducer } = this.props
        const trip = tripReducer.trip
        const executeMultipleTrips = multipleTripReducer.executeMultipleTrips
        const columns = {
            passenger: [2, 3],
            origin: [2, 3],
            destination: [2, 3],
            date: [2, 3],
            actions: 3
        }
        const index = executeMultipleTrips | 0
        const hasTripWithError = multipleTripReducer.results.some(item => item.hasErrors)
        return (
            <div>
                <Prompt
                    when={hasTripWithError && !this.state.preventExitWithTripPending}
                    message={(location, action) => {
                        return t('trips.multipleBook.pendingTripsConfirm')
                    }}
                />
                <Grid
                    container
                    spacing={1}
                    style={{
                        paddingBottom: 20,
                        paddingLeft: 20,
                    }}
                >
                    <Grid item md={12}>
                        <h2>
                            <BackButton onClick={this.onClickBack} />
                            {t('trips.resume.title')}
                        </h2>
                    </Grid>
                    <Grid item md={columns.passenger[index]} sm={6} xs={12}>
                        <TripProperty isBold>{t('trips.resume.customer')}</TripProperty>
                        <TripProperty>{this.props.customerName}</TripProperty>
                        <TripProperty isBold>
                            {t('trips.resume.price')}
                        </TripProperty>
                        <TripProperty>
                            <Box display={'flex'} alignContent={'center'}>
                                {this.props.price}€
                                {trip.serviceType != SERVICES_TYPE.regularWithSchedule ? (
                                    <Tooltip title={t('trips.resume.priceTable')}>
                                        <IconButton style={{ padding: 0, fontSize: 'inherit' }}>
                                            <InfoIcon onClick={this.handleClickInfo} fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </Box>
                        </TripProperty>
                    </Grid>
                    <Grid item md={columns.origin[index]} sm={6} xs={12}>
                        <TripProperty isBold>{t('trips.resume.origin')}:</TripProperty>
                        <TripProperty>
                            {this.props.originAddress}
                        </TripProperty>
                        {this.props.pickUpStopName ? (
                            <>
                                <TripProperty isBold>
                                    {t('trips.resume.pickUpStopName')}
                                </TripProperty>
                                <TripProperty>{this.props.pickUpStopName}</TripProperty>
                            </>
                        ) : null}
                    </Grid>
                    <Grid item md={columns.destination[index]} sm={6} xs={12}>
                        <TripProperty isBold>{t('trips.resume.destination')}:</TripProperty>
                        <TripProperty>
                            {this.props.destinationAddress}
                        </TripProperty>
                        {this.props.dropOffStopName ? (
                            <>
                                <TripProperty isBold>
                                    {t('trips.resume.dropOffStopName')}
                                </TripProperty>
                                <TripProperty>{this.props.dropOffStopName}</TripProperty>
                            </>
                        ) : null}
                    </Grid>
                    <Grid item md={columns.date[index]} sm={6} xs={12}>
                        <TripProperty isBold>{t('trips.resume.date')}</TripProperty>
                        <TripProperty>
                            {t('trips.resume.pickUp') + this.props.pickupTime}
                            {trip.status === TripStatus.Available && trip.isAlternateSolution ? (
                                <span>
                                    <Tooltip title={t('services.trips.warningAlternateSolution')}>
                                        <WarningIcon />
                                    </Tooltip>
                                </span>
                            ) : null}
                        </TripProperty>
                        <TripProperty>
                            {t('trips.resume.dropOff') + this.props.dropOffTime}
                        </TripProperty>
                    </Grid>
                    {!executeMultipleTrips && allowAccessRole(MODULES.Trips, OPERATIONS_ALLOWED.manage) && (
                        <Grid item md={3} sm={6} xs={12}>
                            {trip.status == TripStatus.Available ? (
                                <React.Fragment>
                                    <Grid item xl={6} md={10} sm={11} xs={12} style={{ padding: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="medium"
                                            disabled={tripReducer.pending ? true : false}
                                            onClick={() => this.onAcceptClick(trip.tripId)}
                                        >
                                            {t('trips.resume.accept')}
                                        </Button>
                                    </Grid>
                                    <Grid item xl={6} md={10} sm={11} xs={12} style={{ padding: 10 }}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            size="medium"
                                            disabled={tripReducer.pending ? true : false}
                                            onClick={() => this.onRejectClick(trip.tripId)}
                                        >
                                            {t('trips.resume.reject')}
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            ) : null}
                            {[
                                TripStatus.AcceptedByCustomerService,
                                TripStatus.AcceptedByUser,
                                TripStatus.AcceptedByIVR,
                                TripStatus.AcceptedByThirdParty,
                                TripStatus.AcceptedByDriver,
                                TripStatus.DriverAssignedByOperator,
                                TripStatus.DriverAssignedAfterCompaction
                            ].includes(/*trip.status*/) ? (
                                <>
                                    <Grid item xl={6} md={10} sm={11} xs={12} style={{ padding: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="medium"
                                            disabled={tripReducer.pending ? true : false}
                                            onClick={() => this.onRepeatTrip(trip)}
                                        >
                                            {t('trips.resume.repeat')}
                                        </Button>
                                    </Grid>
                                    <Grid item xl={6} md={10} sm={11} xs={12} style={{ padding: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="medium"
                                            disabled={tripReducer.pending ? true : false}
                                            onClick={() => this.handleOnClickPlan(trip)}
                                        >
                                            {t('trips.resume.programTrip')}
                                        </Button>
                                    </Grid>
                                </>
                            ) : null}
                        </Grid>
                    )}
                </Grid>
                <AlertDialog
                    open={this.state.clickInfoPrice}
                    title={''}
                    maxWidth={'md'}
                    fullWidth
                    onClose={this.handleClickInfo}
                    onClickAccept={this.handleClickInfo}
                    content={() => <UserTariffTypology tariff={this.props.tariff} t={t} canEdit={false} />}
                />
                <AlertDialog
                    title={t('trips.multipleBook.pendingTripsConfirm')}
                    open={this.state.preventExitWithTripPending}
                    onClose={() => this.setState({ preventExitWithTripPending: false })}
                    onClickCancel={() => this.setState({ preventExitWithTripPending: false })}
                    onClickAccept={() => history.goBack()}
                />
            </div >
        )
    }
}

export default ResumeTripView