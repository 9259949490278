import React from 'react'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'
import i18next from 'i18next'
import { defaultStaticRanges } from '../defaultRanges'
import { defaultInputRanges } from '../defaultRanges'
import { Button, Grid, Hidden } from '@material-ui/core'

class ServiceCalendarRangeView extends React.Component {
    constructor(props) {
        super()
        this.state = {}
    }

    componentDidMount() {
        let lng = i18next.language
        let locale = ''
        switch (true) {
            case lng == 'es':
                locale = 'es'
                break
            case lng == 'cat':
                locale = 'ca'
                break
            case lng == 'en':
                locale = 'enGB'
                break
            default:
                locale = 'ca'
        }
        this.setState({
            language: locale,
        })
    }

    handleChange = item => {
        let selection = [item.selection]
        this.props.setRangeCalendar(selection)
    }

    render() {
        const { serviceReducer, t } = this.props
        let ranges = serviceReducer.ranges
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item md={12}>
                        <h2>{t('services_comp.calendar.range.title')}</h2>
                    </Grid>
                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                    <Grid item md={11}>
                        <DateRangePicker
                            firstDayOfWeek={7}
                            locale={locales[this.state.language]}
                            onChange={this.handleChange}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            staticRanges={defaultStaticRanges(i18next)}
                            inputRanges={defaultInputRanges(i18next)}
                            ranges={ranges}
                            direction="horizontal"
                        />
                    </Grid>
                    <Grid
                        item
                        md={1}
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <Button variant="contained" color="primary" fullWidth size="small" onClick={() => this.props.setExcludeDays(ranges)}>
                            {t('services_comp.calendar.range.add')}
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default ServiceCalendarRangeView
