import React from 'react';
import TotalBookings from './numTotalBookings'
import store from '../../../../redux/store';
import { tripActions } from '../../../../redux/actions/trip_actions';
import BookingsTimeDistribution from './timeDistribution';

export const KPI_SELECTED = { 'TOTAL_BOOKINGS': 'totalRequests', 'HOURS_DISTRIBUTION': 'timeDistributionRequest' }
// timeDistributionRequest: "Distribución horaria de las reservas"
// totalRequests: "Total de reservas"

export const getDataForBookingsChart = (kpiSelected, chartProps) => {
    if (kpiSelected == KPI_SELECTED.TOTAL_BOOKINGS) {
        const { startDate, finishDate } = chartProps;
        return store.dispatch(tripActions.getAllKPI(startDate, finishDate)).then(
            confirmedTrips => {
                console.log("confimedTrips => ", confirmedTrips)
                return confirmedTrips
            },
            (error) => {
                console.log("error getDataForBookingsChart => ", error)
                return null
            }
        )
    }
    else if (kpiSelected == KPI_SELECTED.HOURS_DISTRIBUTION) {
        const { startDate, finishDate } = chartProps;
        return store.dispatch(tripActions.getAllKPI(startDate, finishDate)).then(
            confirmedTrips => {
                console.log("confimedTrips => ", confirmedTrips)
                return confirmedTrips
            },
            (error) => {
                console.log("error getDataForBookingsChart => ", error)
                return null
            }
        )
    }
}

export const getBookingsChart = (kpiSelected, chartProps, data) => {
    const { chartType, title, groupedBy, value, filterByDate, startDate, finishDate, timeUnits, tripOrigin } = chartProps;
    if (kpiSelected == KPI_SELECTED.TOTAL_BOOKINGS) {
        return (
            <TotalBookings
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                timeUnits={timeUnits}
                tripOrigin={tripOrigin}
            />
        )
    }
    else if (kpiSelected == KPI_SELECTED.HOURS_DISTRIBUTION) {
        return (
            <BookingsTimeDistribution
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                timeUnits={timeUnits}
                tripOrigin={tripOrigin}
            />
        )
    } else {
        return null
    }
}