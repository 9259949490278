import { Button, Grid, IconButton, Paper, Tooltip, makeStyles } from '@material-ui/core';
import React, { forwardRef } from 'react';
import Spinner from '../../shared/spinner';
import MultipleTrips from './multipleTrip';
import TripMap from '../maps/index'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import ReplayIcon from '@material-ui/icons/Replay';
import moment from "moment";
import i18next from 'i18next';
import DatePicker from "react-datepicker";
import { Alert, AlertTitle } from '@material-ui/lab';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { SERVICES_TYPE } from '../../../constants/types';
import { SelectPosibleScheduleComponent } from '../form/modalSelectPosibleSchedule';
import { useEffect } from 'react';

const styles = makeStyles((theme) => ({
    center: {
        textAlign: 'center',
        padding: 10,
    },
    defaultPadding: {
        padding: theme.spacing(2)
    },
    divContainer: {
        display: 'flex'
    },
    border: {
        borderBottom: '1px solid #E0E0E0'
    },
    tripProperty: {
        padding: 8,
        fontSize: '13.5px'
    },
    bold: {
        fontWeight: 'bold'
    },
    width100: {
        width: '100%'
    },
    errorContainer: {
        width: '100%',
        fontSize: theme.typography.fontSize,
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}))


export const TripProperty = ({ children, ...props }) => {
    const classes = styles()
    return (
        <div className={classes.tripProperty}>
            <div className={props.isBold ? classes.bold : ''} >
                {children}
            </div>
        </div >
    )
}

export const ResumeTripWrapped = (Component) => {
    return function (props) {
        const classes = styles()
        if (props.loading)
            return (<Spinner loading={props.loading} {...props} />)
        const { multipleTripReducer } = props
        return (
            <>
                <Grid item md={multipleTripReducer.executeMultipleTrips ? 8 : 12} className={classes.defaultPadding}>
                    <Paper elevation={1}>
                        <Component {...props} />
                        <Grid item md={12}>
                            <TripMap {...props} />
                        </Grid>
                    </Paper>
                </Grid>
                {
                    multipleTripReducer.executeMultipleTrips && (
                        <Grid item md={4} className={classes.defaultPadding}>
                            <Paper elevation={1}>
                                <MultipleTrips
                                    listOfTrips={multipleTripReducer.results}
                                    {...props}
                                />
                            </Paper>
                        </Grid>
                    )
                }
            </>
        )
    }
}

export const GridCenter = ({ children }) => {
    const classes = styles()
    return (
        <Grid item md={6} className={`${classes.center} ${classes.border}`}>
            {children}
        </Grid>
    )
}

export const ListOfTripsPlannedComponent = ({ listOfTrips, handleMouseOver, handleCancelTrip, handleRetryTrip, serviceType }) => {
    const classes = styles()
    return (
        listOfTrips.map(trip => {
            return (
                <React.Fragment key={trip.tripId}>
                    <Grid item md={12} className={classes.divContainer}>
                        <GridCenter key={trip.tripId + '_date'}>
                            <Grid item style={{ padding: 13 }}>
                                {moment(trip.hasErrors ? trip.requestPickUpTime : trip.tripResponse.schedulePickUpTime).format("DD/MM/YYYY HH:mm")}
                            </Grid>
                        </GridCenter>
                        <GridCenter key={trip.tripId + '_detail'}>
                            {
                                trip.hasErrors ?
                                    <>
                                        <Tooltip title={i18next.t('trips.multipleBook.showErrorTooltip')}>
                                            <IconButton onClick={() => handleMouseOver(trip)}>
                                                <ErrorIcon color="error" />
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            // prevent retry when we dont't have options to choose and the service is regularWithSchedule
                                            !(SERVICES_TYPE.regularWithSchedule === serviceType && trip.pickupHours?.length === 0) && (
                                                <Tooltip title={i18next.t('trips.multipleBook.retryTripTooltip')}>
                                                    <IconButton onClick={() => handleRetryTrip(trip)}>
                                                        <ReplayIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        }
                                    </>
                                    :
                                    <>
                                        <Tooltip title={i18next.t('trips.multipleBook.tripAcceptedTooltip')}>
                                            <IconButton>
                                                <CheckCircleIcon color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={i18next.t('customers.trips.page.actions.delete')}>
                                            <IconButton>
                                                <CancelIcon onClick={() => handleCancelTrip(trip)} />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                            }
                        </GridCenter>
                    </Grid>
                </React.Fragment>
            )
        })
    )
}

export const ShowErrorComponent = ({ errorsToShow }) => {
    const classes = styles()
    return (
        <div className={classes.errorContainer}>
            <Alert severity='error'>
                <AlertTitle><strong>{i18next.t('trips.multipleBook.errorsOfTrip')}</strong></AlertTitle>
                <ul>
                    {
                        errorsToShow.map(item => <li key={item}>
                            {item?.detail}
                            {(item?.object?.nextAvailableTime)
                                && (
                                    `. ${i18next.t('services.trips.nextTimeAvailable')} ${moment(item?.object?.nextAvailableTime).format('HH:mm')}`
                                )
                            }
                        </li>)
                    }
                </ul>
            </Alert>
        </div>
    )
}

export const withListOfTrips = (Component) => {
    const classes = styles()
    return function (props) {
        if (props.listOfTrips?.length == 0)
            return (
                <Grid item md={12} className={classes.center}>
                    {i18next.t('trips.multipleBook.emptyTrips')}
                </Grid>
            )
        return <Component {...props} />
    }
}

export const RetryTripComponent = ({ date, setDate }) => {

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button onClick={onClick} ref={ref} variant='contained' startIcon={<ScheduleIcon />} fullWidth color='primary' >
            {value}
        </Button>
    ));
    const classes = styles()
    return (
        <Grid container justify='center'>
            <Grid item md={4} className='datePickerContainer'>
                <DatePicker
                    wrapperClassName={classes.width100}
                    selected={date !== '' ? moment(date, 'HH:mm').toDate() : date}
                    onChange={(date) => setDate(date)}
                    customInput={<CustomInput />}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption={i18next.t('trips.multipleBook.time')}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                />
            </Grid>
        </Grid>
    )
}

export const withSelectNewTimeComponent = (serviceType, expeditions, date, setDate, pending) => {

    const setNewDateWithTime = (dateToSet) => {
        if (dateToSet instanceof Date) {
            setDate(moment(dateToSet).format('HH:mm'))
        } else {
            setDate(dateToSet)
        }
    }
    if (pending)
        return (
            <Spinner loading={pending} />
        )

    if ([SERVICES_TYPE.regularWithSchedule].includes(serviceType))
        return (
            <Grid item md={6}>
                <SelectPosibleScheduleComponent
                    expeditions={expeditions}
                    pending={pending}
                    handleClickOption={(value) => setNewDateWithTime(value)}
                    value={date}
                />
            </Grid>
        )
    return (
        <RetryTripComponent
            setDate={setNewDateWithTime}
            date={date}
        />
    )
}