import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getWheelJourneyDriversTable } from '../../../../../helpers/kpiHelper';
import KpiWrapper from '../../../components/kpiWrapper';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

export default function WheelJourneyDriversTable({ isLoading }) {
    const { t } = useTranslation()

    const driverRequest = useSelector(state => state.kpiReducer.driverRequest)
    const isDriverRequestLoading = useSelector(state => state.kpiReducer.driverRequestLoading)

    const { data } = useMemo(() => {
        if (isDriverRequestLoading) {
            return { data: [] }
        }

        let data = getWheelJourneyDriversTable({ driverRequest })
        return { data }
    }, [driverRequest])

    isLoading = isLoading || isDriverRequestLoading

    return (
        <KpiWrapper isLoading={isLoading} title={t('commandBox.drivers.wheelJourneyDriversTable.title')} skeletonVariant="rect">
            <TableContainer component={Box}>
                <Table arial-label="reservation table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("commandBox.drivers.wheelJourneyDriversTable.driver")}</TableCell>
                            <TableCell align="right">{t("commandBox.drivers.wheelJourneyDriversTable.acceptedServices")}</TableCell>
                            <TableCell align="right">{t("commandBox.drivers.wheelJourneyDriversTable.rejectedServices")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(data).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell align="right">{value.acceptedServices}</TableCell>
                                    <TableCell align="right">{value.rejectedServices}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </KpiWrapper>

    )
}