import React from 'react';
import Dashboard from '../../dashboard';
import ZoneStopView from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { zoneActions } from '../../../redux/actions/zones_actions';
import { alertActions } from '../../../redux/actions/alert_actions';
import { withTheme } from '@material-ui/core/styles';

class ZoneStops extends React.Component {

    componentDidMount() {
        this.props.getAllZones()
    }

    render() {
        const { zoneReducer } = this.props
        return (
            <Dashboard
                component={
                    <ZoneStopView
                        {...this.props}
                    />
                }
            />
        );
    }
}

function mapState(state) {
    const { zoneReducer, alertReducer } = state;
    return { zoneReducer, alertReducer };
}

const actionCreators = {
    getAllZones: zoneActions.getAll,
    addStops: zoneActions.addStops,
    clear: alertActions.clear,
    info: alertActions.info,
    setStops: zoneActions.setStops,
    changeZone: zoneActions.changeZoneSelectStops,
    closeModal: zoneActions.closeModalConflictStops,
    getServicesFromStops: zoneActions.getServicesFromStops

}

export default compose(withTranslation('common'), connect(mapState, actionCreators), withTheme)(ZoneStops);