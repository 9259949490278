import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'
import { FilterOptions, options, DownloadChart } from '../../../customFunctions'
import i18next from 'i18next'

const filterDataByGroup = (data, groupedBy, driver, value) => {
    console.log(groupedBy)
    switch (groupedBy) {
        case 'municipalities':
            return data.filter(item => item.municipalities?.some(m => value.includes(m)) && item.driverName === driver)
        case 'regions':
            return data.filter(item => item.regions?.some(r => value.includes(r)) && item.driverName === driver)
        case 'otps':
            return data.filter(item => item.otps?.some(o => value.includes(o)) && item.driverName === driver)
        case 'services':
            return data.filter(item => item.services?.some(s => value.includes(s)) && item.driverName === driver)
        case 'tenants':
            return data.filter(item => item.tenants?.some(t => value.includes(t)) && item.driverName === driver)
        default:
            return data
    }
}

const filteredData = (data, filterOptions, title, driver, value) => {
    const { groupedBy } = filterOptions
    const textsPuntuality = i18next.t('commandBox.puntuality', { returnObjects: true })

    let dataValue = filterDataByGroup(data, groupedBy, driver, value)
    let results = [['Trips ' + driver, textsPuntuality[0]]]
    results.push([textsPuntuality[1], dataValue.filter(item => !item.isLateArrival).length])
    results.push([textsPuntuality[2], dataValue.filter(item => item.isLateArrival).length])
    console.log(results)
    return results
}

const TripsPunctuality = ({ data, title, groupedBy = 'municipalities', value = [], filterByDate = true, startDate = '2022-01-01', finishDate = '2022-02-01' }) => {
    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate)

    const chartType = 'PieChart'
    let drivers = Array.from(new Set(data.map(item => item.driverName)))
    return (
        <>
            {drivers.map(function (driver, i) {
                return filteredData(data, filterOptions, title, driver, value).filter(([a, b]) => b > 0).length > 0 ? (
                    <React.Fragment>
                        <Chart key={Math.random()} graphID={'chart_' + i.toString()} chartType={chartType} width="100%" height="400px" data={filteredData(data, filterOptions, title, driver, value)} options={options(chartProps, null, driver)} />
                        <DownloadChart key={Math.random()} img64={i} />
                    </React.Fragment>
                ) : null
            })}
        </>
    )
}

TripsPunctuality.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(['municipalities', 'regions', 'otps', 'services', 'tenants']).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default TripsPunctuality
