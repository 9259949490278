import { incidentConstants } from '../../constants/incident_constants'
import { incidentService } from '../../services/incidentService'
import { alertActions } from '../actions/alert_actions'
import { userActions } from '../actions/user_actions'
import i18next from 'i18next'
import { history } from '../../helpers/history'
import { userService } from '../../services/userService'
import { ERROR_CODES } from '../../constants/types'
import { customerService } from '../../services/customerService'

const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value.toString());

export const incidentActions = {
    getAll,
    getSpecificIncident,
    asignIncidenceToUser,
    closeIncidence,
    reopenIncidence,
    getPriorities,
    getCauses,
    getTicketStatus,
    setIncidencePriority,
    setIncidenceStatus,
    addActivity,
    getAllActivitiesInIncidence,
    receiveEmail,
    receivePushNotifications,
    getOpUserWantsEmailNotifications,
    getOpUserWantsPushNotifications,
    getCustomerWantsEmailNotifications,
    getCustomerWantsPushNotifications,
    setPendingToReview,
    isThereSomethingToReview,
    subscribeToTopic,
    getNumberOfTicketsToReviewFromOpUser,
    insert,
    modifyIncidence,
    setIncidentsFilter,
    setFilterDate
}

function getAll(filters = null, startDate = null, endDate = null) {
    return dispatch => {
        dispatch(request())
        incidentService.getAll(startDate, endDate).then(
            results => {
                let all = results.slice() //copy only by value
                if (filters != null) {
                    results = filterPlainArray(results, filters)
                }
                dispatch(success(results, all));
                // dispatch(getNumberOfTicketsToReviewFromOpUser())
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getAll())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenGetAll'),
                            ),
                        )
                        break
                    default:
                    /*  dispatch(
                       alertActions.error(
                           i18next.t('services.incidents.getAllError'),
                       ),
                    ) */
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.GET_ALL_REQUEST }
    }

    function success(results, all) {
        return { type: incidentConstants.GET_ALL_SUCCESS, results, all }
    }

    function failure(error) {
        return { type: incidentConstants.GET_ALL_FAILURE, error }
    }
}

function setFilterDate(typeDate, value) {
    return dispatch => {
        dispatch(request(typeDate, value))
    }

    function request(typeDate, value) {
        return { type: incidentConstants.SET_FILTER_DATE_INCIDENCES, typeDate, value }
    }
}

function getSpecificIncident(data, redirect) {
    return dispatch => {
        dispatch(request())
        incidentService.getSpecificIncident(data.id).then(
            results => {
                dispatch(success(results))
                if (redirect) {
                    history.push({
                        pathname: '/incidents/' + data.id,
                        state: { data },
                    })
                    //history.push("/support/incidents/" + data.id);
                }
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getSpecificIncident(data))
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t('services.incidents.getByIdError'),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.GET_SPECIFIC_INCIDENT_REQUEST }
    }

    function success(incident) {
        return {
            type: incidentConstants.GET_SPECIFIC_INCIDENT_SUCCESS,
            incident,
        }
    }

    function failure(error) {
        return { type: incidentConstants.GET_SPECIFIC_INCIDENT_FAILURE, error }
    }
}

function setIncidentsFilter(prop, values, filters) {
    return dispatch => {
        dispatch(request(prop, values, filters))
    }

    function request(prop, values, filters) {
        return { type: incidentConstants.SET_FILTER_INCIDENTS, prop, values, filters }
    }
}

function filterPlainArray(array, filters) {
    const filterKeys = Object.keys(filters);
    return array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
            // ignores an empty filter
            if (!filters[key].length) return true;
            return filters[key].find(filter => getValue(filter) === getValue(item[key]));
        });
    });
}

function asignIncidenceToUser(incidence) {
    return dispatch => {
        dispatch(request())
        incidentService.asignIncidenceToUser(incidence).then(
            results => {
                dispatch(success(results))
                dispatch(
                    alertActions.success(
                        i18next.t('services.incidents.asignToUserSuccess'),
                    ),
                )
                history.goBack();
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(asignIncidenceToUser(incidence))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenUpdate'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.asignToUserError'),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.ASIGN_INCIDENCE_TO_USER_REQUEST }
    }

    function success(results) {
        return {
            type: incidentConstants.ASIGN_INCIDENCE_TO_USER_SUCCESS,
            results,
        }
    }

    function failure(error) {
        return {
            type: incidentConstants.ASIGN_INCIDENCE_TO_USER_FAILURE,
            error,
        }
    }
}

function closeIncidence(body) {
    return dispatch => {
        dispatch(request())
        incidentService.getCustomerById(body.customerId).then(
            customer => {
                body['pushToken'] = customer.pushToken
                body['email'] = customer.email
                incidentService.closeIncidence(body).then(
                    results => {
                        dispatch(success(results))
                        // dispatch(getAll())
                        dispatch(
                            alertActions.success(
                                i18next.t('services.incidents.closingSuccess'),
                            ),
                        )
                        history.goBack()
                    },
                    error => {
                        switch (error) {
                            case ERROR_CODES.unauthorized:
                                if (!userService.existRefreshToken()) return
                                dispatch(userActions.refreshToken())
                                dispatch(closeIncidence(body))
                                break
                            case ERROR_CODES.forbidden:
                                dispatch(
                                    alertActions.error(
                                        i18next.t('services.incidents.forbiddenUpdate'),
                                    ),
                                )
                                break
                            default:
                                dispatch(
                                    alertActions.error(
                                        i18next.t(
                                            'services.incidents.closingError',
                                        ),
                                    ),
                                )
                        }
                        dispatch(failure(error))
                    },
                )
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(closeIncidence(body))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenUpdate'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t(
                                    'services.incidents.closingError',
                                ),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.CLOSE_INCIDENCE_REQUEST }
    }

    function success(results) {
        return { type: incidentConstants.CLOSE_INCIDENCE_SUCCESS, results }
    }

    function failure(error) {
        return { type: incidentConstants.CLOSE_INCIDENCE_FAILURE, error }
    }
}

function reopenIncidence(id) {
    return dispatch => {
        dispatch(request())
        incidentService.setIncidenceStatus(id, 10).then(
            results => {
                dispatch(success(results))
                // dispatch(getAll())
                dispatch(
                    alertActions.success(
                        i18next.t('services.incidents.reopeningSuccess'),
                    ),
                )
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(reopenIncidence(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenUpdate'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.reopeningError'),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.REOPEN_INCIDENCE_REQUEST }
    }

    function success(results) {
        return { type: incidentConstants.REOPEN_INCIDENCE_SUCCESS, results }
    }

    function failure(error) {
        return { type: incidentConstants.REOPEN_INCIDENCE_FAILURE, error }
    }
}

function getPriorities() {
    return dispatch => {
        dispatch(request())
        incidentService.getPriorities().then(
            priorities => {
                priorities = convertEnumToList(priorities)
                dispatch(success(priorities))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getPriorities())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenGetAll'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.getPrioritiesError'),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.GET_PRIORITIES_REQUEST }
    }

    function success(priorities) {
        return { type: incidentConstants.GET_PRIORITIES_SUCCESS, priorities }
    }

    function failure(error) {
        return { type: incidentConstants.GET_PRIORITIES_FAILURE, error }
    }
}

function getCauses() {
    return dispatch => {
        dispatch(request())
        incidentService.getCauses().then(
            causes => {
                causes = convertEnumToList(causes)
                dispatch(success(causes))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getCauses())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenGetAll'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.getCausesError'),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.GET_CAUSES_REQUEST }
    }

    function success(causes) {
        return { type: incidentConstants.GET_CAUSES_SUCCESS, causes }
    }

    function failure(error) {
        return { type: incidentConstants.GET_CAUSES_FAILURE, error }
    }
}

function getTicketStatus() {
    return dispatch => {
        dispatch(request())
        incidentService.getTicketStatus().then(
            status => {
                status = convertEnumToList(status)
                dispatch(success(status))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getTicketStatus())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenGetAll'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t(
                                    'services.incidents.getTicketStatusError',
                                ),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.GET_TICKET_STATUS_REQUEST }
    }

    function success(status) {
        return { type: incidentConstants.GET_TICKET_STATUS_SUCCESS, status }
    }

    function failure(error) {
        return { type: incidentConstants.GET_TICKET_STATUS_FAILURE, error }
    }
}

function setIncidencePriority(id, priorityId) {
    return dispatch => {
        dispatch(request())
        incidentService.setIncidencePriority(id, priorityId).then(
            status => {
                status = convertEnumToList(status)
                dispatch(success(status))
                dispatch(
                    alertActions.success(
                        i18next.t(
                            'services.incidents.setIncidencePrioritySuccess',
                        ),
                    ),
                )
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(setIncidencePriority(id, priorityId))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenUpdate'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t(
                                    'services.incidents.setIncidencePriorityError',
                                ),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.SET_INCIDENCE_PRIORITY_REQUEST }
    }

    function success(status) {
        return {
            type: incidentConstants.SET_INCIDENCE_PRIORITY_SUCCESS,
            status,
        }
    }

    function failure(error) {
        return { type: incidentConstants.SET_INCIDENCE_PRIORITY_FAILURE, error }
    }
}

function setIncidenceStatus(body) {
    return dispatch => {
        dispatch(request())
        incidentService.getCustomerById(body.customerId).then(
            customer => {
                body['pushToken'] = customer.pushToken
                body['email'] = customer.email
                incidentService.setIncidenceStatus(body).then(
                    status => {
                        status = convertEnumToList(status)
                        dispatch(success(status))
                        dispatch(
                            alertActions.success(
                                i18next.t(
                                    'services.incidents.setIncidenceStatusSuccess',
                                ),
                            ),
                        )
                    },
                    error => {
                        switch (error) {
                            case ERROR_CODES.unauthorized:
                                if (!userService.existRefreshToken()) return
                                dispatch(userActions.refreshToken())
                                dispatch(setIncidenceStatus(body))
                                break
                            case ERROR_CODES.forbidden:
                                dispatch(
                                    alertActions.error(
                                        i18next.t('services.incidents.forbiddenUpdate'),
                                    ),
                                )
                                break
                            default:
                                dispatch(
                                    alertActions.error(
                                        i18next.t(
                                            'services.incidents.setIncidenceStatusError',
                                        ),
                                    ),
                                )
                        }
                        dispatch(failure(error))
                    },
                )
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(setIncidenceStatus(body))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenUpdate'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t(
                                    'services.incidents.setIncidenceStatusError',
                                ),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.SET_INCIDENCE_STATUS_REQUEST }
    }

    function success(status) {
        return { type: incidentConstants.SET_INCIDENCE_STATUS_SUCCESS, status }
    }

    function failure(error) {
        return { type: incidentConstants.SET_INCIDENCE_STATUS_FAILURE, error }
    }
}

function addActivity(body, data) {
    return dispatch => {
        dispatch(request())
        customerService.getById(body.customerId).then(
            customer => {
                body['customerPushToken'] = customer.pushToken
                body['customerEmail'] = customer.email
                incidentService.getCustomerWantsEmailNotifications(body.customerId).then(
                    customerEmailNotifyBool => {
                        body['customerReceiveEmail'] = customerEmailNotifyBool
                        incidentService.getCustomerWantsPushNotifications(body.customerId).then(
                            customerPushNotifyBool => {
                                body['customerReceiveNotifications'] = customerPushNotifyBool
                                incidentService.addActivity(body).then(
                                    activity => {
                                        dispatch(success(activity))
                                        dispatch(
                                            alertActions.success(
                                                i18next.t(
                                                    'services.incidents.addActivitySuccess',
                                                ),
                                            ),
                                        )
                                        dispatch(getAllActivitiesInIncidence(body.ticketId))
                                    },
                                    error => {
                                        switch (error) {
                                            case ERROR_CODES.unauthorized:
                                                if (!userService.existRefreshToken()) return
                                                dispatch(userActions.refreshToken())
                                                dispatch(addActivity(body))
                                                break
                                            case ERROR_CODES.forbidden:
                                                dispatch(
                                                    alertActions.error(
                                                        i18next.t(
                                                            'services.incidents.forbiddenUpdate',
                                                        ),
                                                    ),
                                                )
                                                break
                                            default:
                                                dispatch(
                                                    alertActions.error(
                                                        i18next.t(
                                                            'services.incidents.addActivityError',
                                                        ),
                                                    ),
                                                )
                                        }
                                        dispatch(failure(error))
                                    },
                                )
                            }, error => {
                                console.log("Error when trying to get the push notification customer preferences")
                            }
                        )
                    }, error => {
                        console.log("Error when trying to get the email notification customer preferences")
                    }
                )
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(addActivity(body))
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t('services.incidents.addActivityError'),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.ADD_ACTIVITY_REQUEST }
    }

    function success(activity) {
        return { type: incidentConstants.ADD_ACTIVITY_SUCCESS, activity }
    }

    function failure(error) {
        return { type: incidentConstants.ADD_ACTIVITY_FAILURE, error }
    }
}

function getAllActivitiesInIncidence(ticketId) {
    return dispatch => {
        dispatch(request())
        incidentService.getAllActivitiesInIncidence(ticketId).then(
            activities => {
                dispatch(success(activities))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getAllActivitiesInIncidence(ticketId))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenGetAll'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t(
                                    'services.incidents.getTicketStatusError',
                                ),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return {
            type: incidentConstants.GET_ALL_ACTIVITIES_IN_INCIDENCE_REQUEST,
        }
    }

    function success(activities) {
        return {
            type: incidentConstants.GET_ALL_ACTIVITIES_IN_INCIDENCE_SUCCESS,
            activities,
        }
    }

    function failure(error) {
        return {
            type: incidentConstants.GET_ALL_ACTIVITIES_IN_INCIDENCE_FAILURE,
            error,
        }
    }
}

function receiveEmail(opUserId, userWantsToReceiveEmailsNotifications) {
    return dispatch => {
        dispatch(request())
        incidentService
            .receiveEmailNotifications(
                opUserId,
                userWantsToReceiveEmailsNotifications,
            )
            .then(
                userWantsToReceiveEmails => {
                    if (userWantsToReceiveEmailsNotifications) {
                        dispatch(
                            alertActions.success(
                                i18next.t(
                                    'services.incidents.receiveEmailSuccess',
                                ),
                            ),
                        )
                    } else {
                        dispatch(
                            alertActions.success(
                                i18next.t(
                                    'services.incidents.doNotReceiveEmailSuccess',
                                ),
                            ),
                        )
                    }
                    dispatch(getOpUserWantsEmailNotifications(opUserId))
                    dispatch(success(userWantsToReceiveEmailsNotifications))
                },
                error => {
                    if (error === 401 && userService.existRefreshToken()) {
                        dispatch(userActions.refreshToken())
                        dispatch(
                            receiveEmail(
                                opUserId,
                                userWantsToReceiveEmailsNotifications,
                            ),
                        )
                    } else {
                        dispatch(
                            alertActions.error(
                                i18next.t(
                                    'services.incidents.receiveEmailError',
                                ),
                            ),
                        )
                    }
                    dispatch(failure(error))
                },
            )
    }

    function request() {
        return { type: incidentConstants.RECEIVE_EMAIL_NOTIFICATIONS_REQUEST }
    }

    function success(userWantsToReceiveEmails) {
        return {
            type: incidentConstants.RECEIVE_EMAIL_NOTIFICATIONS_SUCCESS,
            userWantsToReceiveEmails,
        }
    }

    function failure(error) {
        return {
            type: incidentConstants.RECEIVE_EMAIL_NOTIFICATIONS_FAILURE,
            error,
        }
    }
}

function receivePushNotifications(opUserId, userWantsToReceiveNotifications) {
    return dispatch => {
        dispatch(request())
        incidentService
            .receivePushNotifications(opUserId, userWantsToReceiveNotifications)
            .then(
                userWantsToReceivePush => {
                    if (userWantsToReceiveNotifications) {
                        dispatch(
                            alertActions.success(
                                i18next.t(
                                    'services.incidents.receivePushSuccess',
                                ),
                            ),
                        )
                    } else {
                        dispatch(
                            alertActions.success(
                                i18next.t(
                                    'services.incidents.doNotReceivePushSuccess',
                                ),
                            ),
                        )
                    }

                    dispatch(getOpUserWantsPushNotifications(opUserId))
                    dispatch(success(userWantsToReceiveNotifications))
                },
                error => {
                    if (error === 401 && userService.existRefreshToken()) {
                        dispatch(userActions.refreshToken())
                        dispatch(
                            receivePushNotifications(
                                opUserId,
                                userWantsToReceiveNotifications,
                            ),
                        )
                    } else {
                        dispatch(
                            alertActions.error(
                                i18next.t(
                                    'services.incidents.receivePushError',
                                ),
                            ),
                        )
                    }
                    dispatch(failure(error))
                },
            )
    }

    function request() {
        return { type: incidentConstants.RECEIVE_PUSH_NOTIFICATIONS_REQUEST }
    }

    function success(userWantsToReceivePush) {
        return {
            type: incidentConstants.RECEIVE_PUSH_NOTIFICATIONS_SUCCESS,
            userWantsToReceivePush,
        }
    }

    function failure(error) {
        return {
            type: incidentConstants.RECEIVE_PUSH_NOTIFICATIONS_FAILURE,
            error,
        }
    }
}

function getOpUserWantsEmailNotifications(opUserId) {
    return dispatch => {
        dispatch(request())
        incidentService.getOpUserWantsEmailNotifications(opUserId).then(
            opUserWantsEmail => {
                dispatch(success(opUserWantsEmail))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getOpUserWantsEmailNotifications(opUserId))
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t(
                                'services.incidents.getOpUserWantsEmailNotificationsError',
                            ),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return {
            type:
                incidentConstants.GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_REQUEST,
        }
    }

    function success(opUserWantsEmail) {
        return {
            type:
                incidentConstants.GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_SUCCESS,
            opUserWantsEmail,
        }
    }

    function failure(error) {
        return {
            type:
                incidentConstants.GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_FAILURE,
            error,
        }
    }
}

function getOpUserWantsPushNotifications(opUserId) {
    return dispatch => {
        dispatch(request())
        incidentService.getOpUserWantsPushNotifications(opUserId).then(
            opUserWantsPush => {
                dispatch(success(opUserWantsPush))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getOpUserWantsPushNotifications(opUserId))
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t(
                                'services.incidents.getOpUserWantsPushNotificationsError',
                            ),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return {
            type:
                incidentConstants.GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_REQUEST,
        }
    }

    function success(opUserWantsPush) {
        return {
            type:
                incidentConstants.GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_SUCCESS,
            opUserWantsPush,
        }
    }

    function failure(error) {
        return {
            type:
                incidentConstants.GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_FAILURE,
            error,
        }
    }
}

function getCustomerWantsEmailNotifications(customerId) {
    return dispatch => {
        dispatch(request())
        incidentService.getCustomerWantsEmailNotifications(customerId).then(
            customerWantsEmail => {
                dispatch(success(customerWantsEmail))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getCustomerWantsEmailNotifications(customerId))
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t(
                                'services.incidents.getCustomerWantsEmailNotificationsError',
                            ),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return {
            type:
                incidentConstants.GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_REQUEST,
        }
    }

    function success(customerWantsEmail) {
        return {
            type:
                incidentConstants.GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_SUCCESS,
            customerWantsEmail,
        }
    }

    function failure(error) {
        return {
            type:
                incidentConstants.GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_FAILURE,
            error,
        }
    }
}

function getCustomerWantsPushNotifications(customerId) {
    return dispatch => {
        dispatch(request())
        incidentService.getCustomerWantsPushNotifications(customerId).then(
            customerWantsPush => {
                dispatch(success(customerWantsPush))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getCustomerWantsPushNotifications(customerId))
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t(
                                'services.incidents.getCustomerWantsPushNotificationsError',
                            ),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return {
            type:
                incidentConstants.GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_REQUEST,
        }
    }

    function success(customerWantsPush) {
        return {
            type:
                incidentConstants.GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_SUCCESS,
            customerWantsPush,
        }
    }

    function failure(error) {
        return {
            type:
                incidentConstants.GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_FAILURE,
            error,
        }
    }
}

function isThereSomethingToReview() {
    return dispatch => {
        dispatch(request())
        incidentService.isThereSomethingToReview().then(
            isThereSomethingToReview => {
                if (isThereSomethingToReview) {
                    dispatch(getNumberOfTicketsToReviewFromOpUser())
                    dispatch(getAll())
                }
                dispatch(success(isThereSomethingToReview))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(isThereSomethingToReview())
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t(
                                'services.incidents.getTicketStatusError',
                            ),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.IS_THERE_SOMETHING_TO_REVIEW_REQUEST }
    }

    function success(isThereSomethingToReview) {
        return {
            type: incidentConstants.IS_THERE_SOMETHING_TO_REVIEW_SUCCESS,
            isThereSomethingToReview,
        }
    }

    function failure(error) {
        return {
            type: incidentConstants.IS_THERE_SOMETHING_TO_REVIEW_FAILURE,
            error,
        }
    }
}

function setPendingToReview(ticketId, bool) {
    return dispatch => {
        dispatch(request())
        incidentService.setPendingToReview(ticketId, bool).then(
            pendingToReview => {
                dispatch(success(pendingToReview))
                dispatch(isThereSomethingToReview())
                // dispatch(getAll())
                // dispatch(getNumberOfTicketsToReviewFromOpUser())
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(setPendingToReview(ticketId, bool))
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t(
                                'services.incidents.setPendingToReviewError',
                            ),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.SET_PENDING_TO_REVIEW_REQUEST }
    }

    function success(pendingToReview) {
        return {
            type: incidentConstants.SET_PENDING_TO_REVIEW_SUCCESS,
            pendingToReview,
        }
    }

    function failure(error) {
        return { type: incidentConstants.SET_PENDING_TO_REVIEW_FAILURE, error }
    }
}

function subscribeToTopic(token) {
    return dispatch => {
        incidentService.subscribeToTopic(token).then(
            data => {
                dispatch(getAll())
                dispatch(getNumberOfTicketsToReviewFromOpUser())
            },
            error => {
                console.log('error incidence subscribeToTopic', error)
            },
        )
    }
}

function getNumberOfTicketsToReviewFromOpUser() {
    return dispatch => {
        dispatch(request())
        incidentService.getNumberOfTicketsToReviewFromOpUser().then(
            numberOfPendingToReview => {
                dispatch(success(numberOfPendingToReview))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getNumberOfTicketsToReviewFromOpUser())
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t(
                                'services.incidents.getTicketStatusError',
                            ),
                        ),
                    )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return {
            type:
                incidentConstants.GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_REQUEST,
        }
    }

    function success(numberOfPendingToReview) {
        return {
            type:
                incidentConstants.GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_SUCCESS,
            numberOfPendingToReview,
        }
    }

    function failure(error) {
        return {
            type:
                incidentConstants.GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_FAILURE,
            error,
        }
    }
}

function insert(body) {
    return dispatch => {
        dispatch(request())
        incidentService.isertIncidence(body).then(
            incident => {
                dispatch(success(incident))
                dispatch(addImagesToIncidence(body.images, incident.id))
                dispatch(
                    alertActions.success(
                        i18next.t(
                            'services.incidents.insertNewIncidenceSuccess',
                        ),
                    ),
                )
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(insert({ body }))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenUpdate'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t(
                                    'services.incidents.insertNewIncidenceError',
                                ),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.INSERT_INCIDENCE_REQUEST }
    }

    function success(incident) {
        return { type: incidentConstants.INSERT_INCIDENCE_SUCCESS, incident }
    }

    function failure(error) {
        return { type: incidentConstants.INSERT_INCIDENCE_FAILURE, error }
    }
}

function addImagesToIncidence(images, incidentId) {
    return dispatch => {
        dispatch(request())
        incidentService.addImagesToIncidence(images, incidentId).then(
            incidence => {
                dispatch(success(incidence))
                dispatch(getSpecificIncident(incidence, true))
                // dispatch(getAll())
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    console.log('error 401 en addImagesToIncidence')
                    dispatch(userActions.refreshToken())
                    setTimeout(() => {
                        dispatch(addImagesToIncidence(images, incidentId))
                    }, 1500)
                } else {
                    dispatch(failure(error))
                    dispatch(getAll())
                    history.goBack()
                }
            },
        )
    }

    function request() {
        return { type: incidentConstants.ADD_IMAGES_TO_INCIDENCE_REQUEST }
    }
    function success(incident) {
        return {
            type: incidentConstants.ADD_IMAGES_TO_INCIDENCE_SUCCESS,
            incident,
        }
    }
    function failure(error) {
        return {
            type: incidentConstants.ADD_IMAGES_TO_INCIDENCE_FAILURE,
            error,
        }
    }
}

function modifyIncidence(body) {
    return dispatch => {
        dispatch(request())
        incidentService.modifyIncidence(body).then(
            incident => {
                dispatch(success(incident))
                dispatch(addImagesToIncidence(body.images, body.id))
                dispatch(
                    alertActions.success(
                        i18next.t(
                            'services.incidents.updateIncidenceSuccess',
                        ),
                    ),
                )
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(modifyIncidence(body))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.incidents.forbiddenUpdate'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t(
                                    'services.incidents.updateIncidenceError',
                                ),
                            ),
                        )
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: incidentConstants.MODIFY_INCIDENCE_REQUEST }
    }

    function success(incident) {
        return { type: incidentConstants.MODIFY_INCIDENCE_SUCCESS, incident }
    }

    function failure(error) {
        return { type: incidentConstants.MODIFY_INCIDENCE_FAILURE, error }
    }
}

function convertEnumToList(enumObject) {
    var all = []
    for (let [key, value] of Object.entries(enumObject)) {
        all.push({
            id: key,
            value,
        })
    }
    return all
}
