import MultipleBookPage from "./page"
import React, { useEffect, useState } from 'react';
import moment from "moment";
import i18next from "i18next";
import { withSpinner } from "./dayButton";
import AlertDialog from "../../alert-dialog";



const MultipleBook = ({ weekDays, open, setOpenModalSelectFrecuency, tripIdToPlan, processMultipleTrip, isPending, requestPickUpStartTime }) => {
    const [daysOfWeek, setDaysOfWeek] = useState([])
    const [startDate] = useState(moment().toDate())
    const [endDate, setEndDate] = useState(startDate)

    useEffect(() => {
        setDaysOfWeek([])
    }, [open])

    useEffect(() => {
        if (requestPickUpStartTime) {
            const dateTimeOfTrip = moment(requestPickUpStartTime)
            const today = moment()
            setEndDate(moment.max(today, dateTimeOfTrip).add(1, 'week').toDate())
        }
    }, [requestPickUpStartTime])

    const handleAccept = () => {
        const body = {
            tripId: tripIdToPlan,
            weekDays: daysOfWeek,
            endDate: endDate.toISOString(),
            lang: i18next.language
        }
        processMultipleTrip(body)
    }

    const MultipleBook = withSpinner(MultipleBookPage)

    return (
        <AlertDialog
            open={open}
            maxWidth={'xs'}
            onClickCancel={() => setOpenModalSelectFrecuency(false)}
            onClickAccept={() => handleAccept()}
            fullWidth
            conditionDisableCancel={isPending}
            conditionDisableAccept={!daysOfWeek.length > 0 || isPending}
            title={isPending ? i18next.t('trips.multipleBook.requestingTrips') : i18next.t('trips.multipleBook.title')}
            content={() =>
                <MultipleBook
                    isPending={isPending}
                    endDate={endDate}
                    weekDays={weekDays}
                    setEndDate={setEndDate}
                    setDayOffWeek={(newValue) => setDaysOfWeek(newValue)}
                    daysOfWeek={daysOfWeek}
                    minDate={startDate}
                />}

        />
    )
}

export default MultipleBook