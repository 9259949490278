import React from 'react'
import { tripActions } from '../../../../redux/actions/trip_actions';
import store from '../../../../redux/store';
import FullCostService from './fullCost';
import PricePaidByCustomer from './pricePaidByCustomer';
import OnlyServiceCost from './serviceCost';


const KPI_SELECTED = { 'TOTAL_COST': 'fullCostService', 'PAID_BY_CUSTOMER': 'rateByUser', 'SERVICE_COST_ONLY_TOWN_HALL': 'serviceCost' }

// totalUsers: "Numero total de usuarios"

export const getDataForEconomyData = (kpiSelected, chartProps) => {
    console.log("kpiSelected", kpiSelected)
    const { startDate, finishDate } = chartProps;
    if (kpiSelected === KPI_SELECTED.PAID_BY_CUSTOMER || kpiSelected === KPI_SELECTED.TOTAL_COST || kpiSelected === KPI_SELECTED.SERVICE_COST_ONLY_TOWN_HALL) {
        return store.dispatch(tripActions.getConfirmedTripsKPI(startDate, finishDate)).then(
            results => {
                return results
            },
            error => {
                console.log("error errorGetUsersData => ", error)
                return null
            }
        )
    } /* else if (kpiSelected == KPI_SELECTED.TOTAL_TRIPS_BY_USER) {
        const { startDate, finishDate } = chartProps;
        return store.dispatch(tripActions.getConfirmedTripsKPI(startDate, finishDate)).then(
            results => {
                return results
            },
            error => {
                console.log("error errorGetUsersData => ", error)
                return null
            }
        )
    } */
    return []
}

export const getChartForEconomyData = (kpiSelected, chartProps, data) => {
    const { chartType, title, groupedBy, value, filterByDate, startDate, finishDate, typologySelected } = chartProps;
    if (kpiSelected === KPI_SELECTED.PAID_BY_CUSTOMER) {
        return (
            <PricePaidByCustomer
                groupedBy={groupedBy}
                chartType={chartType}
                data={data}
                value={value}
                title={title}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                typologySelected={typologySelected}
            />
        )
    } else if (kpiSelected === KPI_SELECTED.TOTAL_COST) {
        return (
            <FullCostService
                groupedBy={groupedBy}
                chartType={chartType}
                data={data}
                value={value}
                title={title}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                typologySelected={typologySelected}

            />
        )
    } else if (kpiSelected === KPI_SELECTED.SERVICE_COST_ONLY_TOWN_HALL) {
        return (
            <OnlyServiceCost
                groupedBy={groupedBy}
                chartType={chartType}
                data={data}
                value={value}
                title={title}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                typologySelected={typologySelected}
            />
        )
    }

    return null
}
