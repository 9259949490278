import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from "react-google-charts";
import { FilterOptions, getChartName, options, DownloadChart } from '../../../customFunctions';

const getColumnName = () => {
    let columnName = "Vehicles";
    return columnName
}

const filteredData = (data, filterOptions) => {
    const { groupedBy, value } = filterOptions
    let numActiveVechicles = 0;
    let nameOfVehicles = []
    console.log("groupedby => ", groupedBy)
    if (Array.isArray(value)) {
        let listOfActiveVehicles = []
        value.forEach(element => {
            let numActiveVechicles = 0;
            let nameOfVehicles = []
            
            data.forEach(service => {
                if (service[groupedBy] && service[groupedBy].find(el => el == element) != undefined) {
                    numActiveVechicles++;
                    if(numActiveVechicles < 10)
                        nameOfVehicles.push(service.displayName)
                    else if(numActiveVechicles == 10)
                        nameOfVehicles.push("...")
                }
            });
            if (listOfActiveVehicles.find(el => el == element) == undefined) {
                if (value.length < 7 && groupedBy !== 'tenants') {
                    listOfActiveVehicles.push([{ v: element + '\n' + nameOfVehicles.join('\n'), f: element }, { v: numActiveVechicles, f: numActiveVechicles }]);
                } else {
                    listOfActiveVehicles.push([{ v: '' + '\n', f: element }, { v: numActiveVechicles, f: numActiveVechicles }]);
                }
            }
        });
        let result = [
            [getChartName(groupedBy), getColumnName()],
        ];
        listOfActiveVehicles.forEach(el => {
            result.push([el[0], el[1]])
        });
        console.log("result => ", result)
        return result;

    } else {
        data.forEach(element => {
            if (element[groupedBy] && element[groupedBy].find(el => el == value) != undefined) {
                numActiveVechicles++
                nameOfVehicles.push(element.name)
            }
        });
        return [
            [getChartName(groupedBy), getColumnName()],
            [value + ': ' + nameOfVehicles.join(', '), { v: numActiveVechicles, f: numActiveVechicles }]
        ];
    }
}

/**
 * CASES
 *  groupedBy: services & value != null (value can be string or array (dictionary of strings))
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const VehiclesPerServiceChart = ({
    data,
    chartType = "Bar",
    title = "Nombre de vehicles",
    groupedBy = "municipalities",
    value = [],
    filterByDate = false,
    startDate = "2022-01-01",
    finishDate = "2022-02-01"
}) => {

    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate);

    return (
        <>
            <Chart
                chartType={chartType}
                graphID={'chart_0'}
                width="100%"
                height="400px"
                data={filteredData(data, filterOptions)}
                options={options(chartProps)}
            />
            <DownloadChart key={Math.random()} img64={0} />
        </>
    );
}

VehiclesPerServiceChart.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(["municipalities", "regions", "otps", "tenants"]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string,
    finishDate: PropTypes.string,
}

export default VehiclesPerServiceChart;