import { driverConstants } from '../../constants/driver_constants'
import { servicesConstants } from '../../constants/services_constants'
import { vehiclesConstants } from '../../constants/vehicles_constants'

let initialState = {
    pending: false,
    typePending: false,
    allServices: [],
    results: [],
    typesService: [],
    typesPrices: [],
    serviceTypes: [],
    typeTypologies: [],
    typePeriodicities: [],
    anticipationTypes: [],
    routes: [],
    allRoutes: [],
    journeys: [],
    oldJourneys: [],
    allJourneys: [],
    routeStops: [],
    journeyTrips: [],
    zonesStops: [],
    shiftDays: [],
    requestShiftDays: [],
    weekDays: [],
    typesTrips: [],
    service_filterOTP: [],
    service_filters: null,
    route_filterDate: '',
    route_filterOTP: [],
    route_filterService: [],
    route_filterNumStop: [],
    route_filterVehicle: [],
    route_filters: null,
    journey_filterDate: '',
    journey_filterOTP: [],
    journey_filterService: [],
    journey_filterNumStop: [],
    journey_filterVehicle: [],
    journey_filterNumPassenger: [],
    kpiTypes: [],
    kpisByType: {},
    kpiFilters: {},
    journey_filters: null,
    filterJourneyStartDate: new Date(),
    filterJourneyEndDate: new Date(),
    ranges: [
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ],
    workingRanges: [
        {
            startDate: new Date(),
            endDate: new Date(),
            shiftHours: [],
            key: 'selection',
        },
    ],
    serviceLineSingleStops: null,
    serviceLineReturnStops: null,
    serviceBilling: [],
    calendar: null,
    serviceList: [],
    tableFilters: [],
    journeysTableFilters: []
}

export function serviceReducer(state = initialState, action) {
    switch (action.type) {
        case servicesConstants.SET_SERVICE_DATA_EDIT:
            const { id, name } = action.body
            let updatedService = state.service
            let updatedServiceResponse = state.service.serviceResponse
            updatedServiceResponse.otpId = id
            updatedServiceResponse.otpName = name
            return {
                ...state,
                service: {
                    ...updatedService,
                    serviceResponse: updatedServiceResponse,
                    vehiclesResponse: [],
                },
            }
        case servicesConstants.DETAIL_REQUEST:
            return {
                ...state,
                pending: true,
                ranges: [
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection',
                    },
                ],
                workingRanges: [
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        shiftHours: [],
                        key: 'selection',
                    },
                ],
                service: null,
            }
        case servicesConstants.DETAIL_SUCCESS:
            if (action.service.serviceResponse.calendar != null) {
                if (action.service.serviceResponse.calendar.shiftDays.length == 0 || action.service.serviceResponse.calendar.requestShiftDays.length == 0)
                    action.service.serviceResponse.calendar = {
                        excludedPeriods: action.service.serviceResponse.calendar.excludedPeriods,
                        workingBankHolidays: action.service.serviceResponse.calendar.workingBankHolidays,
                        shiftDays: action.service.serviceResponse.calendar.shiftDays,
                        requestShiftDays:
                            action.service.serviceResponse.calendar.requestShiftDays.length == 0
                                ? [
                                    {
                                        weekDays: [],
                                        earliestStart: '',
                                        latestArrival: '',
                                        endsNextDay: true,
                                    },
                                ]
                                : action.service.serviceResponse.calendar.requestShiftDays,
                    }
            }
            return {
                ...state,
                service: action.service,
                pending: false,
            }
        case servicesConstants.DETAIL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results,
                calendar: null,
                allServices: action.all,
            }
        case servicesConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        case servicesConstants.ADD_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.ADD_SUCCESS:
            return {
                ...state,
                pending: false,
                area: action.area,
            }
        case servicesConstants.ADD_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_TYPES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                typesService: action.types,
            }
        case servicesConstants.GET_TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_SERVICES_TYPES_REQUEST:
            return {
                ...state,
                typePending: true,
            }
        case servicesConstants.GET_SERVICES_TYPES_SUCCESS:
            return {
                ...state,
                typePending: false,
                serviceTypes: action.types,
            }
        case servicesConstants.GET_SERVICES_TYPES_FAILURE:
            return {
                ...state,
                typePending: false,
                error: action.error,
            }
        case servicesConstants.GET_WEEK_DAYS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_WEEK_DAYS_SUCCESS:
            return {
                ...state,
                pending: false,
                weekDays: action.days,
            }
        case servicesConstants.GET_WEEK_DAYS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_PRICES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_PRICES_SUCCESS:
            return {
                ...state,
                pending: false,
                typesPrices: action.prices,
            }
        case servicesConstants.GET_PRICES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(x => x.id !== action.id),
            }
        case servicesConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.EDIT_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.EDIT_SUCCESS:
            return {
                ...state,
                pending: false,
            }
        case servicesConstants.EDIT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_ROUTES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_ROUTES_SUCCESS:
            return {
                ...state,
                pending: false,
                routes: action.routes,
                allRoutes: action.all,
            }
        case servicesConstants.GET_ROUTES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_ROUTES_FROM_VEHICLE_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_ROUTES_FROM_VEHICLE_SUCCESS:
            return {
                ...state,
                pending: false,
                routes: action.routes,
            }
        case servicesConstants.GET_ROUTES_FROM_VEHICLE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_SPECIFIC_ROUTE_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_SPECIFIC_ROUTE_SUCCESS:
            return {
                ...state,
                pending: false,
                route: action.route,
            }
        case servicesConstants.GET_CALENDAR_SUCCESS:
            return {
                ...state,
                calendar: action.calendar
            }
        case servicesConstants.GET_SPECIFIC_ROUTE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_ZONES_STOPS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_ZONES_STOPS_SUCCESS:
            return {
                ...state,
                pending: false,
                zonesStops: action.zones,
                typesService: action.types,
            }
        case servicesConstants.SET_ROUTE_ID:
            return {
                ...state,
                pending: false,
                routeId: action.routeId,
            }
        case servicesConstants.GET_ROUTE_ID:
            return {
                ...state,
                pending: false,
            }
        case servicesConstants.GET_ZONES_STOPS_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        case servicesConstants.SET_WORKS_DAY:
            state.calendar.workingWeekDays = action.days
            return {
                ...state,
            }
        case servicesConstants.REMOVE_EXCLUDE_DAYS:
            const filtered = state.calendar.excludedPeriods.filter((_, i) => i !== action.index)
            state.calendar.excludedPeriods = filtered
            return {
                ...state,
            }
        case servicesConstants.REMOVE_EXCLUDED_WORKING_DAYS:
            const filteredExcludedWorking = state.calendar.workingBankHolidays.filter((_, i) => i !== action.index)
            state.calendar.workingBankHolidays = filteredExcludedWorking
            return {
                ...state,
            }
        case servicesConstants.SET_EXCLUDE_DAYS:
            state.calendar.excludedPeriods.push(action.element)
            return {
                ...state,
                ranges: [
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection',
                    },
                ],
            }
        case servicesConstants.SET_RANGE_CALENDAR:
            return {
                ...state,
                ranges: action.range,
            }
        case servicesConstants.SET_EXCLUDED_WORKING_DAYS:
            state.calendar.workingBankHolidays.push(action.element)
            return {
                ...state,
                workingRanges: [
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        shiftHours: [],
                        key: 'selection',
                    },
                ],
            }
        case servicesConstants.SET_WORKING_RANGE_CALENDAR:
            return {
                ...state,
                workingRanges: action.workingRange,
            }
        case servicesConstants.ADD_TIME_REQUESTSHIFT_DAY:
            state.calendar.requestShiftDays.push({
                weekDays: [],
                earliestStart: '',
                latestArrival: '',
                endsNextDay: true,
            })
            return {
                ...state,
            }
        case servicesConstants.ADD_TIME_SHIFT_DAY:
            state.calendar.shiftDays.push({
                weekDays: [],
                earliestStart: '',
                latestArrival: '',
                endsNextDay: true,
            })
            return {
                ...state,
            }
        case servicesConstants.ADD_TIME_SHIFT_HOUR:
            state.calendar.workingBankHolidays[action.index].shiftHours.push({
                earliestStart: '',
                latestArrival: '',
                endsNextDay: true,
            })
            return {
                ...state,
            }
        case servicesConstants.REMOVE_TIME_REQUESTSHIFT_DAY:
            const filtered_request = state.calendar.requestShiftDays.filter((_, i) => i !== action.index)
            state.calendar.requestShiftDays = filtered_request
            return {
                ...state,
            }
        case servicesConstants.REMOVE_TIME_SHIFT_DAY:
            const filtered_shiftDays = state.calendar.shiftDays.filter((_, i) => i !== action.index)
            state.calendar.shiftDays = filtered_shiftDays
            return {
                ...state,
            }
        case servicesConstants.REMOVE_TIME_SHIFT_HOUR:
            const filtered_shiftHours = state.calendar.workingBankHolidays[action.index].shiftHours.filter((_, i) => i !== action.indexShiftHour)
            state.calendar.workingBankHolidays[action.index].shiftHours = filtered_shiftHours
            return {
                ...state,
            }
        case servicesConstants.SET_SHIFT_DAY:
            return {
                ...state,
                shiftDays: action.shiftDay,
            }
        case servicesConstants.SET_REQUEST_SHIFT_DAY:
            return {
                ...state,
                requestShiftDays: action.requestShiftDay,
            }
        case servicesConstants.SET_SHIFT_HOUR:
            return {
                ...state,
                shiftDays: action.shiftHour,
            }
        case servicesConstants.GET_JOURNEYS_REQUEST:
            return {
                ...state,
                pending: true,
                journeys: [],
            }
        case servicesConstants.GET_JOURNEYS_SUCCESS:
            return {
                ...state,
                pending: false,
                journeys: action.journeys,
                allJourneys: action.all,
            }
        case servicesConstants.GET_JOURNEYS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_OLD_JOURNEYS_REQUEST:
            return {
                ...state,
                pending: true,
                journeys: [],
            }
        case servicesConstants.GET_OLD_JOURNEYS_SUCCESS:
            return {
                ...state,
                pending: false,
                journeys: action.oldJourneys,
                allJourneys: action.all,
            }
        case servicesConstants.GET_OLD_JOURNEYS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_ROUTE_STOPS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_ROUTE_STOPS_SUCCESS:
            return {
                ...state,
                pending: false,
                routeStops: action.routeStops,
            }
        case servicesConstants.GET_ROUTE_STOPS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_TRIPS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.TRIPS_SUCCESS:
            return {
                ...state,
                pending: false,
                journeyTrips: action.journeyTrips,
            }
        case servicesConstants.TRIPS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_TRIPS_TYPES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_TRIPS_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                typesTrips: action.results,
            }
        case servicesConstants.GET_TRIPS_TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_JOURNEYS_OBSERVATIONS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_JOURNEYS_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                journeyActivities: action.activities,
            }
        case servicesConstants.GET_JOURNEYS_OBSERVATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                journeyActivities: [],
                error: action.error,
            }
        case servicesConstants.ADD_JOURNEYS_OBSERVATIONS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.ADD_JOURNEYS_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                journeyActivities: action.activities,
            }
        case servicesConstants.ADD_JOURNEYS_OBSERVATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                journeyActivities: [],
                error: action.error,
            }

        //NEW ACTIVITIES IN DRIVER
        case servicesConstants.GET_DRIVER_OBSERVATIONS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_DRIVER_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                driverActivities: action.activities,
            }
        case servicesConstants.GET_DRIVER_OBSERVATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                driverActivities: [],
                error: action.error,
            }
        case servicesConstants.ADD_DRIVER_OBSERVATIONS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.ADD_DRIVER_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                driverActivities: action.activities,
            }
        case servicesConstants.ADD_DRIVER_OBSERVATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                driverActivities: [],
                error: action.error,
            }

        //NEW ACTIVITIES IN CUSTOMER
        case servicesConstants.GET_CUSTOMER_OBSERVATIONS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_CUSTOMER_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                customerActivities: action.activities,
            }
        case servicesConstants.GET_CUSTOMER_OBSERVATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                customerActivities: [],
                error: action.error,
            }
        case servicesConstants.ADD_CUSTOMER_OBSERVATIONS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.ADD_CUSTOMER_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                customerActivities: action.activities,
            }
        case servicesConstants.ADD_CUSTOMER_OBSERVATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                customerActivities: [],
                error: action.error,
            }
        //NEW ACTIVITIES IN TRIP
        case servicesConstants.GET_TRIP_OBSERVATIONS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_TRIP_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                tripActivities: action.activities,
            }
        case servicesConstants.GET_TRIP_OBSERVATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                tripActivities: [],
                error: action.error,
            }
        case servicesConstants.ADD_TRIP_OBSERVATIONS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.ADD_TRIP_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                tripActivities: action.activities,
            }
        case servicesConstants.ADD_TRIP_OBSERVATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                tripActivities: [],
                error: action.error,
            }
        /** */

        case servicesConstants.SET_FILTER_SERVICE:
            let otpNameFilter = action.prop === 'OTP' ? action.values : state.service_filterOTP
            return {
                ...state,
                service_filterOTP: otpNameFilter,
                service_filters: action.filters,
            }
        case servicesConstants.CLEAN_FILTER_SERVICE:
            return {
                ...state,
                service_filterOTP: [],
                service_filters: null,
            }
        case servicesConstants.SET_FILTER_ROUTE:
            let dateFilter = action.prop === 'date' ? action.values : state.route_filterDate
            let route_otpNameFilter = action.prop === 'OTP' ? action.values : state.route_filterOTP
            let numStops = action.prop === 'numStop' ? action.values : state.route_filterNumStop
            let vehicleDisplayName = action.prop === 'vehicle' ? action.values : state.route_filterVehicle
            let serviceName = action.prop === 'service' ? action.values : state.route_filterService
            return {
                ...state,
                route_filterDate: dateFilter,
                route_filterOTP: route_otpNameFilter,
                route_filterNumStop: numStops,
                route_filterVehicle: vehicleDisplayName,
                route_filterService: serviceName,
                route_filters: action.filters,
            }
        case servicesConstants.CLEAN_FILTER_ROUTE:
            return {
                ...state,
                route_filterDate: [],
                route_filterOTP: [],
                route_filterNumStop: [],
                route_filterVehicle: [],
                route_filterService: [],
                route_filters: null,
            }
        case servicesConstants.SET_FILTER_JOURNEY:
            let JdateFilter = action.prop === 'date' ? action.values : state.journey_filterDate
            let Jroute_otpNameFilter = action.prop === 'OTP' ? action.values : state.journey_filterOTP
            let JnumPass = action.prop === 'numPass' ? action.values : state.journey_filterNumPassenger
            let JvehicleDisplayName = action.prop === 'vehicle' ? action.values : state.journey_filterVehicle
            let JserviceName = action.prop === 'service' ? action.values : state.journey_filterService
            return {
                ...state,
                journey_filterDate: JdateFilter,
                journey_filterOTP: Jroute_otpNameFilter,
                journey_filterNumPassenger: JnumPass,
                journey_filterVehicle: JvehicleDisplayName,
                journey_filterService: JserviceName,
                journey_filters: action.filters,
            }
        case servicesConstants.CLEAN_FILTER_JOURNEY:
            return {
                ...state,
                journey_filterDate: [],
                journey_filterOTP: [],
                journey_filterNumPassenger: [],
                journey_filterVehicle: [],
                journey_filterService: [],
                journeysTableFilters: [],
                journey_filters: null,
            }
        case servicesConstants.GET_TYPE_PERIODICITY_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_TYPE_PERIODICITY_SUCCESS:
            return {
                ...state,
                pending: false,
                typePeriodicities: action.periodicities,
            }
        case servicesConstants.GET_TYPE_PERIODICITY_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_TYPE_TYPOLOGY_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_TYPE_TYPOLOGY_SUCCESS:
            return {
                ...state,
                pending: false,
                typeTypologies: action.typologies,
            }
        case servicesConstants.GET_TYPE_TYPOLOGY_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_ANTICIPATION_TYPES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_ANTICIPATION_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                anticipationTypes: action.anticipations,
            }
        case servicesConstants.GET_ANTICIPATION_TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.SET_TYPOLOGY_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.SET_TYPOLOGY_SUCCESS:
            const service = state.service
            service.serviceResponse = action.serviceUpdated
            return {
                ...state,
                service: service,
                pending: false,
            }
        case servicesConstants.SET_TYPOLOGY_FAILURE:
            service = state.service
            service.serviceResponse.customerTipologies.serviceCustomerTipology = action.refreshTypology.serviceCustomerTipology
            service.serviceResponse.customerTipologies.bonusCustomers = action.refreshTypology.bonusCustomers
            return {
                ...state,
                pending: false,
                service: service,
                error: action.error,
            }
        case servicesConstants.SET_FILTER_JOURNEY_DATES:
            if (action.typeDate === 'startDate')
                return {
                    ...state,
                    filterJourneyStartDate: action.value,
                }
            else
                return {
                    ...state,
                    filterJourneyEndDate: action.value,
                }
        case servicesConstants.GET_REPORT_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_REPORT_SUCCESS:
            return {
                ...state,
                pending: false,
            }
        case servicesConstants.GET_REPORT_FAILURE:
            return {
                ...state,
                pending: false,
            }
        case driverConstants.CLEAN_SERVICES_NAME:
            return {
                ...state,
                results: [],
            }
        case servicesConstants.GET_SERVICE_LINE_REQUEST:
            return {
                ...state,
                pending: true,
                serviceLine: null,
            }
        case servicesConstants.GET_SERVICE_LINE_SUCCESS:
            return {
                ...state,
                pending: false,
                calendar: null,
                serviceLine: action.line,
            }
        case servicesConstants.GET_SERVICE_LINE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.SET_SERVICE_LINE_SUCCESS:
            return {
                ...state,
                pending: false,
                serviceLine: action.line,
            }
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_REQUEST:
            return {
                ...state,
                pending: true,
                serviceLineSingleStops: null,
            }
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_REQUEST:
            return {
                ...state,
                pending: true,
                serviceLineReturnStops: null,
            }
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_SUCCESS:
            return {
                ...state,
                serviceLineSingleStops: action.stops,
                pending: false,
            }
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_FAILURE:
            return {
                ...state,
                serviceLineSingleStops: [],
                error: action.error,
                pending: false,
            }
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_SUCCESS:
            return {
                ...state,
                serviceLineReturnStops: action.stops,
                pending: false,
            }
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_FAILURE:
            return {
                ...state,
                serviceLineReturnStops: [],
                error: action.error,
                pending: false,
            }
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.SET_SERVICE_LINE_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.SET_SERVICE_LINE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.SET_EXPEDITIONS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.SET_EXPEDITIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                serviceLine: action.expeditions
            }
        case servicesConstants.SET_EXPEDITIONS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_KPI_TYPES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_KPI_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                kpiTypes: action.results,
            }
        case servicesConstants.GET_KPI_TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                kpiTypes: [],
                error: action.error,
            }
        case servicesConstants.GET_KPIS_BY_TYPE_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_KPIS_BY_TYPE_SUCCESS:
            return {
                ...state,
                pending: false,
                kpisByType: action.results,
            }
        case servicesConstants.GET_KPIS_BY_TYPE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                kpisByType: [],
            }
        case servicesConstants.GET_KPIS_FILTER_GROUP_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_KPIS_FILTER_GROUP_SUCCESS:
            return {
                ...state,
                pending: false,
                kpiFilters: action.results,
            }
        case servicesConstants.GET_KPIS_FILTER_GROUP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                kpiFilters: [],
            }
        case servicesConstants.GET_KPI_TIME_UNITS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_KPI_TIME_UNITS_SUCCESS:
            return {
                ...state,
                pending: false,
                KPITimeUnits: action.results,
            }
        case servicesConstants.GET_KPI_TIME_UNITS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                KPITimeUnits: [],
            }
        case servicesConstants.GET_KPI_CHANNEL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_KPI_CHANNEL_SUCCESS:
            return {
                ...state,
                pending: false,
                kPITripOrigins: action.results,
            }
        case servicesConstants.GET_KPI_CHANNEL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                kPITripOrigins: [],
            }
        case vehiclesConstants.DETAIL_REQUEST:
            return {
                ...state,
                results: [],
            }
        case servicesConstants.GET_BILLING_REQUEST:
            return {
                ...state,
                pending: true,
                serviceBilling: null,
            }
        case servicesConstants.GET_BILLING_SUCCESS:
            return {
                ...state,
                pending: false,
                serviceBilling: action.results,
            }
        case servicesConstants.GET_BILLING_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_SERVICE_LIST_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_SERVICE_LIST_SUCCESS:
            return {
                ...state,
                pending: false,
                serviceList: action.results,
            }
        case servicesConstants.GET_SERVICE_LIST_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.SAVE_TABLE_FILTERS:
            return {
                ...state,
                tableFilters: action.tableFilters
            }
        case servicesConstants.SAVE_JOURNEYS_TABLE_FILTERS:
            return {
                ...state,
                journeysTableFilters: action.journeysTableFilters
            }
        default:
            return state
    }
}
