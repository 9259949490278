import React from 'react';
import { Chart } from "react-google-charts";
import PropTypes from 'prop-types';
import { FilterOptions, getChartName, options, DownloadChart } from '../../../customFunctions';

const getColumnName = () => {
    let columnName = "Serveis";
    return columnName
}

const filteredData = (data, filterOptions) => {
    const { groupedBy, value } = filterOptions
    //console.log("groupedBy => ", groupedBy)
    let numActiveServices = 0;
    let nameOfServices = []
    if (Array.isArray(value)) {
        let listOfActiveServices = []

        //element = value of groupedBy
        value.forEach(element => {
            let numActiveServices = 0;
            let nameOfServices = []
            data.forEach(service => {
                if (service[groupedBy]?.find(el => el == element) != undefined) {
                    numActiveServices++;
                    nameOfServices.push(service.name)
                }
            });
            if (listOfActiveServices.find(el => el == element) == undefined) {
                //si se filtra por muchos valores no se agregan los nombre del servicio en el chart
                if (value.length < 7 && groupedBy !== 'tenants' && nameOfServices.length <= 20) {
                    listOfActiveServices.push([{ v: element + '\n' + nameOfServices.join('\n'), f: element }, { v: numActiveServices, f: numActiveServices }]);
                } else {
                    listOfActiveServices.push([{ v: '\n', f: element }, { v: numActiveServices, f: numActiveServices }]);
                }

            }
        });
        let result = [
            [getChartName(groupedBy), getColumnName()],
        ];
        listOfActiveServices.forEach(el => {
            result.push([el[0], el[1]])
        });
        console.log("result => ", result)
        return result;

    } else {
        data.forEach(element => {
            if (element[groupedBy]?.find(el => el == value) != undefined) {
                numActiveServices++
                nameOfServices.push(element.name)
            }
        });
        return [
            [getChartName(groupedBy), getColumnName()],
            [value + ': ' + nameOfServices.join(', '), { v: numActiveServices, f: numActiveServices }]
            //[X,Y]
        ];
    }
}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const ActiveServicesChart = ({
    data,
    chartType = "Bar",
    title = "Nombre de serveis actius",
    groupedBy = "municipalities",
    value = [],
    filterByDate = false,
    startDate = "2022-01-01",
    finishDate = "2022-02-01"
}) => {

    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate);

    return (
        <>
            <Chart
                chartType={chartType}
                graphID={'chart_0'}
                width="100%"
                height="400px"
                data={filteredData(data, filterOptions)}
                options={options(chartProps)}
            />
            <DownloadChart key={Math.random()} img64={0} />
        </>

    );
}

ActiveServicesChart.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(["municipalities", "regions", "otps", "tenants"]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string,
    finishDate: PropTypes.string,
}

export default ActiveServicesChart;