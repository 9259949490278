import React, { useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import ModalConfirmZone from './modalConfirm';
import AlertDialog from '../../alert-dialog';
import { factory } from '../../../helpers/factory';
import { Grid, Typography, ListItem, ListItemText, List } from "@material-ui/core";
import stringConstants from '../../../constants/strings';
import store from "../../../redux/store";
import { alertActions } from "../../../redux/actions/alert_actions";
import Spinner from "../spinner";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";

const Map = ({
    defaultCenter = {
        lat: 41.390205,
        lng: 2.154007
    },
    libraries = ["drawing,places"],
    mapType,
    onMapTypeChange,
    children,
    ...rest
}) => {
    return (
        <GoogleMapReact
            {...rest}
            bootstrapURLKeys={{
                key: stringConstants.API_Key_GoogleMaps,
                libraries,
            }}
            options={{
                styles: [],
                mapTypeId: mapType,
                mapTypeControl: true,
                streetViewControl: true,
                rotateControl: true,
                scaleControl: true,
            }}
            defaultCenter={defaultCenter}
            defaultZoom={12}
            onMapTypeIdChange={onMapTypeChange}
        >
            {children}
        </GoogleMapReact>
    );
};

const MapsGeofence = ({
    toolsAvaiable = true,
    onAccept,
    onEdit,
    t,
    info,
    clear,
    onRemove,
    data = {},
    displayOnly = true,
    mapStateLoading,
}) => {
    const mapInstance = useRef();
    const mapApi = useRef();

    const [nameZone, setZoneName] = useState('')
    const [actionName, setActionName] = useState(t('shared.map.actions.edit'))
    const [polygonData, setpolygonData] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedShapeObject, setselectedShapeObject] = useState({});
    const [isDialogConfirmActionOpen, setisDialogConfirmActionOpen] = useState(false)
    const [isDialogDeleteOpen, setisDialogDeleteOpen] = useState(false)
    const [idZoneToDelete, setidZoneToDelete] = useState('')
    const [idZoneDBToDelete, setidZoneDBToDelete] = useState('')
    const [clickShowMunicipies, setClickShowMunicipies] = useState(false)
    const [loadingMunicipies, setLoadingMunicipies] = useState(false)
    const selectedShape = useRef();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [mapType, setMapType] = useState("roadmap");
    var storePolygons = [];

    const formatToDisplayPolygons = (data) => {
        let updatedList = [];
        data &&
            data.map((d) => {
                updatedList.push({
                    id: Math.random(),
                    name: d.name,
                    idDB: d.id,
                    zones: d.zoneServices,
                    coord: d.points.map(p => {
                        return {
                            lat: p.lat,
                            lng: p.lon
                        }
                    })
                });
            });
        return updatedList;
    };

    const Content = (zones) => (
        <React.Fragment>
            <Grid container>
                <Grid item md={12}>
                    <Typography>{t('otp.page.alert_dialog.servicesAssociated')}</Typography>
                    <List>
                        {
                            zones.map(({ item1, _ }, index) => (
                                <ListItem key={item1 + index}>
                                    <ListItemText>{item1}</ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </Grid>
            </Grid >
        </React.Fragment >
    )

    const Error = (errorServices) => (
        <React.Fragment>
            <Grid container>
                <Grid item md="12">
                    <Alert severity="error">
                        {t('shared.modalDelete.error')} {errorServices.map(service => service.serviceName + " ")}
                    </Alert>
                </Grid>
            </Grid>
        </React.Fragment>
    )

    const deleteZone = (idDB, id, selectedShapeObject) => {
        onRemove(idDB)
        setpolygonData(polygonData.filter(cf => cf.id != id))
        selectedShapeObject.setMap(null)
        setisDialogDeleteOpen(false)
        clearSelection()
    }

    const handleConfirmAction = () => {
        let bodyAreas = []
        selectedShapeObject
            .getPath()
            .getArray()
            .map((p) => {
                bodyAreas.push({ lat: p.lat(), lon: p.lng() })
            })
        if (isUpdate) {
            const plg = factory.createZone(selectedShapeObject.idDB, nameZone, bodyAreas)
            onEdit(plg)
        } else {
            const plg = factory.createZone('', nameZone, bodyAreas)
            onAccept(plg, () => removePolygon(selectedShape.current))
        }
        setZoneName('')
        setisDialogConfirmActionOpen(false)
        setselectedShapeObject(null)
    };

    const findAndUpdateData = (data, action) => {
        const selectedShapeId = selectedShape.id;
        let updatedList;
        switch (action) {
            case "set_at":
            case "insert_at":
            case "dragend":
                updatedList = [
                    ...storePolygons.filter((p) => p.id != selectedShapeId),
                    data,
                ];
                storePolygons = updatedList;
                setpolygonData(storePolygons);
                break;
            case "radius_changed":
            default:
                break;
        }
    };

    const createPolygonWithEvents = (p, map) => {
        const polygon = new mapApi.current.Polygon({
            paths: p.coord,
            strokeColor: '#0000FF',
            fillColor: "#0000FF",
            id: p.id,
            zones: p.zones,
            name: p.name,
            idDB: p.idDB
        });
        polygon.getPaths().forEach(function (path, index) {
            mapApi.current.event.addListener(path, "insert_at", function (e) {
                findAndUpdateData(polygon, "insert_at");
            });

            mapApi.current.event.addListener(path, "set_at", function (e) {
                findAndUpdateData(polygon, "set_at");
            });
        });

        polygon.addListener("mouseover", () => {
            closeSnackbar()
            info(p.name)
        });

        polygon.addListener("mouseout", () => {
            closeSnackbar()
            clear()
        });


        mapApi.current.event.addListener(polygon, "click", function (e) {
            displayOnly && setSelection(polygon);
        });

        mapApi.current.event.addListener(polygon, "dragend", function (e) {
            findAndUpdateData(polygon, "dragend");
        });
        storePolygons.push(polygon);
        setpolygonData(storePolygons)
        polygon.setMap(map);
    };

    const renderShapes = (map) => {
        //render polygons
        formatToDisplayPolygons(data.freeZones).map((p) => {
            createPolygonWithEvents(p, map);
        });
        setpolygonData(storePolygons)


    };

    const revertHandleClick = (map) => {
        storePolygons.map((p) => {
            p.setMap(null);
            setpolygonData(storePolygons.filter((p) => p.id != p.id))
        });
        storePolygons = polygonData
        renderShapes(map);
        selectedShape.current = null
        setselectedShapeObject(null)
    }

    const removePolygon = (selectedShape) => {
        if (!isUpdate) {
            selectedShape.setMap(null)
            setpolygonData(storePolygons.filter((p) => selectedShape.id != p.id))
        }
    }

    //custom controls drawing tools
    const clearSelection = () => {
        if (selectedShape.current) {
            if (selectedShape.current.type !== "marker") {
                selectedShape.current.setEditable(false);
                selectedShape.current.setDraggable(false);
            }
            selectedShape.current = null;
            setZoneName('')
            setIsUpdate(false)
            setselectedShapeObject(null)
        }
    }
    const setSelection = (shape) => {
        if (shape.type !== "marker") {
            clearSelection();
            shape.setEditable(true);
            shape.setDraggable(true);
        }
        if (shape.name) {
            setZoneName(shape.name)
            setActionName(t('shared.map.actions.update'))
            setIsUpdate(true)
        } else {
            setActionName(t('shared.map.actions.confirm'))
        }
        selectedShape.current = shape;
        if (selectedShape.current.zones) {
            selectedShape.current.errorServices = validateErrors(shape.zones);
        }
        setselectedShapeObject(shape)
    }

    const validateErrors = (zones) => {
        var errorServices = [];
        if (zones.length > 0 || zones !== undefined) {
            zones.forEach(({ item1, item2 }, index) => {
                if (item2 == 10 || item2 == 40) {
                    errorServices.push({ serviceName: item1, serviceType: item2 });
                }
            });
            return errorServices;
        } else {
            return errorServices;
        }
    }

    const deleteSelectedShape = () => {
        if (selectedShape.current) {
            if (selectedShape.current.id != null) {
                setidZoneDBToDelete(selectedShape.current.idDB)
                setidZoneToDelete(selectedShape.current.id)
                setisDialogDeleteOpen(true)
            }
        } else {
            store.dispatch(alertActions.error(t('shared.map.actions.notSelected')))
        }
    }

    const buttonize = (btn, type) => {
        // Set CSS for the control border.
        let color, colorHover;
        if (type === "delete") {
            color = "tomato";
            colorHover = "red";
        } else if (type === "save") {
            color = "forestgreen";
            colorHover = "green";
        } else if (type === "reverse") {
            color = "steelblue";
            colorHover = "blue";
        } else if (type === "show") {
            color = "#FFE599";
            colorHover = "#F1C232";
        } else if (type === "hide") {
            color = "#999999";
            colorHover = "#666666";
        }

        btn.type = "button";

        btn.setAttribute(
            "style",
            `
        background-color: ${color};
        cursor: pointer;
        padding: 0px 8px;
        margin: 5px;
        margin-left: -5px;
        color:white;
        font-size: 0.9em;
        font-weight: bold;
        height:28px;
        border:none;
        outline: none;
        box-shadow: none;
      `
        );

        if (type === "delete") {
            btn.innerHTML = t('shared.map.actions.delete');
        } else if (type === "save") {
            btn.innerHTML = actionName;
        } else if (type === "reverse") {
            btn.innerHTML = t('shared.map.actions.revert');
        } else if (type === "show") {
            btn.innerHTML = t('shared.map.actions.show');
        }
        else if (type === "hide") {
            btn.innerHTML = t('shared.map.actions.hide');
            btn.disabled = true
        }

        btn.addEventListener("mouseover", () => {
            btn.style.background = colorHover;
        });
        btn.addEventListener("mouseout", () => {
            btn.style.background = color;
        });
    };



    const apiHasLoaded = ({ map, maps }) => {
        mapInstance.current = map;
        mapApi.current = maps;




        // remove transit layers
        const transitLayer = new mapApi.current.TransitLayer();
        transitLayer.setMap(null);
        var polygon;


        renderShapes(map);

        //drawing tool options
        let drawingManager = null;
        if (toolsAvaiable) {
            drawingManager = new mapApi.current.drawing.DrawingManager({
                // drawingMode: mapApi.current.drawing.OverlayType.MARKER,
                drawingControl: true,
                drawingControlOptions: {
                    position: mapApi.current.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        mapApi.current.drawing.OverlayType.POLYGON
                    ],
                },
                polygonOptions: {
                    fillColor: "#ffff00",
                    id: `polygon-${Math.random()}`,
                },
            });
            mapApi.current.event.addListener(
                drawingManager,
                "overlaycomplete",
                function (event) {
                    polygon = event.overlay
                    setSelection(event.overlay);
                    if (event.type === "polygon") {
                        // createPolygonWithEvents(event.overlay)
                        storePolygons.push(event.overlay);
                    }
                    if (drawingManager.drawingMode != null)
                        setisDialogConfirmActionOpen(true);
                    drawingManager.setDrawingMode(null);
                }
            );

            toolsAvaiable && drawingManager.setMap(map);
        }

        mapApi.current.event.addDomListener(document, 'keyup', function (e) {

            var code = (e.keyCode ? e.keyCode : e.which);

            if (code === 27) {
                drawingManager.setDrawingMode(null);
                setisDialogConfirmActionOpen(false);
                if (polygon)
                    polygon.setMap(null);
                clearSelection()
            }
        });

        maps.Polygon.prototype.getGeoJSON = function () {
            let geoJSON = {
                type: "Polygon",
                coordinates: [],
                name: ''
            };

            let paths = this.getPaths().getArray();

            for (let path of paths) {
                let pathArray = [];
                let points = path.getArray();
                let firstPoint = false;

                for (let point of points) {
                    if (firstPoint === false) {
                        firstPoint = point;
                    }

                    pathArray.push([point.lng(), point.lat()])
                }

                pathArray.push([firstPoint.lng(), firstPoint.lat()]);
                geoJSON.name = this.name
                geoJSON.coordinates.push(pathArray);
            }

            return geoJSON;
        };
        let results = []
        storePolygons.map(i => results.push(i.getGeoJSON()))
        //console.log(results)

        mapApi.current.event.addListener(map, "click", function (e) {
            clearSelection();
        });

        const deleteShapeBtn = document.createElement("button");
        const saveShapeBtn = document.createElement("button");
        const revertShapeBtn = document.createElement("button");
        const showMunicipsBtn = document.createElement("button");
        const cleanMunicipsBtn = document.createElement("button");



        // add styles
        buttonize(deleteShapeBtn, "delete");
        buttonize(saveShapeBtn, "save");
        buttonize(revertShapeBtn, "reverse");
        buttonize(showMunicipsBtn, "show");
        buttonize(cleanMunicipsBtn, "hide");

        deleteShapeBtn.addEventListener("click", () => {
            deleteSelectedShape();
        });

        saveShapeBtn.addEventListener("click", () => {
            handleSaveEvent(storePolygons, selectedShape.current);
        });

        revertShapeBtn.addEventListener("click", () => {
            revertHandleClick(map)
        });

        showMunicipsBtn.addEventListener("click", () => {
            handleShowMunicips(map, drawingManager, showMunicipsBtn, cleanMunicipsBtn)
        });

        cleanMunicipsBtn.addEventListener("click", () => {
            handleCleanMunicips(map, showMunicipsBtn, cleanMunicipsBtn)
        });



        if (toolsAvaiable) {
            map.controls[mapApi.current.ControlPosition.TOP_CENTER].push(
                deleteShapeBtn
            );
            map.controls[mapApi.current.ControlPosition.TOP_CENTER].push(
                saveShapeBtn
            );
            map.controls[mapApi.current.ControlPosition.TOP_CENTER].push(
                revertShapeBtn
            );
            map.controls[mapApi.current.ControlPosition.TOP_CENTER].push(
                showMunicipsBtn
            );
            map.controls[mapApi.current.ControlPosition.TOP_CENTER].push(
                cleanMunicipsBtn
            );
        }
    };

    const handleSaveEvent = (storePolygons, selectedShape) => {
        setpolygonData(
            storePolygons.map((o) => {
                return {
                    id: Math.random(),
                    coord: o
                        .getPath()
                        .getArray()
                        .map((c) => {
                            return { lat: c.lat(), lng: c.lng() };
                        }),
                };
            })
        );
        if (selectedShape) {
            setisDialogConfirmActionOpen(true);
        } else {
            store.dispatch(alertActions.error(t('shared.map.actions.notSelected')))
        }

    };

    const handleShowMunicips = (map, drawingManager, buttonShow, buttonHide) => {
        var polygon;
        buttonShow.disabled = true
        setLoadingMunicipies(true)
        map.data.forEach(x => map.data.remove(x))
        map.data.loadGeoJson(process.env.PUBLIC_URL + "/content/municips.json", null, function () {
            setLoadingMunicipies(false)
            buttonHide.disabled = false
        })

        map.data.addListener('mouseover', function (event) {
            info(event.feature.i ? event.feature.i.nom_muni : event.feature.h.nom_muni)
            map.data.revertStyle();
            map.data.overrideStyle(event.feature, { strokeWeight: 8 });
        });




        /* map.data.addListener(
            "click",
            function (event) {
                polygon = event.overlay
                setSelection(event.overlay);
                if (event.type === "polygon") {
                    // createPolygonWithEvents(event.overlay)
                    storePolygons.push(event.overlay);
                }
                if (drawingManager.drawingMode != null)
                    setisDialogConfirmActionOpen(true);
                drawingManager.setDrawingMode(null);
            }
        ); */

        map.data.addListener('mouseout', function (event) {
            map.data.revertStyle();
            clear()
        });
    }

    const handleCleanMunicips = (map, buttonShow, buttonHide) => {
        map.data.forEach(x => map.data.remove(x))
        setClickShowMunicipies(false)
        buttonShow.disabled = false
        buttonHide.disabled = true
    }

    const onMapTypeChange = (mapType) => {
        setMapType(mapType);
    }


    return (
        <React.Fragment>
            <div style={{ height: "80vh", width: "100%" }}>
                {!mapStateLoading && (
                    <React.Fragment>
                        <Map
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={apiHasLoaded}
                            mapType={mapType}
                            onMapTypeChange={onMapTypeChange}
                        />

                    </React.Fragment>

                )}
            </div>
            <ModalConfirmZone
                open={isDialogConfirmActionOpen}
                t={t}
                zoneName={nameZone}
                isUpdate={isUpdate}
                onChangeName={setZoneName}
                onClose={() => setisDialogConfirmActionOpen(false)}
                revert={() => revertHandleClick(mapInstance.current)}
                cleanSelection={() => removePolygon(selectedShape.current)}
                clear={() => clearSelection()}
                onConfirm={handleConfirmAction}
            />
            <Spinner
                loading={loadingMunicipies}
            />
            <AlertDialog
                open={isDialogDeleteOpen}
                onClickCancel={() => setisDialogDeleteOpen(false)}
                onClickAccept={() => deleteZone(idZoneDBToDelete, idZoneToDelete, selectedShapeObject)}
                title={t('shared.modalDelete.title')}
                content={() => selectedShape.current ? Content(selectedShape.current.zones) : null}
                error={() => selectedShape.current?.errorServices.length > 0 ? Error(selectedShape.current?.errorServices) : null}
                conditionDisableAccept={selectedShape?.current?.errorServices?.length > 0}
            />
        </React.Fragment>
    );
};

export default React.memo(MapsGeofence, (prevProps, nextProps) => prevProps.mapStateLoading === nextProps.mapStateLoading && prevProps.data === nextProps.data);
