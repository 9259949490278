import React from 'react';
import GoogleMapReact from 'google-map-react';
import stringConstants from '../../../constants/strings';

class MapZoneStops extends React.Component {

    static defaultProps = {
        center: {
            lat: 41.390205,
            lng: 2.154007
        },
        zoom: 11
    };

    constructor(props) {
        super()
        this.state = {
            markers: []
        }
    }



    render() {
        const { zoneReducer } = this.props
        return (
            <div style={{ height: '100vh', width: '100%' }}>
                {
                    (this.props.points != '' && this.props.points != null) && (
                        <React.Fragment>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: stringConstants.API_Key_GoogleMaps, libraries: ["places,drawing"] }}
                                defaultCenter={this.props.center}
                                defaultZoom={this.props.zoom}
                                yesIWantToUseGoogleMapApiInternals={true}
                                onGoogleApiLoaded={({ map, maps }) => this.props.renderMarkers(map, maps)}
                            >

                            </GoogleMapReact>
                        </React.Fragment>
                    )
                }

            </div>
        );
    }
}

export default MapZoneStops;