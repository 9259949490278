import React from 'react'
import NumTotalUsers from './totalUsers/index'
import store from '../../../../redux/store';
import { customerActions } from '../../../../redux/actions/customer_actions'
import TotalTripsByUser from './totalTripsByUser';
import { tripActions } from '../../../../redux/actions/trip_actions';

const KPI_SELECTED = { 'TOTAL_USERS': 'totalUsers', 'TOTAL_TRIPS_BY_USER': 'totalTripsByUser' }

// totalUsers: "Numero total de usuarios"

export const getDataForUsersChart = (kpiSelected, chartProps) => {
    console.log("kpiSelected", kpiSelected)
    if (kpiSelected == KPI_SELECTED.TOTAL_USERS) {
        return store.dispatch(customerActions.getKPI()).then(
            results => {
                return results
            },
            error => {
                console.log("error errorGetUsersData => ", error)
                return null
            }
        )
    } else if (kpiSelected == KPI_SELECTED.TOTAL_TRIPS_BY_USER) {
        const { startDate, finishDate } = chartProps;
        return store.dispatch(tripActions.getConfirmedTripsKPI(startDate, finishDate)).then(
            results => {
                return results
            },
            error => {
                console.log("error errorGetUsersData => ", error)
                return null
            }
        )
    }
    return []
}

export const getUsersChart = (kpiSelected, chartProps, data) => {
    const { chartType, title, groupedBy, value, filterByDate, startDate, finishDate, tripOrigin } = chartProps;

    if (kpiSelected == KPI_SELECTED.TOTAL_USERS) {
        return (
            <NumTotalUsers
                groupedBy={groupedBy}
                chartType={chartType}
                data={data}
                value={value}
                title={title}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
            />
        )
    } else if (kpiSelected == KPI_SELECTED.TOTAL_TRIPS_BY_USER) {
        return (
            <TotalTripsByUser
                data={data}
                chartType={chartType}
                title={title}
                groupedBy={groupedBy}
                value={value}
                filterByDate={filterByDate}
                startDate={startDate}
                finishDate={finishDate}
                tripOrigin={tripOrigin}
            />)
    }

    return null
}
