import { MenuItem, TextField } from "@material-ui/core"
import i18next from "i18next";
import moment from "moment";
import React from 'react';
import { useEffect, useState } from "react";
import Spinner from "../../shared/spinner";
import AlertDialog from "../../alert-dialog";


export const SelectPosibleScheduleComponent = ({ value, handleClickOption, expeditions, pending }) => {
    return (
        <div style={{ width: '100%', alignItems: 'center' }} >
            <TextField
                fullWidth
                value={value}
                variant="outlined"
                select
                label={i18next.t('trips.form.modal.modalSchedule.availableTimes')}
                onChange={(event) => handleClickOption(event.target.value)}
            >
                {expeditions?.map(option =>
                    <MenuItem
                        value={option.hour}
                        key={option.hour}
                    >
                        {option.hour}
                    </MenuItem>
                )}
            </TextField>
            <Spinner
                loading={pending}
            />
        </div>


    )
}


const ModalSelectPosibleSchedule = ({ closeModalSelectScheduledPickUp, expeditions, tripReducer, open, insert, noRestriction }) => {

    const handleClickOption = (value) => {
        const optionSelected = expeditions.find(item => item.hour == value)
        const originStop = tripReducer.originStop
        const destinationStop = tripReducer.destinationStop
        const hourSplitted = value.split(":")
        let pickUpTime = moment(tripReducer.requestTrip.requestPickUpStartTime).set({ 'hours': hourSplitted[0], 'minutes': hourSplitted[1] })
        setRequestTrip(prevRequestTrip => ({
            ...tripReducer.requestTrip,
            pickUpStopId: originStop.id,
            dropOffStopId: destinationStop.id,
            regularLineId: optionSelected.regularLineId,
            expeditionId: optionSelected.expeditionId,
            requestPickUpStartTime: pickUpTime.toDate()
        }))
        setValue(value)

    }

    const [value, setValue] = useState('')
    const [requestTrip, setRequestTrip] = useState(tripReducer.requestTrip)

    const onClickAcceptExpedition = () => {
        insert(requestTrip, tripReducer.customerId, tripReducer.serviceSelected.id, noRestriction, tripReducer.requestTripError)
    }

    const onClickCancelExpedition = () => {
        closeModalSelectScheduledPickUp()
    }

    useEffect(() => {
        setValue('')
    }, [open])

    useEffect(() => {
        return () => {
            closeModalSelectScheduledPickUp()
        }
    }, [closeModalSelectScheduledPickUp])

    const requestTripObject = tripReducer.requestTrip

    return (
        <AlertDialog
            open={open}
            title={`${i18next.t('trips.form.modal.modalSchedule.title')} ${moment(requestTripObject?.requestPickUpStartTime).format("HH:mm")}`}
            onClose={() => onClickCancelExpedition()}
            onClickCancel={() => onClickCancelExpedition()}
            onClickAccept={() => onClickAcceptExpedition()}
            conditionDisableCancel={tripReducer.pending}
            conditionDisableAccept={value === '' || tripReducer.pending}
            content={() =>
                <SelectPosibleScheduleComponent
                    handleClickOption={handleClickOption}
                    expeditions={expeditions}
                    value={value}
                    pending={tripReducer.pending}
                />
            }
        />
    )
}

export default ModalSelectPosibleSchedule