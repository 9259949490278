import React from 'react'
import GoogleMapReact from 'google-map-react';
import stringConstants from '../../../constants/strings';
import { Grid } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import ReactDOM from "react-dom";
import { positionService } from '../../../services/positionService';
import { googleMapsService } from '../../../services/googleMapsService';
import i18next from 'i18next';
import CancelIcon from '@material-ui/icons/Cancel';
import store from '../../../redux/store';
import { alertActions } from '../../../redux/actions/alert_actions';
import { withTheme } from '@material-ui/core/styles';
import { REGULAR_SERVICES_TYPES, SERVICES_TYPE, ZONES_STOPS_TYPE } from '../../../constants/types';

const ORIGIN = 0;


class TripMap extends React.Component {

    static defaultProps = {
        center: {
            lat: 41.390205,
            lng: 2.154007
        },
        zoom: 11
    };

    constructor(props) {
        super()
        this.state = {
            markers: [],
            possibleOrigins: [],
            possibleDestinations: [],
            direction: 'outbound'

        }
        window.originMarkers = []
        window.destinationsMarkers = []
        this.showMarkers = this.showMarkers.bind(this)
        this.apiHasLoaded = this.apiHasLoaded.bind(this)
        this.bounds = this.bounds.bind(this)
    }

    componentWillUnmount() {
        if (this.props.tripReducer.tripSuccess)
            this.props.clearAllMarkers()
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
            localStorage.removeItem('vehiclePosition');
        }
    }

    apiHasLoaded(map, maps, props) {

        const directionsService = new maps.DirectionsService();
        const directionsDisplay = new maps.DirectionsRenderer();
        this.setState({ map: map })
        this.state.markers.forEach(element => {
            element.setMap(null)
        });
        const { tripReducer, zoneReducer, t } = this.props
        if (tripReducer.trip != null) {
            directionsService.route({
                origin: { lat: tripReducer.trip.pickUpLocation.lat, lng: tripReducer.trip.pickUpLocation.lon },
                destination: { lat: tripReducer.trip.dropOffLocation.lat, lng: tripReducer.trip.dropOffLocation.lon },
                travelMode: 'DRIVING'
            }, (response, status) => {
                if (status === 'OK') {
                    directionsDisplay.setDirections(response);
                    console.log(response.routes[0].overview_path, 'Ruta')
                    const routePolyline = new maps.Polyline({
                        path: response.routes[0].overview_path,
                        strokeColor: 'black',
                        fillOpacity: 0,
                        fillColor: 'white'
                    });
                    routePolyline.setMap(map);
                    let latlngList = []
                    let points = response.routes[0].overview_path
                    points.forEach(element => {
                        latlngList.push(new maps.LatLng({ lat: element.lat(), lng: element.lng() }));
                    });
                    let markerStart = new maps.Marker({
                        map: map,
                        animation: maps.Animation.DROP,
                        icon: 'http://maps.google.com/mapfiles/kml/paddle/go.png',
                        position: points[0]
                    });
                    let markerEnd = new maps.Marker({
                        map: map,
                        animation: maps.Animation.DROP,
                        icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-circle.png',
                        position: points[points.length - 1]
                    });
                    const trip = tripReducer.trip
                    // if it´s accepted or onGoing, must show the vehicle
                    if ([50, 51, 52, 53, 80, 110, 111].includes(trip.status)) {
                        positionService.getPositionForTrip(trip.vehicleId, trip.schedulePickUpTime, trip.scheduleDropOffTime).then(
                            position => {
                                let url_available = process.env.PUBLIC_URL + "/content/img/bus_available.png"
                                localStorage.setItem('vehiclePosition', JSON.stringify(position))
                                let vehicle = new window.google.maps.Marker(
                                    {
                                        icon: {
                                            url: url_available,
                                            scaledSize: new maps.Size(50, 50)
                                        },
                                        position: { lat: position.latitude, lng: position.longitude },
                                        key: 'vehicle',
                                        map
                                    }
                                )
                                const item = { 'id': 'vehicle', 'item': vehicle }
                                this.state.markers.push(item)
                            },
                            error => {
                                if (this.state.markers.find(x => x.id === 'vehicle') != null) {
                                    const marker = this.state.markers.find(x => x.id === 'vehicle').item;
                                    if (marker != null) {
                                        marker.setMap(null)
                                    }
                                }
                            }
                        )
                        let intervalId = setInterval(() => this.refreshVehiclePosition(trip.vehicleId, trip.schedulePickUpTime, trip.scheduleDropOffTime, map), stringConstants.TIME_REFRESH_VEHICLE_POSITION)
                        this.setState({ intervalId: intervalId })
                    }
                    let infoWindowStart = new maps.InfoWindow({ pixelOffset: new window.google.maps.Size(0, -70), });
                    let infoWindowEnd = new maps.InfoWindow({ pixelOffset: new window.google.maps.Size(0, -70), });
                    markerStart.addListener('mouseover', showInfoStart);
                    markerEnd.addListener('mouseover', showInfoEnd);
                    markerStart.addListener('mouseout', () => {
                        setTimeout(() => {
                            infoWindowStart.close()
                        }, 50);
                    })
                    markerEnd.addListener('mouseout', () => {
                        setTimeout(() => {
                            infoWindowEnd.close()
                        }, 50);
                    });
                    function showInfoStart(event) {
                        let contentString = t('trips.maps.index.timePickUp') + (tripReducer.trip.actualPickUpTime != null ? moment(tripReducer.trip.actualPickUpTime).format("DD/MM/YYYY HH:mm") : moment(tripReducer.trip.schedulePickUpTime).format("DD/MM/YYYY HH:mm"))
                        // Replace the info window's content and position.
                        if (tripReducer.originDistanceTime != null) {
                            contentString = contentString + "<br/>" + i18next.t('trips.maps.index.distance') + tripReducer.originDistanceTime.distance.text + " / " + i18next.t('trips.maps.index.timeToDestination') + tripReducer.originDistanceTime.duration.text;
                        }
                        infoWindowStart.setContent(contentString);
                        infoWindowStart.setPosition(event.latLng);

                        infoWindowStart.open(map);
                    }
                    function showInfoEnd(event) {
                        let contentString = t('trips.maps.index.timeDropOff') + moment(tripReducer.trip.scheduleDropOffTime).format("DD/MM/YYYY HH:mm")
                        // Replace the info window's content and position.
                        if (tripReducer.destinationDistanceTime != null) {
                            contentString = contentString + "<br/>" + i18next.t('trips.maps.index.distance') + tripReducer.destinationDistanceTime.distance.text + " / " + i18next.t('trips.maps.index.timeToDestination') + tripReducer.destinationDistanceTime.duration.text;
                        }
                        infoWindowEnd.setContent(contentString);
                        infoWindowEnd.setPosition(event.latLng);

                        infoWindowEnd.open(map);
                    }
                    let bounds = new maps.LatLngBounds();
                    latlngList.forEach(function (n) {
                        bounds.extend(new maps.LatLng(n.lat(), n.lng()));
                    });
                    map.setCenter(bounds.getCenter());
                    map.fitBounds(bounds);
                    markerStart.setMap(map)
                    markerEnd.setMap(map)
                } else {
                    window.alert('Directions request failed due to ' + status);
                }
            });
        }
        zoneReducer.results.forEach(element => {
            const points = []
            element.points.forEach(point => {
                points.push(
                    {
                        lat: point.lat, lng: point.lon
                    }
                )
            });
            const zone = new maps.Polygon({
                paths: points,
                strokeColor: !element.pickupAndDropOffAutoComplete ? "#0000FF" : "#999ee0",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: !element.pickupAndDropOffAutoComplete ? "#0000FF" : "#999ee0",
                fillOpacity: 0.35
            })

            zone.addListener('click', function (event) {
                if (element.pickupAndDropOffAutoComplete) {
                    if (element.stops.length >= 1) {
                        props.setOriginAndDestination(element.id, element.name, element.stops[0].point)
                        window.selectAutoCompleteOrigin = true
                        window.selectAutoCompleteDestination = true
                    } else {
                        store.dispatch(alertActions.error(t('trips.form.page.zoneEmpty')))
                    }

                }
            });
            zone.setMap(map)
        });
    }

    customControl(controlDiv, map, title, text, direction) {
        const controlUI = document.createElement("div");

        controlUI.style.backgroundColor = "#fff";
        controlUI.style.color = "#fff";
        controlUI.style.border = "0.6rem solid"
        controlUI.style.borderRadius = "3px";
        controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlUI.style.cursor = "pointer";
        controlUI.style.marginTop = "8px";
        controlUI.style.marginBottom = "22px";
        controlUI.style.textAlign = "center";
        controlUI.title = title
        controlUI.id = "div_" + direction
        controlDiv.appendChild(controlUI);

        // Set CSS for the control interior.
        const controlText = document.createElement("div");

        controlText.style.color = "rgb(25,25,25)";
        controlText.style.fontFamily = "Roboto,Arial,sans-serif";
        controlText.style.fontSize = "16px";
        controlText.style.lineHeight = "38px";
        controlText.style.paddingLeft = "5px";
        controlText.style.paddingRight = "5px";
        controlText.innerHTML = text
        controlUI.appendChild(controlText);
        if (direction == this.state.direction) {
            controlUI.style.color = this.props.theme.palette.primary.main
            controlUI.style.border = '0.6rem solid'
        }
        // Setup the click event listeners: simply set the map to Chicago.
        controlUI.addEventListener("click", () => {
            //map.setCenter(chicago);
            if (direction == "outbound") {
                window.originMarkers.forEach(function (marker) {
                    marker.setVisible(marker.outbound === true ? true : false)
                })
                this.state.direction = 'outbound'
            } else {
                window.originMarkers.forEach(function (marker) {
                    marker.setVisible(marker.return === true ? true : false)
                })
                this.state.direction = 'return'
            }
            controlUI.style.color = this.props.theme.palette.primary.main
            controlUI.style.border = '0.6rem solid'
            let oppositiveDiv = document.getElementById('div_' + (direction == 'return' ? 'outbound' : 'return'))
            if (oppositiveDiv) {
                oppositiveDiv.style.color = "#fff"
                oppositiveDiv.style.backgroundColor = "#fff"
                oppositiveDiv.style.border = "0.6rem solid"
            }

        });
    }


    showMarkers = (tripReducer) => {
        if (tripReducer.markers.length > 0) {
            let map = this.state.map
            this.state.markers.forEach(element => {
                element.setMap(null)
            });
            this.state.markers = []
            tripReducer.markers.map((marker, i) => {
                let markerObj = new window.google.maps.Marker(
                    {
                        position: { lat: marker.position.lat, lng: marker.position.lng },
                        key: i,
                        name: marker.name == ORIGIN ? 'Origen' : 'Destino',
                        map
                    }
                )
                this.state.markers.push(markerObj)
                if (this.state.map != null) {
                    this.bounds()
                }
            })
        } else {
            this.state.markers.forEach(element => {
                if (element.setMap)
                    element.setMap(null)
            });
        }
    }

    printOrigins = (tripReducer) => {
        if (tripReducer.serviceSelected && tripReducer.originStop == null) {
            let map = this.state.map
            let originPickUp = [tripReducer.requestTrip.pickUpLocation.lat, tripReducer.requestTrip.pickUpLocation.lon]
            let pin_pickUp = process.env.PUBLIC_URL + "/content/img/marker-get-on.png"
            let iconOrigin = {
                url: pin_pickUp,
                scaledSize: new window.google.maps.Size(50, 70)
            }
            let Mypin_pickUp = process.env.PUBLIC_URL + "/content/img/marker-user-blue.png";
            let MyiconOrigin = {
                url: Mypin_pickUp,
                scaledSize: new window.google.maps.Size(50, 70)
            };
            let contentString = ""
            if (map && ([SERVICES_TYPE.regularService, SERVICES_TYPE.regularService.toString()].includes(tripReducer.serviceSelected.serviceType))) {
                const centerControlDivOrigin = document.createElement("div");
                centerControlDivOrigin.style.paddingRight = '30px'
                const centerControlDivDestinations = document.createElement("div");
                if (document.getElementById('div_outbound'))
                    document.getElementById('div_outbound').hidden = tripReducer.originStop != null
                if (document.getElementById('div_return'))
                    document.getElementById('div_return').hidden = tripReducer.originStop != null
                if (tripReducer.originStop == null) {
                    this.customControl(centerControlDivOrigin, map, i18next.t('trips.maps.index.titleButton'), i18next.t('trips.maps.index.outbound'), "outbound")
                    this.customControl(centerControlDivDestinations, map, i18next.t('trips.maps.index.titleButton'), i18next.t('trips.maps.index.return'), "return")
                    if (map.controls[window.google.maps.ControlPosition.TOP_CENTER].length > 0)
                        map.controls[window.google.maps.ControlPosition.TOP_CENTER] = []
                    map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(centerControlDivOrigin);
                    if (tripReducer.serviceSelected.serviceLine.hasReturn)
                        map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(centerControlDivDestinations);
                } else {
                    map.controls[window.google.maps.ControlPosition.TOP_CENTER] = []
                }

            }

            tripReducer.possibleOrigins.map((item, i) => {
                iconOrigin = {
                    url: pin_pickUp,
                    scaledSize: new window.google.maps.Size(50, 70)
                }

                let marker = new window.google.maps.Marker(
                    {
                        position: { lat: item.point.lat, lng: item.point.lon },
                        key: i,
                        icon: item.id == 'my_address' ? MyiconOrigin : iconOrigin,
                        map,
                        return: item.return,
                        outbound: item.outbound,
                        zIndex: item.id == 'my_address' ? 100 : 0,
                        outboundIndex: item.outboundIndex,
                        returnIndex: item.returnIndex,
                        visible: (item.outbound && this.state.direction == 'return') ? false : (item.return && this.state.direction == 'outbound') ? false : true
                    }
                )
                contentString = "<b>" + item.name + "</b> / " + item.address + "<br>"
                let infoWindow = new window.google.maps.InfoWindow({
                    content: ''
                });
                let infoWindowName = new window.google.maps.InfoWindow({
                    content: ''
                });
                const isServiceLine = REGULAR_SERVICES_TYPES.includes(tripReducer.serviceSelected.serviceType)
                const noRestriction = this.props.getNoRestrictionValue()
                const setOrigen = (stop, tripReducer, isServiceLine, noRestriction) => this.props.setOrigin(stop, tripReducer, isServiceLine, noRestriction)
                const setOriginDistanceTime = (originDistanceTime) => this.props.setOriginDistanceTime(originDistanceTime)
                if (item.id != 'my_address' || ([SERVICES_TYPE.tadService, SERVICES_TYPE.assignmentWheelService].includes(tripReducer.serviceSelected.serviceType) && tripReducer.serviceSelected.originZone?.zoneType != ZONES_STOPS_TYPE.withoutStops) || (item.id == 'my_address' && ([SERVICES_TYPE.tadService, SERVICES_TYPE.assignmentWheelService].includes(tripReducer.serviceSelected.serviceType) && tripReducer.serviceSelected.originZone?.zoneType == ZONES_STOPS_TYPE.withoutStops)))
                    window.google.maps.event.addListener(marker, 'click', (function (marker, contentString, infoWindow, item, tripReducer, setOrigen) {
                        return function () {
                            infoWindowName.close()
                            let destination = [marker.position.lat(), marker.position.lng()]
                            if (!contentString.includes(i18next.t('trips.maps.index.distance')))
                                googleMapsService.calculateTimePoints(originPickUp, destination, window.google.maps.TravelMode.WALKING).then(
                                    result => {
                                        let newDiv = document.createElement("div");
                                        if (item.id != 'my_address' && !window.selectAutoCompleteOrigin)
                                            contentString = contentString + i18next.t('trips.maps.index.distance') + result[0].distance.text + " / " + i18next.t('trips.maps.index.timeToDestination') + result[0].duration.text + "<br>"
                                        const button = (
                                            <div>
                                                {ReactHtmlParser(contentString)}
                                                <div style={{ textAlign: 'center', paddingTop: 10 }}>
                                                    <button
                                                        onClick={() => {
                                                            setOriginDistanceTime(result[0])
                                                            setOrigen(item, tripReducer, isServiceLine, noRestriction)
                                                        }}
                                                    >
                                                        {i18next.t('trips.maps.markers.select')}
                                                    </button>
                                                </div>
                                            </div>);
                                        ReactDOM.render(React.Children.only(button), newDiv);
                                        infoWindow.setContent(newDiv)
                                        infoWindow.open(map, marker);
                                    }
                                )
                            else
                                infoWindow.open(map, marker);
                        };
                    })(marker, contentString, infoWindow, item, tripReducer, setOrigen));
                let stopNumber = tripReducer.serviceSelected.serviceType == SERVICES_TYPE.regularService ? (item.outboundIndex != null
                    ? (' - <b>#' + (item.outboundIndex + 1).toString() + '</b>')
                    : (' - <b>#' + (item.returnIndex + 1).toString() + '</b>'))
                    : ''
                window.google.maps.event.addListener(marker, 'mouseover', (function (marker, contentString, infoWindowName) {
                    return function () {
                        infoWindowName.setContent(contentString);
                        infoWindowName.open(map, marker);
                    };
                })(marker, item['name'] + stopNumber, infoWindowName));
                window.google.maps.event.addListener(marker, 'mouseout', function () {
                    infoWindowName.close();
                });
                window.originMarkers.push(marker)
                if (map != null) {
                    this.bounds(tripReducer)
                }

            })
        } else {
            window.originMarkers.forEach(element => {
                element.setMap(null)
            });
        }
    }

    printDestinations = (tripReducer) => {
        if (tripReducer.serviceSelected && tripReducer.originStop != null) {
            this.state.markers.forEach(element => {
                element.setMap(null)
            });
            window.originMarkers.forEach(element => {
                element.setMap(null)
            });
            let map = this.state.map
            let originDropOff = [tripReducer.requestTrip.dropOffLocation.lat, tripReducer.requestTrip.dropOffLocation.lon]
            let pin_dropOff = process.env.PUBLIC_URL + "/content/img/marker-get-off.png"
            let iconDestinations = {
                url: pin_dropOff,
                scaledSize: new window.google.maps.Size(50, 70)
            }
            let Mypin_dropOff = process.env.PUBLIC_URL + "/content/img/marker-user-blue.png";
            let MyiconDestinations = {
                url: Mypin_dropOff,
                scaledSize: new window.google.maps.Size(50, 70)
            };
            let contentString = ""
            if (map && [SERVICES_TYPE.regularService, SERVICES_TYPE.regularService.toString()].includes(tripReducer.serviceSelected.serviceType)) {
                const centerControlDivOrigin = document.createElement("div");
                centerControlDivOrigin.style.padding = 10
                const centerControlDivDestinations = document.createElement("div");
                centerControlDivDestinations.style.padding = 10
                if (tripReducer.originStop == null) {
                    if (document.getElementById('div_outbound'))
                        document.getElementById('div_outbound').hidden = false
                    if (document.getElementById('div_return'))
                        document.getElementById('div_return').hidden = false
                    this.customControl(centerControlDivOrigin, map, i18next.t('trips.maps.index.titleButton'), i18next.t('trips.maps.index.outbound'), "outbound")
                    this.customControl(centerControlDivDestinations, map, i18next.t('trips.maps.index.titleButton'), i18next.t('trips.maps.index.return'), "return")
                    if (map.controls[window.google.maps.ControlPosition.TOP_CENTER].length > 0)
                        map.controls[window.google.maps.ControlPosition.TOP_CENTER] = []
                    map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(centerControlDivOrigin);
                    if (tripReducer.serviceSelected.serviceLine.hasReturn)
                        map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(centerControlDivDestinations);
                } else {
                    map.controls[window.google.maps.ControlPosition.TOP_CENTER] = []
                    if (document.getElementById('div_outbound'))
                        document.getElementById('div_outbound').hidden = true
                    if (document.getElementById('div_return'))
                        document.getElementById('div_return').hidden = true
                }

            }
            tripReducer.possibleDestinations.map((item, i) => {
                if (tripReducer.requestTrip.dropOffLocation.lat == item.point.lat && tripReducer.requestTrip.dropOffLocation.lon == item.point.lon && item.id != 'my_address' && !window.selectAutoCompleteDestination) {
                    Mypin_dropOff = process.env.PUBLIC_URL + "/content/img/marker-get-off-blue.png"
                    MyiconDestinations = {
                        url: Mypin_dropOff,
                        scaledSize: new window.google.maps.Size(50, 70)
                    }
                    iconDestinations = MyiconDestinations
                } else {
                    iconDestinations = {
                        url: pin_dropOff,
                        scaledSize: new window.google.maps.Size(50, 70)
                    }
                }
                let marker = new window.google.maps.Marker(
                    {
                        position: { lat: item.point.lat, lng: item.point.lon },
                        key: i,
                        map,
                        icon: item.id == 'my_address' ? MyiconDestinations : iconDestinations,
                        return: item.return,
                        outbound: item.outbound,
                        zIndex: item.id == 'my_address' ? 100 : 0,
                        outboundIndex: item.outboundIndex,
                        returnIndex: item.returnIndex,
                        visible: (item.outbound && this.state.direction == 'return') ? false : (item.return && this.state.direction == 'outbound') ? false : true
                    }
                )
                const isServiceLine = tripReducer.serviceSelected.serviceType == SERVICES_TYPE.regularService
                const noRestriction = this.props.getNoRestrictionValue()
                const setDestination = (stop, tripReducer, noRestriction) => this.props.setDestination(stop, tripReducer, noRestriction)
                const setDestinationDistanceTime = (destinationDistanceTime) => this.props.setDestinationDistanceTime(destinationDistanceTime)
                contentString = "<b>" + item.name + "</b> / " + item.address + "<br>"
                let infoWindow = new window.google.maps.InfoWindow({
                    content: ''
                });
                let infoWindowName = new window.google.maps.InfoWindow({
                    content: ''
                });
                if (item.id != 'my_address' || ([SERVICES_TYPE.tadService, SERVICES_TYPE.assignmentWheelService].includes(tripReducer.serviceSelected.serviceType) && tripReducer.serviceSelected.originZone?.zoneType != ZONES_STOPS_TYPE.withoutStops) || (item.id == 'my_address' && ([SERVICES_TYPE.tadService, SERVICES_TYPE.assignmentWheelService].includes(tripReducer.serviceSelected.serviceType) && tripReducer.serviceSelected.originZone?.zoneType == ZONES_STOPS_TYPE.withoutStops)))
                    window.google.maps.event.addListener(marker, 'click', (function (marker, contentString, infoWindow, item, tripReducer, setDestination) {
                        return function () {
                            infoWindowName.close()
                            let destination = [marker.position.lat(), marker.position.lng()]
                            if (!contentString.includes(i18next.t('trips.maps.index.distance')))
                                googleMapsService.calculateTimePoints(originDropOff, destination, window.google.maps.TravelMode.WALKING).then(
                                    result => {
                                        let newDiv = document.createElement("div");
                                        if (item.id != 'my_address' && !window.selectAutoCompleteDestination)
                                            contentString = contentString + i18next.t('trips.maps.index.distance') + result[0].distance.text + " / " + i18next.t('trips.maps.index.timeToDestination') + result[0].duration.text
                                        const button = (
                                            <div>
                                                {ReactHtmlParser(contentString)}
                                                <div style={{ textAlign: 'center', paddingTop: 10 }}>
                                                    <button
                                                        onClick={() => {
                                                            setDestinationDistanceTime(result[0])
                                                            setDestination(item, tripReducer, noRestriction)
                                                        }}
                                                    >
                                                        {i18next.t('trips.maps.markers.select')}
                                                    </button>
                                                </div>
                                            </div>);
                                        ReactDOM.render(React.Children.only(button), newDiv);
                                        infoWindow.setContent(newDiv)
                                        infoWindow.open(map, marker);
                                    }
                                )
                            else
                                infoWindow.open(map, marker);

                        };
                    })(marker, contentString, infoWindow, item, tripReducer, setDestination));
                let stopNumber = isServiceLine ? (item.outboundIndex != null
                    ? (' - <b>#' + (item.outboundIndex + 1).toString() + '</b>')
                    : (' - <b>#' + (item.returnIndex + 1).toString() + '</b>'))
                    : ''
                window.google.maps.event.addListener(marker, 'mouseover', (function (marker, contentString, infoWindowName) {
                    return function () {
                        infoWindowName.setContent(contentString);
                        infoWindowName.open(map, marker);
                    };
                })(marker, item['name'] + stopNumber, infoWindowName));
                window.google.maps.event.addListener(marker, 'mouseout', function () {
                    infoWindowName.close();
                });

                window.destinationsMarkers.push(marker)
                if (map != null) {
                    this.bounds(tripReducer)
                }

            })
        } else {
            window.destinationsMarkers.forEach(element => {
                element.setMap(null)
            });
        }
    }

    bounds(tripReducer = null) {
        let points = []
        let map = this.state.map
        this.state.markers.forEach(element => {
            if (element.position) {
                points.push(element.position)
            } else {
                points.push(element.item.position)
            }
        });
        if (tripReducer && tripReducer.serviceSelected && tripReducer.originStop == null) {
            tripReducer.possibleOrigins.map((item, i) => {
                points.push(
                    { lat: item.point.lat, lng: item.point.lon }
                )
            })
        }
        if (tripReducer && tripReducer.serviceSelected && tripReducer.originStop != null) {
            tripReducer.possibleDestinations.map((item, i) => {
                points.push(
                    { lat: item.point.lat, lng: item.point.lon }
                )
            })
        }
        let bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
        }
        // Don't zoom in too far on only one marker
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            let extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
            let extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
        }
        map.fitBounds(bounds);
        /* var zoom = map.getZoom();
        map.setZoom(zoom); */

    }


    refreshVehiclePosition(vehicle_id, pickUpDate, dropOffDate, map) {
        let now = moment().utc()
        let pickUp = moment(pickUpDate).utc().subtract(30, 'minutes')
        let dropOff = moment(dropOffDate).utc().add(30, 'minutes')
        if (pickUp.isSameOrBefore(now) && dropOff.isSameOrAfter(now)) {
            this.props.getPositionForTrip(vehicle_id, pickUpDate, dropOffDate)
            const position = JSON.parse(localStorage.getItem('vehiclePosition'));
            if (position != null) {
                if (this.state.markers.find(x => x.id === 'vehicle') != null) {
                    const marker = this.state.markers.find(x => x.id === 'vehicle').item;
                    if (marker != null) {
                        marker.setPosition(new window.google.maps.LatLng(position.latitude, position.longitude));
                        marker.setMap(map)
                        //this.bounds()
                    }
                }
            } else {
                if (this.state.markers.find(x => x.id === 'vehicle') != null) {
                    const marker = this.state.markers.find(x => x.id === 'vehicle').item;
                    if (marker != null) {
                        marker.setMap(null)
                    }
                }
            }
        }

    }




    render() {
        const { tripReducer, t } = this.props
        return (
            <React.Fragment>
                <Grid item md={12} style={{ width: '100%', overflow: 'hidden', height: '100vh' }}>
                    {
                        tripReducer.originStop != null && tripReducer.serviceSelected ?
                            <Grid item md={12} style={{ padding: 20 }}>
                                <h3> {t('trips.maps.markers.originSelected') + tripReducer.originStop.address} <CancelIcon style={{ cursor: 'pointer' }} onClick={() => this.props.setOrigin(null, tripReducer, REGULAR_SERVICES_TYPES.includes(tripReducer.serviceSelected.serviceType))} /></h3>
                            </Grid>
                            : null
                    }
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: stringConstants.API_Key_GoogleMaps, libraries: ['places'] }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps, this.props)}
                    >
                        {this.showMarkers(tripReducer)}
                        {this.printOrigins(tripReducer)}
                        {this.printDestinations(tripReducer)}
                    </GoogleMapReact>
                </Grid>
                <div id="iwc" />
            </React.Fragment>
        );
    }
}

export default withTheme(TripMap);