import { useDispatch, useSelector } from "react-redux"
import { kpiFiltersActions } from "../../../redux/actions/kpiFilters_action"
import { useEffect, useState } from "react"
import moment from "moment"
import { KPIS_PAGES, KPI_FILTER_OPTIONS } from "../../../constants/types"
import { kpiActions } from "../../../redux/actions/kpi_action"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

export function useFilters({ currentKPI }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [filterByOptions, setFilterByOptions] = useState([])

    const { dateStart, dateEnd, type, filterBy } = useSelector(state => state.kpiFiltersReducer);

    const { serviceList } = useSelector(state => state.serviceReducer)
    const otps = useSelector(state => state.otpReducer.results)
    const tenants = useSelector(state => state.tenantReducer.results)
    const regions = useSelector(state => state.areaReducer.regions)
    const municipalities = useSelector(state => state.areaReducer.municipalities)

    const setDateStart = (date) => {
        dispatch(kpiFiltersActions.setDateStart(date))
    }

    const setDateEnd = (date) => {
        dispatch(kpiFiltersActions.setDateEnd(date))
    }

    const setType = (type) => {
        dispatch(kpiFiltersActions.setFilterBy([]))
        dispatch(kpiFiltersActions.setType(type))
    }

    const setFilterBy = (filterBy) => {
        dispatch(kpiFiltersActions.setFilterBy(filterBy))
    }

    const dispatchDataForKPI = ({ dateStart, dateEnd, filterBy }) => {
        const commonPayload = { type, data: filterBy };

        switch (currentKPI) {
            case KPIS_PAGES.summary:
                dispatch(kpiActions.getCustomersKPI(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getReservationsKPI(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getAlertsKPI(dateStart, dateEnd, commonPayload));
                break;
            case KPIS_PAGES.journey:
                dispatch(kpiActions.getJourneysKPI(dateStart, dateEnd, commonPayload, [20, 30], i18next.language));
                break;

            case KPIS_PAGES.reservations:
                dispatch(kpiActions.getReservationsKPI(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getTripBookingKPI(dateStart, dateEnd, commonPayload, [20, 30], i18next.language))
                break;
            case KPIS_PAGES.trips:
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getConfirmedGroupedTripsKPI(dateStart, dateEnd, commonPayload, [10, 20, 30, 40, 50], i18next.language));
                break;
            case KPIS_PAGES.users:
                dispatch(kpiActions.getCustomersKPI(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                break;
            case KPIS_PAGES.drivers:
                dispatch(kpiActions.getDriversKPI(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getDriverRequestsKPI(dateStart, dateEnd, commonPayload, [10, 20], i18next.language));
                dispatch(kpiActions.getJourneysKPI(dateStart, dateEnd, commonPayload, [20, 30], i18next.language));
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                dispatch(kpiActions.getDriverCalendarKPI(dateStart, dateEnd, commonPayload, [20, 30], i18next.language));
                break;
            case KPIS_PAGES.vehicles:
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                break;
            case KPIS_PAGES.economyData:
                dispatch(kpiActions.getConfirmedTrips(dateStart, dateEnd, commonPayload));
                break;
            default:
                break;
        }
    };

    const onSubmit = async () => {
        dispatchDataForKPI({ dateStart, dateEnd, filterBy })
    }

    useEffect(() => {
        let filterByData = filterBy;

        if (filterByData?.length > 0) {
            dispatchDataForKPI({ dateStart, dateEnd, filterBy: filterByData });
        }

    }, [currentKPI])


    useEffect(() => {
        // init filters by default with services

        if (serviceList.length > 0 && filterBy.length === 0) {
            const res = dispatch(kpiFiltersActions.setFilterBy(serviceList.map(e => ({ label: e.name, value: e.id }))))
            dispatchDataForKPI({ dateStart, dateEnd, filterBy: res.filterBy });
        }
    }, [serviceList])

    useEffect(() => {
        const optionsMap = {
            [KPI_FILTER_OPTIONS.services]: serviceList,
            [KPI_FILTER_OPTIONS.otps]: otps,
            [KPI_FILTER_OPTIONS.tenant]: tenants,
            [KPI_FILTER_OPTIONS.region]: regions,
            [KPI_FILTER_OPTIONS.municipalities]: municipalities,
        };

        const options = optionsMap[type];
        if (options) {
            setFilterByOptions(options.map(e => ({ label: e.name, value: e.id })));
        }
    }, [type, serviceList, otps, tenants, regions, municipalities]);

    const typeOptions = [
        { label: t('commandBox.services'), value: KPI_FILTER_OPTIONS.services },
        { label: t('commandBox.otps'), value: KPI_FILTER_OPTIONS.otps },
        { label: t('commandBox.tenant'), value: KPI_FILTER_OPTIONS.tenant },
        { label: t('commandBox.region'), value: KPI_FILTER_OPTIONS.region },
        { label: t('commandBox.municipalities'), value: KPI_FILTER_OPTIONS.municipalities },
    ]

    return {
        dateStart: dateStart ? moment(dateStart, 'YYYY-MM-DD').utcOffset(0, true).toDate().toISOString() : '', setDateStart,
        dateEnd: dateEnd ? moment(dateEnd, 'YYYY-MM-DD').utcOffset(0, true).toDate().toISOString() : '', setDateEnd,
        type, setType,
        typeOptions,
        filterBy, setFilterBy,
        filterByOptions,
        onSubmit,
    }
}
