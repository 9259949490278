import i18next from "i18next"
import { multipleTripConstants } from "../../constants/multipleTrip_constants"
import { tripService } from "../../services/tripService"
import { alertActions } from "./alert_actions"
import { serviceService } from "../../services/servicesService"

export const multipleTripsActions = {
    processMultipleTrip,
    setOpenModalSelectFrecuency,
    setOpenModalShowDetails,
    cancelTripPlanned,
    processMultipleTripRetry
}

function processMultipleTrip(body) {
    return dispatch => {
        dispatch(request())
        tripService.processMultipleTrip(body).then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function request() {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_REQUEST }
    }

    function success(results) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_SUCCESS, results }
    }

    function failure(error) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_FAILURE, error }
    }
}

function processMultipleTripRetry(body, tripIdToRetry, callback) {
    return dispatch => {
        dispatch(request())
        tripService.processMultipleTripRetry(body).then(
            result => {
                dispatch(success(result, tripIdToRetry))
                if (callback)
                    callback()
                if (result.hasErrors) {
                    dispatch(alertActions.error(i18next.t('services.trips.multipleBook.retryError')))
                } else {
                    dispatch(alertActions.success(i18next.t('services.trips.multipleBook.retrySuccess')))
                }
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function request() {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_REQUEST }
    }

    function success(result, tripIdToRetry) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_SUCCESS, result, tripIdToRetry }
    }

    function failure(error) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_FAILURE, error }
    }
}

function setOpenModalSelectFrecuency(value, tripId = null, requestPickUpStartTime = null, serviceType = null, serviceId = null) {
    return dispatch => {
        if (value)
            serviceService.getServiceAnticipationMaxRequestTimeDays(serviceId).then(
                days => {
                    dispatch(request(value, tripId, requestPickUpStartTime, serviceType, days))
                }
            )
        else
            dispatch(request(value, tripId, requestPickUpStartTime, serviceType, null))
    }
    function request(value, tripId, requestPickUpStartTime, serviceType, maxRequestTimeDays) {
        return { type: multipleTripConstants.OPEN_MODAL_SELECT_FRECUENCY, value, tripId, requestPickUpStartTime, serviceType, maxRequestTimeDays }
    }
}

function setOpenModalShowDetails(value) {
    return dispatch => {
        dispatch(request(value))
    }
    function request(value) {
        return { type: multipleTripConstants.SHOW_MODAL_DETAILS, value }
    }
}

function cancelTripPlanned(tripId, callback) {
    return dispatch => {
        dispatch(request())
        tripService.cancelTrip(tripId).then(
            trip => {
                dispatch(success(tripId))
                if (trip.cancellationMinutesExpired) {
                    dispatch(alertActions.warning(i18next.t('services.trips.CancellationMinutesExpired')))
                }
                if (callback)
                    callback()
                dispatch(alertActions.success(i18next.t('services.trips.cancelSuccess')))
            },
            error => {
                dispatch(failure())
            }
        )
    }

    function success(tripId) {
        return { type: multipleTripConstants.UPDATE_LIST_TRIPS, tripId }
    }
    function request() {
        return { type: multipleTripConstants.CANCEL_TRIP_REQUEST }
    }
    function failure(error) {
        return { type: multipleTripConstants.CANCEL_TRIP_FAILURE, error }
    }

}
