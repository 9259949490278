import React from 'react';
import { Chart } from "react-google-charts";
import PropTypes from 'prop-types';
import { FilterOptions, options, DownloadChart, filterDataByGroup } from '../../../customFunctions';
import moment from 'moment';



const filteredData = (data, filterOptions, title, value, tripOrigin) => {
    const { groupedBy } = filterOptions
    let actualIndexDate = null
    data.forEach(x => {
        actualIndexDate = moment(x.schedulePickUpTime)
        x.hour = actualIndexDate.format('HH:00');
    });
    let hours = []
    Array.from(Array(24).keys()).forEach(function (item) {
        hours.push(
            item.toString().padStart(2, '0') + ':00'
        )
    })
    let results = [['Hours', title]]
    switch (tripOrigin) {
        case '10':
            data = data.filter(item => item.requestedByUser)
            break
        case '20':
            data = data.filter(item => item.requestedByCustomerService)
            break
        case '30':
            data = data.filter(item => item.requestedByIVR)
            break
        default:
            break
    }
    let dataValue = filterDataByGroup(data, groupedBy, value)
    hours.forEach(itemHour => {
        results.push(
            [
                itemHour,
                dataValue.filter(itemData => itemData.hour == itemHour).length
            ]
        )
    })
    console.log(results)
    return results;



}

/**
 * CASES
 *  groupedBy: municipalities & value != null
 *  groupedBy: regions & value != null
 *  groupedBy: otpId & value != null
 *  groupedBy: tenant & value != null
 *  filterByDate: startDate != null & finishDate != null & finishDate > startDate
 */

const BookingsTimeDistribution = ({
    data,
    title,
    groupedBy = "municipalities",
    value = [],
    filterByDate = true,
    startDate = "2022-01-01",
    finishDate = "2022-02-01",
    timeUnits,
    tripOrigin
}) => {

    const chartProps = { title, filterByDate, startDate, finishDate }
    const filterOptions = FilterOptions(groupedBy, value, filterByDate, startDate, finishDate, timeUnits);

    const chartType = "ColumnChart"

    return (
        <>
            {
                value.map(function (element, i) {
                    return (
                        filteredData(data, filterOptions, title, element).filter(([a, b]) => b > 0).length > 0 ?
                            <React.Fragment key={Math.random()}>
                                <Chart
                                    key={Math.random()}
                                    graphID={'chart_' + i.toString()}
                                    chartType={chartType}
                                    width="100%"
                                    height="400px"
                                    data={filteredData(data, filterOptions, title, element, tripOrigin)}
                                    options={options(chartProps, null, element)}
                                />
                                <DownloadChart key={Math.random()} img64={i} />
                            </React.Fragment>
                            : null
                    )
                })
            }
        </>
    );
}

BookingsTimeDistribution.propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groupedBy: PropTypes.oneOf(["municipalities", "regions", "otps", "services", "tenant"]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    filterByDate: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    finishDate: PropTypes.string.isRequired,
}

export default BookingsTimeDistribution;